﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var productionService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('production', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            Route: 'Route',
            Operation: 'Operation',
            WorkCenter: 'WorkCenter',
            WorkCenterGroup: 'WorkCenterGroup',
            WWeekTemplate: 'WWeekTemplate',
            Holiday: 'Holiday',
            BankHoliday: 'BankHoliday',
            WCalendarTemplate: 'WCalendarTemplate',
            WCalendar: 'WCalendar',
            WDay: 'WDay',
            WDaySlot: 'WDaySlot',
            WDaySlotTemplate: 'WDaySlotTemplate',
            VirtualWorkCenter: 'VirtualWorkCenter',
            WorkCenterGenerality: 'WorkCenterGenerality',
            CurrentIntervalClientData: 'CurrentIntervalClientData',
            BomRouteMapping: 'BomRouteMapping',
            VirtualWorkCenterEscape: 'VirtualWorkCenterEscape',
            WorkCenterCapacityDto: 'WorkCenterCapacityDto',

            VirtualWorkCenterDto: 'VirtualWorkCenterDto',

            // M2M
            VirtualWorkCenterM2MAppUserDto: 'VirtualWorkCenterM2MAppUserDto',
            VirtualWorkCenterM2MDrbacUserGroupDto: 'VirtualWorkCenterM2MDrbacUserGroupDto'
        };

        nbsService.call(this, manager, $q, $timeout);

        //breeze client type
        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.CurrentIntervalClientData,
                dataProperties: {
                    CurrentIntervalClientDataId: { type: breeze.DataType.Guid },
                    selectedId: { type: breeze.DataType.Int32, isNullable: false },
                    DateFrom: { type: breeze.DataType.DateTime, isNullable: false },
                    DateTo: { type: breeze.DataType.DateTime, isNullable: false }
                }
            },
            'Nembus.Domain'
        );

        // ADD Custom validator for BomRouteMapping
        function isByClassificationActivityCodeValidationFn(entity, context) {
            if (entity.getProperty('TargetActivity_IsByClassification') === false) {
                if (!entity.getProperty('ActivityCode')) { return false; } else { return true; }
            }
            return true;
        }
        function isByClassificationModuleTargetValidationFn(entity, context) {
            if (entity.getProperty('TargetActivity_IsByClassification') === true) {
                if (!entity.getProperty('ModuleTargetId')) { return false; } else { return true; }
            }
            return true;
        }

        var bomRouteMappingType = manager.metadataStore.getEntityType('BomRouteMapping');
        bomRouteMappingType.validators.push(new breeze.Validator('isByClassificationActivityCodeValidator', isByClassificationActivityCodeValidationFn));
        bomRouteMappingType.validators.push(new breeze.Validator('isByClassificationModuleTargetValidator', isByClassificationModuleTargetValidationFn));
        
        // ADD Custom validator for VirtualWorkCenterEscape
        var virtualWorkCenterEscapeType = manager.metadataStore.getEntityType('VirtualWorkCenterEscape');
        virtualWorkCenterEscapeType.validators.push(new breeze.Validator('isByClassificationActivityCodeValidator', isByClassificationActivityCodeValidationFn));
        virtualWorkCenterEscapeType.validators.push(new breeze.Validator('isByClassificationModuleTargetValidator', isByClassificationModuleTargetValidationFn));
        

        this.dataContext = {
            CurrentIntervalClientData: dataLayer.setContextMethods(manager, nbsService, entityTypes.CurrentIntervalClientData),
            workCenterCapacityDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkCenterCapacityDto),
            virtualWorkCenterM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualWorkCenterM2MAppUserDto),
            virtualWorkCenterM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualWorkCenterM2MDrbacUserGroupDto),
            routes: dataLayer.setContextMethods(manager, nbsService, entityTypes.Route),
            operations: dataLayer.setContextMethods(manager, nbsService, entityTypes.Operation),
            workCenters: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkCenter),
            virtualWorkCenters: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualWorkCenter),
            virtualWorkCenterDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualWorkCenterDto),
            workCenterGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkCenterGroup),
            workCenterGeneralities: dataLayer.setContextMethods(manager, nbsService, entityTypes.WorkCenterGenerality),
            weekTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.WWeekTemplate),
            holidays: dataLayer.setContextMethods(manager, nbsService, entityTypes.Holiday),
            bankHolidays: dataLayer.setContextMethods(manager, nbsService, entityTypes.BankHoliday),
            calendarTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.WCalendarTemplate),
            calendars: dataLayer.setContextMethods(manager, nbsService, entityTypes.WCalendar),
            daySlotTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.WDaySlotTemplate),
            days: dataLayer.setContextMethods(manager, nbsService, entityTypes.WDay),
            calendarDaySlots: dataLayer.setContextMethods(manager, nbsService, entityTypes.WDaySlot),
            bomRouteMappings: dataLayer.setContextMethods(manager, nbsService, entityTypes.BomRouteMapping),
            virtualWorkCenterEscapes: dataLayer.setContextMethods(manager, nbsService, entityTypes.VirtualWorkCenterEscape),
            
            enumTypes: {
                RouteSchedulingType: dataLayer.GetEnumType('RouteSchedulingType'),
                RouteStatus: dataLayer.GetEnumType('RouteStatus'),
                OperationApprovalMode: dataLayer.GetEnumType('OperationApprovalMode'),
                OperationCommunicationMode: dataLayer.GetEnumType('OperationCommunicationMode'),
                DayOfWeek: dataLayer.GetEnumType('DayOfWeek'),
                CapacityUnit: dataLayer.GetEnumType('CapacityUnit'),
                ResourceType: dataLayer.GetEnumType('ResourceType'),
                WCalendarStatus: dataLayer.GetEnumType('WCalendarStatus'),
                WCalendarKind: dataLayer.GetEnumType('WCalendarKind'),
                WorkCenterType: Lazy(dataLayer.GetEnumType('WorkCenterType')).reject({ value: "0" }).toArray()     // "None" item removed
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getWorkCentersCapacityBasedOnVhrEvents = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkCenterCapacityDto, cache: this.dataContext.workCenterCapacityDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkCentersCapacityBasedOnVhrEvents");
        };

        this.getVirtualWorkCenterM2MAppUserDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualWorkCenterM2MAppUserDto, cache: this.dataContext.virtualWorkCenterM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualWorkCenterM2MAppUserDto");
        };

        this.getVirtualWorkCenterM2MDrbacUserGroupDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualWorkCenterM2MDrbacUserGroupDto, cache: this.dataContext.virtualWorkCenterM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualWorkCenterM2MDrbacUserGroupDto");
        };

        this.getRoutes = function (filter, forceReload) {
            var params = { entityType: entityTypes.Route, cache: this.dataContext.routes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Routes");
        };
        this.getRoute = function (filter, forceReload) {
            var params = { key: filter.RouteId, dataContext: this.dataContext.routes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Routes");
        };

        this.getOperations = function (filter, forceReload) {
            var params = { entityType: entityTypes.Operation, cache: this.dataContext.operations.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Operations");
        };

        this.getWorkCenters = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkCenter, cache: this.dataContext.workCenters.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkCenters");
        };
        this.getWorkCenter = function (filter, forceReload) {
            var params = { key: filter.GroupCapacityUnitId, dataContext: this.dataContext.workCenters };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "WorkCenters");
        };

        this.getWorkCenterGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.WorkCenterGroup, cache: this.dataContext.workCenterGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkCenterGroups");
        };
        this.getWorkCenterGroup = function (filter, forceReload) {
            var params = { key: filter.HrGroupId, dataContext: this.dataContext.workCenterGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "WorkCenterGroups");
        };

        this.getWeekTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.WWeekTemplate, cache: this.dataContext.weekTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WeekTemplates");
        };
        this.getWeekTemplate = function (filter, forceReload) {
            var params = { key: filter.WWeekTemplateId, dataContext: this.dataContext.weekTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "WeekTemplates");
        };

        this.getBankHolidays = function (filter, forceReload) {
            var params = { entityType: entityTypes.BankHoliday, cache: this.dataContext.bankHolidays.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "BankHolidays");
        };
        this.getBankHoliday = function (filter, forceReload) {
            var params = { key: filter.BankHolidayId, dataContext: this.dataContext.bankHolidays };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "BankHolidays");
        };

        this.getCalendarTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.WCalendarTemplate, cache: this.dataContext.calendarTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CalendarTemplates");
        };
        this.getCalendarTemplate = function (filter, forceReload) {
            var params = { key: filter.WCalendarId, dataContext: this.dataContext.calendarTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CalendarTemplates");
        };

        this.getCalendars = function (filter, forceReload) {
            var params = { entityType: entityTypes.WCalendar, cache: this.dataContext.calendars.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Calendars");
        };
        this.getCalendar = function (filter, forceReload) {
            var params = { key: filter.WCalendarId, dataContext: this.dataContext.calendars };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Calendars");
        };

        this.getWorkCenterCalendars = function (filter, forceReload) {
            var params = { entityType: entityTypes.WCalendar, cache: this.dataContext.calendars.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "WorkCenterCalendars");
        };

        this.getDaySlotTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.WDaySlotTemplate, cache: this.dataContext.daySlotTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "DaySlotTemplates");
        };

        this.getHolidays = function (filter, forceReload) {
            var params = { entityType: entityTypes.Holiday, cache: this.dataContext.holidays.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Holidays");
        };
        this.getHoliday = function (filter, forceReload) {
            var params = { key: filter.HolidayId, dataContext: this.dataContext.holidays };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Holidays");
        };

        this.getCalendarDays = function (filter, forceReload) {
            var params = { entityType: entityTypes.WDay, cache: this.dataContext.days.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CalendarDays");
        };
        this.getCalendarDay = function (filter, forceReload) {
            var params = { key: filter.WDayId, dataContext: this.dataContext.days };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CalendarDays");
        };

        this.getCalendarDaySlots = function (filter, forceReload) {
            var params = { entityType: entityTypes.WDaySlot, cache: this.dataContext.calendarDaySlots.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CalendarDaySlots");
        };
        this.getCalendarDaySlot = function (filter, forceReload) {
            var params = { key: filter.WDaySlotId, dataContext: this.dataContext.calendarDaySlots };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CalendarDaySlots");
        };

        this.getVirtualWorkCenters = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualWorkCenter, cache: this.dataContext.virtualWorkCenters.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualWorkCenters");
        };
        this.getVirtualWorkCenter = function (filter, forceReload) {
            var params = { key: filter.VirtualWorkCenterId, dataContext: this.dataContext.virtualWorkCenters };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualWorkCenter");
        };

        this.getVirtualWorkCenterDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualWorkCenterDto, cache: this.dataContext.virtualWorkCenterDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualWorkCenterDtos");
        };
        this.getVirtualWorkCenterDto = function (filter, forceReload) {
            var params = { key: filter.VirtualWorkCenterId, dataContext: this.dataContext.virtualWorkCenterDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualWorkCenterDto");
        };

        this.getBomRouteMappings = function (filter, forceReload) {
            var params = { entityType: entityTypes.BomRouteMapping, cache: this.dataContext.bomRouteMappings.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "BomRouteMapping");
        };
        this.getBomRouteMapping = function (filter, forceReload) {
            var params = { key: filter.BomRouteMappingId, dataContext: this.dataContext.bomRouteMappings };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "BomRouteMapping");
        };

        this.getVirtualWorkCenterEscapes = function (filter, forceReload) {
            var params = { entityType: entityTypes.VirtualWorkCenterEscape, cache: this.dataContext.virtualWorkCenterEscapes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VirtualWorkCenterEscape");
        };
        this.getVirtualWorkCenterEscape = function (filter, forceReload) {
            var params = { key: filter.VirtualWorkCenterEscapeId, dataContext: this.dataContext.virtualWorkCenterEscapes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VirtualWorkCenterEscape");
        };

        //Http Post
        this.composeCalendarTemplate = function (calendarClientData) {
            var query = dataLayer.InvokeQuery("ComposeCalendarTemplate", calendarClientData);
            return manager.executeQuery(query);
        };

        // Routes paged
        this.getRouteDtoPagedDm = function () {
            return ejDataManager.getEjManager('RouteDtosPaged');
        };
        this.getRouteDtoPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('RouteDtosPaged');
            return dmObj;
        };

        //Http Post
        this.mergeCalendar = function (calendarClientData) {
            var query = dataLayer.InvokeQuery("MergeCalendar", calendarClientData);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneCalendar = function (calendarClientData) {
            var query = dataLayer.InvokeQuery("CloneCalendar", calendarClientData);
            return manager.executeQuery(query);
        };
    };

    productionService.$inject = injectParams;
    productionService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('productionService', productionService);
});