'use strict';
var sharedmodule = angular.module('nbs.shared.module', []);

define([
	'./services/breezeDataLayer-service'
	,'./services/watchdog-service'
	, './services/navigation-service'
	, './services/cms-service'
	, './services/nbsStorage-service'
	, './services/public-service'
	, './services/authentication-service'
	, './services/fileTransfer-service'
	, './services/context-service'
	, './services/authInterceptor-service'
	, './services/foundation-service'
	, './services/report-service'
	, './services/vhrevent-service'
	, './services/integration-service'
	, './services/notification-service'
	, './services/rbac-service'
	, './services/drbac-service'
	, './services/currentSession-service'
	, './services/checkStateChange-service'
], function () {



	return sharedmodule;
});
