﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'utilService', 'navigationService', 'checkStateChangeService', 'fileTransferService', 'contextService', 'formSummaryService'];
    function fileBoxAttachDetail($q, $scope, $timeout, $injector, foundationService, utilService, navigationService, checkStateChangeService, fileTransferService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, true);
        ribbon.refreshStatusButtons();

        var currentFsFolderId = navigationService.stateParams().fsFolderId;
        var fsFolderId = navigationService.stateParams().fsFolderId;
        var createRootFolder = utilService.getFsDtoRootAddFunction(fsFolderId);
        var entityType = { FsFolder: 1, FileMetadata: 2 };
        var modalAction = { AddFolder: 1, UpdateFolder: 2 };

        // grid in UxHelper
        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('fsdto', null);
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        // datasource grid context menù
        vm.customContextMenuItems = [];

        // property used to set visibility true ad grid files
        vm.viewUploadFiles = false;
        vm.folderSelected = null;
        vm.currentEntitySelected = null;
        vm.currentModalAction = null;

        // datasource grid files
        vm.currentFiles = [];

        // datasource treeview folders
        vm.currentFolders = [];

        vm.uploadfileData = {};

        //used by upload
        vm.files = [];

        vm.setIsEditing();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCategories(forceReload, 'BSC.CC'));

            $q.all(funcsPromises)
                .then(function () {
                    genericFsDtoCategorizeds(forceReload, fsFolderId);
                });
        }

        //Categories must be CC categories (are uset to categorize files after upload)
        function getCategories(forceReload, currentSystemCategoryCode) {
            var filter = { SystemCategoryCode: currentSystemCategoryCode };
            var defer = $q.defer();

            foundationService.getCategories(filter, forceReload)
                .then(function (data) {
                    vm.currentCategories = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function genericFsDtoCategorizeds(forceReload, currentFsFolderId) {
            var filter = { FsFolderId: currentFsFolderId };
            var defer = $q.defer();

            utilService.genericFsDtoCategorizedBypass(filter, forceReload)
                .then(function (data) {
                    //set root node parentId = null
                    var rootNode = Lazy(data.results).findWhere({ Depth: 0, ParentFsDtoId: currentFsFolderId });

                    if (rootNode) {
                        vm.rootFolderAlreadyPresent = true;

                        rootNode.ParentFsDtoId = null;
                        rootNode.entityAspect.setUnchanged();

                        Lazy(data.results)
                            .each((file) => {
                                file.Categories = Nembus.Common.Library.converter.JsonFieldParse(file.CategoriesSerialized);
                                file.Category = Lazy(file.Categories).first();
                                file.CategoriesSerialized = JSON.stringify(Nembus.Common.Library.converter.JsonFieldParse(file.CategoriesSerialized));
                                file.entityAspect.setUnchanged();
                            });

                        vm.currentFsDtos = data.results;
                        vm.currentFolders = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.folder }).toArray();
                        vm.currentFiles = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.file, ParentFsDtoId: currentFsFolderId }).toArray();
                        vm.treeNbsExplorerRefresh = new Date().getTime();

                        vm.forceDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: vm.currentFiles
                        }
                    } else {
                        vm.rootFolderAlreadyPresent = false;
                    }

                    ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, !vm.rootFolderAlreadyPresent);
                    ribbon.refreshStatusButtons();

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.onNodeSelect = function (args) {
            if (args.currentElement.children().length !== 1) {
                return;
            }

            var radioElement = $(args.currentElement).find("input[type='radio']");
            var categoryId = radioElement.attr('value');
            if (categoryId) {
                categoryId = Number(categoryId);

                var category = Lazy(vm.currentCategories).findWhere({ CategoryId: categoryId });
                if (category) {
                    var categoryDto = mapCategoryToCategoryDto(category);
                    setCategoryToFsDto(vm.fileSelected, categoryDto);
                }
            }
        };

        vm.refreshAction = function () {
            genericFsDtoCategorizeds(true, fsFolderId);
        };

        vm.newFolderAction = function () {
            var fs = null;
            vm.isAddingRootFolder = false;

            if (vm.folderSelected) {
                fs = {
                    FsDtoId: NbsGuidManager.newGuid(),
                    ParentFsDtoId: vm.folderSelected.folder.FsDtoId,
                    FsTypeId: utilService.fsType.folder,
                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                };
            } else {
                if (!vm.rootFolderAlreadyPresent) {
                    vm.isAddingRootFolder = true;

                    fs = {
                        FsDtoId: NbsGuidManager.newGuid(),
                        ParentFsDtoId: NbsGuidManager.emptyGuid(),
                        FsName: 'root',
                        FsTypeId: utilService.fsType.folder,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                    };
                }
            }

            if (fs) {
                vm.currentFolder = fs;
                vm.currentModalAction = modalAction.AddFolder;
                vm.showModal();
            }
        };

        vm.deleteAction = function () {
            if (vm.currentEntitySelected === entityType.FsFolder) {
                if (!vm.folderSelected.folder)
                    return;

                utilService.deleteEntity(vm.folderSelected.folder);
                vm.submitAction();
            }

            if (vm.currentEntitySelected === entityType.FileMetadata) {
                utilService.deleteEntity(vm.fileSelected);
                vm.submitAction();
            }

            vm.currentEntitySelected = null;
        };

        vm.modifyAction = function () {
            if (vm.currentEntitySelected === entityType.FsFolder) {
                if (!vm.folderSelected.folder)
                    return;

                vm.currentFolder = vm.folderSelected.folder;
                vm.currentModalAction = modalAction.UpdateFolder;
                vm.showModal();
            }

            if (vm.currentEntitySelected === entityType.FileMetadata) {
                vm.openModalTreeView();
            }
        };

        // START opens modal
        vm.showModal = function () {
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({
                windowClass: vm.NBS_MODAL_CLASS_FORM_MD,
                templateUrl: 'foldersTemplate',
                scope: $scope
            }));
            vm.modalInstance.result.then(function () {
                //ok
                if (vm.currentModalAction === modalAction.AddFolder) {
                    utilService.dataContext.fsDtoCategorizeds.add(vm.currentFolder);
                }

                vm.currentModalAction = null;
                vm.submitAction();
            }, function () {
                //dismiss
                vm.currentModalAction = null;
                utilService.rejectChanges();
            });
        };

        vm.openModalTreeView = function () {
            vm.modal = {
                instanceName: 'modalInstanceTreeView',
                title: vm.translate('Categories'),
                datasource: categoriesListForTreeview({ datasource: vm.currentCategories })
            };

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'treeViewTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                setCategoriesToFilemetadata(vm.fileSelected, vm.fileSelected.CategoriesSerialized);
            }, function () {
                utilService.rejectChanges();
            });
        };

        vm.modalOk = function () {
            vm.modalInstance.close();
        };
        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };
        // END opens modal 

        //Htp Post
        function setCategoriesToFilemetadata(fileMetadata, categoriesSerialized) {
            var fsDtoFsDtoCategorized = {
                FsDtoId: fileMetadata.FsDtoId,
                CategoriesSerialized: categoriesSerialized
            };

            utilService.setCategoriesToFilemetadata(fsDtoFsDtoCategorized)
                .then(function (result) {
                    loadData(true);
                });
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    vm.viewUploadFiles = false;
                    if (createRootFolder && !vm.rootFolderAlreadyPresent) {
                        // update root folder id to current event
                        var ent = Lazy(saveResult.entities).filter((e) => { return e.entityType.shortName === 'FsDtoCategorized'; }).first();
                        if (ent) {
                            fsFolderId = ent.FsDtoId;
                        }

                        createRootFolder.fnSetRootFolder(fsFolderId);
                        createRootFolder.entityService.saveChanges().then(
                            function (saveResult) {
                                utilService.clearFsDtoRootAddFunction(fsFolderId);
                                vm.refreshAction();
                            });
                    } else {
                        vm.refreshAction();
                    }
                }
            );
        };

        vm.rowSelected = function (args) {
            $timeout(function () {
                vm.currentEntitySelected = entityType.FileMetadata;
                vm.fileSelected = args.data;
                if (vm.fileSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
                    ribbon.refreshStatusButtons();
                }
            });
        };

        vm.rowDblClick = function (args) {
            if (args.data.FileNotSavedYet === true) {
                vm.removeAlerts();
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('FileNotSaved') });
            } else {
                var downloadData = {
                    entityGuid: args.data.FsDtoId,
                    asAttach: true
                };
                fileTransferService.downloadRemoteFileByGuid(downloadData);
            }
        };

        vm.onNodeSelectFolder = function (args) {
            $timeout(function () {
                vm.viewUploadFiles = true;

                var folder = Lazy(vm.currentFolders).findWhere({ FsDtoId: args.id });
                if (folder) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
                    ribbon.refreshStatusButtons();

                    vm.currentEntitySelected = entityType.FsFolder;
                }

                vm.folderSelected = {
                    folder: folder
                };
                currentFsFolderId = folder.FsDtoId;

                var files = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.file, ParentFsDtoId: currentFsFolderId }).toArray();
                vm.currentFiles = files;

                vm.forceDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentFiles
                }
            });
        };
        vm.onFileUploaded = function (file, res) {
            vm.removeAlerts();
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            vm.uploadfileData.SourceFileName = file.name;

            var fs = {
                FsDtoId: NbsGuidManager.newGuid(),
                ParentFsDtoId: currentFsFolderId,
                FsName: file.name,
                DisplayFileName: file.name,
                FsTypeId: utilService.fsType.file,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                FileNotSavedYet: true
            };

            fs.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);

            addCategoryToFsDto(fs, vm.currentCategories);

            utilService.dataContext.fsDtoCategorizeds.add(fs);
            vm.currentFiles.push(fs);

            vm.forceDatasourceLocal = {
                refreshkey: new Date().getTime(),
                dataSource: vm.currentFiles
            }
        };

        vm.queryCellInfo = function (args) {
            if (args.column.field === "FsExtension") {
                // FsExtension views icon based on file type
                var className = Nembus.Gas.Library.Tools.GetIconClassNameByExtension(args.text, '16');
                args.cell.innerHTML = "<img class='" + className + "' src='/images/TreeView/transparent.gif' border='0'></img>";
            }
        }

        //Using filename, get category that has the code equal to filename without extension 
        function addCategoryToFsDto(fsDto, categories) {
            fsDto.Category = {};
            var fName = fsDto.FsName;
            var categoryCode = fName.split('.')[0];
            var category = Lazy(categories).findWhere({ CategoryCode: categoryCode });
            if (category) {
                var categoryDto = mapCategoryToCategoryDto(category);
                setCategoryToFsDto(fsDto, categoryDto);
            }
        }

        function setCategoryToFsDto(fsDto, categoryDto) {
            fsDto.Category = categoryDto;
            fsDto.CategoriesSerialized = JSON.stringify([categoryDto]);
        }

        function mapCategoryToCategoryDto(category) {
            return {
                CategoryId: category.CategoryId,
                CategoryCode: category.CategoryCode,
                CategoryName: category.CategoryName
            };
        }

        function categoriesListForTreeview(params) {
            // params.datasource
            // params.fieldId
            // params.fieldParentId
            // params.fieldText

            params.fieldId = 'CategoryId';
            params.fieldParentId = 'ParentCategoryId';
            params.fieldText = 'CategoryName';

            return Lazy(params.datasource)
                .map((item) => {
                    var itemMapped = item;

                    itemMapped.FieldId = item[params.fieldId];
                    itemMapped.FieldParentId = item[params.fieldParentId];
                    itemMapped.FieldText = item[params.fieldText];

                    return itemMapped;
                }).toArray();
        };
    }

    fileBoxAttachDetail.$inject = injectParams;
    fileBoxAttachDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxAttachDetail', fileBoxAttachDetail);
});