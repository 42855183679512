﻿'use strict';
//modal params: 
//cronstring: string 
//modal result
//CronObj   ///  cronstring
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$timeout', 'navigationService', 'contextService', 'workflowService', 'vhreventService'];
    function vhrEventWorkflowChangeStatusDetail($q, $injector, $timeout, navigationService, contextService, workflowService, vhreventService) {

        var vm = this;
        nbsVm.call(vm, null, navigationService, contextService, $q, $injector, $timeout);
        
        var pParams = navigationService.stateParamsModal().parentParams;
        var vhrEventId = pParams.VhrEventId;                        // history mode
        var appStoreCode = pParams.appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var workflowStatusId = pParams.WorkflowStatusId;
        var workflowStatusKindParsedEnum = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);
        var vhrEventEntityHistoryTypeEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventEntityHistoryType, null, null).obj.normal.name_value;
        var workflowStatusKindEnumTranslatedObj = workflowStatusKindParsedEnum.obj.translated.value_name;

        vm.messageCopyIsVisible = false;
        
        vm.operationPermitted = Nembus.Aud.Library.SSV.CanManageSurveyWorkflow()
            && vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDIT_WORKFLOW_CHANGESTATUS'], contextService.virtualContext);
        if (!vm.operationPermitted) {
            vm.operationNotPermittedMessage = vm.translate('OperationNotPermitted');
        }

        pParams = pParams ? pParams : navigationService.stateParams();

        vm.viewKindConst = { MODIFY: 'modify', HISTORY: 'history' };
        vm.viewKind = pParams.viewKind;        // execute mode or changestatus mode

        // modalResult
        vm.modalWorkFlow = {
            SourceParams: pParams,
            WorkflowStatusId: null,
            WorkflowStatusNote: null
        };

        if (workflowStatusId) {
            vm.modalWorkFlow.WorkflowStatusId = workflowStatusId;
        }

        switch (vm.viewKind) {
            case vm.viewKindConst.MODIFY:
                getWorkflowActiveStatuses(true, moduleTargetId)
                    .then(function (wkfStatuses) {
                        vm.workflowStatuses = [];
                        if (vm.viewKind === vm.viewKindConst.MODIFY) {
                            vm.workflowStatuses = wkfStatuses;                            
                        }                        
                    });
                break;
            case vm.viewKindConst.HISTORY:
                if (!vhrEventId) {
                    alert('error vhrEventId');
                    return;
                }

                var nbsGridDatacontext = new NbsGrid(vm.translate);
                nbsGridDatacontext.configureGrid('vhreventhistory', 'workflowstatus');
                nbsGridDatacontext.setDatasource([], false);
                nbsGridDatacontext.setModalMode();
                nbsGridDatacontext.allowPaging = false;
                nbsGridDatacontext.allowSearching = false;
                vm.nbsGridDatacontext = nbsGridDatacontext;

                var forceReload = true;
                var funcsPromises = [];
                funcsPromises.push(getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeEnum.WorkflowStatus));

                $q.all(funcsPromises)
                    .then(function (results) {
                        var histories = results[0];

                        Lazy(histories)
                            .each((item) => {
                                item.WorkflowStatusKindName = workflowStatusKindEnumTranslatedObj[item.WorkflowStatusKindId];
                                item.CreationDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.CreationDate, contextService.virtualContext.cultureInfo, false);
                            });

                        vm.forceDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: histories
                        }
                    });
                break;
        }

        var historySelected = null;
        vm.gridSelection = function (args) {
            historySelected = args.data;
        }

        vm.modalCopyToClipboard = function () {
            vm.messageCopyIsVisible = false;

            if (!historySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            Nembus.Common.Library.util.copyTextToClipboard(historySelected.VhrEventWorkflowStatusChangeNote);
            
            vm.messageCopyIsVisible = true;
        }

        vm.modalOk = function () {
            var modalResult = getModalResult(navigationService.navigationActionTypes.modalOk);
            var hasErrors = false;

            if (!hasErrors) {
                navigationService.closeCurrentModal(modalResult);
            } else {
                vm.alertConfig.isModal = true;
                vm.alertConfig.isReadonly = true;
                vm.removeAlertFromList(true);
                vm.setErrorMsg({ isMessageVisible: true, message: vm.currentCron.errorMessage() });
            }
        };

        vm.modalCancel = function () {
            navigationService.closeCurrentModal();
        };

        function getModalResult(dialogResult) {
            var modalResult = new navigationService.modalResult();
            modalResult.data = {
                ViewKind: pParams.viewKind,
                ModuleTargetId: moduleTargetId,
                WorkflowStatusId: vm.modalWorkFlow.WorkflowStatusId,
                WorkflowStatusNote: vm.modalWorkFlow.WorkflowStatusNote
            };

            modalResult.sourceParams = pParams;
            modalResult.dialogResult = dialogResult;
            return modalResult;
        }

        function getVhrEventEntityHistories(forceReload, vhrEventId, vhrEventEntityHistoryTypeId ) {
            var defer = $q.defer();

            vhreventService.getVhrEventEntityHistories({ VhrEventId: vhrEventId, VhrEventEntityHistoryTypeId: vhrEventEntityHistoryTypeId  }, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getWorkflowActiveStatuses(forceReload, moduleTargetId) {
            var defer = $q.defer();

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    vhrEventWorkflowChangeStatusDetail.$inject = injectParams;
    vhrEventWorkflowChangeStatusDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('vhrEventWorkflowChangeStatusDetail', vhrEventWorkflowChangeStatusDetail);
});