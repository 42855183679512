﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var fdsupportService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('fdsupport', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            FdTicket: 'FdTicket',
            FdConversation: 'FdConversation',
            FdAttachmentTicket: 'FdAttachmentTicket'
        };

        this.entityTypes = entityTypes;

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            fdTickets: dataLayer.setContextMethods(manager, nbsService, entityTypes.FdTicket),
            fdConversations: dataLayer.setContextMethods(manager, nbsService, entityTypes.FdConversation),
            fdAttachmentTickets: dataLayer.setContextMethods(manager, nbsService, entityTypes.FdAttachmentTicket),

            constants: {}
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };


        this.getTickets = function (filter, forceReload) {
            var params = { entityType: entityTypes.FdTicket, cache: this.dataContext.fdTickets.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Tickets");
        };
        this.getTicket = function (filter, forceReload) {
            var params = { key: filter.TicketId, dataContext: this.dataContext.fdTickets };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Ticket");
        };

        this.getTicketConversations = function (filter, forceReload) {
            var params = { entityType: entityTypes.FdConversation, cache: this.dataContext.fdConversations.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TicketConvestions");
        };

        this.getAttachments = function (filter, forceReload) {
            var params = { entityType: entityTypes.FdTicket, cache: this.dataContext.fdAttachmentTickets.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Attachments");
        };

    };

    fdsupportService.$inject = injectParams;
    fdsupportService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('fdsupportService', fdsupportService);
});