﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {
    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'workflowService', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function myActBoardWorkflow($q, $injector, $scope, $timeout, workflowService, actService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'board');
        ribbon.addHomeTab();
        ribbon.addTab(ribbon.GetActViewsTab());
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var actPermissionManager = new NbsActPermissionManager({
            translator: vm.translate,
            virtualContext: contextService.virtualContext,
            actServiceEnumTypes: actService.dataContext.enumTypes,
            havePermissionFn: vm.havePermission,
            validationSchema: null,
            rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
            audPermissionObj: Nembus.Aud.Library.auth.Permission,
            parseEnumFn: Nembus.Common.Library.util.parseEnum
        });
        var actMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActMassivelyUpdateRequestType, null, vm.translate).obj.normal.name_value;
        var actFixedCode = navigationService.stateParams().actFixedCode;
        var workflowStatusKindId = navigationService.stateParams().workflowStatusKindId;

        //#region set ribbon fn navigations
        var navParams = { 'workflowStatusKindId': workflowStatusKindId, 'viewKind': viewKind, 'appStoreCode': appStoreCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_MyAct(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_MyAct(navParams); };
        vm.onRibbonBoardWorkflow = () => { ribbon.onRibbonBoardWorkflow_MyAct(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_MyAct(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewList, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        var nbsGridDatacontext = null;
        workflowService.getActWorkflowActiveStatuses({}, true)
            .then(function (data) {
                var nCols = data.results.length > 4 ? 4 : data.results.length; // parametrized
                var columnFilters = [];
                Lazy(data.results)
                    .each((wkfStatus) => {
                        var filterParams = { filter: null, field: 'ActWorkflowStatusId', text: wkfStatus.WorkflowStatusName, value: wkfStatus.WorkflowStatusId, isDueDate: false, defaultValue: null };
                        columnFilters.push([filterParams]);
                    });
                var boardParams = {
                    dueDateFilters: [],
                    columnFilters: columnFilters,
                    nbsGuidManager: NbsGuidManager,
                    localAsUtcFn: Nembus.Common.Library.converter.date.localAsUtc
                };

                nbsGridDatacontext = new NbsGrid(vm.translate);
                nbsGridDatacontext.configureGrid('act', null);
                nbsGridDatacontext.setBoardMode();
                nbsGridDatacontext.allowSearching = false;
                nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid

                vm.board = new NbsBoard(nCols, boardParams);
                vm.board.setRefreshScopeFn(function () { $timeout(() => { }); });
                vm.board.setGridDatacontext(nbsGridDatacontext);
                vm.board.onBoardReady = function () {
                    setBarDatacontext();
                };
                vm.board.onDropAction = function (items, indexColSrc, indexColDest) {
                    dropAction(items, indexColSrc, indexColDest);
                };
            })
            .catch(function (error) {
                vm.setErrorMsg(error);
                defer.reject(error);
            });

        // hook from gridbar
        var ejDataManager = null;
        var currentBoardBarFilter = null;
        vm.actBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentBoardBarFilter = result;
            currentBoardBarFilter.actFilter.ActFixedCode = actFixedCode;

            if (ejDataManager === null) {
                var supportData = { isMyActs: true, translate: vm.translate, contextService: contextService };
                ejDataManager = actService.getActDataManager(supportData, currentBoardBarFilter.actFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);

                vm.board.setBoardFilter(currentBoardBarFilter.actFilter);
            } else {
                vm.board.setBoardFilter(currentBoardBarFilter.actFilter);
            }
        };

        vm.refreshAction = function () {
            vm.board.refreshGridDatasources();
        };

        function setBarDatacontext() {
            vm.actBarDataContext = {
                workflowStatusKindId: workflowStatusKindId,
                commonBarDatasource: { modeUI: 'board' },
                uiMode: viewKind,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: viewKind, sortingKey: null
                }
            };
        }

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function dropAction(acts, indexColSrc, indexColDest) {
            var actsId = Lazy(acts).pluck('ActId').toArray();
            var srcCol = vm.board.columns[indexColSrc];
            var destCol = vm.board.columns[indexColDest];
            var actWorkflowStatusId = vm.board.getColFilter(destCol).ActWorkflowStatusId;

            var actPermission = actPermissionManager.getPermissionByActList(acts);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            vm.actMassivelyUpdateRequest = actService.dataContext.actMassivelyUpdateRequests.add({
                WorkflowStatusId: actWorkflowStatusId,
                ExecutionNote: null,
                ActMassivelyUpdateRequestTypeId: actMassivelyUpdateRequestType.UpdateWorkflow,
                ActFilterSerialized: JSON.stringify({ ActIds: actsId }),
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                ForceRealtime: true
            });

            vm.submitAction()
                .then(function (saveResult) {
                    if (vm.actMassivelyUpdateRequest.IsBatch) {
                        contextService.addBatchRequest({
                            requestId: vm.actMassivelyUpdateRequest.BatchRequestId,
                            requestName: vm.actMassivelyUpdateRequest.BatchRequestName,
                            requestStatus: 'inserted'
                        });
                    } else {
                        vm.board.refreshGridDatasource(srcCol);
                        vm.board.refreshGridDatasource(destCol);
                    }
                }).catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }
    }

    myActBoardWorkflow.$inject = injectParams;
    myActBoardWorkflow.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('myActBoardWorkflow', myActBoardWorkflow);
});