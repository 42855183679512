﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var notificationService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('notification', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            Notification: 'Notification',
            NotificationDto: 'NotificationDto',
            NotificationDefinition: 'NotificationDefinition',
            NotificationDefinitionDto: 'NotificationDefinitionDto',

            NotificationTemplate: 'NotificationTemplate',
            NotificationDefinitionGroup: 'NotificationDefinitionGroup',
            NotificationInstance: 'NotificationInstance',
            NotificationSchema: 'NotificationSchema',
            NotificationSchemaInstance: 'NotificationSchemaInstance',
            NotificationConfig: 'NotificationConfig',
            NotificationTarget: 'NotificationTarget',
            NotificationTargetEntity: 'NotificationTargetEntity',
            NotificationTargetEntityKey: 'NotificationTargetEntityKey',
            NotificationRequest: 'NotificationRequest',
            NotificationClientRequest: 'NotificationClientRequest',

            // M2M relationships
            NotificationSchemaInstanceM2MAppUserDto: 'NotificationSchemaInstanceM2MAppUserDto',
            NotificationSchemaInstanceM2MDrbacRoleDto: 'NotificationSchemaInstanceM2MDrbacRoleDto',
            NotificationSchemaInstanceM2MDrbacUserGroupDto: 'NotificationSchemaInstanceM2MDrbacUserGroupDto',
            NotificationDefinitionM2MNotificationTargetDto: 'NotificationDefinitionM2MNotificationTargetDto',
            //NotificationDefinitionM2MNotificationConditionDto: 'NotificationDefinitionM2MNotificationConditionDto',
            //NotificationDefinitionM2MTriggerDefinitionDto: 'NotificationDefinitionM2MTriggerDefinitionDto',
            NotificationTargetM2MNotificationTargetEntitiesDto: 'NotificationTargetM2MNotificationTargetEntitiesDto'
        };
        var me = this;

        this.entityTypes = entityTypes;
        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            notifications: dataLayer.setContextMethods(manager, nbsService, entityTypes.Notification),
            notificationDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDto),
            notificationDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinition),
            notificationDefinitionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinitionDto),
            notificationTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationTemplate),
            notificationDefinitionGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinitionGroup),
            notificationInstances: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationInstance),
            notificationSchemas: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationSchema),
            notificationSchemaInstances: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationSchemaInstance),
            notificationConfigs: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationConfig),
            notificationTargets: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationTarget),
            notificationTargetEntities: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationTargetEntity),
            notificationTargetEntityKeys: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationTargetEntityKey),
            notificationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationRequest),
            notificationClientRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationClientRequest),
            notificationSchemaInstanceM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationSchemaInstanceM2MAppUserDto),
            notificationSchemaInstanceM2MDrbacRoleDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationSchemaInstanceM2MDrbacRoleDto),
            notificationSchemaInstanceM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationSchemaInstanceM2MDrbacUserGroupDto),
            notificationDefinitionM2MNotificationTargetDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinitionM2MNotificationTargetDto),
            //notificationDefinitionM2MNotificationConditionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinitionM2MNotificationConditionDto),
            //notificationDefinitionM2MTriggerDefinitionDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationDefinitionM2MTriggerDefinitionDto),
            notificationTargetM2MNotificationTargetEntitiesDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.NotificationTargetM2MNotificationTargetEntitiesDto),
            
            enumTypes: {
                NotificationFlag: dataLayer.GetEnumType('NotificationFlag'),
                NotificationChannelKind: dataLayer.GetEnumType('NotificationChannelKind'),
                NotificationTargetType: dataLayer.GetEnumType('NotificationTargetType'),
                NotificationRequestStatus: dataLayer.GetEnumType('NotificationRequestStatus'),
                NotificationClientRequestStatus: dataLayer.GetEnumType('NotificationClientRequestStatus'),
                NotificationStatus: dataLayer.GetEnumType('NotificationStatus'),
                NotificationPriority: dataLayer.GetEnumType('NotificationPriority')
            },

            constants: {}
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // #region M2M relations
        this.getNotificationSchemaInstanceM2MAppUserDtosBySchema = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationSchemaInstanceM2MAppUserDto, cache: this.dataContext.notificationSchemaInstanceM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationSchemaInstanceM2MAppUserDtoBySchema");
        };

        this.getNotificationSchemaInstanceM2MDrbacRoleDtosBySchema = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationSchemaInstanceM2MDrbacRoleDto, cache: this.dataContext.notificationSchemaInstanceM2MDrbacRoleDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationSchemaInstanceM2MDrbacRoleDtoBySchema");
        };

        this.getNotificationSchemaInstanceM2MDrbacUserGroupDtosBySchema = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationSchemaInstanceM2MDrbacUserGroupDto, cache: this.dataContext.notificationSchemaInstanceM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationSchemaInstanceM2MDrbacUserGroupDtoBySchema");
        };

        this.getNotificationDefinitionM2MNotificationTargetDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationDefinitionM2MNotificationTargetDto, cache: this.dataContext.notificationDefinitionM2MNotificationTargetDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationDefinitionM2MNotificationTargetDto");
        };

        this.getNotificationTargetM2MNotificationTargetEntitiesDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationTargetM2MNotificationTargetEntitiesDto, cache: this.dataContext.notificationTargetM2MNotificationTargetEntitiesDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationTargetM2MNotificationTargetEntitiesDto");
        };

        //this.getNotificationDefinitionM2MNotificationConditionDtos = function (filter, forceReload) {
        //    var params = { entityType: entityTypes.NotificationDefinitionM2MNotificationConditionDto, cache: this.dataContext.notificationDefinitionM2MNotificationConditionDtos.get() };
        //    return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationDefinitionM2MNotificationConditionDto");
        //};

        //this.getNotificationDefinitionM2MTriggerDefinitionDtos = function (filter, forceReload) {
        //    var params = { entityType: entityTypes.NotificationDefinitionM2MTriggerDefinitionDto, cache: this.dataContext.notificationDefinitionM2MTriggerDefinitionDtos.get() };
        //    return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationDefinitionM2MTriggerDefinitionDto");
        //};
        // #endregion

        // Notification
        this.getNotification = function (filter, forceReload) {
            var params = { key: filter.NotificationId, dataContext: this.dataContext.notifications };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "Notifications");
        };
        this.getNotifications = function (filter, forceReload) {
            var params = { entityType: entityTypes.Notification, cache: this.dataContext.notifications.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "Notifications");
        };
        this.getMyNotifications = function (filter, forceReload) {
            var params = { entityType: entityTypes.Notification, cache: this.dataContext.notifications.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MyNotifications");
        };

        this.getMyNotification = function (filter, forceReload) {
            var params = { key: filter.NotificationId, dataContext: this.dataContext.notificationDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "MyNotification");
        };


        // NotificationDefinition
        this.getNotificationDefinitions = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.NotificationDefinition, cache: this.dataContext.notificationDefinitions.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationDefinitions")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToNotificationDefinitionsList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };
        this.getNotificationDefinition = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { key: filter.NotificationDefinitionId, dataContext: this.dataContext.notificationDefinitions };

            dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationDefinitions")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToNotificationDefinitionsList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };
        // returns notification definitions availables by trigger definition
        this.getTriggerNotificationsAvailables = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationDefinitionDto, cache: this.dataContext.notificationDefinitionDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerNotificationsAvailable");
        };
        // returns notification definitions availables by trigger definition
        this.getTriggerInstancesAvailables = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.NotificationDefinitionDto, cache: this.dataContext.notificationDefinitionDtos.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerInstancesAvailable")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToNotificationInstancesList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        // NotificationTemplates
        this.getNotificationTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationTemplate, cache: this.dataContext.notificationTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationTemplates");
        };

        // NotificationDefinitionGroup
        this.getNotificationDefinitionGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationDefinitionGroup, cache: this.dataContext.notificationDefinitionGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationDefinitionGroups");
        };
        this.getNotificationDefinitionGroup = function (filter, forceReload) {
            var params = { key: filter.NotificationDefinitionGroupId, dataContext: this.dataContext.notificationDefinitionGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationDefinitionGroups");
        };

        // NotificationInstance
        this.getNotificationInstances = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.NotificationInstance, cache: this.dataContext.notificationInstances.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationInstances")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToNotificationInstancesList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };
        this.getNotificationInstance = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { key: filter.NotificationInstanceId, dataContext: this.dataContext.notificationInstances };

            dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationInstances")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToNotificationInstancesList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        // NotificationSchema
        this.getNotificationSchemas = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationSchema, cache: this.dataContext.notificationSchemas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationSchemas");
        };
        this.getNotificationSchema = function (filter, forceReload) {
            var params = { key: filter.NotificationSchemaId, dataContext: this.dataContext.notificationSchemas };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationSchemas");
        };

        // NotificationSchemaInstance
        this.getNotificationSchemaInstances = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationSchemaInstance, cache: this.dataContext.notificationSchemaInstances.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationSchemaInstances");
        };
        this.getNotificationSchemaInstance = function (filter, forceReload) {
            var params = { key: filter.NotificationSchemaInstanceId, dataContext: this.dataContext.notificationSchemaInstances };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationSchemaInstances");
        };

        // NotificationConfig
        this.getNotificationConfig = function (filter, forceReload) {
            var params = { key: filter.NotificationConfigId, dataContext: this.dataContext.notificationConfigs };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationConfigs");
        };

        // NotificationTarget
        this.getNotificationTargets = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationTarget, cache: this.dataContext.notificationTargets.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationTargets");
        };
        this.getNotificationTarget = function (filter, forceReload) {
            var params = { key: filter.NotificationTargetId, dataContext: this.dataContext.notificationTargets };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationTargets");
        };

        // NotificationTargetEntity
        this.getNotificationTargetEntities = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationTargetEntity, cache: this.dataContext.notificationTargetEntities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationTargetEntities");
        };
        this.getNotificationTargetEntity = function (filter, forceReload) {
            var params = { key: filter.NotificationTargetEntityId, dataContext: this.dataContext.notificationTargetEntities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationTargetEntities");
        };

        // NotificationTargetEntityKey
        this.getNotificationTargetEntityKeys = function (filter, forceReload) {
            var params = { entityType: entityTypes.NotificationTargetEntityKey, cache: this.dataContext.notificationTargetEntityKeys.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "NotificationTargetEntityKeys");
        };
        this.getNotificationTargetEntityKey = function (filter, forceReload) {
            var params = { key: filter.NotificationTargetEntityKeyId, dataContext: this.dataContext.notificationTargetEntityKeys };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "NotificationTargetEntityKeys");
        };

        //Http Post
        this.cloneNotificationSchema = function (filter) {
            var query = dataLayer.InvokeQuery("CloneNotificationSchema", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneNotificationInstance = function (filter) {
            var query = dataLayer.InvokeQuery("CloneNotificationInstance", filter);
            return manager.executeQuery(query);
        };

        // Notifications Paged 
        this.getNotificationsPagedDm = function () {
            return ejDataManager.getEjManager('NotificationsPaged');
        };
        this.getNotificationsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('NotificationsPaged');
            return dmObj;
        };

        this.getMyNotificationsPagedDm = function () {
            return ejDataManager.getEjManager('MyNotificationsPaged');
        };
        this.getMyNotificationsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MyNotificationsPaged');
            return dmObj;
        };

        // property binded in more controllers
        // at moment appPanel+myNotifications
        // used to refresh mynotifications counter to read
        this.myNotificationCounter = 0;
        this.decreaseMyNotificationCounter = function () {
            if (this.myNotificationCounter > 0) {
                this.myNotificationCounter--;
            } else {
                this.myNotificationCounter = 0;
            }
        };

        //#region DataManager
        this.getNotificationDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var notificationStatusEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.NotificationStatus, 'Notification', supportData.translate);            
            var notificationChannelKindEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.NotificationChannelKind, null, null);

            if (supportData.isMyNotifications === true) {
                dmObj = this.getMyNotificationsPagedDmQuery();
                dmObj.dmgrid = this.getMyNotificationsPagedDm();
            } else if (supportData.isMyNotifications === false) {
                dmObj = this.getNotificationsPagedDmQuery();
                dmObj.dmgrid = this.getNotificationsPagedDm();
            }

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                NotificationStatusName: 'NotificationStatusId'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                NotificationStatusName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'NotificationStatusId',
                    Datasource: notificationStatusEnum.list.translated
                }
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results).each((item) => {
                        if (!item.cardSupport) {
                            item.cardSupport = {};
                        }

                        item.NotificationStatusName = notificationStatusEnum.obj.translated.value_name[item.NotificationStatusId];
                        item.NotificationStatusOriginalName = notificationStatusEnum.obj.normal.value_name[item.NotificationStatusId];
                        item.NotificationChannelKindName = notificationChannelKindEnum.obj.normal.value_name[item.NotificationChannelKindId];

                        // format custom fields for grid cards
                        item.cardSupport.CreationDate = Nembus.Common.Library.converter.date.formatDateTime(item.CreationDate, supportData.contextService.virtualContext.cultureInfo, true);
                    });
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['NotificationSubject', 'AddressTo'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    notificationService.$inject = injectParams;
    notificationService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('notificationService', notificationService);
});