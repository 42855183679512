﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function targetsSearch($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        ribbon.setRibbonStatusButton(ribbon.Buttons.New,  true);
        ribbon.refreshStatusButtons();

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;

        var filtersToSkip = [];
        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var searchFormData = nbsSearchForm.SearchTargetsFormData();
        searchFormData.init(vm, filtersToSkip);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('target', null);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        vm.searchTabIndex = 0;

        var dsCallback = function () { return foundationService.getVhrTypes(false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            vm.currentTargetFilter.VhrTypesId = vm.nbsSelectionGridWithModalVhrType.selectedIds;
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            vm.currentTargetFilter.VhrGroupsId = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
        });

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchTargets();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalVhrType.clear();
                vm.nbsSelectionGridWithModalVhrGroup.clear();
            });
        };

        var ejDataManager = null;
        function searchTargets() {
            vm.currentTargetFilter = nbsSearchForm.ParseTargetFilter(vm.currentTargetFilter, appStoreBw);
            
            // only enabled
            vm.currentTargetFilter.VhrStatusId = vhrStatusEnum.Enabled;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = foundationService.getTargetDataManager(supportData, vm.currentTargetFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(vm.currentTargetFilter);
            } else {
                refreshGridDatasource(vm.currentTargetFilter)
            }
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.targetftc", {
                'virtualHumanResourceId': args.data.VirtualHumanResourceId,
                'appStoreCodeFtc': appStoreCode
            });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    targetsSearch.$inject = injectParams;
    targetsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('targetsSearch', targetsSearch);
});