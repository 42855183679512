﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'audService', 'foundationService', 'vhreventService', 'workflowService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function audActsSearch($q, $scope, $timeout, $injector, actService, audService, foundationService, vhreventService, workflowService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 3;
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActWorkflowsSection()]));
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        var forceReload = false;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var actStatusEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActStatus, 'Act', vm.translate);
        var actStatusEnumObj = actStatusEnumParsed.obj.normal.name_value;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.translate);
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var actPermissionManager = new NbsActPermissionManager({
            translator: vm.translate,
            virtualContext: contextService.virtualContext,
            actServiceEnumTypes: actService.dataContext.enumTypes,
            havePermissionFn: vm.havePermission,
            validationSchema: null,
            rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
            audPermissionObj: Nembus.Aud.Library.auth.Permission,
            parseEnumFn: Nembus.Common.Library.util.parseEnum
        });
        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);

        vm.actPrioritiesList = actPriorityEnumParsed.list.translated;
        vm.actWorkflowStatusesList = workflowStatusKindEnumParsed.list.translated;

        //vhrevent life status drop down
        vm.surveyLifeStatusList = surveyLifeStatusEnumParsed.list.translated;

        var filtersToSkip = [];
        var nbsAudSearchForm = new NbsAudSearchForm(vm.translate);
        var searchFormData = nbsAudSearchForm.SearchAudActsFormData();
        searchFormData.init(vm, filtersToSkip);

        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;

        var selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                vm.currentActFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
            } else {
                vm.currentActFilter.CategoriesId = [];
            }
        });

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.viewUsers();
        vm.nbsActionDrbacModalObj.viewUserGroups();
        vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
            if (vm.nbsActionDrbacModalObj.drbacUserGroupAreSelected()) {
                vm.currentActFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();
            } else {
                vm.currentActFilter.UserGroupAssignedIds = [];
            }
            if (vm.nbsActionDrbacModalObj.appUserAreSelected()) {
                vm.currentActFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();
            } else {
                vm.currentActFilter.AppUserAssignedIds = [];
            }
        });

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventType.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventTypesId = vm.nbsSelectionGridWithModalVhrEventType.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventGroup.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventGroupsId = vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds;
        });

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            vm.currentActFilter.VhrTypeIds = vm.nbsSelectionGridWithModalVhrType.selectedIds;
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            vm.currentActFilter.VhrGroupIds = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
        });

        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: false, dataKey: 'surveycompleted', filter: { VhrEventLifeStatusIds: [surveyLifeStatusEnumObj.ReportedAsFinished] } };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurvey.setOnSelectFnCallback(function () {
            vm.currentSurveyFilter.SurveyIds = vm.nbsSelectionGridWithModalSurvey.selectedIds;
        });

        selectionParams = {
            stateTo: 'app.nav.surveytemplateslistmodal',
            destModalKey: 'surveyTemplate'
        };
        vm.nbsSelectionGridWithModalSurveyTemplate = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalSurveyTemplate.setOnSelectFnCallback(function () {
            vm.currentSurveyVhrEventFilter.SurveyTemplatesId = vm.nbsSelectionGridWithModalSurveyTemplate.selectedIds;
        });

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VirtualHumanResourcesId = vm.nbsSelectionGridWithModalTarget.selectedIds;
        });

        dsCallback = function () { return vhreventService.getVhrEventLabels({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventLabel', viewTitle: vm.translate('SurveyDto.VhrEventLabels'), isMultiselect: true, ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventLabel = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrEventLabel.setOnSelectFnCallback(function () {
            vm.currentVhrEventFilter.VhrEventLabelsId = vm.nbsSelectionGridWithModalVhrEventLabel.selectedIds;
        });

        vm.surveyCreationPatternList = [
            { value: surveyCreationPatternObj.InstanceOnDemand, name: vm.translate('OnCall') }
        ];

        // workflow buttons disabled onload
        setActionButtonsStatus();

        vm.searchTabIndex = 0;
        
        vm.currentActFilter.ActStartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActStartDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vm.currentActFilter.ActExpireDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActExpireDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vm.currentActFilter.ActExecutionDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActExecutionDate2Expression = expressionTypeEnum.LessThanOrEqual;

        loadData(forceReload);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getWorkflowActiveStatuses(false, moduleTargetId));

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchAudActs();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalVhrEventType.clear();
                vm.nbsSelectionGridWithModalVhrEventGroup.clear();
                vm.nbsSelectionGridWithModalSurveyTemplate.clear();
                vm.nbsSelectionGridWithModalTarget.clear();
                vm.nbsSelectionGridWithModalVhrEventLabel.clear();
                vm.nbsSelectionGridWithModalSurvey.clear();
                vm.nbsActionDrbacModalObj.clear();
                vm.nbsSelectionGridWithModalVhrType.clear();
                vm.nbsSelectionGridWithModalVhrGroup.clear();
                vm.nbsSelectionTreeviewWithModalCategory.clear();
            });
        };

        var currentActFilter = null;
        var ejDataManager = null;
        function searchAudActs() {
            var clientDataFilter = {};

            vm.currentSurveyVhrEventFilter.SurveyCompileDateSystem1Expression = expressionTypeEnum.GreaterThanOrEqual;
            vm.currentSurveyVhrEventFilter.SurveyCompileDateSystem2Expression = expressionTypeEnum.LessThanOrEqual;
            vm.currentVhrEventFilter.ModuleTargetId = moduleTargetId;
            vm.currentVhrEventFilter.HaveToExpandVhrsWithAssetTree = true;
            vm.currentVhrEventFilter.HaveToExpandCategories = true;

            clientDataFilter.actFilter = nbsAudSearchForm.ParseActFilter(vm.currentActFilter);
            clientDataFilter.surveyVhrEventFilter = nbsAudSearchForm.ParseSurveyVhrEventFilter(vm.currentSurveyVhrEventFilter, appStoreBw);
            clientDataFilter.vhrEventFilter = nbsAudSearchForm.ParseVhrEventFilter(vm.currentVhrEventFilter, appStoreBw, vhrEventKindEnum, expressionTypeEnum);
            clientDataFilter.surveyFilter = nbsAudSearchForm.ParseSurveyFilter(vm.currentSurveyFilter, appStoreBw);
            
            if (!clientDataFilter.vhrEventFilter.VhrEventGroupsId
                && !clientDataFilter.vhrEventFilter.VhrEventTypesId
                && !clientDataFilter.vhrEventFilter.VhrEventLabelsId
                && !clientDataFilter.vhrEventFilter.WorkCentersId
                && !clientDataFilter.vhrEventFilter.VirtualHumanResourcesId
                && !clientDataFilter.vhrEventFilter.StartDate1
                && !clientDataFilter.vhrEventFilter.StartDate2
                && !clientDataFilter.vhrEventFilter.WorkflowStatusId
                && !Number(clientDataFilter.vhrEventFilter.VhrEventLifeStatus) > 0
            ) {
                clientDataFilter.vhrEventFilter = {};
            }

            clientDataFilter.actFilter.ActStatusId = actStatusEnumObj.Opened;
            
            // refresh of filter parsed
            currentActFilter = clientDataFilter;


            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService, audService: audService };
                ejDataManager = actService.getActDataManager(supportData, currentActFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentActFilter);
            } else {
                refreshGridDatasource(currentActFilter)
            }
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateToActDetail({ act: args.data, appStoreCode: appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        var gridResult = {};
        vm.gridSelection = function (args) {
            gridResult = args;

            setActionButtonsStatus();
        };

        vm.workflowExecuteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowExecuteMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, gridResult.dataIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();

                    setActionButtonsStatus();
                });
            });
        };

        vm.workflowModifyAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowModifyMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, gridResult.dataIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();

                    setActionButtonsStatus();
                });
            });
        };

        vm.workflowInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var currentAct = gridResult.data[0];
                navigationService.navToActWorkflowHistoryMode(vm.suspendDirty, currentAct.ActId);
            }
        };

        vm.deleteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canDeleteAct) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            actService.actMassivelyUpdateRequestManageDeleteAct(contextService, vm.translate, gridResult.dataIds)
                .then(() => {
                    // force refresh grid
                    vm.refreshAction();
                });
        };

        function setActionButtonsStatus() {
            $timeout(function () {
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusExecute, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusInfo, gridResult.isSingleSelection);
                ribbon.refreshStatusButtons();
            });
        }

        function getWorkflowActiveStatuses(forceReload, moduleTargetId) {
            var defer = $q.defer();

            workflowService.getWorkflowActiveStatuses({ ModuleTargetId: moduleTargetId }, forceReload)
                .then(function (data) {
                    vm.workflowStatusList = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }
    }

    audActsSearch.$inject = injectParams;
    audActsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audActsSearch', audActsSearch);
});