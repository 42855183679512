﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];
    var dataflowService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('dataflow', true);
        var ejDataManager = dataLayer.GetEjDataManager();
        var manager = dataLayer.GetManager();        
        var entityTypes = {
            FileShuffleRequest: 'FileShuffleRequest',
            FileShuffleJournal: 'FileShuffleJournal',
            FileShuffleDefinition: 'FileShuffleDefinition'
        };
        var me = this;

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            fileShuffleRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.FileShuffleRequest),
            fileShuffleJournals: dataLayer.setContextMethods(manager, nbsService, entityTypes.FileShuffleJournal),
            fileShuffleDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.FileShuffleDefinition),
            
            enumTypes: {
                FileConnectionType: dataLayer.GetEnumType('FileConnectionType'),
                FileAction: dataLayer.GetEnumType('FileAction'),
                FileShuffleRequestStatus: dataLayer.GetEnumType('FileShuffleRequestStatus')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getFileShuffleRequests = function (forceReload) {
            var params = { entityType: entityTypes.FileShuffleRequest, cache: this.dataContext.fileShuffleRequests.get() };
            return dataLayer.GetDataListPromise(params, null, forceReload, "FileShuffleRequests");
        };

        this.getFileShuffleJournals = function (filter, forceReload) {
            var params = { entityType: entityTypes.FileShuffleJournal, cache: this.dataContext.fileShuffleJournals.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FileShuffleJournals");
        };

        this.getFileShuffleRequestsPagedDm = function () {
            return ejDataManager.getEjManager('FileShuffleRequestsPaged');
        };
        this.getFileShuffleRequestsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('FileShuffleRequestsPaged');
            return dmObj;
        };

        this.getFileShuffleJournalsPagedDm = function () {
            return ejDataManager.getEjManager('FileShuffleJournalsPaged');
        };
        this.getFileShuffleJournalsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('FileShuffleJournalsPaged');
            return dmObj;
        };

        this.getFileShuffleDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.FileShuffleDefinition, cache: this.dataContext.fileShuffleDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "FileShuffleDefinitions");
        };
        this.getFileShuffleDefinition = function (filter, forceReload) {
            var params = { key: filter.FileShuffleDefinitionId, dataContext: this.dataContext.fileShuffleDefinitions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "FileShuffleDefinitions");
        };


        //#region DataManager
        this.getFileShuffleRequestDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var fileShuffleRequestStatusEnumObj = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.FileShuffleRequestStatus, null, supportData.translate).obj.normal.value_name;

            dmObj = me.getFileShuffleRequestsPagedDmQuery();
            dmObj.dmgrid = me.getFileShuffleRequestsPagedDm();

            var ejDataManager = dmObj.dmgrid;
            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];
                    data.results = me.getAttachedEntities(data.results);

                    Lazy(data.results)
                        .each((item) => {
                            item.FileShuffleRequestStatusName = fileShuffleRequestStatusEnumObj[item.FileShuffleRequestStatusId];
                            item.FileShuffleDefinitionName = supportData.fileShuffleDefinitionsObj[item.FileShuffleDefinitionId];
                        });

                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['WarningMessage'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getFileShuffleJournalDataManager = function (filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = me.getFileShuffleJournalsPagedDmQuery();
            dmObj.dmgrid = me.getFileShuffleJournalsPagedDm();

            var ejDataManager = dmObj.dmgrid;
            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['WarningMessage'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion
    };

    dataflowService.$inject = injectParams;
    dataflowService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('dataflowService', dataflowService);
});