﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'audService', 'workflowService', 'utilService', 'vhreventService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function audActsExecutionList($q, $scope, $timeout, $injector, actService, audService, workflowService, utilService, vhreventService, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'massive');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 3;
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActWorkflowsSection()]));
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();
        ribbon.setRibbonStatusButton(ribbon.Buttons.Search, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusExecute, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusModify, true);
        ribbon.refreshStatusButtons();

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var actStatusEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActStatus, 'Act', vm.translate);
        var actStatusEnumObj = actStatusEnumParsed.obj.normal.name_value;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var selectedActs = [];

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var actPermissionManager = new NbsActPermissionManager({
            translator: vm.translate,
            virtualContext: contextService.virtualContext,
            actServiceEnumTypes: actService.dataContext.enumTypes,
            havePermissionFn: vm.havePermission,
            validationSchema: null,
            rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
            audPermissionObj: Nembus.Aud.Library.auth.Permission,
            parseEnumFn: Nembus.Common.Library.util.parseEnum
        });
        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);

        var dsCallback = function () { return vhreventService.getVhrEventTypes({ AppStoreBw: appStoreBw }, false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventType', viewTitle: vm.translate('SurveyDto.VhrEventTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return vhreventService.getVhrEventGroups({ AppStoreBw: appStoreBw }, false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrEventGroup', viewTitle: vm.translate('SurveyDto.VhrEventGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrEventGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrTypes(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        //#region Multiselect with grid selection
        selectionParams = { stateTo: 'app.nav.surveyslistmodal', destModalKey: 'survey', isMultiselect: true };
        vm.nbsSelectionGridWithModalSurvey = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        //#endregion

        //#region assignment modal
        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.viewUsers();
        vm.nbsActionDrbacModalObj.viewUserGroups();
        //#endregion

        vm.actPrioritiesForDropDown = actPriorityEnumParsed.list.translated;
        vm.workflowStatusKindsForDropDown = workflowStatusKindEnumParsed.list.translated;


        vm.formFilter = {};

        vm.searchTabIndex = 0;

        vm.searchAction = function () {
            searchActDtos();
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        var currentActFilter = null;
        var ejDataManager = null;
        function searchActDtos() {
            var filter = {
                actFilter: {
                    ActStatusId: actStatusEnumObj.Opened,
                    HaveToExpandVhrsWithAssetTree: true,
                    HaveToExpandCategories: true
                },
                surveyActionFilter: {},
                vhrEventFilter: null,
                virtualHrFilter: {}
            };

            // actFilter
            filter.actFilter.ActStartDate1 = vm.formFilter.ActStartDate1;
            filter.actFilter.ActStartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            filter.actFilter.ActStartDate2 = vm.formFilter.ActStartDate2;
            filter.actFilter.ActStartDate2Expression = expressionTypeEnum.LessThanOrEqual;

            filter.actFilter.ActExpireDate1 = vm.formFilter.ActExpireDate1;
            filter.actFilter.ActExpireDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            filter.actFilter.ActExpireDate2 = vm.formFilter.ActExpireDate2;
            filter.actFilter.ActExpireDate2Expression = expressionTypeEnum.LessThanOrEqual;

            filter.actFilter.ActExecutionDate1 = vm.formFilter.ActExecutionDate1;
            filter.actFilter.ActExecutionDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
            filter.actFilter.ActExecutionDate2 = vm.formFilter.ActExecutionDate2;
            filter.actFilter.ActExecutionDate2Expression = expressionTypeEnum.LessThanOrEqual;

            filter.actFilter.ActTargetIds = vm.nbsSelectionGridWithModalTarget.selectedIds
                ? vm.nbsSelectionGridWithModalTarget.selectedIds
                : [];
            filter.actFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds
                ? vm.nbsSelectionTreeviewWithModalCategory.selectedIds
                : [];
            filter.actFilter.ActPriorityId = vm.formFilter.ActPriorityId;
            filter.actFilter.ActWorkflowStatusKindId = vm.formFilter.ActWorkflowStatusKindId;

            filter.actFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();
            filter.actFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();

            // surveyActionFilter
            filter.surveyActionFilter.SurveyIds = vm.nbsSelectionGridWithModalSurvey.selectedIds
                ? vm.nbsSelectionGridWithModalSurvey.selectedIds
                : [];

            if (Lazy(vm.nbsSelectionGridWithModalVhrEventType.selectedIds).some() || Lazy(vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds).some()) {
                filter.vhrEventFilter = {
                    ModuleTargetId: moduleTargetId,
                    VhrEventTypesId: vm.nbsSelectionGridWithModalVhrEventType.selectedIds,
                    VhrEventGroupsId: vm.nbsSelectionGridWithModalVhrEventGroup.selectedIds
                };
            }

            filter.virtualHrFilter.VhrTypesId = Lazy(vm.nbsSelectionGridWithModalVhrType.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrType.selectedIds : [];
            filter.virtualHrFilter.VhrGroupsId = Lazy(vm.nbsSelectionGridWithModalVhrGroup.selectedIds).some() ? vm.nbsSelectionGridWithModalVhrGroup.selectedIds : [];

            // refresh of filter parsed
            currentActFilter = filter;

            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService, audService: audService };
                ejDataManager = actService.getActDataManager(supportData, currentActFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentActFilter);
            } else {
                refreshGridDatasource(currentActFilter)
            }
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateToActDetail({ act: args.data, appStoreCode: appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.gridSelection = function (args) {
            selectedActs = args.data;
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.workflowExecuteAction = function () {
            if (!Lazy(selectedActs).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(selectedActs);
            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            var actIds = Lazy(selectedActs).pluck('ActId').toArray();

            navigationService.navToActWorkflowExecuteMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, actIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate })
                    .then(() => {
                        // force refresh grid
                        vm.refreshAction();
                    });
            });
        };

        vm.workflowModifyAction = function () {
            if (!Lazy(selectedActs).some()) {
                navigationService.selectAtLeastOneItemMessageModal();
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(selectedActs);
            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            var actIds = Lazy(selectedActs).pluck('ActId').toArray();

            navigationService.navToActWorkflowModifyMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, actIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate })
                    .then(() => {
                        // force refresh grid
                        vm.refreshAction();
                    });
            });
        };
    }

    audActsExecutionList.$inject = injectParams;
    audActsExecutionList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('audActsExecutionList', audActsExecutionList);
});