﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'foundationService', 'workflowService', 'actService', 'drbacService', 'audService', 'rbacService', 'utilService', 'navigationService', 'checkStateChangeService', 'fileTransferService', 'contextService', 'cmsService'];
    function surveyTemplateDetail($q, $injector, $scope, $timeout, foundationService, workflowService, actService, drbacService, audService, rbacService, utilService, navigationService, checkStateChangeService, fileTransferService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, audService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addPrintReportTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetTemplateNewSection());
        ribbon.addTab(ribbon.GetAuditSurveyDetailManageTab());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var fsDtoData = new NbsFsDtoData({
            fileTransferService: fileTransferService,
            virtualContext: contextService.virtualContext,
            translator: vm.translate,
            thumbnailDatasourceIsGrouped: false,
            formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
            nbsGuidManager: NbsGuidManager
        });
        var surveyInputTypeEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyInputType, 'SurveyQuestion', vm.translate);
        var surveyInputTypeEnumObj = surveyInputTypeEnumParsed.obj.normal.name_value;
        var surveyId = navigationService.stateParams().surveyId;
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var workflowStatusKindEnumObj = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, null, vm.translate).obj.normal.name_value;
        var sectionSelected = null;
        var canManageActFreetextQuestions = Nembus.Common.Library.server.getServerVarBool('ssv_audit_act_question_freetext_manage_rule');

        vm.surveyIsEditable = true;
        vm.modalSection = null;
        vm.modalErrorSummary = null;
        vm.activeDetailTabIndex = 0;
        vm.optionvalue_ssv_parsed = Nembus.Common.Library.server.parseSsvWeightValueList('value', vm.translate);
        vm.optionweight_ssv_parsed = Nembus.Common.Library.server.parseSsvWeightValueList('weight', vm.translate);
        vm.ssv_valueListDropdown = [];
        vm.ssv_weightListDropdown = [];

        vm.documents = [];

        vm.actionCardDatasource = new Nembus.Aud.Library.SurveyActionTemplateDs(vm.translate, vm.havePermission, contextService);
        vm.survey = null;
        vm.questions = [];
        vm.options = [];
        vm.sections = [];
        vm.sectionQuestions = [];
        vm.modalQuestion = [];
        vm.modalQuestionOptions = [];
        vm.modalOptionChoiceGroups = null;
        vm.modalQuestionIsEditMode = false;
        vm.surveyOptionChoices = [];
        vm.surveyOptionChoiceGroups = [];
        vm.questionTabIsActive = false;
        vm.questionOptionTabIsActive = false;
        vm.questionOptionTabIsVisible = false;
        vm.actTabIsVisible = false;
        vm.actTabIsActive = false;
        vm.attachmentsTabIsVisible = false;
        vm.attachmentsTabIsActive = false;

        var surveyInputTypesList = surveyInputTypeEnumParsed.list.translated;
        vm.supportData = {
            surveyFreeTextTranslation: surveyInputTypeEnumParsed.obj.translated.value_name[surveyInputTypeEnumObj.SurveyFreeText],

            surveyInputTypes: surveyInputTypesList,			// radio, checkbox
            surveyInputTypeObj: surveyInputTypeEnumObj,

            surveyOptionChoiceGroupsForUI: [],
            surveyOptionChoiceGroupSelected: null,		// store group of option choice in creation question
            saveOptionChoiceGroup: false				// used to save eventually new option choice group with related option choices
        };

        addQuestionCreationButton()

        // on start modify button is disabled
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        if (surveyId) {
            vm.setIsViewing();
            setSurveyRibbonButtonsStatus(false);

            vm.modifyIsLocked = true;
            loadDataForUpdate(true);
        } else {
            vm.setIsAdding();
            setSurveyRibbonButtonsStatus(true);

            vm.modifyIsLocked = false;
            loadDataForInsert(false);
        }

        function setSurveyRibbonButtonsStatus(isEnabled) {
            ribbon.setRibbonStatusButton(ribbon.Buttons.NewQuestion, isEnabled);
            ribbon.setRibbonStatusButton(ribbon.Buttons.NewSection, isEnabled);
            ribbon.setRibbonStatusButton(ribbon.Buttons.ModifySection, isEnabled);
            ribbon.refreshStatusButtons();
        }

        function loadDataForInsert(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyTemplateGroups(forceReload));
            funcsPromises.push(getSurveyTemplateTypes(forceReload));
            funcsPromises.push(getValidationSchemaInstancesPublish(forceReload, false));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.surveyTemplateGroups = results[0];
                    vm.surveyTemplateTypes = results[1];
                    vm.validationInstances = results[2];

                    var defaultSsvValue = Lazy(vm.optionvalue_ssv_parsed).findWhere({ isDefaultSsv: true });
                    var defaultSsvWeight = Lazy(vm.optionweight_ssv_parsed).findWhere({ isDefaultSsv: true });

                    // new survey is ever a template
                    var newSurveyTemplate = audService.dataContext.surveyTemplates.add({
                        SurveyTemplateCode: NbsGuidManager.newGuid(),
                        SurveyTemplateGroupId: null,
                        SurveyTemplateTypeId: null,
                        ValidationSchemaId: null,
                        IsActionsEnabled: true,
                        OptionsWeightRange: defaultSsvWeight.value,
                        OptionsValueRange: defaultSsvValue.value,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                        ModuleTargetId: moduleTargetId
                    });

                    setSurveyTemplate(newSurveyTemplate);

                    setWeightAndValueDropDownBySurvey(vm.survey);
                });
        }

        function setSurveyTemplate(survey) {
            vm.survey = survey;
            vm.actionCardDatasource.actFireIsOptionableVisible = survey.IsActionFireOptional;
        }

        function loadDataForUpdate(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getSurveyTemplateGroups(forceReload));
            funcsPromises.push(getSurveyTemplateTypes(forceReload));
            funcsPromises.push(getSurveyTemplate(forceReload, surveyId));
            funcsPromises.push(getSurveyTemplateSections(forceReload, surveyId));
            funcsPromises.push(getSurveyTemplateQuestions(forceReload, surveyId));
            funcsPromises.push(getSurveyTemplateQuestionOptions(forceReload, surveyId));
            funcsPromises.push(getValidationSchemaInstancesPublish(forceReload, null));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.surveyTemplateGroups = results[0];
                    vm.surveyTemplateTypes = results[1];

                    vm.sections = results[3];

                    setSurveyTemplate(results[2]);

                    addSurveyQuestions(results[4]);
                    addSurveyQuestionOptions(results[5]);
                    vm.setCurrentSurveySectionSelected(sectionSelected);

                    vm.validationInstances = results[6];

                    // manage locked UI
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, true);
                    ribbon.refreshStatusButtons();

                    setWeightAndValueDropDownBySurvey(vm.survey);
                });
        }

        // add new section to survey template
        vm.newAuditSurveySection = function () {
            addSurveySection();
        };

        vm.surveySortableParams = {
            update: function (e, ui) {
                if (vm.viewContext.isLocked) { ui.item.sortable.cancel(); }
            },
            stop: function (e, ui) {
                if (!vm.viewContext.isLocked) {
                    switch (ui.item.sortable.model.entityAspect.entity.entityType.shortName) {
                        case 'SurveySection':
                            Nembus.Rds.Library.SetArrayOrder(vm.sections, 'SurveySectionOrder');
                            break;
                        case 'SurveyQuestion':
                            Nembus.Rds.Library.SetArrayOrder(vm.sectionQuestions, 'SurveyQuestionOrder');
                            break;
                        case 'SurveyQuestionOption':
                            Nembus.Rds.Library.SetArrayOrder(vm.modalQuestionOptions, 'SurveyQuestionOptionOrder');
                            break;
                    }
                }
            }
        };

        vm.setCurrentSurveySectionSelected = function (section) {
            sectionSelected = section;

            vm.sectionQuestions = [];

            if (section) {
                vm.sectionQuestions = Lazy(vm.questions)
                    .filter(notRemovedFilter)
                    .where({ SurveySectionId: section.SurveySectionId })
                    .sortBy((q) => { return q.SurveyQuestionOrder; })
                    .toArray();

                Lazy(vm.sectionQuestions)
                    .each((sectionQuestion) => {
                        sectionQuestion.questionOptions = Lazy(vm.options)
                            .filter(notRemovedFilter)
                            .where({ SurveyQuestionId: sectionQuestion.SurveyQuestionId })
                            .sortBy((q) => { return q.SurveyQuestionOptionOrder; })
                            .toArray();
                    });
            }
        };

        // #region Delete methods
        vm.deleteSurveySection = function (surveySection) {
            navigationService.confirmModal(vm.translate('DeleteSection'))
                .then(function (result) {
                    if (result) {
                        // removed from UI
                        surveySection.entityAspect.setDeleted();
                        vm.sections = Lazy(vm.sections).reject({ SurveySectionId: surveySection.SurveySectionId }).toArray();

                        // set new order of remaining sections
                        Nembus.Rds.Library.SetArrayOrder(vm.sections, 'SurveySectionOrder');

                        // submit
                        submit();
                    }
                });
        };
        vm.deleteSurveyQuestion = function (surveyQuestion) {
            // do not remove, fix to not change status change on icon click
            surveyQuestion.SurveyQuestionCardIsOpened = !surveyQuestion.SurveyQuestionCardIsOpened;

            navigationService.confirmModal(vm.translate('DeleteQuestion'))
                .then(function (result) {
                    if (result) {
                        // removed from UI
                        surveyQuestion.entityAspect.setDeleted();

                        vm.sectionQuestions = Lazy(vm.sectionQuestions).reject({ SurveyQuestionId: surveyQuestion.SurveyQuestionId }).toArray();

                        // set new order of remaining questions
                        Nembus.Rds.Library.SetArrayOrder(vm.sectionQuestions, 'SurveyQuestionOrder');

                        // submit (From DB were removed related actions)
                        submit();
                    }
                });
        };
        vm.deleteSurveyQuestionOption = function (surveyQuestionOption) {
            navigationService.confirmModal(vm.translate('DeleteAnswer'))
                .then(function (result) {
                    if (result) {
                        deleteSurveyQuestionOption(surveyQuestionOption);
                    }
                });
        };
        vm.deleteSurveyOptionChoiceGroup = function (surveyOptionChoiceGroupForUI) {
            navigationService.confirmModal(vm.translate('DeleteAnswerTemplate'))
                .then(function (result) {
                    if (result) {
                        audService.deleteSurveyOptionChoiceGroup(surveyOptionChoiceGroupForUI.SurveyOptionChoiceGroup.SurveyOptionChoiceGroupId)
                            .then(function (data) {
                                if (data.results[0] === '1') {
                                    // remove from UI in modal
                                    vm.modalOptionChoiceGroups = Lazy(vm.modalOptionChoiceGroups).reject({ SurveyOptionChoiceGroupId: surveyOptionChoiceGroupForUI.SurveyOptionChoiceGroup.SurveyOptionChoiceGroupId }).toArray();

                                    // set create answer as default
                                    setSurveyOptionChoiceGroupsForUISelection(null);

                                    refreshOptionChoiceData(vm.survey.SurveyId);
                                }
                            });
                    }
                });
        };
        // #endregion

        function getValidationSchemaInstancesPublish(forceReload, isArchived) {
            var filter = {
                EntityName: 'Act',
                IsArchived: isArchived
            };
            var defer = $q.defer();

            foundationService.getValidationSchemaInstancesPublish(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function refreshOptionChoiceData(surveyTemplateId) {
            audService.dataContext.surveyOptionChoices.clearCache();
            audService.dataContext.surveyOptionChoiceGroups.clearCache();

            getSurveyOptionChoicesBySurveyTemplateId(true, surveyTemplateId);
            getSurveyOptionChoiceGroups(true, surveyTemplateId);
        }

        vm.addSurveyQuestionOption = function () {
            var newSurveyQuestionOption = Nembus.Aud.Library.GetNewSurveyQuestionOption(vm.modalQuestion.SurveyQuestionId);
            newSurveyQuestionOption.SurveyQuestionOptionOrder = Nembus.Rds.Library.GetLastArrayOrderIndex(vm.modalQuestionOptions, 'SurveyQuestionOptionOrder');
            newSurveyQuestionOption.OptionWeight = vm.survey.IsOptionsWeightEnabled ? null : 0;
            newSurveyQuestionOption.OptionValue = vm.survey.IsOptionsValueEnabled ? null : 0;
            newSurveyQuestionOption.OptionAggravating = 0;

            var newQuestionOption = audService.dataContext.surveyQuestionOptions.add(newSurveyQuestionOption);
            vm.modalQuestionOptions.push(newQuestionOption);
        };

        vm.cloneSurveyQuestion = function (sourceSurveyQuestion) {
            // do not remove, fix to not change status change on icon click
            sourceSurveyQuestion.SurveyQuestionCardIsOpened = !sourceSurveyQuestion.SurveyQuestionCardIsOpened;

            navigationService.confirmModal(vm.translate('CloneQuestion'))
                .then(function (result) {
                    if (result) {
                        audService.cloneSurveyQuestion({ SurveyQuestionId: sourceSurveyQuestion.SurveyQuestionId })
                            .then(function (saveResult) {
                                var funcsPromises = [];
                                funcsPromises.push(getSurveyTemplateQuestions(true, vm.survey.SurveyId));
                                funcsPromises.push(getSurveyTemplateQuestionOptions(true, vm.survey.SurveyId));

                                $q.all(funcsPromises)
                                    .then(function (results) {
                                        vm.questions = [];
                                        vm.options = [];
                                        audService.dataContext.surveyActionDtos.clearCache();

                                        var questions = results[0];
                                        var questionOptions = results[1];

                                        Lazy(questions)
                                            .each((entity) => {
                                                if (entity.entityAspect.entityState.isDetached()) {
                                                    entity.entityAspect.setUnchanged();
                                                }
                                            })

                                        addSurveyQuestions(questions);
                                        addSurveyQuestionOptions(questionOptions);
                                        vm.setCurrentSurveySectionSelected(sectionSelected);
                                    });
                            });
                    }
                });
        };

        // #region Modal question ADD/UPDATE
        vm.modifySurveyQuestion = function (surveyQuestion) {
            addUpdateSurveyQuestion(surveyQuestion);
        };
        vm.newAuditSurveyQuestion = function () {
            addUpdateSurveyQuestion(null);
        };

        function setModalQuestion(question) {
            vm.modalQuestion = question;

            if (!question) {
                console.error('getSurveyQuestionOptions: surveyQuestion is mandatory');
                return;
            }

            vm.modalQuestionOptions = Lazy(vm.options)
                .filter(notRemovedFilter)
                .sortBy((o) => { return o.SurveyQuestionOptionOrder; })
                .where({ SurveyQuestionId: question.SurveyQuestionId })
                .toArray();
        };

        function setModalQuestionIsEditMode(isEditMode, question) {
            vm.modalQuestionIsEditMode = isEditMode;

            vm.questionOptionTabIsVisible = isEditMode;
            if (question && question.SurveyInputTypeId === vm.supportData.surveyInputTypeObj.SurveyFreeText) {
                vm.actTabIsVisible = isEditMode && canManageActFreetextQuestions;
            } else {
                vm.actTabIsVisible = isEditMode;
            }
            vm.attachmentsTabIsVisible = vm.survey.IsAttachmentsEnabled && isEditMode;
            vm.questionTabIsActive = true;
            vm.questionOptionTabIsActive = false;
            vm.actTabIsActive = false;
            vm.attachmentsTabIsActive = false;
        };

        function addSurveyQuestions(questions) {
            Lazy(questions)
                .each((question) => {
                    addSurveyQuestion(question);
                });
        };

        function addSurveyQuestion(question) {
            if (!question.SurveySectionId) {
                console.error('surveyQuestion.SurveySectionId not setted');
            }

            // view card is opened default
            question.SurveyQuestionCardIsOpened = true;

            if (!Lazy(vm.questions).where({ SurveyQuestionId: question.SurveyQuestionId }).some()) {
                vm.questions.push(question);
            }
        };

        function addSurveyQuestionOptions(questionOptions) {
            Lazy(questionOptions)
                .each((questionOption) => {
                    addSurveyQuestionOption(questionOption);
                });
        };

        function addSurveyQuestionOption(questionOption) {
            if (!questionOption.SurveyQuestionId) {
                console.error('questionOption.SurveyQuestionId not setted');
            }

            if (!Lazy(vm.options).where({ SurveyQuestionOptionId: questionOption.SurveyQuestionOptionId }).some()) {
                vm.options.push(questionOption);
            }
        };

        function addUpdateSurveyQuestion(surveyQuestion) {
            var isModifyMode = false;
            if (surveyQuestion) {
                // do not remove, fix to not change status change on icon click
                surveyQuestion.SurveyQuestionCardIsOpened = !surveyQuestion.SurveyQuestionCardIsOpened;

                isModifyMode = true;
            }

            if (!isModifyMode && !sectionSelected) {
                navigationService.customMessageModal(vm.translate('SelectSection'));
                return;
            }

            var funcsPromises = [];
            funcsPromises.push(getActWorkflowActiveStatuses(false));
            funcsPromises.push(getActActionGroups(false));
            funcsPromises.push(getActActionTypes(false));
            funcsPromises.push(getCategoriesSubTreeOutsideAuthorization(false, 'ACT'));
            funcsPromises.push(getCurrentContextAppUsers(false));
            funcsPromises.push(getSurveyOptionChoicesBySurveyTemplateId(false, vm.survey.SurveyId));
            funcsPromises.push(getSurveyOptionChoiceGroups(false, vm.survey.SurveyId));
            funcsPromises.push(getDrbacUserGroups(false, virtualCompanyId));
            funcsPromises.push(getSurveyActionDtos(false, vm.survey.SurveyId));
            funcsPromises.push(getSurveyTemplateDocuments(true, vm.survey.SurveyId));
            funcsPromises.push(getSurveyTemplateAnswerSummaries(true, vm.survey.SurveyId));
            funcsPromises.push(getValidationSchemaInstanceDto(true, vm.survey.ValidationSchemaId));

            $q.all(funcsPromises)
                .then(function () {
                    setModalQuestionIsEditMode(isModifyMode, surveyQuestion);

                    if (isModifyMode) {
                        setModalQuestion(surveyQuestion);
                    } else {
                        // IS Addinq question
                        // reset data binded on modal
                        vm.supportData.surveyOptionChoiceGroupSelected = null;
                        vm.supportData.saveOptionChoiceGroup = false;
                        var newModalQuestion = audService.dataContext.surveyQuestions.add({
                            SurveyInputTypeId: surveyInputTypeEnumObj.SurveyRadio,
                            SurveyQuestionOrder: Nembus.Rds.Library.GetLastArrayOrderIndex(vm.sectionQuestions, 'SurveyQuestionOrder'),
                            SurveySectionId: sectionSelected.SurveySectionId
                        });

                        setModalQuestion(newModalQuestion);

                        vm.modalOptionChoiceGroups = getSurveyOptionChoiceGroupsForUI();
                    }

                    setCurrentActionCards();

                    vm.currentAnswerSummary = Lazy(vm.answerSummaries).findWhere({ SurveyQuestionId: vm.modalQuestion.SurveyQuestionId });
                    if (!vm.currentAnswerSummary) {
                        var summaryRootNode = fsDtoData.getNewRootNode();
                        vm.currentAnswerSummary = audService.dataContext.surveyAnswerSummaries.add({
                            DocFsFolderId: summaryRootNode.FsDtoId,
                            SurveyQuestionId: vm.modalQuestion.SurveyQuestionId
                        });
                        utilService.dataContext.fsDtos.add(summaryRootNode);
                    }

                    // open modal
                    vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'AddUpdateSurveyQuestionModal', scope: $scope }));
                    vm.modalInstance.ok = function () {
                        vm.modalErrorSummary = null;

                        var resultValidation = Nembus.Aud.Library.ValidateSurveyQuestion(
                            vm.modalQuestion
                            , vm.modalQuestionOptions
                            , vm.actionCardDatasource
                            , vm.modalQuestionIsEditMode
                            , vm.supportData
                        );

                        if (resultValidation.hasError) {
                            vm.modalErrorSummary = vm.translate(resultValidation.errorMessageKey);
                            return;
                        }

                        vm.modalQuestion.AllowMultipleOptionAnswer = vm.modalQuestion.SurveyInputTypeId === surveyInputTypeEnumObj.SurveyCheckbox;

                        // most important
                        // if fire action can be decided in compiltion then default ActIsFiredOptionally is TRUE
                        Lazy(vm.actionCardDatasource.actions)
                            .each((action) => {
                                action.ActIsFiredOptionally = action.ActFireIsOptional;
                            });

                        // EDITING MODE
                        if (isModifyMode) {
                            parents()
                                .then(function (saveResult) {
                                    // add survey question option
                                    addSurveyQuestionOptions(vm.modalQuestionOptions);

                                    // refresh parsed UI choices, options
                                    vm.setCurrentSurveySectionSelected(sectionSelected);

                                    // refresh action for UI
                                    vm.actions = audService.dataContext.surveyActionDtos.get();

                                    // fsdtos
                                    utilService.saveChanges()
                                        .then(function (saveResult) {
                                            vm.modalInstance.close();
                                        });
                                });
                        } else {
                            // ADDING MODE
                            var optionChoiceGroupSelected = vm.supportData.surveyOptionChoiceGroupSelected;
                            if (optionChoiceGroupSelected.SurveyOptionChoiceGroupId === 0) { // Create new options

                                // save answers like model
                                if (vm.supportData.saveOptionChoiceGroup) {
                                    // add new option choice group to context
                                    var newOptionChoiceGroup = audService.dataContext.surveyOptionChoiceGroups.add({
                                        SurveyTemplateId: vm.survey.SurveyId,
                                        SurveyInputTypeId: vm.modalQuestion.SurveyInputTypeId
                                    });

                                    // add new option choices related to group just inserted
                                    Lazy(vm.modalQuestionOptions)
                                        .each((item) => {
                                            audService.dataContext.surveyOptionChoices.add({
                                                SurveyOptionChoiceGroupId: newOptionChoiceGroup.SurveyOptionChoiceGroupId,
                                                SurveyOptionChoiceName: item.SurveyQuestionOptionName,
                                                SurveyOptionChoiceOrder: item.SurveyQuestionOptionOrder,
                                                SurveyOptionChoiceColor: item.SurveyQuestionOptionColor,
                                                AnswerWeight: item.OptionWeight,
                                                AnswerValue: item.OptionValue,
                                                AnswerAggravating: item.OptionAggravating
                                            });
                                        });
                                }
                            }

                            parents()
                                .then(function (saveResult) {
                                    // add survey question
                                    addSurveyQuestion(vm.modalQuestion);

                                    // add survey question option
                                    addSurveyQuestionOptions(vm.modalQuestionOptions);

                                    refreshOptionChoiceData(vm.survey.SurveyId);

                                    // refresh parsed UI options
                                    vm.setCurrentSurveySectionSelected(sectionSelected);

                                    // refresh action for UI
                                    vm.actions = audService.dataContext.surveyActionDtos.get();

                                    // fsdtos
                                    utilService.saveChanges()
                                        .then(function (saveResult) {
                                            vm.modalInstance.close();
                                        });
                                });
                        }
                    };
                    vm.modalInstance.cancel = function () {
                        Nembus.Common.Library.entity.rejectChanges([vm.modalQuestion]);
                        Nembus.Common.Library.entity.rejectChanges(vm.modalQuestionOptions);
                        Nembus.Common.Library.entity.rejectChanges(vm.actionCardDatasource.actions);
                        Nembus.Common.Library.entity.rejectChanges([vm.currentAnswerSummary]);
                        Nembus.Common.Library.entity.rejectChanges(vm.summariesDocs);

                        vm.setCurrentSurveySectionSelected(sectionSelected);

                        vm.modalInstance.dismiss();
                    };
                    vm.modalInstance.result.then(function () {
                        // onClose
                    }, function () {
                        // onDismiss
                    });
                });
        }
        // #endregion

        vm.onFileUploaded = function (file, res) {
            vm.removeAlerts();

            var fs = {
                FsDtoId: NbsGuidManager.newGuid(),
                ParentFsDtoId: vm.currentAnswerSummary.DocFsFolderId,
                FsName: file.name,
                DisplayName: file.name,
                DisplayFileName: file.name,
                FsTypeId: audService.fsType.file,
                VirtualCompanyId: virtualCompanyId,
                FileNotSavedYet: true
            };
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            fs.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);
            var fsDto = utilService.dataContext.fsDtos.add(fs);

            vm.summariesDocs.push(fsDto);
            fsDtoData.setFsDtos(vm.summariesDocs); // refresh ico
        };

        vm.editDocument = function (document) {
            vm.currentdocument = document;

            vm.modalInstanceDocument = navigationService.modal().open(vm.modalOptions({ templateUrl: 'editDocumentTemplate', scope: $scope }));
            vm.modalInstanceDocument.ok = function () {
                vm.modalInstanceDocument.close();
            };
            vm.modalInstanceDocument.cancel = function () {
                vm.modalInstanceDocument.dismiss();
            };
            vm.modalInstanceDocument.result.then(function () {
                // onClose
            }, function () {
                Nembus.Common.Library.entity.rejectChanges([vm.currentdocument]);
            });
        };

        vm.downloadDocument = function (document, asAttach) {
            if (!document.TemporaryFileName) {
                fileTransferService.downloadRemoteFileByGuid({
                    entityGuid: document.FsDtoId,
                    asAttach: asAttach
                });
            } else {
                fileTransferService.downloadRemoteTemporary({
                    temporaryFileName: document.TemporaryFileName,
                    asAttach: asAttach
                });
            }
        };

        // #region Modal section ADD
        function addSurveySection() {
            vm.survey.entityAspect.validateEntity();
            if (vm.survey.entityAspect.hasValidationErrors) {
                vm.activeDetailTabIndex = 0;
            } else {
                vm.removeAlertFromList(true);

                vm.modalSection = audService.dataContext.surveySections.add({
                    SurveySectionOrder: Nembus.Rds.Library.GetLastArrayOrderIndex(vm.sections, 'SurveySectionOrder'),
                    SurveyId: vm.survey.SurveyId,
                    SurveySectionRequired: true
                });

                vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'AddSurveySectionModal', scope: $scope }));
                vm.modalInstance.ok = function () {
                    vm.modalSection.entityAspect.validateEntity();
                    if (!vm.modalSection.entityAspect.hasValidationErrors) {
                        vm.sections.push(vm.modalSection);

                        vm.modalInstance.close();
                    }

                    // only submit without reload data
                    vm.submitAction();
                };
                vm.modalInstance.cancel = function () {
                    Nembus.Common.Library.entity.rejectChanges([vm.modalSection]);
                    vm.modalInstance.dismiss();
                };
                vm.modalInstance.result.then(function () {
                    // onClose
                }, function () {
                    // onDismiss
                });
            }
        }
        // #endregion

        // #region Modal section UPDATE
        vm.modifyAuditSurveySection = function () {
            vm.sections = Lazy(vm.sections)
                .sortBy((s) => { return s.SurveySectionOrder; })
                .toArray();

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'ManageSurveySectionModal', scope: $scope }));
            vm.modalInstance.ok = function () {
                var resultValidation = Nembus.Aud.Library.ValidateSurveySections(vm.sections);
                if (!resultValidation.hasError) {
                    vm.modalInstance.close();
                }

                submit();
            };
            vm.modalInstance.cancel = function () {
                Nembus.Common.Library.entity.rejectChanges(vm.sections);
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                // onClose
            }, function () {
                // onDismiss
            });
        };

        // #endregion
        vm.actsTabIsSelected = function () {
            // refresh data in actions
            vm.actionCardDatasource.setActions(vm.actionCardDatasource.actions);
            vm.actionCardDatasource.refreshActionDs();
        };

        vm.isActionFireOptionalOnChange = function () {
            vm.actionCardDatasource.actFireIsOptionableVisible = vm.survey.IsActionFireOptional;
        };

        vm.surveyInputTypeOnChange = function () {
            if (vm.modalQuestion.SurveyInputTypeId === vm.supportData.surveyInputTypeObj.SurveyFreeText) {
                Lazy(vm.modalQuestionOptions)
                    .each((questionOption) => {
                        // removed from UI
                        deleteSurveyQuestionOption(questionOption);
                    });

                if (!canManageActFreetextQuestions) {
                    // reset actions
                    Nembus.Common.Library.entity.rejectChanges(vm.actionCardDatasource.actions);
                    vm.actionCardDatasource.actions = [];
                }

                // add new FreeText question option
                var newFreetextQuestionOption = Nembus.Aud.Library.GetNewSurveyQuestionOption(vm.modalQuestion.SurveyQuestionId);
                newFreetextQuestionOption.SurveyQuestionOptionName = '-'; // cannot be null
                newFreetextQuestionOption.IsImportant = false;
                // add to context
                newFreetextQuestionOption = audService.dataContext.surveyQuestionOptions.add(newFreetextQuestionOption);
                vm.modalQuestionOptions.push(newFreetextQuestionOption);

                // refresh questionoptions action datasource and refresh selected serialized
                vm.actionCardDatasource.refreshActionQuestionOptionSerialized(vm.modalQuestionOptions);
            }

            // acts tab visibility
            if (vm.modalQuestion.SurveyInputTypeId === vm.supportData.surveyInputTypeObj.SurveyFreeText) {
                vm.actTabIsVisible = canManageActFreetextQuestions;
            } else {
                vm.actTabIsVisible = true;
            }
        };

        vm.optionChoiceOnClick = function (optionChoiceGroup) {
            setSurveyOptionChoiceGroupsForUISelection(optionChoiceGroup);

            // reset modaloptions
            Lazy(vm.modalQuestionOptions)
                .each((questionOption) => {
                    // removed from UI
                    deleteSurveyQuestionOption(questionOption);
                });

            // reset actions
            Nembus.Common.Library.entity.rejectChanges(vm.actionCardDatasource.actions);
            vm.actionCardDatasource.actions = [];


            if (optionChoiceGroup.SurveyOptionChoiceGroupId === 0) {
                // CREA RISPOSTA
                vm.modalQuestion.SurveyInputTypeId = vm.supportData.surveyInputTypeObj.SurveyRadio;

                vm.modalQuestion.entityAspect.validateEntity();

                if (!vm.modalQuestion.entityAspect.hasValidationErrors) {
                    vm.questionTabIsActive = false;
                    vm.questionOptionTabIsActive = true;
                    vm.questionOptionTabIsVisible = true;
                    if (vm.modalQuestion.SurveyInputTypeId === vm.supportData.surveyInputTypeObj.SurveyFreeText) {
                        vm.actTabIsVisible = canManageActFreetextQuestions;
                    } else {
                        vm.actTabIsVisible = true;
                    }
                    vm.actTabIsActive = false;
                    vm.attachmentsTabIsVisible = vm.survey.IsAttachmentsEnabled;
                    vm.attachmentsTabIsActive = false;
                }
            } else {
                // MODELLO RISPOSTA SELEZIONATO
                // Selected a predefined answer
                vm.modalQuestion.SurveyInputTypeId = optionChoiceGroup.SurveyOptionChoiceGroup.SurveyInputTypeId;

                var surveyOptionChoices = Lazy(vm.surveyOptionChoices).where({ SurveyOptionChoiceGroupId: Number(optionChoiceGroup.SurveyOptionChoiceGroupId) }).toArray();
                Lazy(surveyOptionChoices)
                    .each((item) => {
                        vm.modalQuestionOptions.push(audService.dataContext.surveyQuestionOptions.add({
                            SurveyQuestionId: vm.modalQuestion.SurveyQuestionId,
                            SurveyQuestionOptionName: item.SurveyOptionChoiceName,
                            SurveyQuestionOptionOrder: item.SurveyOptionChoiceOrder,
                            SurveyQuestionOptionColor: item.SurveyOptionChoiceColor,
                            OptionWeight: item.AnswerWeight,
                            OptionValue: item.AnswerValue,
                            OptionAggravating: item.AnswerAggravating
                        }));
                    });

                vm.questionOptionTabIsVisible = false;
                vm.actTabIsVisible = false;
                vm.attachmentsTabIsVisible = false;
            }
        };

        // #endregion
        function deleteSurveyQuestionOption(questionOption) {
            questionOption.entityAspect.setDeleted();

            // refresh modal question options UI
            vm.modalQuestionOptions = Lazy(vm.modalQuestionOptions)
                .filter(notRemovedFilter)
                .toArray();

            // set new order of remaining question options
            Nembus.Rds.Library.SetArrayOrder(vm.modalQuestionOptions, 'SurveyQuestionOptionOrder');

            // refresh questionoptions action datasource and refresh selected serialized
            vm.actionCardDatasource.refreshActionQuestionOptionSerialized(vm.modalQuestionOptions);
        };

        function setSurveyOptionChoiceGroupsForUISelection(optionChoice) {
            if (!optionChoice) {
                optionChoice = Lazy(vm.supportData.surveyOptionChoiceGroupsForUI).findWhere({ SurveyOptionChoiceGroupId: 0 });
            }

            Lazy(vm.supportData.surveyOptionChoiceGroupsForUI).each((currentOptionChoiceGroupForUI) => {
                currentOptionChoiceGroupForUI.ClassName = currentOptionChoiceGroupForUI.SurveyOptionChoiceGroupId === optionChoice.SurveyOptionChoiceGroupId
                    ? 'nbs-survey-option-choice-selected'
                    : null;
            });

            vm.supportData.surveyOptionChoiceGroupSelected = optionChoice;
        };

        function getSurveyOptionChoiceGroupsForUI() {
            var JOINARRAYPATTERN = ' / ';

            vm.supportData.surveyOptionChoiceGroupsForUI = [];
            addQuestionCreationButton();

            Lazy(vm.surveyOptionChoiceGroups)
                .each((surveyOptionChoiceGroup) => {
                    var surveyOptionChoicesList = Lazy(vm.surveyOptionChoices).where({ SurveyOptionChoiceGroupId: surveyOptionChoiceGroup.SurveyOptionChoiceGroupId }).toArray();
                    if (surveyOptionChoicesList.length > 0) {
                        var surveyOptionChoicesParsed = Lazy(surveyOptionChoicesList)
                            .sortBy((optionChoice) => { return optionChoice.SurveyOptionChoiceOrder; }, false)
                            .pluck('SurveyOptionChoiceName').toArray();

                        surveyOptionChoicesParsed = surveyOptionChoicesParsed.join(JOINARRAYPATTERN);

                        var surveyOptionChoiceForUI = new NbsAudSurveyOptionChoiceForUI(surveyOptionChoiceGroup.SurveyOptionChoiceGroupId, surveyOptionChoicesParsed, null, surveyOptionChoiceGroup);
                        vm.supportData.surveyOptionChoiceGroupsForUI.push(surveyOptionChoiceForUI);
                    }
                });

            return vm.supportData.surveyOptionChoiceGroupsForUI;
        };

        function addQuestionCreationButton() {
            var surveyOptionChoiceForUI = new NbsAudSurveyOptionChoiceForUI(0, vm.translate('CreateAnswer'), 'nbs-survey-option-choice-selected');
            vm.supportData.surveyOptionChoiceGroupsForUI.push(surveyOptionChoiceForUI);
        }
        function addSurveyOptionChoice(optionChoice) {
            if (!Lazy(vm.surveyOptionChoices).where({ SurveyOptionChoiceId: optionChoice.SurveyOptionChoiceId }).some()) {
                vm.surveyOptionChoices.push(optionChoice);
            }
        }
        function addSurveyOptionChoiceGroup(optionChoiceGroup) {
            if (!Lazy(vm.surveyOptionChoiceGroups).where({ SurveyOptionChoiceGroupId: optionChoiceGroup.SurveyOptionChoiceGroupId }).some()) {
                vm.surveyOptionChoiceGroups.push(optionChoiceGroup);
            }
        }

        function notRemovedFilter(entity) {
            return !entity.entityAspect.entityState.isDeleted() && !entity.entityAspect.entityState.isDetached();
        }

        function setCurrentActionCards() {
            var params = {
                surveyId: vm.survey.SurveyId,
                surveyQuestionId: vm.modalQuestion.SurveyQuestionId,
                appStoreBw: appStoreBw,
                virtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                actService: actService,
                audService: audService,
                utilService: utilService,
                navigationService: navigationService,
                workflowService: workflowService
            };

            var datasources = {
                categories: vm.categories,
                questionOptions: vm.modalQuestionOptions,
                drbacUserGroups: vm.drbacUserGroups,
                appUsers: vm.currentUsers,
                workflowStatuses: vm.workflowStatuses,
                actActionGroups: vm.actActionGroups,
                actActionTypes: vm.actActionTypes,
                validationSchema: vm.validationSchema
            };

            vm.actionCardDatasource.init(params, datasources);
            vm.actionCardDatasource.setActionPreDefined();
            vm.actionCardDatasource.byPassAssignmentControl();

            var actions = Lazy(vm.actions).where({ SurveyQuestionId: vm.modalQuestion.SurveyQuestionId }).toArray();
            vm.actionCardDatasource.setActions(actions);

            // actionDto.SurveyActionTypeId === QUESTION 
            // actionDto.SurveyActionTypeId === QUESTIONOPTION
            vm.actionCardDatasource.setActionDsFnFilter(function (a) {
                return (a.SurveyActionTypeId === 2 || a.SurveyActionTypeId === 3) && a.SurveyQuestionId === vm.modalQuestion.SurveyQuestionId
                    && !a.entityAspect.entityState.isDeleted() && !a.entityAspect.entityState.isDetached();
            });

            vm.actionCardDatasource.refreshActionDs();
        }

        function getSurveyTemplateDocuments(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            audService.getSurveyTemplateDocuments($sxb(filter), forceReload)
                .then(function (data) {
                    fsDtoData.setFsDtos(data.results);

                    vm.summariesDocs = fsDtoData.getFiles();                    // attachments related to AnswerSummary
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplate(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            audService.getSurveyTemplate(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateSections(forceReload, surveyId) {
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };
            var defer = $q.defer();

            audService.getSurveyTemplateSections(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateQuestions(forceReload, surveyId) {
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };
            var defer = $q.defer();

            audService.getSurveyTemplateQuestions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyOptionChoicesBySurveyTemplateId(forceReload, surveyTemplateId) {
            var filter = { SurveyTemplateId: surveyTemplateId };
            var defer = $q.defer();

            audService.getSurveyOptionChoicesBySurveyTemplateId(filter, forceReload)
                .then(function (data) {
                    vm.surveyOptionChoices = [];
                    Lazy(data.results)
                        .each((optionChoice) => {
                            addSurveyOptionChoice(optionChoice);
                        });
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyOptionChoiceGroups(forceReload, surveyTemplateId) {
            var filter = { SurveyTemplateId: surveyTemplateId };
            var defer = $q.defer();

            audService.getSurveyOptionChoiceGroups(filter, forceReload)
                .then(function (data) {
                    vm.surveyOptionChoiceGroups = [];
                    Lazy(data.results)
                        .each((optionChoiceGroup) => {
                            addSurveyOptionChoiceGroup(optionChoiceGroup);
                        });

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateQuestionOptions(forceReload, surveyId) {
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };
            var defer = $q.defer();

            audService.getSurveyTemplateQuestionOptions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyActionDtos(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            audService.getSurveyActionDtos(filter, forceReload)
                .then(function (data) {
                    vm.actions = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateTypes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            audService.getSurveyTemplateGroups(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCategoriesSubTreeOutsideAuthorization(forceReload, systemCategoryCode) {
            var filter = { SystemCategoryCode: systemCategoryCode };
            var defer = $q.defer();

            foundationService.getCategoriesSubTreeOutsideAuthorization(filter, forceReload)
                .then(function (data) {
                    vm.categories = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var filter = {};
            var defer = $q.defer();

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    vm.currentUsers = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActWorkflowActiveStatuses(forceReload) {
            var filter = {
                WorkFlowStatusKindId: workflowStatusKindEnumObj.ToExecute
            };
            var defer = $q.defer();

            workflowService.getActWorkflowActiveStatuses(filter, forceReload)
                .then(function (data) {
                    vm.workflowStatuses = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    vm.actActionTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    vm.actActionGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };
            var defer = $q.defer();

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.drbacUserGroups = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyTemplateAnswerSummaries(forceReload, surveyId) {
            var defer = $q.defer();

            audService.getSurveyTemplateAnswerSummariesBySurveyId($sxb(surveyId), forceReload)
                .then(function (data) {
                    vm.answerSummaries = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getValidationSchemaInstanceDto(forceReload, validationSchemaInstanceId) {
            var defer = $q.defer();

            foundationService.getValidationSchemaInstanceDto(validationSchemaInstanceId, forceReload)
                .then(function (data) {
                    vm.validationSchema = data;
                    defer.resolve(vm.validationSchema);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            parentm().then(
                //enable disable button new & delete because is custom behavior
                //generally saveResult eneble or disable only save button 
                function (saveResult) {
                    vm.modifyIsLocked = vm.viewContext.isLocked;

                    setSurveyRibbonButtonsStatus(vm.viewContext.isEditingState);

                    vm.setCurrentSurveySectionSelected(sectionSelected);
                }
            );
        };

        vm.optionsWeightRangeOnChange = function () {
            setWeightAndValueDropDownBySurvey(vm.survey);
        };
        vm.optionsValueRangeOnChange = function () {
            setWeightAndValueDropDownBySurvey(vm.survey);
        };

        function setWeightAndValueDropDownBySurvey(survey) {
            var surveySsvValue = Lazy(vm.optionvalue_ssv_parsed).findWhere({ value: survey.OptionsValueRange });
            var surveySsvWeight = Lazy(vm.optionweight_ssv_parsed).findWhere({ value: survey.OptionsWeightRange });
            vm.ssv_valueListDropdown = surveySsvValue.data;
            vm.ssv_weightListDropdown = surveySsvWeight.data;
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            // submit
            submit();
        };

        function submit() {
            var defer = $q.defer();

            var changes = Nembus.Aud.Library.GetChangesSurveyWeightsAndValues(vm.survey, vm.questions, vm.options, vm.supportData.surveyInputTypeObj, vm.ssv_valueListDropdown, vm.ssv_weightListDropdown);
            if (Lazy(changes).some()) {
                navigationService.customMessageModal(vm.translate('QuestionOptionWeightAndValueMessage'));
            }

            parents()
                .then(function (saveResult) {
                    vm.setCurrentSurveySectionSelected(sectionSelected);

                    defer.resolve(saveResult);
                });

            return defer.promise;
        }

        //#region Override of base methods to add verify on selection record
        function canPrintReport() {
            var result = false;
            if (vm.survey) {
                result = true;
            }
            return result;
        }

        vm.exportReportToPdfAction = function () { pExportReport('pdf'); };
        vm.exportReportToWordAction = function () { pExportReport('openword'); };
        vm.exportReportToXmlAction = function () { pExportReport('xml'); };
        vm.exportReportToExcelAction = function () { pExportReport('openexcel'); };
        vm.exportReportToHtmlAction = function () { pExportReport('html'); };

        function pExportReport(format) {
            if (canPrintReport()) {
                vm.getReportsForCmsView(cmsService)
                    .then(function () {
                        var cEntity = vm.survey;
                        var currentReportDefinition = vm.currentReportData.reportDefinition;
                        var currentConcatName = cEntity.SurveyCode + ' - ' + cEntity.SurveyName;

                        if (currentReportDefinition) {
                            currentConcatName = currentReportDefinition.ReportDefinitionReferenceCode + ' - ' + currentConcatName;
                        }

                        var reportArgs = {
                            Ds1_Arg1: { SurveyId: cEntity.SurveyId },                 // SurveyFilter
                            Ds1_Arg2: false                                                             // IsConfigurationMode
                        };

                        vm.setReportData({ reportArgs: reportArgs, replaceName: currentConcatName });

                        vm.exportReport(format);
                    });
            }
        }
        //#endregion overload of base methods to add verify on selection record
    }

    surveyTemplateDetail.$inject = injectParams;
    surveyTemplateDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyTemplateDetail', surveyTemplateDetail);
});