﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'customerService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService', 'foundationService'];
    var customerDataDetail = function ($q, $scope, $timeout, $injector, customerService, navigationService, checkStateChangeService, contextService, utilService, foundationService) {

        var vm = this;
        nbsVm.call(vm, customerService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, customerService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSyncCustomerSection()]));
        vm.ribbonDatacontext = ribbon;

        var customerStorageTypeEnumParsed = Nembus.Common.Library.util.parseEnum(customerService.dataContext.enumTypes.CustomerStorageType, 'CustomerData', vm.translate);
        var dataStorageUnitEnumParsed = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.DataStorageUnit, null, vm.translate);
        var customerContactTypeEnumParsed = Nembus.Common.Library.util.parseEnum(customerService.dataContext.enumTypes.CustomerContactType, 'CustomerData', vm.translate);
        var customerBackupTypeEnumParsed = Nembus.Common.Library.util.parseEnum(customerService.dataContext.enumTypes.CustomerBackupType, null, null);
        vm.isUA = contextService.virtualContext.IsUa;

        vm.customerBackupTypeEnumDropDown = customerBackupTypeEnumParsed.list.translated;
        vm.customerStorageTypeEnumDropDown = customerStorageTypeEnumParsed.list.translated;
        vm.dataStorageUnitEnumDropDown = dataStorageUnitEnumParsed.list.translated;
        vm.customerStorageTypeEnumObj = customerStorageTypeEnumParsed.obj.normal.name_value;
        vm.customerContactTypeList = customerContactTypeEnumParsed.list.translated;
        vm.customerContactTypeEnumObj = customerContactTypeEnumParsed.obj.translated.value_name;

        vm.canModifyCusDataGeneral = vm.havePermission(Nembus.Rds.Library.auth.Permission['ATS_SETUP_VIEW_CUSDATA_GENERAL'], contextService.virtualContext);
        vm.canModifyCusDataOtherSections = vm.havePermission(Nembus.Rds.Library.auth.Permission['ATS_SETUP_VIEW_CUSDATA_OTHERS'], contextService.virtualContext);

        //vm.customerServicesMultiselect = new NbsMultiselect(vm.translate);
        //vm.customerServicesMultiselect.setOnchangeFnCallback(function () {
        //    vm.currentCustomer.CustomerServices = Lazy(vm.customerServicesMultiselect.selectedIds).some()
        //        ? JSON.stringify(vm.customerServicesMultiselect.selectedIds)
        //        : null;
        //});

        vm.currentCustomer = null;
        vm.authtsdatetimelineselected = null;        
        vm.setIsViewing();

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, vm.canModifyCusData);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Sync, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Send, true);
        ribbon.refreshStatusButtons();

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('authaudit', null);
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false);
        nbsGridDatacontext.allowGrouping = false;
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getCustomerDatas(forceReload));
            //funcsPromises.push(getCustomerServices(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentCustomer = results[0];
                    //vm.customerServicesMultiselect.addData(results[1], 'CustomerServiceCode', 'CustomerServiceName');

                    var subFuncsPromises = [];
                    subFuncsPromises.push(getCustomerContacts(forceReload, vm.currentCustomer.CustomerDataId));
                    subFuncsPromises.push(getCustomerBackupDtos(forceReload, vm.currentCustomer.CustomerDataId));

                    $q.all(subFuncsPromises)
                        .then(function (subresults) {
                            vm.currentCustomerContacts = subresults[0];
                            vm.currentCustomerBackups = subresults[1];
                            Lazy(vm.currentCustomerBackups)
                                .each((item) => {
                                    if (item.CustomerBackupLastDate) {
                                        item.CustomerBackupLastDateUI = new Date(item.CustomerBackupLastDate);
                                        item.CustomerBackupLastTimeUI = Nembus.Common.Library.converter.timespan.dateToJson(item.CustomerBackupLastDateUI, true);
                                    }
                                    if (item.CustomerBackupFirstDate) {
                                        item.CustomerBackupFirstDateUI = new Date(item.CustomerBackupFirstDate);
                                        item.CustomerBackupFirstTimeUI = Nembus.Common.Library.converter.timespan.dateToJson(item.CustomerBackupFirstDateUI, true);
                                    }
                                })

                            Lazy(vm.currentCustomerContacts)
                                .each((item) => {
                                    item.CustomerContactTypeName = vm.customerContactTypeEnumObj[item.CustomerContactTypeId];
                                });

                            vm.currentCustomer.CustomerUsedSpaceLastUpdateUI = Nembus.Common.Library.converter.date.formatDateTime(vm.currentCustomer.CustomerUsedSpaceLastUpdate, contextService.virtualContext.cultureInfo, false);

                            // manage of folders
                            if (!vm.currentCustomer.FsFolderId) {
                                vm.rootFsDtoId = NbsGuidManager.newGuid();
                                utilService.setFsDtoRootAddFunction({
                                    entityService: foundationService,
                                    rootFolderKey: vm.rootFsDtoId,
                                    fnSetRootFolder: function (folderId) { vm.currentCustomer.FsFolderId = folderId; }
                                });
                            } else {
                                vm.rootFsDtoId = vm.currentCustomer.FsFolderId;
                            }

                            //// set selected services
                            //if (vm.currentCustomer.CustomerServices) {
                            //    var sServices = Nembus.Common.Library.converter.decodeHtml(vm.currentCustomer.CustomerServices);
                            //    vm.currentCustomer.CustomerServices = sServices; 
                            //    vm.currentCustomer.entityAspect.setUnchanged()

                            //    if (Lazy(sServices).some()) {
                            //        vm.customerServicesMultiselect.setSelected(JSON.parse(sServices)).toArray()
                            //    }
                            //}
                        });
                });
        }

        var parents = vm.submitAction;
        vm.submitAction = function () {
            var customerHasErrors = false;
            
            vm.currentCustomer.entityAspect.validateEntity();
            customerHasErrors = vm.currentCustomer.entityAspect.hasValidationErrors;

            if (!customerHasErrors) {
                parents();
            }
        };

        //vm.refreshAction = function () {
            
        //};

        vm.syncAction = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        customerService.updateCustomerData()
                            .then((result) => {
                                loadData(true);
                            });
                    }
                });
        };

        vm.sendAction = function () {
            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        customerService.sendCustomerDataToTracy();
                    }
                });
        };

        vm.tabAttachmentsOnSelect = function () {
            // send fsfolderid to media gallery component
            vm.attachmentsDataContext = {
                canShare: false,
                canCategorize: true,
                fsFolderId: vm.rootFsDtoId
            };
        };

        vm.tabLogsOnSelect = function () {
            if (!vm.authtsdatetimelineselected) {
                vm.authtsdatetimelineselected = Nembus.Common.Library.component.bar.datarange.getTimelineDatarange('d+0');
                refreshAuthAudits(vm.authtsdatetimelineselected.dateFrom, vm.authtsdatetimelineselected.dateTo);
            }
        }

        vm.onDatarangeAuthTsSelectionEvent = function (timeline) {
            var actexpiretimelinefrom = timeline.dateFrom;
            var actexpiretimelineto = timeline.dateTo;

            refreshAuthAudits(actexpiretimelinefrom, actexpiretimelineto);
        }

        vm.manageContactOnClick = function (contactType, contact, mode) {
            vm.modalCustomer = null;

            // mode => add, update
            if (mode === 'add') {
                vm.modalCustomer = customerService.dataContext.customerContacts.add({
                    CustomerDataId: vm.currentCustomer.CustomerDataId,
                    CustomerContactTypeId: contactType.value
                })
                vm.modalCustomer.CustomerContactTypeName = contactType.name;
            } else if (mode === 'update') {
                vm.modalCustomer = contact;
            } else {
                console.error('manageContactOnClick mode not managed');
            }


            vm.modal = { instanceName: 'modalInstance', title: contactType.name, datasource: vm.modalCustomer };
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ windowClass: vm.NBS_MODAL_CLASS_FORM_MD, templateUrl: 'contactModalTemplate', scope: $scope }));
            vm.modalInstance.ok = function () {
                vm.modalCustomer.entityAspect.validateEntity();
                if (!vm.modalCustomer.entityAspect.hasValidationErrors) {
                    if (mode === 'add') {
                        vm.currentCustomerContacts.push(vm.modalCustomer);                        
                    }

                    parents()
                        .then(() => {
                            vm.modalInstance.close();
                        });                    
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalCustomer.entityAspect.rejectChanges();
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {
                //onClose
            }, function () {
                //onDismiss                
            });
        };

        vm.deleteContactOnClick = function (contact) {
            customerService.deleteEntity(contact);
            vm.currentCustomerContacts = Lazy(vm.currentCustomerContacts).reject({ CustomerContactId: contact.CustomerContactId }).toArray();
        };

        vm.openCustomerStatusPage = function () {
            if (vm.currentCustomer.CustomerStatusPage) {
                window.open(vm.currentCustomer.CustomerStatusPage);
            }
        }

        function refreshAuthAudits(from, to) {
            var defer = $q.defer();
            var filter = {
                AuthTsDate1: from,
                AuthTsDate2: to,
                ServerHostName: document.location.hostname,
                CustomerCode: contextService.virtualContext.CurrentCustomerCode,
                CustomerAppCode: contextService.virtualContext.CurrentCustomerAppCode
            };

            foundationService.getAuthAudits(filter, true)
                .then(function (data) {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: data.results
                    }

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCustomerDatas(forceReload) {
            var filter = {
                CustomerKey: null  // only one customerdata
            };
            var defer = $q.defer();

            customerService.getCustomerDatas(filter, forceReload)
                .then(function (data) {
                    var customerData = null;
                    if (!data.results[0]) {
                        // customer data not inserted yet
                        customerData = customerService.dataContext.customerDatas.add({
                            CustomerKey: NbsGuidManager.newGuid(),
                            CustomerStorageTypeId: vm.customerStorageTypeEnumObj.None
                        })

                        // add customer data by default
                        customerService.saveChanges()
                            .then(function (result) {
                                defer.resolve(customerData);
                            });
                    } else {
                        customerData = data.results[0];
                        defer.resolve(customerData);
                    }
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCustomerContacts(forceReload, customerDataId) {
            var filter = {
                CustomerDataId: customerDataId
            };
            var defer = $q.defer();

            customerService.getCustomerContacts(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCustomerBackupDtos(forceReload, customerDataId) {
            var filter = {
                CustomerDataId: customerDataId
            };
            var defer = $q.defer();

            customerService.getCustomerBackupDtos(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //function getCustomerServices(forceReload) {
        //    var filter = {};
        //    var defer = $q.defer();

        //    customerService.getCustomerServices(filter, forceReload)
        //        .then(function (data) {
        //            defer.resolve(data.results);
        //        })
        //        .catch(function (error) {
        //            vm.setErrorMsg(error);
        //        });

        //    return defer.promise;
        //}
    };

    customerDataDetail.$inject = injectParams;
    customerDataDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('customerDataDetail', customerDataDetail);
});