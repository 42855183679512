﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var metricService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('metric', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            MetricData: 'MetricData',
            MetricKpiDefinition: 'MetricKpiDefinition',
            MetricSpecialization: 'MetricSpecialization',
            MetricSpecializationDto: 'MetricSpecializationDto',
            MetricInstance: 'MetricInstance',
            MetricDataRequest: 'MetricDataRequest',
            MetricDataRequestJournal: 'MetricDataRequestJournal',

            MetricInstanceM2MVhrDto: 'MetricInstanceM2MVhrDto',
            MetricInstanceM2MMetricSpecializationDto: 'MetricInstanceM2MMetricSpecializationDto',

            MetricTableReportFormClientData: 'MetricTableReportFormClientData'
        };
        var me = this;

        //breeze client type
        //#region client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.MetricTableReportFormClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    PeriodId: { type: breeze.DataType.String },
                    MetricInstanceId: { type: breeze.DataType.Int32 },
                    MetricValueUnitId: { type: breeze.DataType.Int32 }
                }
            },
            'Nembus.Domain'
        );

        var metricTableReportFormClientData = manager.metadataStore.getEntityType(entityTypes.MetricTableReportFormClientData);
        metricTableReportFormClientData.getProperty("PeriodId").validators.push(new breeze.Validator("periodValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        metricTableReportFormClientData.getProperty("MetricInstanceId").validators.push(new breeze.Validator("metricInstanceValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        //#endregion

        this.entityTypes = entityTypes;
        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            metricDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricData),
            metricKpiDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricKpiDefinition),
            metricSpecializations: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricSpecialization),
            metricSpecializationDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricSpecializationDto),
            metricInstances: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricInstance),
            metricDataRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricDataRequest),
            metricDataRequestJournals: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricDataRequestJournal),
            metricTableReportFormClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricTableReportFormClientData),

            // M2M
            metricInstanceM2MVhrDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricInstanceM2MVhrDto),
            metricInstanceM2MHrBucketDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricInstanceM2MHrBucketDto),
            metricInstanceM2MMetricSpecializationDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.MetricInstanceM2MMetricSpecializationDto),

            enumTypes: {
                MetricValueUnit: dataLayer.GetEnumType('MetricValueUnit'),
                MetricDataRange: dataLayer.GetEnumType('MetricDataRange'),
                MetricDataFrequency: Lazy(dataLayer.GetEnumType('MetricDataFrequency'))
                    .filter((item) => {
                        return item.value === '1' || item.value === '2' || item.value === '3';
                    }).toArray(),
                MetricDataRequestStatus: dataLayer.GetEnumType('MetricDataRequestStatus')
            },

            constants: {}
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // MetricKpiDefinition
        this.getMetricKpiDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricKpiDefinition, cache: this.dataContext.metricKpiDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricKpiDefinitions");
        };

        // MetricSpecializationDto
        this.getMetricSpecializationDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricSpecializationDto, cache: this.dataContext.metricSpecializationDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricSpecializationDtos");
        };

        // MetricSpecialization
        this.getMetricSpecializations = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricSpecialization, cache: this.dataContext.metricSpecializations.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricSpecializations");
        };
        this.getMetricSpecialization = function (filter, forceReload) {
            var params = { key: filter.MetricSpecializationId, dataContext: this.dataContext.metricSpecializations };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "MetricSpecializations");
        };

        //MetricKpiData
        this.getMetricDatas = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricData, cache: this.dataContext.metricDatas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricDatas");
        };
        this.getLastOrCurrentDefinitionMetricData = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricData, cache: this.dataContext.metricDatas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "LastOrCurrentDefinitionMetricData");
        };

        // MetricInstance
        this.getMetricInstances = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricInstance, cache: this.dataContext.metricInstances.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricInstances");
        };
        this.getMetricInstance = function (filter, forceReload) {
            var params = { key: filter.MetricInstanceId, dataContext: this.dataContext.metricInstances };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "MetricInstances");
        };

        // MetricInstanceM2MVhrDto
        this.getMetricInstanceM2MVhrDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricInstanceM2MVhrDto, cache: this.dataContext.metricInstanceM2MVhrDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricInstanceM2MVhrDto");
        };

        // MetricInstanceM2MMetricSpecializationDto
        this.getMetricInstanceM2MMetricSpecializationDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.MetricInstanceM2MMetricSpecializationDto, cache: this.dataContext.metricInstanceM2MMetricSpecializationDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "MetricInstanceM2MMetricSpecializationDto");
        };

        // MetricKpiDefinition
        this.getMetricKpiDefinitionPagedDm = function () {
            return ejDataManager.getEjManager('MetricKpiDefinitionPaged');
        };
        this.getMetricKpiDefinitionPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MetricKpiDefinitionPaged');
            return dmObj;
        };

        // MetricSpecialization
        this.getMetricSpecializationPagedDm = function () {
            return ejDataManager.getEjManager('MetricSpecializationDtoPaged');
        };
        this.getMetricSpecializationPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MetricSpecializationDtoPaged');
            return dmObj;
        };

        // MetricInstance
        this.getMetricInstancePagedDm = function () {
            return ejDataManager.getEjManager('MetricInstancePaged');
        };
        this.getMetricInstancePagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MetricInstancePaged');
            return dmObj;
        };

        this.getMetricKpiDefinitionManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var metricValueUnitEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.MetricValueUnit, 'MetricKpiDefinition', supportData.translate);
            var metricValueUnitObj = metricValueUnitEnumParsed.obj.translated.value_name;

            dmObj = this.getMetricKpiDefinitionPagedDmQuery();
            dmObj.dmgrid = this.getMetricKpiDefinitionPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                MetricValueUnitName: 'MetricValueUnitId',
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                MetricValueUnitName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'MetricValueUnitId',
                    Datasource: metricValueUnitEnumParsed.list.translated
                },
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];

                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            item.MetricValueUnitName = metricValueUnitObj[item.MetricValueUnitId];
                        });

                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['MetricKpiDefinitionCode', 'MetricKpiDefinitionName'];
            //ejDataManager.freeSearchFieldsNumber = ['MetricKpiDefinitionId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getMetricSpecializationDtoManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            dmObj = this.getMetricSpecializationPagedDmQuery();
            dmObj.dmgrid = this.getMetricSpecializationPagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {};
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];
                    data.results = me.getAttachedEntities(data.results);
                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['MetricSpecializationCode', 'MetricSpecializationName', 'MetricKpiDefinitionCode', 'MetricKpiDefinitionName'];
            ejDataManager.freeSearchFieldsNumber = ['MetricSpecializationId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getMetricInstanceManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var metricDataRequestStatusParsedEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.MetricDataRequestStatus, null, supportData.translate);
            var metricDataRequestStatusObj = metricDataRequestStatusParsedEnum.obj.normal.name_value;

            var metricDataFrequencyParsedEnum = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.MetricDataFrequency, null, supportData.translate);
            var metricDataFrequencyObj = metricDataFrequencyParsedEnum.obj.translated.value_name;

            dmObj = this.getMetricInstancePagedDmQuery();
            dmObj.dmgrid = this.getMetricInstancePagedDm();
            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                MetricDataFrequencyName: 'MetricDataFrequencyId',
                RunMetricCronExpressionLabel: 'RunMetricCronExpression'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                MetricDataFrequencyName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'MetricDataFrequencyId',
                    Datasource: metricDataFrequencyParsedEnum.list.translated
                },
            };
            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];
                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results)
                        .each((item) => {
                            item.MetricDataFrequencyName = metricDataFrequencyObj[item.MetricDataFrequencyId];
                        });

                    Nembus.Common.Library.parser.AddCronExpressionLabelToList(data.results, 'RunMetricCronExpression', supportData.translate);

                    return data.results;
                }
            };
            ejDataManager.freeSearchFields = ['MetricInstanceCode', 'MetricInstanceName'];
            ejDataManager.freeSearchFieldsNumber = ['MetricInstanceId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        // HttpPost
        this.synchroMetricSpecializationAllVirtualContext = function () {
            var query = dataLayer.InvokeQuery("SynchroMetricSpecializationAllVirtualContext", {});
            return manager.executeQuery(query);
        };

        // HttpPost
        this.setMetricDataUniqueKey = function () {
            var query = dataLayer.InvokeQuery("SetMetricDataUniqueKey", {});
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneMetricSpecialization = function (filter) {
            var query = dataLayer.InvokeQuery("CloneMetricSpecialization", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.cloneMetricInstance = function (filter) {
            var query = dataLayer.InvokeQuery("CloneMetricInstance", filter);
            return manager.executeQuery(query);
        };
    };

    metricService.$inject = injectParams;
    metricService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('metricService', metricService);
});