﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var vhreventService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('vhrevent', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            VhrEventType: 'VhrEventType',
            VhrEventGroup: 'VhrEventGroup',
            VhrEventMatcher: 'VhrEventMatcher',
            VhrEvent: 'VhrEvent',
            VhrEventEntityHistory: 'VhrEventEntityHistory',

            VhrEventDetail: 'VhrEventDetail',
            VhrEventTemplateDto: 'VhrEventTemplateDto',
            VhrEventLabel: 'VhrEventLabel',
            VhrEventShuffleDto: 'VhrEventShuffleDto',
            SmallRouteDto: 'SmallRouteDto',
            VhrEventMatcherM2MTargetActivityDto: 'VhrEventMatcherM2MTargetActivityDto',
            VhrEventMatcherM2MTargetDto: 'VhrEventMatcherM2MTargetDto',
            VhrEventMatcherM2MTargetVhrEventTypeDto: 'VhrEventMatcherM2MTargetVhrEventTypeDto',
            VhrEventMatcherM2MTargetVhrEventGroupDto: 'VhrEventMatcherM2MTargetVhrEventGroupDto',
            VhrEventManualRequest: 'VhrEventManualRequest',
            VhrEventManualWarning: 'VhrEventManualWarning',
            VhrEventMassivelyUpdateRequest: 'VhrEventMassivelyUpdateRequest',
            VhrEventMassivelyUpdateWarning: 'VhrEventMassivelyUpdateWarning',

            VhrEventManualWhatClientData: 'VhrEventManualWhatClientData',
            VhrEventManualUpdateDateClientData: 'VhrEventManualUpdateDateClientData',
            VhrEventsListReportFormClientData: 'VhrEventsListReportFormClientData',
            VhrEventManualStepActivitySelectionClientData: 'VhrEventManualStepActivitySelectionClientData',

            VhrEventMassivelyUpdateRequestClientData: 'VhrEventMassivelyUpdateRequestClientData',

            // business intelligence data (dashboards)
            VhrEventBiData: 'VhrEventBiData',
            VhrEventBiDataHistory: 'VhrEventBiDataHistory',

            // M2M
            VhrEventM2MAppUserDto: 'VhrEventM2MAppUserDto',
            VhrEventM2MDrbacUserGroupDto: 'VhrEventM2MDrbacUserGroupDto'
        };
        var me = this;

        nbsService.call(this, manager, $q, $timeout);

        //breeze client type

        // validation functions list for VhrEventManualWhatClientData
        function vhrEventManualWhatClientDataEndDateValidationFn(value, context) {
            if (context.entity.IsRecurrent === true) {
                if (!context.entity.RecurrenceEndDate) { return false; } else { return true; }
            }
            return true;
        }
        function vhrEventManualWhatClientDataEndTimeValidationFn(value, context) { if (!context.entity.EventEndTime) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataCodeValidationFn(value, context) { if (context.entity.Code === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataNameValidationFn(value, context) { if (context.entity.Name === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataExternalCodeValidationFn(value, context) { if (context.entity.ExternalCode === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataModuleTargetIdValidationFn(value, context) { if (context.entity.ModuleTargetId === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataWorkCenterIdValidationFn(value, context) { if (context.entity.WorkCenterId === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataVhrEventTypeIdValidationFn(value, context) { if (context.entity.VhrEventTypeId === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataVhrEventGroupIdValidationFn(value, context) { if (context.entity.VhrEventGroupId === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataVhrEventTemplateIdValidationFn(value, context) { if (context.entity.VhrEventTemplateId === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataStartDateValidationFn(value, context) { if (context.entity.RecurrenceStartDate === null) { return false; } else { return true; } }
        function vhrEventManualWhatClientDataStartTimeValidationFn(value, context) { if (context.entity.EventStartTime === null) { return false; } else { return true; } }

        var vhrEventManualWhatClientDataCodeValidator = new breeze.Validator('codeValidator', vhrEventManualWhatClientDataCodeValidationFn);
        var vhrEventManualWhatClientDataNameValidator = new breeze.Validator('nameValidator', vhrEventManualWhatClientDataNameValidationFn);
        var vhrEventManualWhatClientDataExternalCodeValidator = new breeze.Validator('vhrEventManualWhatClientDataExternalCodeValidator', vhrEventManualWhatClientDataExternalCodeValidationFn);
        var vhrEventManualWhatClientDataModuleTargetIdValidator = new breeze.Validator('vhrEventManualWhatClientDataModuleTargetIdValidator', vhrEventManualWhatClientDataModuleTargetIdValidationFn);
        var vhrEventManualWhatClientDataWorkCenterIdValidator = new breeze.Validator('workCenterValidator', vhrEventManualWhatClientDataWorkCenterIdValidationFn);
        var vhrEventManualWhatClientDataVhrEventTypeIdValidator = new breeze.Validator('vhrEventManualWhatClientDataVhrEventTypeIdValidator', vhrEventManualWhatClientDataVhrEventTypeIdValidationFn);
        var vhrEventManualWhatClientDataVhrEventGroupIdValidator = new breeze.Validator('vhrEventManualWhatClientDataVhrEventGroupIdValidator', vhrEventManualWhatClientDataVhrEventGroupIdValidationFn);
        var vhrEventManualWhatClientDataVhrEventTemplateIdValidator = new breeze.Validator('vhrEventManualWhatClientDataVhrEventTemplateIdValidator', vhrEventManualWhatClientDataVhrEventTemplateIdValidationFn);
        var vhrEventManualWhatClientDataStartDateValidator = new breeze.Validator('startDateValidator', vhrEventManualWhatClientDataStartDateValidationFn);
        var vhrEventManualWhatClientDataStartTimeValidator = new breeze.Validator('startTimeValidator', vhrEventManualWhatClientDataStartTimeValidationFn);
        var vhrEventManualWhatClientDataEndDateValidator = new breeze.Validator('endDateValidator', vhrEventManualWhatClientDataEndDateValidationFn);
        var vhrEventManualWhatClientDataEndTimeValidator = new breeze.Validator('endTimeValidator', vhrEventManualWhatClientDataEndTimeValidationFn);

        // validation functions list for VhrEventManualWhatClientData
        function vhrEventManualStepActivitySelectionActivityIdValidationFn(value, context) {
            if (context.entity.IsEventManualByActivity === true) {
                if (!context.entity.ActivityId) { return false; } else { return true; }
            }
            return true;
        }
        var vhrEventManualStepActivitySelectionActivityIdValidator = new breeze.Validator('endTimeValidator', vhrEventManualStepActivitySelectionActivityIdValidationFn);
        //

        //client validation for VhrEventManualUpdateDateClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventManualUpdateDateClientData,
                dataProperties: {
                    VhrEventManualUpdateDateClientDataId: { type: breeze.DataType.Guid },
                    RecurrenceStartDate: { type: breeze.DataType.DateTime },
                    EventStartTime: { type: breeze.DataType.String },
                    RecurrenceEndDate: { type: breeze.DataType.DateTime },
                    EventEndTime: { type: breeze.DataType.String },
                    IsRecurrent: { type: breeze.DataType.Boolean },
                    IsEventManualByActivity: { type: breeze.DataType.Boolean },
                    ActivityId: { type: breeze.DataType.Int32 }
                }
            },
            'Nembus.Domain'
        );

        // entity used to validate step Activity Selection in VhrEventManual insert step
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventManualStepActivitySelectionClientData,
                dataProperties: {
                    VhrEventManualStepActivitySelectionClientDataId: { type: breeze.DataType.Guid },
                    IsEventManualByActivity: { type: breeze.DataType.Boolean },
                    ActivityId: { type: breeze.DataType.Int32 }
                }
            },
            'Nembus.Domain'
        );

        var vhrEventManualStepActivitySelectionClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventManualStepActivitySelectionClientData);
        vhrEventManualStepActivitySelectionClientData.getProperty("ActivityId").validators.push(vhrEventManualStepActivitySelectionActivityIdValidator);

        //#region VhrEventMassivelyUpdateRequestClientData
        //client validation for VhrEventMassivelyUpdateRequestClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventMassivelyUpdateRequestClientData,
                dataProperties: {
                    Id: { type: breeze.DataType.Guid },
                    Flag: { type: breeze.DataType.Boolean },
                    Percentage: { type: breeze.DataType.Decimal },
                }
            },
            'Nembus.Domain'
        );

        function vhrEventMassivelyUpdateRequestClientDataPercentageValidationFn(value, context) {
            if (value === null || value === undefined || value > 100) {
                return false;
            } else {
                return true;
            }
        }
        var vhrEventMassivelyUpdateRequestClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventMassivelyUpdateRequestClientData);
        vhrEventMassivelyUpdateRequestClientData.getProperty("Percentage").validators.push(new breeze.Validator('percentValidator', vhrEventMassivelyUpdateRequestClientDataPercentageValidationFn));
        //#endregion



        // use same validation function of vhrEventManualWhatClientData
        var vhrEventManualUpdateDateClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventManualUpdateDateClientData);
        vhrEventManualUpdateDateClientData.getProperty("RecurrenceStartDate").validators.push(vhrEventManualWhatClientDataStartDateValidator);
        vhrEventManualUpdateDateClientData.getProperty("EventStartTime").validators.push(vhrEventManualWhatClientDataStartTimeValidator);
        vhrEventManualUpdateDateClientData.getProperty("RecurrenceEndDate").validators.push(vhrEventManualWhatClientDataEndDateValidator);
        vhrEventManualUpdateDateClientData.getProperty("EventEndTime").validators.push(vhrEventManualWhatClientDataEndTimeValidator);
        // end vhrEventManualUpdateDateClientData validators

        //client validation for VhrEventManualWhatClientData
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventManualWhatClientData,
                dataProperties: {
                    VhrEventManualWhatClientDataId: { type: breeze.DataType.Guid },
                    Code: { type: breeze.DataType.String },
                    Name: { type: breeze.DataType.String },
                    ExternalCode: { type: breeze.DataType.String },
                    Description: { type: breeze.DataType.String },
                    ModuleTargetId: { type: breeze.DataType.Int32 },
                    VhrEventTemplateId: { type: breeze.DataType.Int32 },
                    VhrEventTypeId: { type: breeze.DataType.Int32 },
                    VhrEventGroupId: { type: breeze.DataType.Int32 },
                    WorkCenterId: { type: breeze.DataType.Int32 },
                    RecurrenceStartDate: { type: breeze.DataType.DateTime },
                    EventStartTime: { type: breeze.DataType.String },
                    RecurrenceEndDate: { type: breeze.DataType.DateTime },
                    EventEndTime: { type: breeze.DataType.String },
                    IsRecurrent: { type: breeze.DataType.Boolean }
                }
            },
            'Nembus.Domain'
        );

        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.VhrEventsListReportFormClientData,
                dataProperties: {
                    VhrEventsListReportFormClientDataId: { type: breeze.DataType.Guid },
                    StartDate1: { type: breeze.DataType.DateTime },
                    StartDate2: { type: breeze.DataType.DateTime }
                }
            },
            'Nembus.Domain'
        );

        var vhrEventsListReportFormClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventsListReportFormClientData);
        vhrEventsListReportFormClientData.getProperty("StartDate1").validators.push(new breeze.Validator("fromDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));
        vhrEventsListReportFormClientData.getProperty("StartDate2").validators.push(new breeze.Validator("toDateValidator", Nembus.Common.Library.validators.requiredFieldValidator));


        // start vhrEventManualWhatClientData validators
        var vhrEventManualWhatClientData = manager.metadataStore.getEntityType(entityTypes.VhrEventManualWhatClientData);
        vhrEventManualWhatClientData.getProperty("Code").validators.push(vhrEventManualWhatClientDataCodeValidator);
        vhrEventManualWhatClientData.getProperty("Name").validators.push(vhrEventManualWhatClientDataNameValidator);
        vhrEventManualWhatClientData.getProperty("ExternalCode").validators.push(vhrEventManualWhatClientDataExternalCodeValidator);
        vhrEventManualWhatClientData.getProperty("ModuleTargetId").validators.push(vhrEventManualWhatClientDataModuleTargetIdValidator);
        vhrEventManualWhatClientData.getProperty("WorkCenterId").validators.push(vhrEventManualWhatClientDataWorkCenterIdValidator);
        vhrEventManualWhatClientData.getProperty("VhrEventTypeId").validators.push(vhrEventManualWhatClientDataVhrEventTypeIdValidator);
        vhrEventManualWhatClientData.getProperty("VhrEventGroupId").validators.push(vhrEventManualWhatClientDataVhrEventGroupIdValidator);
        vhrEventManualWhatClientData.getProperty("VhrEventTemplateId").validators.push(vhrEventManualWhatClientDataVhrEventTemplateIdValidator);
        vhrEventManualWhatClientData.getProperty("RecurrenceStartDate").validators.push(vhrEventManualWhatClientDataStartDateValidator);
        vhrEventManualWhatClientData.getProperty("EventStartTime").validators.push(vhrEventManualWhatClientDataStartTimeValidator);
        vhrEventManualWhatClientData.getProperty("RecurrenceEndDate").validators.push(vhrEventManualWhatClientDataEndDateValidator);
        vhrEventManualWhatClientData.getProperty("EventEndTime").validators.push(vhrEventManualWhatClientDataEndTimeValidator);
        // end vhrEventManualWhatClientData validators



        function vhrEventShuffleDtoTargetWorkCenterValidationFn(entity, context) {
            // This validator only validates US Zip Codes.
            var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventShuffleAction, null, null).obj.normal.name_value;
            if (entity.VhrEventShuffleAction !== vhrEventShuffleActionObj.ChangeLifeStatus.toString()
                && entity.VhrEventShuffleAction !== vhrEventShuffleActionObj.ChangeWorkflowStatus.toString()
                && entity.VhrEventShuffleAction !== vhrEventShuffleActionObj.DeleteWorkflowStatus.toString()) {

                if (entity.getProperty('KeepSameWorkCenter') === false) {
                    //context.TargetWorkCenterId = TargetWorkCenterId;
                    if (!entity.getProperty('TargetWorkCenterId')) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            }

            return true;
        }

        // add US zip code validator to the entity (not to a property)
        var vhrEventShuffleDtoType = manager.metadataStore.getEntityType('VhrEventShuffleDto');
        vhrEventShuffleDtoType.validators.push(new breeze.Validator('workCenterValidator', vhrEventShuffleDtoTargetWorkCenterValidationFn));

        this.dataContext = {
            vhrEventMassivelyUpdateRequestClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventMassivelyUpdateRequestClientData),
            vhrEventManualStepActivitySelectionClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventManualStepActivitySelectionClientData),
            VhrEventsListReportFormClientData: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventsListReportFormClientData),
            vhrEventManualUpdateDateClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventManualUpdateDateClientData),
            vhrEventManualWhatClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventManualWhatClientData),
            vhrEventBiDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventBiData),
            vhrEventBiDataHistories: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventBiDataHistory),
            vhrEventManualRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventManualRequest),
            vhrEventManualWarnings: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventManualWarning),
            vhrEventMassivelyUpdateRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventMassivelyUpdateRequest),
            vhrEventMassivelyUpdateWarnings: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventMassivelyUpdateWarning),
            smallRoutes: dataLayer.setContextMethods(manager, nbsService, entityTypes.SmallRouteDto),
            vhrEvents: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEvent),
            vhrEventDetails: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventDetail),
            searchVhrEventsPerson: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEvent),
            searchVhrEventsAsset: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEvent),
            vhrEventTypes: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventType),
            vhrEventGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventGroup),
            vhrEventMatchers: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventMatcher),
            vhrEventTemplates: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventTemplateDto),
            vhrEventLabels: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventLabel),
            vhrEventFathers: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEvent),
            vhrEventImmediateChildren: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEvent),
            vhrEventMatcherM2MTargetDto: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventMatcherM2MTargetDto),
            vhrEventEntityHistories: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventEntityHistory),
            vhrEventM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventM2MAppUserDto),
            vhrEventM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.VhrEventM2MDrbacUserGroupDto),

            // custom
            vhrEventShuffleDto: {
                add: function (entity) {
                    if (!entity) {
                        entity = {};
                    }
                    entity.VhrEventShuffleId = breeze.core.getUuid();

                    return nbsService.prototype.createEntityStatic(entityTypes.VhrEventShuffleDto, entity, manager);
                }
            },
            vhrEventMatcherM2MTargetActivityDto: {
                get: function () { return manager.getEntities(entityTypes.VhrEventMatcherM2MTargetActivityDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetActivityDto, key); },
                add: function (entity) {
                    //[{"name": "VhrEventMatcherId"},{"name": "ActivityId"}]
                    var entInContext = manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetActivityDto, [entity.VhrEventMatcherId, entity.ActivityId]);
                    if (entInContext) {
                        entInContext.entityAspect.setModified();
                    }
                    else {
                        return nbsService.prototype.createEntityStatic(entityTypes.VhrEventMatcherM2MTargetActivityDto, entity, manager);
                    }
                }
            },
            vhrEventMatcherM2MTargetVhrEventTypeDto: {
                get: function () { return manager.getEntities(entityTypes.VhrEventMatcherM2MTargetVhrEventTypeDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetVhrEventTypeDto, key); },
                add: function (entity) {
                    //[{"name": "VhrEventMatcherId"},{"name": "VhrEventTypeId"}]
                    var entInContext = manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetVhrEventTypeDto, [entity.VhrEventMatcherId, entity.VhrEventTypeId]);
                    if (entInContext) {
                        entInContext.entityAspect.setModified();
                    }
                    else {
                        return nbsService.prototype.createEntityStatic(entityTypes.VhrEventMatcherM2MTargetVhrEventTypeDto, entity, manager);
                    }
                }
            },
            vhrEventMatcherM2MTargetVhrEventGroupDto: {
                get: function () { return manager.getEntities(entityTypes.VhrEventMatcherM2MTargetVhrEventGroupDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetVhrEventGroupDto, key); },
                add: function (entity) {
                    var entInContext = manager.getEntityByKey(entityTypes.VhrEventMatcherM2MTargetVhrEventGroupDto, [entity.VhrEventMatcherId, entity.VhrEventGroupId]);
                    if (entInContext) {
                        entInContext.entityAspect.setModified();
                    }
                    else {
                        return nbsService.prototype.createEntityStatic(entityTypes.VhrEventMatcherM2MTargetVhrEventGroupDto, entity, manager);
                    }
                }
            },
            enumTypes: {
                VhrEventLifeStatus: Lazy(dataLayer.GetEnumType('VhrEventLifeStatus')).reject({ value: "0" }).reject({ value: "30" }).toArray(),        //None,AutoApproved aren't visible in all application
                VhrEventBillingStatus: dataLayer.GetEnumType('VhrEventBillingStatus'),
                VhrEventKind: dataLayer.GetEnumType('VhrEventKind'),
                RouteSchedulingType: dataLayer.GetEnumType('RouteSchedulingType'),
                VhrEventPublisher: dataLayer.GetEnumType('VhrEventPublisher'),
                VhrEventCreationType: dataLayer.GetEnumType('VhrEventCreationType'),
                VhrEventCreationArchetype: dataLayer.GetEnumType('VhrEventCreationArchetype'),
                VhrEventManualRequestStatus: dataLayer.GetEnumType('VhrEventManualRequestStatus'),
                VhrEventManualWarningType: dataLayer.GetEnumType('VhrEventManualWarningType'),
                VhrEventManualConflictType: dataLayer.GetEnumType('VhrEventManualConflictType'),
                VhrEventManualConflictAction: dataLayer.GetEnumType('VhrEventManualConflictAction'),
                VhrEventManualRequestType: dataLayer.GetEnumType('VhrEventManualRequestType'),
                VhrEventMassivelyUpdateWarningType: dataLayer.GetEnumType('VhrEventMassivelyUpdateWarningType'),
                VhrEventMassivelyUpdateRequestStatus: dataLayer.GetEnumType('VhrEventMassivelyUpdateRequestStatus'),
                VhrEventMassivelyUpdateRequestType: dataLayer.GetEnumType('VhrEventMassivelyUpdateRequestType'),
                VhrEventBiDataDefinitionCode: dataLayer.GetEnumType('VhrEventBiDataDefinitionCode'),
                VhrEventBiDataPivotCode: dataLayer.GetEnumType('VhrEventBiDataPivotCode'),
                VhrEventShuffleAction: dataLayer.GetEnumType('VhrEventShuffleAction'),
                VhrEventEntityHistoryType: dataLayer.GetEnumType('VhrEventEntityHistoryType'),
                VhrEventUserType: dataLayer.GetEnumType('VhrEventUserType')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        this.getVhrEventM2MAppUserDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventM2MAppUserDto, cache: this.dataContext.vhrEventM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventM2MAppUserDto");
        };

        this.getVhrEventM2MDrbacUserGroupDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventM2MDrbacUserGroupDto, cache: this.dataContext.vhrEventM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventM2MDrbacUserGroupDto");
        };

        this.getVhrEventBiDatas = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventBiData, cache: this.dataContext.vhrEventBiDatas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventBiData");
        };

        this.getVhrEventBiDataHistories = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventBiDataHistory, cache: this.dataContext.vhrEventBiDataHistories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventBiDataHistory");
        };

        this.getVhrEventMassivelyUpdateRequests = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMassivelyUpdateRequest, cache: this.dataContext.vhrEventMassivelyUpdateRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMassivelyUpdateRequests");
        };
        this.getVhrEventMassivelyUpdateRequest = function (filter, forceReload) {
            var params = { key: filter.VhrEventMassivelyUpdateRequestId, dataContext: this.dataContext.vhrEventMassivelyUpdateRequests };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventMassivelyUpdateRequests");
        };

        this.getVhrEventMassivelyUpdateWarnings = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMassivelyUpdateWarning, cache: this.dataContext.vhrEventMassivelyUpdateWarnings.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMassivelyUpdateWarnings");
        };
        this.getVhrEventMassivelyUpdateWarning = function (filter, forceReload) {
            var params = { key: filter.VhrEventMassivelyUpdateWarningId, dataContext: this.dataContext.vhrEventMassivelyUpdateWarnings };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventMassivelyUpdateWarnings");
        };

        this.getVhrEventManualRequests = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventManualRequest, cache: this.dataContext.vhrEventManualRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventManualRequests");
        };
        this.getVhrEventManualRequest = function (filter, forceReload) {
            var params = { key: filter.VhrEventManualRequestId, dataContext: this.dataContext.vhrEventManualRequests };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventManualRequests");
        };

        this.getVhrEventManualWarnings = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventManualWarning, cache: this.dataContext.vhrEventManualWarnings.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventManualWarnings");
        };

        this.getVhrEvents = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEvent, cache: this.dataContext.VhrEventId.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEvents");
        };

        this.getVhrEvent = function (filter, forceReload) {
            var params = { key: filter.VhrEventId, dataContext: this.dataContext.VhrEventId };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEvents");
        };

        this.getVhrEventListDto = function (filter, forceReload) {
            var params = { key: filter.VhrEventId, dataContext: this.dataContext.VhrEventId };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventListDto");
        };

        this.getRoutesForEvent = function (filter, forceReload) {
            var params = { entityType: entityTypes.SmallRouteDto, cache: this.dataContext.smallRoutes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "RoutesForEvent");
        };

        this.getVhrEventTemplates = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventTemplateDto, cache: this.dataContext.vhrEventTemplates.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventTemplates");
        };
        this.getVhrEventTemplate = function (filter, forceReload) {
            var params = { key: filter.VhrEventTemplateCode, dataContext: this.dataContext.vhrEventTemplates };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventTemplates");
        };

        this.getVhrEventLabels = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventLabel, cache: this.dataContext.vhrEventLabels.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventLabels");
        };
        this.getVhrEventLabel = function (filter, forceReload) {
            var params = { key: filter.VhrEventLabelId, dataContext: this.dataContext.vhrEventLabels };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventLabels");
        };

        this.getVhrEventTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventType, cache: this.dataContext.vhrEventTypes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventTypes");
        };
        this.getVhrEventType = function (filter, forceReload) {
            var params = { key: filter.VhrEventTypeId, dataContext: this.dataContext.vhrEventTypes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventTypes");
        };

        this.getVhrEventGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventGroup, cache: this.dataContext.vhrEventGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventGroups");
        };
        this.getVhrEventGroup = function (filter, forceReload) {
            var params = { key: filter.VhrEventGroupId, dataContext: this.dataContext.vhrEventGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventGroups");
        };

        this.getVhrEventMatchers = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMatcher, cache: this.dataContext.vhrEventMatchers.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMatchers");
        };
        this.getVhrEventMatcher = function (filter, forceReload) {
            var params = { key: filter.VhrEventMatcherId, dataContext: this.dataContext.vhrEventMatchers };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventMatchers");
        };

        this.getVhrEvents = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEvent, cache: this.dataContext.vhrEvents.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEvents");
        };

        this.getVhrEventsPagedDm = function () {
            return ejDataManager.getEjManager('VhrEventsPaged');
        };
        this.getVhrEventsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('VhrEventsPaged');
            return dmObj;
        };

        this.getMyVhrEventsPagedDm = function () {
            return ejDataManager.getEjManager('MyVhrEventsPaged');
        };
        this.getMyVhrEventsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('MyVhrEventsPaged');
            return dmObj;
        };

        this.getVhrEvent = function (filter, forceReload) {
            var params = { key: filter.VhrEventId, dataContext: this.dataContext.vhrEvents };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEvents");
        };

        this.getVhrEventDetail = function (filter, forceReload) {
            var params = { key: filter.VhrEventDetailId, dataContext: this.dataContext.vhrEventDetails };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "VhrEventDetail");
        };

        this.getVhrEventFather = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEvent, cache: this.dataContext.vhrEventFathers.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventFather");
        };

        this.getVhrEventImmediateChildren = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEvent, cache: this.dataContext.vhrEventImmediateChildren.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventImmediateChildren");
        };

        //get status life list enabled for vhreventlist provided (VhrEventConfigs)
        this.getChangeTargetVhrEventsLifeStatus = function (filter) {
            var query = dataLayer.CreateQuery("GetChangeTargetVhrEventsLifeStatus").withParameters({ filter: filter });
            return manager.executeQuery(query);
        };

        //get change start date is enabled for vhreventlist provided (VhrEventConfigs)
        this.canChangeTargetVhrEventsStartDate = function (filter) {
            var query = dataLayer.CreateQuery("CanChangeTargetVhrEventsStartDate").withParameters({ filter: filter });
            return manager.executeQuery(query);
        };

        // verify if vhrevents can be deleted  (VhrEventConfigs)
        this.canDeleteVhrEvents = function (filter) {
            var query = dataLayer.CreateQuery("CanDeleteVhrEvents").withParameters({ filter: filter });
            return manager.executeQuery(query);
        };

        this.getVhrEventMatchersTargetActivities = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMatcherM2MTargetActivityDto, cache: this.dataContext.vhrEventMatcherM2MTargetActivityDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMatcherM2MTargetActivity");
        };

        this.getVhrEventMatcherM2MTargetDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMatcherM2MTargetDto, cache: this.dataContext.vhrEventMatcherM2MTargetDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMatcherM2MTargetDto");
        };

        this.getVhrEventMatchersTargetActivities_VhrEventTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMatcherM2MTargetVhrEventTypeDto, cache: this.dataContext.vhrEventMatcherM2MTargetVhrEventTypeDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMatcherM2MTargetVhrEventTypeDto");
        };

        this.getVhrEventMatchersTargetActivities_VhrEventGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventMatcherM2MTargetVhrEventGroupDto, cache: this.dataContext.vhrEventMatcherM2MTargetVhrEventGroupDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventMatcherM2MTargetVhrEventGroupDto");
        };

        this.getVhrEventEntityHistories = function (filter, forceReload) {
            var params = { entityType: entityTypes.VhrEventEntityHistory, cache: this.dataContext.vhrEventEntityHistories.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "VhrEventEntityHistory");
        };

        //Http Post
        this.getVhrEventManualSchedulingVhrEventsCount = function (vhrEventManualRequest) {
            var objPost = { vhrEventManualRequest: vhrEventManualRequest };

            var query = dataLayer.InvokeQuery("GetVhrEventManualSchedulingVhrEventsCount", vhrEventManualRequest);
            return manager.executeQuery(query);
        };

        //Http Post
        this.updateVhrEventBillingStatusBulk = function (vhrEventsId, moduleTargetId, vhrEventsBillingStatus) {
            var objPost = { VhrEventsIdSerialized: JSON.stringify(vhrEventsId), ModuleTargetId: moduleTargetId, VhrEventBillingStatus: vhrEventsBillingStatus };

            var query = dataLayer.InvokeQuery("UpdateVhrEventBillingStatusBulk", objPost);
            return manager.executeQuery(query);
        };

        //Http Post
        this.deleteVhrEventsBulk = function (vhrEventsId, moduleTargetId) {
            var objPost = { VhrEventsIdSerialized: JSON.stringify(vhrEventsId), ModuleTargetId: moduleTargetId };

            var query = dataLayer.InvokeQuery("DeleteVhrEventsBulk", objPost);
            return manager.executeQuery(query);
        };

        //Http Post
        this.searchVhrEventsPerson = function (filter) {
            var query = dataLayer.InvokeQuery("SearchVhrEventsPerson", filter);
            return manager.executeQuery(query);
        };

        //Http Post
        this.searchVhrEventsAsset = function (filter) {
            var query = dataLayer.InvokeQuery("SearchVhrEventsAsset", filter);
            return manager.executeQuery(query);
        };

        //real paged
        this.getVhrEventManualrequestsPagedDm = function () {
            return ejDataManager.getEjManager('VhrEventManualRequestsPaged');
        };
        this.getVhrEventManualrequestsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('VhrEventManualRequestsPaged');
            return dmObj;
        };

        this.getSearchVhrEventsPersonPagedDm = function () {
            return ejDataManager.getEjManager('SearchVhrEventsPersonPaged');
        };
        this.getSearchVhrEventsPersonPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchVhrEventsPersonPaged');
            return dmObj;
        };

        this.getSearchVhrEventsAssetPagedDm = function () {
            return ejDataManager.getEjManager('SearchVhrEventsAssetPaged');
        };
        this.getSearchVhrEventsAssetPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchVhrEventsAssetPaged');
            return dmObj;
        };

        this.getSearchVhrEventsHrPagedDm = function () {
            return ejDataManager.getEjManager('SearchVhrEventsHrPaged');
        };
        this.getSearchVhrEventsHrPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchVhrEventsHrPaged');
            return dmObj;
        };

        //Http Post
        this.addSubVhrEventsFromActivities = function (filter) {
            var query = dataLayer.InvokeQuery("AddSubVhrEventsFromActivities", filter);
            return manager.executeQuery(query);
        };

        //#region DataManager
        this.getVhrEventManualRequestDataManager = function (supportData, filter, dmObj) {            
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var vhrEventManualRequestStatusObj = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventManualRequestStatus, 'VhrEventManualRequest', supportData.translate).obj.normal.value_name;

            dmObj = me.getVhrEventManualrequestsPagedDmQuery();
            dmObj.dmgrid = me.getVhrEventManualrequestsPagedDm();

            var ejDataManager = dmObj.dmgrid;
            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {};
            ejDataManager.onWhereCustomPredicate = {};

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = [];
                    data.results = me.getAttachedEntities(data.results);

                    Lazy(data.results).each((item) => {
                        item.VhrEventManualRequestStatusName = vhrEventManualRequestStatusObj[item.VhrEventManualRequestStatusId];
                    });

                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['VhrEventManualRequestCode', 'VhrEventManualRequestExternalCode', 'VhrEventManualRequestName'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        this.getVhrEventDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var vhrEventCreationTypeEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventCreationType, 'VhrEvent', supportData.translate);
            var vhrEventCreationArchetypeEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventCreationArchetype, 'VhrEvent', supportData.translate);
            var vhrEventLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventLifeStatus, 'VhrEvent', supportData.translate);
            var vhrEventBillingStatusEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.VhrEventBillingStatus, 'VhrEvent', supportData.translate);

            switch (supportData.searchMode) {
                case 'searchavatar':
                    dmObj = me.getSearchVhrEventsPersonPagedDmQuery();
                    dmObj.dmgrid = me.getVhrEventsPagedDm();
                    break;
                case 'searchasset':
                    dmObj = me.getSearchVhrEventsAssetPagedDmQuery();
                    dmObj.dmgrid = me.getSearchVhrEventsAssetPagedDm();
                    break;
                case 'searchhr':
                    dmObj = me.getSearchVhrEventsHrPagedDmQuery();
                    dmObj.dmgrid = me.getSearchVhrEventsHrPagedDm();
                    break;
                case 'myvhrevents':
                    dmObj = me.getMyVhrEventsPagedDmQuery();
                    dmObj.dmgrid = me.getMyVhrEventsPagedDm();
                    break;
                case 'vhrevents':
                    dmObj = me.getVhrEventsPagedDmQuery();
                    dmObj.dmgrid = me.getVhrEventsPagedDm();
                    break;
                default:
                    dmObj = me.getVhrEventsPagedDmQuery();
                    dmObj.dmgrid = me.getVhrEventsPagedDm();
            }

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                VhrEventLifeStatusName: 'VhrEventLifeStatusId',
                VhrEventStartTime: 'VhrEventStartDate',
                VhrEventBillingStatusName: 'VhrEventBillingStatusId',
                VhrEventCreationTypeFlag: 'VhrEventCreationTypeId',
                VhrEventCreationArchetypeName: 'VhrEventCreationArchetypeId',
                VirtualHumanResourceCode: 'VirtualHumanResource.VirtualCode',
                VirtualHumanResourceName: 'VirtualHumanResource.VirtualName'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                VhrEventLifeStatusName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'VhrEventLifeStatusId',
                    Datasource: vhrEventLifeStatusEnumParsed.list.translated
                },
                VhrEventCreationTypeFlag: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'VhrEventCreationTypeId',
                    Datasource: vhrEventCreationTypeEnumParsed.list.translated
                },
                VhrEventCreationArchetypeName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'VhrEventCreationArchetypeId',
                    Datasource: vhrEventCreationArchetypeEnumParsed.list.translated
                }
            };

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    data.results = me.getAttachedEntities(data.results);
                    Lazy(data.results).each((item) => {
                        if (!item.cardSupport) {
                            item.cardSupport = {
                                translations: {}
                            };
                        }

                        item.VhrEventCreationTypeFlag = item.VhrEventCreationTypeId === 0 ? false : true;
                        item.VhrEventCreationArchetypeName = vhrEventCreationArchetypeEnumParsed.obj.translated.value_name[item.VhrEventCreationArchetypeId];
                        item.VhrEventBillingStatusName = vhrEventBillingStatusEnumParsed.obj.translated.value_name[item.VhrEventBillingStatusId];
                        item.VhrEventLifeStatusNameNotTranslated = vhrEventLifeStatusEnumParsed.obj.normal.value_name[item.VhrEventLifeStatusId];
                        item.VhrEventLifeStatusColor = supportData.statusColorObj[item.VhrEventLifeStatusNameNotTranslated];
                        item.VhrEventLifeStatusName = vhrEventLifeStatusEnumParsed.obj.translated.value_name[item.VhrEventLifeStatusId];
                        item.VhrEventStartTime = item.VhrEventStartDate;
                        item.VhrEventLabelsUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.VhrEventLabelsSerialized));

                        // format custom fields for grid cards
                        item.cardSupport.VhrEventStartDate = Nembus.Common.Library.converter.date.formatDate(item.VhrEventStartDate, supportData.contextService.virtualContext.cultureInfo, true);
                        item.cardSupport.VhrEventStartTime = Nembus.Common.Library.converter.date.formatTime(item.VhrEventStartDate, supportData.contextService.virtualContext.cultureInfo, true);
                        item.cardSupport.translations.approved = item.IsApproved ? supportData.translate('Approved') : ' ';
                        item.cardSupport.translations.executed = item.IsExecuted ? supportData.translate('Executed') : ' ';
                        item.cardSupport.translations.customerApproved = item.IsCustomerApproval ? supportData.translate('CustomerApproved') : ' ';
                    });
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['BomCode', 'BomName', 'ActivityCode', 'VhrEventName', 'VhrEventCode', 'VhrEventName', 'VirtualHumanResource.VirtualCode', 'VirtualHumanResource.VirtualName', 'WorkCenterName', 'ActivityFrequencyCode'];
            ejDataManager.freeSearchFieldsNumber = ['VhrEventId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }
        //#endregion

        //cross controller (through wizard steps of vhrevents manual)
        this.vhrEventManualWhatClientData = null;               // used to validate step event detail in insert VhrEventManualRequest wizard
        this.vhrEventManualUpdateDateClientData = null;         // used to validate step change date in update VhrEventManualRequest wizard
        this.vhrEventManualCollector = {};                      // used to mantains data collection in insert/update VhrEventManualRequest wizard
    };

    vhreventService.$inject = injectParams;
    vhreventService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.shared.module').service('vhreventService', vhreventService);
});