﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'triggerService', 'navigationService', 'checkStateChangeService', 'notificationService', 'contextService'];
    var triggerInstancesList = function ($q, $scope, $timeout, $injector, triggerService, navigationService, checkStateChangeService, notificationService, contextService) {

        var vm = this;
        nbsVm.call(vm, triggerService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return triggerInstanceSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, triggerService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var triggerInstanceSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var triggerModeObj = Nembus.Common.Library.util.parseEnum(triggerService.dataContext.enumTypes.TriggerMode, 'TriggerInstance', vm.translate).obj.translated.value_name;

        vm.currentTriggerInstances = [];

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('triggerinstance', null);
        nbsGridDatacontext.viewCommonBar();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getTriggerDefinitions(forceReload));
            funcsPromises.push(getNotificationInstances(forceReload));
            funcsPromises.push(getTriggerInstances(forceReload));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.currentTriggerDefinitions = results[0];
                    vm.currentTriggerDefinitionsObj = Nembus.Common.Library.parser.objFromList(vm.currentTriggerDefinitions, 'TriggerDefinitionId', 'TriggerDefinitionName');

                    vm.notificationInstances = results[1];
                    vm.notificationInstancesObj = Nembus.Common.Library.parser.objFromList(vm.notificationInstances, 'NotificationInstanceId', 'NotificationInstanceNameUI');

                    Lazy(results[2]).each((r) => {
                        r.TriggerDefinitionName = vm.currentTriggerDefinitionsObj[r.TriggerDefinitionId];
                        r.NotificationInstanceName = vm.notificationInstancesObj[r.NotificationInstanceId];
                        r.TriggerModeName = triggerModeObj[r.TriggerModeId];
                    });
                    vm.currentTriggerInstances = results[2];

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentTriggerInstances
                    }
                });
        }

        //Go to detail of triggerinstance
        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.triggerinstancedetail", { 'appStoreCode': appStoreCode, 'triggerInstanceId': args.data.TriggerInstanceId });
        };

        //Add new triggerinstance
        vm.newAction = function () {
            navigationService.navigateTo("app.nav.triggerinstancedetail", { 'appStoreCode': appStoreCode, 'triggerInstanceId': null });
        };

        //Delete triggerInstance entity from list
        vm.deleteAction = function () {
            if (!triggerInstanceSelected) {
                return;
            }

            triggerService.deleteEntity(triggerInstanceSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction(true);
                });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                triggerInstanceSelected = args.data;
               
                if (triggerInstanceSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, true);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, true);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.copyAction = function () {
            if (!triggerInstanceSelected) {
                return;
            }

            // client entity
            vm.triggerInstanceClientData = triggerService.dataContext.triggerInstanceClientDatas.add({
                TriggerInstanceId: triggerInstanceSelected.TriggerInstanceId,
                TriggerInstanceName: triggerInstanceSelected.TriggerInstanceName
            });

            // open modal used to set triggerinstance name for clone
            vm.modalTitle = vm.translate('TriggerInstance.TriggerInstance');
            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'cloneTriggerInstanceTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                // ok
                triggerService.dataContext.triggerInstanceClientDatas.clearCache();
            }, function () {
                // detach of the client data attached only for client validation
                triggerService.dataContext.triggerInstanceClientDatas.clearCache();
            });
        };

        vm.modalOk = function () {
            //force validation on entity (manual metadata only client)
            vm.triggerInstanceClientData.entityAspect.validateEntity();

            if (!vm.triggerInstanceClientData.entityAspect.hasValidationErrors) {
                triggerService.cloneTriggerInstance({ TriggerInstanceId: vm.triggerInstanceClientData.TriggerInstanceId, TriggerInstanceName: vm.triggerInstanceClientData.TriggerInstanceName })
                    .then(function (result) {
                        vm.refreshAction();
                        triggerInstanceSelected = null;
                        vm.modalInstance.close();

                    }).catch(function (error) {
                        vm.alertConfig.isModal = true;
                        vm.alertConfig.isReadonly = true;
                        vm.removeAlertFromList(true);
                        vm.managePostMessage(error);
                        vm.setErrorMsg(error);
                    });
            }
        };

        vm.modalCancel = function () {
            vm.modalInstance.dismiss();
        };

        //Reload triggerinstance list
        vm.refreshAction = function () {
            loadData(true);
        };

        function getTriggerDefinitions(forceReload) {
            var filter = {
            };
            var defer = $q.defer();

            triggerService.getTriggerDefinitions(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTriggerInstances(forceReload) {
            var filter = {
            };
            var defer = $q.defer();

            triggerService.getTriggerInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNotificationInstances(forceReload) {
            var filter = {};
            var defer = $q.defer();

            notificationService.getNotificationInstances(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    triggerInstancesList.$inject = injectParams;
    triggerInstancesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('triggerInstancesList', triggerInstancesList);
});
