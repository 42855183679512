﻿// TECCLEANGENERALDATA data is the event of type data viewed in events list

'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var fileBoxViewerDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var fileBoxId = navigationService.stateParams().fileBoxId;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        if (fileBoxId) {
            vm.setIsViewing();

            loadData(true);
        } else {
            //Only view data
        }

        function loadData(forceReload) {
            var funcsPromises = []
            funcsPromises.push(getFileBox(forceReload, fileBoxId));

            $q.all(funcsPromises)
                .then(function () {
                    //nothing to load
                });
        }

        function getFileBox(forceReload, fileBoxId) {
            var filter = { FileBoxId: fileBoxId };
            var defer = $q.defer();

            foundationService.getFileBox(filter, forceReload)
                .then(function (data) {
                    vm.currentFileBox = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });


            return defer.promise;
        }
    };

    fileBoxViewerDetail.$inject = injectParams;
    fileBoxViewerDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxViewerDetail', fileBoxViewerDetail);
});