'use strict';

var audmodule = angular.module('nbs.aud.module', ['nbs.shared.module']);
define([
	'./services/aud-service'
	, '../rds/services/util-service'
	, '../rds/services/act-service'
	, '../rds/services/workflow-service'
	, './audacts/audActCreationRequestDetail'
	, './audacts/audActDetail'
	, './surveys/audActsExecutionList'
	, './surveys/audActsSearch'
	, './surveys/audDeveloperToolsDetail'
	, './surveys/audProcessList'
	, './audcommon/copySurveyOptionsDetail'

	, './surveys/mySurveyBoardDueDate'
	, './surveys/mySurveySchedule'
	, './surveys/mySurveysList'
	, './surveys/mySurveyStream'
	, './reportformsaud/audActsListReportForm'
	, './reportformsaud/surveyKpiReportForm'
	, './reportformsaud/surveyQuestionOptionAnalysisReportForm'
	, './reportformsaud/surveyReportForm'
	, './reportformsaud/surveysExport2ReportForm'
	, './reportformsaud/surveysExportReportForm'
	, './reportformsaud/surveysListReportForm'
	, './reportformsaud/vhrTargetsSurveyFlagUIListReportForm'
	, './reportformsaud/vhrTargetsSurveyKpiListReportForm'
	, './reportformsaud/vhrTargetsSurveyListReportForm'
	, './reportformsaud/htmlToExcelSurveyGanttReportForm'
	, './reportformsaud/htmlToPdfAudActsExecutionSyntesisReportForm'
	, './reportformsaud/htmlToPdfSurveyDetailReportForm'
	, './reportformsaud/htmlToPdfSurveyKpiReportForm'
	, './reportformsaud/htmlToPdfSurveyQuestionGroupingReportForm'
	, './reportformsaud/htmlToPdfSurveyQuestionOptionDistributionReportForm'
	, './reportformsaud/htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm'
	, './reportformsaud/htmlToPdfSurveySectionKpiReportForm'
	, './reportformsaud/htmlToPdfSurveysExecutionSyntesisReportForm'
	, './reportformsaud/htmlToPdfSurveyVhrTargetKpiReportForm'
	, './reportformsaud/htmlToPdfSurveyVhrTargetSynthesisReportForm'
	, './surveys/surveyBoardDueDate'
	, './audcommon/surveyChangeLifeStatusDetail'
	, './surveyscreation/surveyCreationOnDemandOtat'
	, './surveys/surveyCreationRequestDetail'
	, './surveyscreation/surveyCreationStepDetail'
	, './surveyscreation/surveyCreationStepSummary'
	, './surveys/surveyDetail'
	, './surveys/surveySchedule'
	, './surveys/surveysList'
	, './surveys/surveysListModal'
	, './surveys/surveysSearch'
	, './surveys/surveyStream'
	, './surveys/surveyTemplateDetail'
	, './surveys/surveyTemplateGroupDetail'
	, './surveys/surveyTemplateGroupsList'
	, './surveys/surveyTemplatesList'
	, './surveys/surveyTemplatesListModal'
	, './surveys/surveyTemplatesSearch'
	, './surveys/surveyTemplateTypeDetail'
	, './surveys/surveyTemplateTypesList'
	, './surveys/surveysVhrList'
	, './surveys/surveyCompile'



	, './surveys/components/nbsSurveyBarComponent'
	, './surveys/components/nbsSurveyScheduleComponent'
	, './surveys/components/nbsSurveyTemplateBarComponent'


], function () {


	audmodule.config(['$stateProvider',
		function ($stateProvider) {

            var routeObj = routeResolver.routeObj;

			// Next placeholder is used to FILL all routes by code, DO NOT REMOVE
			// [START-STATEPROVIDER-PLACEHOLDER]
			// version: 10.7.5.0
			// module: Aud
			$stateProvider
				.state('app.nav.audactcreationrequestdetail',routeObj.resolve({ngUrl:'/audactcreationrequestdetail',ngParamsName:'/{appStoreCode}/{virtualHumanResourceId}/{actFixedCode}',baseName:'aud/audacts/audActCreationRequestDetail'}))
				.state('app.nav.audactdetail',routeObj.resolve({ngUrl:'/audactdetail',ngParamsName:'/{actId}',baseName:'aud/audacts/audActDetail'}))
				.state('app.nav.audactsexecutionlist',routeObj.resolve({ngUrl:'/audactsexecutionlist',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/audActsExecutionList'}))
				.state('app.nav.audactssearch',routeObj.resolve({ngUrl:'/audactssearch',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/audActsSearch'}))
				.state('app.nav.auddevelopertoolsdetail',routeObj.resolve({ngUrl:'/auddevelopertoolsdetail',baseName:'aud/surveys/audDeveloperToolsDetail'}))
				.state('app.nav.audprocesslist',routeObj.resolve({ngUrl:'/audprocesslist',ngParamsName:'/{appStoreCode}/{viewKind}',baseName:'aud/surveys/audProcessList'}))
				.state('app.nav.copysurveyoptionsdetail-survey',routeObj.resolve({ngUrl:'/copysurveyoptionsdetail-survey',ngParamsName:'/{parentParams}',viewMode:'survey',baseName:'aud/audcommon/copySurveyOptionsDetail'}))
				.state('app.nav.copysurveyoptionsdetail-surveytemplate',routeObj.resolve({ngUrl:'/copysurveyoptionsdetail-surveytemplate',ngParamsName:'/{parentParams}',viewMode:'surveytemplate',baseName:'aud/audcommon/copySurveyOptionsDetail'}))
				.state('app.nav.mysurveyboardduedate',routeObj.resolve({ngUrl:'/mysurveyboardduedate',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/mySurveyBoardDueDate'}))
				.state('app.nav.mysurveyboardduedate-compiled',routeObj.resolve({ngUrl:'/mysurveyboardduedate-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/mySurveyBoardDueDate'}))
				.state('app.nav.mysurveyboardduedate-ondemand',routeObj.resolve({ngUrl:'/mysurveyboardduedate-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/mySurveyBoardDueDate'}))
				.state('app.nav.mysurveyboardduedate-tocompile',routeObj.resolve({ngUrl:'/mysurveyboardduedate-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/mySurveyBoardDueDate'}))
				.state('app.nav.mysurveyschedule-_calendar',routeObj.resolve({ngUrl:'/mysurveyschedule-_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_calendar',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-_timeline',routeObj.resolve({ngUrl:'/mysurveyschedule-_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timeline',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-_timelineassignment',routeObj.resolve({ngUrl:'/mysurveyschedule-_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timelineassignment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-_timelinevirtualequipment',routeObj.resolve({ngUrl:'/mysurveyschedule-_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timelinevirtualequipment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-compiled_calendar',routeObj.resolve({ngUrl:'/mysurveyschedule-compiled_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_calendar',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-compiled_timeline',routeObj.resolve({ngUrl:'/mysurveyschedule-compiled_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timeline',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-compiled_timelineassignment',routeObj.resolve({ngUrl:'/mysurveyschedule-compiled_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timelineassignment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-compiled_timelinevirtualequipment',routeObj.resolve({ngUrl:'/mysurveyschedule-compiled_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timelinevirtualequipment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-ondemand_calendar',routeObj.resolve({ngUrl:'/mysurveyschedule-ondemand_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_calendar',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-ondemand_timeline',routeObj.resolve({ngUrl:'/mysurveyschedule-ondemand_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timeline',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-ondemand_timelineassignment',routeObj.resolve({ngUrl:'/mysurveyschedule-ondemand_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timelineassignment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-ondemand_timelinevirtualequipment',routeObj.resolve({ngUrl:'/mysurveyschedule-ondemand_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timelinevirtualequipment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-tocompile_calendar',routeObj.resolve({ngUrl:'/mysurveyschedule-tocompile_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_calendar',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-tocompile_timeline',routeObj.resolve({ngUrl:'/mysurveyschedule-tocompile_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timeline',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-tocompile_timelineassignment',routeObj.resolve({ngUrl:'/mysurveyschedule-tocompile_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timelineassignment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyschedule-tocompile_timelinevirtualequipment',routeObj.resolve({ngUrl:'/mysurveyschedule-tocompile_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timelinevirtualequipment',baseName:'aud/surveys/mySurveySchedule'}))
				.state('app.nav.mysurveyslist',routeObj.resolve({ngUrl:'/mysurveyslist',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/mySurveysList'}))
				.state('app.nav.mysurveyslist-compiled',routeObj.resolve({ngUrl:'/mysurveyslist-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/mySurveysList'}))
				.state('app.nav.mysurveyslist-ondemand',routeObj.resolve({ngUrl:'/mysurveyslist-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/mySurveysList'}))
				.state('app.nav.mysurveyslist-tocompile',routeObj.resolve({ngUrl:'/mysurveyslist-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/mySurveysList'}))
				.state('app.nav.mysurveystream',routeObj.resolve({ngUrl:'/mysurveystream',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/mySurveyStream'}))
				.state('app.nav.mysurveystream-compiled',routeObj.resolve({ngUrl:'/mysurveystream-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/mySurveyStream'}))
				.state('app.nav.mysurveystream-ondemand',routeObj.resolve({ngUrl:'/mysurveystream-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/mySurveyStream'}))
				.state('app.nav.mysurveystream-tocompile',routeObj.resolve({ngUrl:'/mysurveystream-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/mySurveyStream'}))
				.state('app.nav.parameterdetail-aud',routeObj.resolve({ngUrl:'/parameterdetail-aud',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'aud',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-audtgt',routeObj.resolve({ngUrl:'/parameterdetail-audtgt',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'audtgt',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-audtpl',routeObj.resolve({ngUrl:'/parameterdetail-audtpl',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'audtpl',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterslist-aud',routeObj.resolve({ngUrl:'/parameterslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-audtgt',routeObj.resolve({ngUrl:'/parameterslist-audtgt',ngParamsName:'/{appStoreCode}',viewMode:'audtgt',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-audtpl',routeObj.resolve({ngUrl:'/parameterslist-audtpl',ngParamsName:'/{appStoreCode}',viewMode:'audtpl',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.reportdefinitiondetail.audactslistreportform',routeObj.resolve({ngUrl:'/audactslistreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/audActsListReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveykpireportform',routeObj.resolve({ngUrl:'/surveykpireportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/surveyKpiReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyquestionoptionanalysisreportform',routeObj.resolve({ngUrl:'/surveyquestionoptionanalysisreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/surveyQuestionOptionAnalysisReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyconfiguration',routeObj.resolve({ngUrl:'/surveyreportform-surveyconfiguration',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyconfiguration',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyexport',routeObj.resolve({ngUrl:'/surveyreportform-surveyexport',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyexport',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyexportaction',routeObj.resolve({ngUrl:'/surveyreportform-surveyexportaction',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyexportaction',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyexportactionanalysys',routeObj.resolve({ngUrl:'/surveyreportform-surveyexportactionanalysys',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyexportactionanalysys',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyexportanalysys',routeObj.resolve({ngUrl:'/surveyreportform-surveyexportanalysys',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyexportanalysys',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveykpisection',routeObj.resolve({ngUrl:'/surveyreportform-surveykpisection',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveykpisection',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveyoptiongrouping',routeObj.resolve({ngUrl:'/surveyreportform-surveyoptiongrouping',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveyoptiongrouping',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveytemplateconfiguration',routeObj.resolve({ngUrl:'/surveyreportform-surveytemplateconfiguration',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveytemplateconfiguration',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyreportform-surveytemplateexport',routeObj.resolve({ngUrl:'/surveyreportform-surveytemplateexport',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'surveytemplateexport',baseName:'aud/reportformsaud/surveyReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveysexport2reportform',routeObj.resolve({ngUrl:'/surveysexport2reportform',regionName:'reportFormRegion',ngParamsName:'/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/surveysExport2ReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveysexportreportform-exporta',routeObj.resolve({ngUrl:'/surveysexportreportform-exporta',regionName:'reportFormRegion',ngParamsName:'/{exportCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'exporta',srvParamsValue:'{"exportCode":"exporta"}',baseName:'aud/reportformsaud/surveysExportReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveysexportreportform-exportb',routeObj.resolve({ngUrl:'/surveysexportreportform-exportb',regionName:'reportFormRegion',ngParamsName:'/{exportCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'exportb',srvParamsValue:'{"exportCode":"exportb"}',baseName:'aud/reportformsaud/surveysExportReportForm'}))
				.state('app.nav.reportdefinitiondetail.surveyslistreportform',routeObj.resolve({ngUrl:'/surveyslistreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/surveysListReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhrtargetssurveyflaguilistreportform',routeObj.resolve({ngUrl:'/vhrtargetssurveyflaguilistreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/vhrTargetsSurveyFlagUIListReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhrtargetssurveykpilistreportform',routeObj.resolve({ngUrl:'/vhrtargetssurveykpilistreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/vhrTargetsSurveyKpiListReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhrtargetssurveylistreportform',routeObj.resolve({ngUrl:'/vhrtargetssurveylistreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/vhrTargetsSurveyListReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltoexcelsurveyganttreportform',routeObj.resolve({ngUrl:'/htmltoexcelsurveyganttreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToExcelSurveyGanttReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfaudactsexecutionsyntesisreportform',routeObj.resolve({ngUrl:'/htmltopdfaudactsexecutionsyntesisreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfAudActsExecutionSyntesisReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveydetailreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveydetailreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyDetailReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveykpireportform',routeObj.resolve({ngUrl:'/htmltopdfsurveykpireportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyKpiReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveyquestiongroupingreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveyquestiongroupingreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyQuestionGroupingReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveyquestionoptiondistributionreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveyquestionoptiondistributionreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyQuestionOptionDistributionReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveyquestionoptiondistributiontemporalyreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveyquestionoptiondistributiontemporalyreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyQuestionOptionDistributionTemporalyReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveysectionkpireportform',routeObj.resolve({ngUrl:'/htmltopdfsurveysectionkpireportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveySectionKpiReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveysexecutionsyntesisreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveysexecutionsyntesisreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveysExecutionSyntesisReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveyvhrtargetkpireportform',routeObj.resolve({ngUrl:'/htmltopdfsurveyvhrtargetkpireportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyVhrTargetKpiReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail.htmltopdfsurveyvhrtargetsynthesisreportform',routeObj.resolve({ngUrl:'/htmltopdfsurveyvhrtargetsynthesisreportform',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',baseName:'aud/reportformsaud/htmlToPdfSurveyVhrTargetSynthesisReportForm'}))
				.state('app.nav.reportdefinitionslist-aud',routeObj.resolve({ngUrl:'/reportdefinitionslist-aud',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'aud',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-audmx',routeObj.resolve({ngUrl:'/reportdefinitionslist-audmx',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'audmx',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-audtpl',routeObj.resolve({ngUrl:'/reportdefinitionslist-audtpl',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'audtpl',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-audtplmx',routeObj.resolve({ngUrl:'/reportdefinitionslist-audtplmx',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'audtplmx',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-audtrg',routeObj.resolve({ngUrl:'/reportdefinitionslist-audtrg',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'audtrg',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.surveyboardduedate',routeObj.resolve({ngUrl:'/surveyboardduedate',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/surveyBoardDueDate'}))
				.state('app.nav.surveyboardduedate-archived',routeObj.resolve({ngUrl:'/surveyboardduedate-archived',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived',baseName:'aud/surveys/surveyBoardDueDate'}))
				.state('app.nav.surveyboardduedate-compiled',routeObj.resolve({ngUrl:'/surveyboardduedate-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/surveyBoardDueDate'}))
				.state('app.nav.surveyboardduedate-ondemand',routeObj.resolve({ngUrl:'/surveyboardduedate-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/surveyBoardDueDate'}))
				.state('app.nav.surveyboardduedate-tocompile',routeObj.resolve({ngUrl:'/surveyboardduedate-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/surveyBoardDueDate'}))
				.state('app.nav.surveychangelifestatusdetail-info',routeObj.resolve({ngUrl:'/surveychangelifestatusdetail-info',ngParamsName:'/{parentParams}',viewMode:'info',baseName:'aud/audcommon/surveyChangeLifeStatusDetail'}))
				.state('app.nav.surveychangelifestatusdetail-modify',routeObj.resolve({ngUrl:'/surveychangelifestatusdetail-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'aud/audcommon/surveyChangeLifeStatusDetail'}))
				.state('app.nav.surveycreationondemandotat',routeObj.resolve({ngUrl:'/surveycreationondemandotat',ngParamsName:'/{surveyId}/{appOtatId}',baseName:'aud/surveyscreation/surveyCreationOnDemandOtat'}))
				.state('app.nav.surveycreationrequestdetail',routeObj.resolve({ngUrl:'/surveycreationrequestdetail',ngParamsName:'/{appStoreCode}/{viewKind}',baseName:'aud/surveys/surveyCreationRequestDetail'}))
				.state('app.nav.surveycreationstepdetail',routeObj.resolve({ngUrl:'/surveycreationstepdetail',ngParamsName:'/{appStoreCode}',baseName:'aud/surveyscreation/surveyCreationStepDetail'}))
				.state('app.nav.surveycreationstepsummary',routeObj.resolve({ngUrl:'/surveycreationstepsummary',ngParamsName:'/{appStoreCode}',baseName:'aud/surveyscreation/surveyCreationStepSummary'}))
				.state('app.nav.surveydetail',routeObj.resolve({ngUrl:'/surveydetail',ngParamsName:'/{surveyId}/{vhrEventId}/{appStoreCode}',baseName:'aud/surveys/surveyDetail'}))
				.state('app.nav.surveyschedule-_calendar',routeObj.resolve({ngUrl:'/surveyschedule-_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_calendar',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-_timeline',routeObj.resolve({ngUrl:'/surveyschedule-_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timeline',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-_timelineassignment',routeObj.resolve({ngUrl:'/surveyschedule-_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timelineassignment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-_timelinevirtualequipment',routeObj.resolve({ngUrl:'/surveyschedule-_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'_timelinevirtualequipment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-archived_calendar',routeObj.resolve({ngUrl:'/surveyschedule-archived_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived_calendar',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-archived_timeline',routeObj.resolve({ngUrl:'/surveyschedule-archived_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived_timeline',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-archived_timelineassignmentt',routeObj.resolve({ngUrl:'/surveyschedule-archived_timelineassignmentt',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived_timelineassignmentt',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-archived_timelinevirtualequipment',routeObj.resolve({ngUrl:'/surveyschedule-archived_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived_timelinevirtualequipment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-compiled_calendar',routeObj.resolve({ngUrl:'/surveyschedule-compiled_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_calendar',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-compiled_timeline',routeObj.resolve({ngUrl:'/surveyschedule-compiled_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timeline',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-compiled_timelineassignment',routeObj.resolve({ngUrl:'/surveyschedule-compiled_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timelineassignment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-compiled_timelinevirtualequipment',routeObj.resolve({ngUrl:'/surveyschedule-compiled_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled_timelinevirtualequipment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-ondemand_calendar',routeObj.resolve({ngUrl:'/surveyschedule-ondemand_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_calendar',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-ondemand_timeline',routeObj.resolve({ngUrl:'/surveyschedule-ondemand_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timeline',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-ondemand_timelineassignment',routeObj.resolve({ngUrl:'/surveyschedule-ondemand_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timelineassignment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-ondemand_timelinevirtualequipment',routeObj.resolve({ngUrl:'/surveyschedule-ondemand_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand_timelinevirtualequipment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-tocompile_calendar',routeObj.resolve({ngUrl:'/surveyschedule-tocompile_calendar',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_calendar',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-tocompile_timeline',routeObj.resolve({ngUrl:'/surveyschedule-tocompile_timeline',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timeline',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-tocompile_timelineassignment',routeObj.resolve({ngUrl:'/surveyschedule-tocompile_timelineassignment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timelineassignment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyschedule-tocompile_timelinevirtualequipment',routeObj.resolve({ngUrl:'/surveyschedule-tocompile_timelinevirtualequipment',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile_timelinevirtualequipment',baseName:'aud/surveys/surveySchedule'}))
				.state('app.nav.surveyslist',routeObj.resolve({ngUrl:'/surveyslist',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/surveysList'}))
				.state('app.nav.surveyslist-archived',routeObj.resolve({ngUrl:'/surveyslist-archived',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived',baseName:'aud/surveys/surveysList'}))
				.state('app.nav.surveyslist-compiled',routeObj.resolve({ngUrl:'/surveyslist-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/surveysList'}))
				.state('app.nav.surveyslistmodal',routeObj.resolve({ngUrl:'/surveyslistmodal',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/surveysListModal'}))
				.state('app.nav.surveyslist-ondemand',routeObj.resolve({ngUrl:'/surveyslist-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/surveysList'}))
				.state('app.nav.surveyslist-tocompile',routeObj.resolve({ngUrl:'/surveyslist-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/surveysList'}))
				.state('app.nav.surveyssearch',routeObj.resolve({ngUrl:'/surveyssearch',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/surveysSearch'}))
				.state('app.nav.surveystream',routeObj.resolve({ngUrl:'/surveystream',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',baseName:'aud/surveys/surveyStream'}))
				.state('app.nav.surveystream-archived',routeObj.resolve({ngUrl:'/surveystream-archived',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'archived',baseName:'aud/surveys/surveyStream'}))
				.state('app.nav.surveystream-compiled',routeObj.resolve({ngUrl:'/surveystream-compiled',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'compiled',baseName:'aud/surveys/surveyStream'}))
				.state('app.nav.surveystream-ondemand',routeObj.resolve({ngUrl:'/surveystream-ondemand',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'ondemand',baseName:'aud/surveys/surveyStream'}))
				.state('app.nav.surveystream-tocompile',routeObj.resolve({ngUrl:'/surveystream-tocompile',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}/{viewKind}',viewMode:'tocompile',baseName:'aud/surveys/surveyStream'}))
				.state('app.nav.surveytemplatedetail',routeObj.resolve({ngUrl:'/surveytemplatedetail',ngParamsName:'/{surveyId}/{appStoreCode}',baseName:'aud/surveys/surveyTemplateDetail'}))
				.state('app.nav.surveytemplategroupdetail',routeObj.resolve({ngUrl:'/surveytemplategroupdetail',ngParamsName:'/{surveyTemplateGroupId}/{appStoreCode}',baseName:'aud/surveys/surveyTemplateGroupDetail'}))
				.state('app.nav.surveytemplategroupslist',routeObj.resolve({ngUrl:'/surveytemplategroupslist',ngParamsName:'/{{appStoreCode}',baseName:'aud/surveys/surveyTemplateGroupsList'}))
				.state('app.nav.surveytemplateslist-all',routeObj.resolve({ngUrl:'/surveytemplateslist-all',ngParamsName:'/{appStoreCode}/{viewKind}',viewMode:'all',baseName:'aud/surveys/surveyTemplatesList'}))
				.state('app.nav.surveytemplateslist-archived',routeObj.resolve({ngUrl:'/surveytemplateslist-archived',ngParamsName:'/{appStoreCode}/{viewKind}',viewMode:'archived',baseName:'aud/surveys/surveyTemplatesList'}))
				.state('app.nav.surveytemplateslistmodal',routeObj.resolve({ngUrl:'/surveytemplateslistmodal',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/surveyTemplatesListModal'}))
				.state('app.nav.surveytemplatessearch',routeObj.resolve({ngUrl:'/surveytemplatessearch',ngParamsName:'/{appStoreCode}',baseName:'aud/surveys/surveyTemplatesSearch'}))
				.state('app.nav.surveytemplatetypedetail',routeObj.resolve({ngUrl:'/surveytemplatetypedetail',ngParamsName:'/{surveyTemplateTypeId}',baseName:'aud/surveys/surveyTemplateTypeDetail'}))
				.state('app.nav.surveytemplatetypeslist',routeObj.resolve({ngUrl:'/surveytemplatetypeslist',baseName:'aud/surveys/surveyTemplateTypesList'}))
				.state('app.nav.targetftc.surveysvhrlist-compiled',routeObj.resolve({ngUrl:'/surveysvhrlist-compiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'compiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-compiled',routeObj.resolve({ngUrl:'/surveysvhrlist-compiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'compiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-compiled',routeObj.resolve({ngUrl:'/surveysvhrlist-compiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'compiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.targetftc.surveysvhrlist-mycompiled',routeObj.resolve({ngUrl:'/surveysvhrlist-mycompiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mycompiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-mycompiled',routeObj.resolve({ngUrl:'/surveysvhrlist-mycompiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mycompiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-mycompiled',routeObj.resolve({ngUrl:'/surveysvhrlist-mycompiled',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mycompiled',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.targetftc.surveysvhrlist-myondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-myondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'myondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-myondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-myondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'myondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-myondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-myondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'myondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.targetftc.surveysvhrlist-mytocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-mytocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mytocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-mytocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-mytocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mytocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-mytocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-mytocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'mytocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.targetftc.surveysvhrlist-ondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-ondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'ondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-ondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-ondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'ondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-ondemand',routeObj.resolve({ngUrl:'/surveysvhrlist-ondemand',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'ondemand',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.targetftc.surveysvhrlist-tocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-tocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'tocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.assetftc.surveysvhrlist-tocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-tocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'tocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.bucketftc.surveysvhrlist-tocompile',routeObj.resolve({ngUrl:'/surveysvhrlist-tocompile',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventLifeStatusKey}/{vhrEventFixedTypeCode}',viewMode:'tocompile',baseName:'aud/surveys/surveysVhrList'}))
				.state('app.nav.vhreventgroupdetail-aud',routeObj.resolve({ngUrl:'/vhreventgroupdetail-aud',ngParamsName:'/{appStoreCode}/{vhrEventGroupId}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventGroupDetail'}))
				.state('app.nav.vhreventgroupslist-aud',routeObj.resolve({ngUrl:'/vhreventgroupslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventGroupsList'}))
				.state('app.nav.vhreventlabeldetail-aud',routeObj.resolve({ngUrl:'/vhreventlabeldetail-aud',ngParamsName:'/{appStoreCode}/{vhrEventLabelId}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventLabelDetail'}))
				.state('app.nav.vhreventlabelslist-aud',routeObj.resolve({ngUrl:'/vhreventlabelslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventLabelsList'}))
				.state('app.nav.vhreventtypedetail-aud',routeObj.resolve({ngUrl:'/vhreventtypedetail-aud',ngParamsName:'/{appStoreCode}/{vhrEventTypeId}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventTypeDetail'}))
				.state('app.nav.vhreventtypeslist-aud',routeObj.resolve({ngUrl:'/vhreventtypeslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/vhrevents/vhrEventTypesList'}))
				.state('app.nav.vhrgroupdetail-aud',routeObj.resolve({ngUrl:'/vhrgroupdetail-aud',ngParamsName:'/{appStoreCode}/{vhrGroupId}',viewMode:'aud',baseName:'rds/virtualhumanresources/vhrGroupDetail'}))
				.state('app.nav.vhrgroupslist-aud',routeObj.resolve({ngUrl:'/vhrgroupslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/virtualhumanresources/vhrGroupsList'}))
				.state('app.nav.vhrtypedetail-aud',routeObj.resolve({ngUrl:'/vhrtypedetail-aud',ngParamsName:'/{appStoreCode}/{vhrTypeId}',viewMode:'aud',baseName:'rds/virtualhumanresources/vhrTypeDetail'}))
				.state('app.nav.vhrtypeslist-aud',routeObj.resolve({ngUrl:'/vhrtypeslist-aud',ngParamsName:'/{appStoreCode}',viewMode:'aud',baseName:'rds/virtualhumanresources/vhrTypesList'}))
				.state('app.navlight.surveycompile',routeObj.resolve({ngUrl:'/surveycompile',ignoreHistory:true,ngParamsName:'/{surveyId}/{appStoreCode}',baseName:'aud/surveys/surveyCompile'}))
			;
			// [END-STATEPROVIDER-PLACEHOLDER]
          
        }]);

    return audmodule;
});
