﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'cmsDocService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var viewPageElementDetail = function ($q, $scope, $timeout, $injector, cmsDocService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, cmsDocService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, cmsDocService);

        var currentViewPageElementId = navigationService.stateParams().viewPageElementId;

        //#region Toolbar
        var toolbar = new NbsToolbar(vm.translate);
        toolbar.setDocumentationToolbar({
            backOnClick: () => { history.back(); },
            saveOnClick: () => { vm.submitAction(); },
            deleteOnClick: () => { },
            cloneOnClick: () => {  },
            refreshOnClick: () => {  },
            publishOnClick: () => { vm.writeAction(); },
            downloadOnClick: () => { },
        });
        vm.toolbarDatacontext = toolbar;
        //#endregion

        vm.setIsEditing();

        if (currentViewPageElementId) {
            getViewPageElement(currentViewPageElementId, true);
        }

        function getViewPageElement(viewPageElementId, forceReload) {
            var filter = {
                ViewPageElementId: viewPageElementId,
                LanguageId: contextService.virtualContext.CurrentLanguageId,
            };
            var defer = $q.defer();

            cmsDocService.getViewPageElement(filter, forceReload)
                .then(function (data) {
                    vm.currentElement = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.modalCancel = function () {
            cmsDocService.rejectChanges();
            navigationService.closeCurrentModal();
        }

        vm.writeAction = function () {
            cmsDocService.generateDocumentationFiles(contextService.virtualContext.CurrentLanguageId);
        }

    };

    viewPageElementDetail.$inject = injectParams;
    viewPageElementDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('viewPageElementDetail', viewPageElementDetail);
});