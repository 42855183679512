﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'fileTransferService', 'contextService', 'formSummaryService'];
    function fileBoxViewerAttachDetail($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, fileTransferService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentFsFolderId = navigationService.stateParams().fsFolderId;
        var fsFolderId = navigationService.stateParams().fsFolderId
        var entityType = { FsFolder: 1, FileMetadata: 2 };

        // grid in UxHelper
        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('fsdto', null);
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.NewFolder, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Save, false);
        ribbon.refreshStatusButtons();

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        // datasource grid context menù
        vm.customContextMenuItems = [];

        // property used to set visibility true ad grid files
        vm.viewUploadFiles = false;
        vm.folderSelected = null;
        vm.currentEntitySelected = null;

        // datasource grid files
        vm.currentFiles = [];

        // datasource treeview folders
        vm.currentFolders = [];

        vm.uploadfileData = {};

        //used by upload
        vm.files = [];

        vm.setIsViewing();

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = []
            funcsPromises.push(genericFsDtoCategorizeds(forceReload, fsFolderId));

            $q.all(funcsPromises)
                .then(function () {
                    //
                });
        };

        function genericFsDtoCategorizeds(forceReload, currentFsFolderId) {
            var filter = { FsFolderId: currentFsFolderId };
            var defer = $q.defer();

            utilService.genericFsDtoCategorizedBypass(filter, forceReload)
                .then(function (data) {
                    //set root node parentId = null
                    var rootNode = Lazy(data.results).findWhere({ Depth: 0, ParentFsDtoId: currentFsFolderId });
                    if (rootNode) {
                        vm.rootFolderAlreadyPresent = true;

                        rootNode.ParentFsDtoId = null;
                        rootNode.entityAspect.setUnchanged();

                        Lazy(data.results)
                            .each((file) => {
                                file.Categories = Nembus.Common.Library.converter.JsonFieldParse(file.CategoriesSerialized);
                                file.Category = Lazy(file.Categories).first();
                                file.CategoriesSerialized = JSON.stringify(Nembus.Common.Library.converter.JsonFieldParse(file.CategoriesSerialized));
                                file.entityAspect.setUnchanged();
                            });

                        vm.currentFsDtos = data.results;
                        vm.currentFolders = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.folder }).toArray();
                        vm.currentFiles = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.file, ParentFsDtoId: currentFsFolderId }).toArray();

                        vm.treeNbsExplorerRefresh = new Date().getTime();

                        vm.forceDatasourceLocal = {
                            refreshkey: new Date().getTime(),
                            dataSource: vm.currentFiles
                        }

                        defer.resolve(true);
                    } else {
                        vm.rootFolderAlreadyPresent = false;
                    }
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        vm.queryCellInfo = function (args) {
            if (args.column.field === "FsExtension") {
                // FsExtension views icon based on file type
                var className = Nembus.Gas.Library.Tools.GetIconClassNameByExtension(args.text, '16');
                args.cell.innerHTML = "<img class='" + className + "' src='/images/TreeView/transparent.gif' border='0'></img>";
            }
        }

        vm.refreshAction = function () {
            genericFsDtoCategorizeds(true, fsFolderId);
        };

        vm.rowDblClick = function (args) {
            if (args.data.FileNotSavedYet === true) {
                vm.removeAlerts();
                vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('FileNotSaved') });
            } else {
                var downloadData = {
                    entityGuid: args.data.FsDtoId,
                    asAttach: true,
                };
                fileTransferService.downloadRemoteFileByGuid(downloadData);
            }
        }

        vm.onNodeSelectFolder = function (args) {
            $timeout(function () {
                var folder = Lazy(vm.currentFolders).findWhere({ FsDtoId: args.id });
                if (folder) {
                    vm.currentEntitySelected = entityType.FsFolder;
                }

                vm.folderSelected = {
                    folder: folder,
                }
                currentFsFolderId = folder.FsDtoId;

                var files = Lazy(vm.currentFsDtos).where({ FsTypeId: utilService.fsType.file, ParentFsDtoId: currentFsFolderId }).toArray();
                vm.currentFiles = files;

                vm.forceDatasourceLocal = {
                    refreshkey: new Date().getTime(),
                    dataSource: vm.currentFiles
                }
            });
        }
    };

    fileBoxViewerAttachDetail.$inject = injectParams;
    fileBoxViewerAttachDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxViewerAttachDetail', fileBoxViewerAttachDetail);
});