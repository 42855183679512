﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'utilService', 'navigationService', 'contextService', 'formSummaryService'];
    var fileBoxFtc = function ($q, $scope, $timeout, $injector, foundationService, utilService, navigationService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        
        var fileBoxId = navigationService.stateParams().fileBoxId;
        var cmsView = 'fileboxftc-_';
        var viewKind = navigationService.stateParams().viewKind;
        var target = navigationService.stateParams().target;
        var fileBoxType = navigationService.stateParams().fileBoxType;
        var fileBoxTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.FileBoxType, null, vm.translate).obj.normal.name_value;
        var fileBoxTypeId = fileBoxTypeEnum[fileBoxType];
        var fileBox = null;
        var state = { generality: 'generality', attachments: 'attachments', importData: 'importData', data: 'data' };

        vm.tabAttachmentsIsVisible = fileBoxTypeId === fileBoxTypeEnum.Attachments;
        vm.tabImportDataIsVisible = fileBoxTypeId === fileBoxTypeEnum.ImportData;

        var fastTab = new NbsAccordion();
        fastTab.AddFastTab(state.generality, "General");
        fastTab.AddFastTab(state.attachments, "Attachments", vm.tabAttachmentsIsVisible);
        fastTab.AddFastTab(state.importData, "Import", vm.tabImportDataIsVisible);
        fastTab.AddFastTab(state.data, "Data", vm.tabImportDataIsVisible);
        vm.fastTabDatacontext = fastTab;

        vm.ftcOnClick = function (event) {
            navigateTo(event.id);
        }

        // raised from detail view
        vm.ftcOnAddEntity = function (entity) {
            $timeout(() => {
                // not used
            });
        }

        if (fileBoxId) {
            foundationService.getFileBox({ FileBoxId: fileBoxId }, false)
                .then(function (data) {
                    fileBox = data.results[0];

                    vm.tabAttachmentsIsVisible = fileBoxTypeId === fileBoxTypeEnum.Attachments;
                    vm.tabImportDataIsVisible = (fileBoxTypeId === fileBoxTypeEnum.ImportData) && (target !== 'view');

                    if (!fileBox.FsFolderId) {
                        //actio for root folder attachment
                        vm.rootKey = NbsGuidManager.newGuid();
                        utilService.setFsDtoRootAddFunction({
                            entityService: foundationService,
                            rootFolderKey: vm.rootKey,
                            fnSetRootFolder: function (folderId) { fileBox.FsFolderId = folderId; }
                        });
                    }

                    $timeout(() => {
                        vm.fastTabsAreDisabled = false;
                    });

                    // configure form summary items
                    formSummaryService.clearDatasource();
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'FileBoxCode', 'FileBox'), value: fileBox.FileBoxCode });
                    formSummaryService.push({ key: vm.translateCmsView(cmsView, 'FileBoxName', 'FileBox'), value: fileBox.FileBoxName });

                    goToDefaultFastTab();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        } else {
            goToDefaultFastTab();

            $timeout(() => {
                vm.fastTabsAreDisabled = true;
            });
        }

        function navigateTo(accordionId) {
            if (!accordionId) {
                return;
            }

            var navigationState = null;
            var navigationStateParams = { fileBoxId: fileBoxId, viewKind: viewKind };
            var vmCalc = vm.viewMode === '_' ? '' : '-' + vm.viewMode;
            var viewer = target === 'view' ? 'viewer' : '';
            switch (accordionId) {
                case state.generality:
                    navigationState = 'app.nav.fileboxftc.filebox' + viewer + 'detail' + vmCalc;
                    break;
                case state.attachments:
                    navigationState = 'app.nav.fileboxftc.filebox' + viewer + 'attachdetail' + vmCalc;
                    var attachmentRootFolder = null;
                    if (fileBox.FsFolderId) {
                        attachmentRootFolder = fileBox.FsFolderId;
                    }
                    else {
                        attachmentRootFolder = vm.rootKey;
                    }
                    navigationStateParams = { 'fsFolderId': attachmentRootFolder };
                    break;
                case state.importData:
                    navigationState = 'app.nav.fileboxftc.fileboximportdetail';
                    break;
                case state.data:
                    var viewPage = viewKind.toLowerCase() + 'slist';
                    navigationState = 'app.nav.fileboxftc.' + viewPage;
                    break;
            }

            // call navigation state
            if (navigationState && navigationStateParams) {
                navigationService.navigateTo(navigationState, navigationStateParams);
            }
        };

        function goToDefaultFastTab() {
            // go to default page only if is called ftc directly
            if (navigationService.currentState().endsWith('.fileboxftc')) {
                navigateTo(state.generality);
            }
        }
    };

    fileBoxFtc.$inject = injectParams;
    fileBoxFtc.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxFtc', fileBoxFtc);
});