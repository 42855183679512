﻿'use strict';

define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function targetsList($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return targetSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetTargetNewSection());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var targetSelected = null;
        var canManageTarget = vm.havePermission(Nembus.Aud.Library.auth.Permission['AUDTARGET_MANAGE'], contextService.virtualContext);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('target', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        ribbon.setRibbonStatusButton(ribbon.Buttons.NewTarget, canManageTarget);
        ribbon.refreshStatusButtons();

        // hook from gridbar
        var ejDataManager = null;
        var currentVhrTargetBarFilter = null;
        vm.vhrTargetGridBarOnChanges = function (result) {
            currentVhrTargetBarFilter = result.targetFilter;
            currentVhrTargetBarFilter.AppStoreBw = appStoreBw;
            currentVhrTargetBarFilter.VhrStatusId = vhrStatusEnum.Enabled;

            if (ejDataManager === null) {
                var supportData = { translate: vm.translate, contextService: contextService };
                ejDataManager = foundationService.getTargetDataManager(supportData, currentVhrTargetBarFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentVhrTargetBarFilter);
            } else {
                refreshGridDatasource(currentVhrTargetBarFilter)
            }
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateTo("app.nav.targetftc", { 'virtualHumanResourceId': args.data.VirtualHumanResourceId, 'appStoreCodeFtc': appStoreCode, 'referral': 'virtualequipment' });
        };

        vm.newAction = function () {
            navigationService.navigateTo("app.nav.targetftc", { 'virtualHumanResourceId': null, 'appStoreCodeFtc': appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        // is not a real delete
        // unbind that set a statuds to Delete
        vm.deleteAction = function () {
            if (!targetSelected) {
                return;
            }            

            foundationService.unbindVhr(targetSelected.VirtualHumanResourceId)
                .then(function (result) {
                    vm.refreshAction();
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        };

        vm.gridSelection = function (args) {
            targetSelected = args.data;

            $timeout(() => {
                if (targetSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, canManageTarget);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, canManageTarget);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Copy, false);
                }

                ribbon.refreshStatusButtons();
            });            
        };

        vm.copyAction = function () {
            if (!targetSelected) {
                return;
            }            

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var filter = {
                            VirtualHumanResourceId: targetSelected.VirtualHumanResourceId
                        };

                        foundationService.cloneTarget(filter)
                            .then(function (result) {
                                vm.refreshAction();
                            });
                    }
                });
        };

        vm.createGridEvent = function (result) {            
            vm.vhrTargetGridBarDataContext = {
                commonBarDatasource: result.barDatasource,
                defaultKeys: {
                    columnsKey: null, sortingKey: null
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    targetsList.$inject = injectParams;
    targetsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('targetsList', targetsList);
});