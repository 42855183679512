﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService'];
    function mySurveysList($q, $injector, $scope, $timeout, vhreventService, audService, utilService, navigationService, checkStateChangeService, contextService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentSurveySelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab({ showGroupArchive: true });
        ribbon.addPrintReportTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetAuditNewSection());
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_manage, ribbon.GetAuditManageSection());
        ribbon.addTab(ribbon.GetMyAuditManageTab());
        ribbon.addTab(ribbon.GetAuditViewsTab());
        ribbon.setUnderBreakpointSmall();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventTypeCode = navigationService.stateParams().vhrEventTypeCode;
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var viewKind = navigationService.stateParams().viewKind;
        var currentSurveySelected = null;
        var currentSurveyBarFilter = null;
        var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;
        var canChangeSurveyWorkflowStatus = Nembus.Aud.Library.SSV.CanManageSurveyWorkflow()
            && vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDIT_WORKFLOW_CHANGESTATUS'], contextService.virtualContext);

        //#region Toolbar
        var toolbar = new NbsToolbar(vm.translate);
        var btnCompile = toolbar.getButton();
        btnCompile.CssClass = "nbs-toolbar-button-compile";
        btnCompile.Title = "Compile";
        btnCompile.OnClick = () => { vm.compileAction() };
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Back, () => { vm.backAction(); });
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Pencil, () => { vm.modifyAction(); });
        toolbar.addButton(btnCompile)
        toolbar.addButtonByType(toolbar.BUTTONTYPE.Pdf, () => { vm.exportReportToPdfAction(); });
        toolbar.setBreakpointUIEnabled();
        toolbar.setHideUpper(toolbar.BREAKPOINTUI.sm);
        vm.toolbarDatacontext = toolbar;
        //#endregion

        // set grid component datacontext
        var gridUIkey = null;
        if (vhrEventLifeStatusKey === 'ondemand') {
            gridUIkey = 'ondemand';
        } else {
            gridUIkey = 'list';
        }

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', gridUIkey, null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        //#region set ribbon fn navigations
        var navParams = { 'appStoreCode': appStoreCode, 'vhrEventLifeStatusKey': vhrEventLifeStatusKey, 'viewKind': viewKind };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_MySurvey(navParams); };
        vm.onRibbonCalendar = () => { ribbon.onRibbonCalendar_MySurvey(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_MySurvey(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_MySurvey(navParams); };
        vm.onRibbonTimeline = () => { ribbon.onRibbonTimeline_MySurvey(navParams); };
        vm.onRibbonTimelineTarget = () => { ribbon.onRibbonTimelineTarget_MySurvey(navParams); };
        vm.onRibbonTimelineAssignment = () => { ribbon.onRibbonTimelineTargetAssignment_MySurvey(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewCalendar, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.Timeline, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineTarget, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.setRibbonStatusButton(ribbon.Buttons.TimelineAssignment, vhrEventLifeStatusKey !== 'ondemand');
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        vm.rowDblClick = function (args) {
            vm.compileAction();
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                currentSurveySelected = args.data;

                var canCompile = false;
                var canView = false;
                if (currentSurveySelected) {
                    canCompile = Nembus.Aud.Library.CanCompileSurvey(contextService, audService, currentSurveySelected);
                    canView = Nembus.Aud.Library.CanViewSurvey(currentSurveySelected, audService);
                }

                ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, canCompile);
                ribbon.setRibbonStatusButton(ribbon.Buttons.View, canView);

                if (currentSurveySelected) {
                    ribbon.setActionWorkflowButtons([currentSurveySelected], canChangeSurveyWorkflowStatus);
                } else {
                    ribbon.setActionWorkflowButtons([], canChangeSurveyWorkflowStatus);
                }

                ribbon.refreshStatusButtons();
            });
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentGridFilter = null;
        vm.surveyBarOnChanges = function (result) {
            // save in view current filter passed by survey bar
            currentSurveyBarFilter = result;
            currentGridFilter = parseFromBarFilter(currentSurveyBarFilter);

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: true, translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, currentGridFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(currentGridFilter);
                    });
            } else {
                refreshGridDatasource(currentGridFilter)
            }
        };


        // myprocess    
        // mysurveys
        var drbacProcessId = null;
        if (viewKind === 'myprocess') {
            // filter by process id
            if (!audService.SurveyProcessSelected) {

                navigationService.navigateTo("app.nav.audprocesslist", { 'appStoreCode': appStoreCode, 'viewKind': viewKind });
            } else {

                drbacProcessId = audService.SurveyProcessSelected.DrbacProcessId;
            }
        }

        vm.modalOk = function (modalInstance) {
            vm[modalInstance].close();
        };

        vm.modalCancel = function (modalInstance) {
            vm[modalInstance].dismiss();
        };

        vm.createGridEvent = function (result) {
            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: result.barDatasource,
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                groupingKey: vhrEventLifeStatusKey,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: vhrEventLifeStatusKey
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.viewAction = function () {
            vm.compileActionP(false);
        };

        vm.compileAction = function () {
            vm.compileActionP(true);
        };

        vm.compileActionP = function (isCompileAction) {
            if (!currentSurveySelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (isCompileAction) {
                var canCompile = Nembus.Aud.Library.CanCompileSurvey(contextService, audService, currentSurveySelected);
                if (!canCompile) {
                    return;
                }
            }

            if (currentSurveySelected.SurveyCreationPatternId === surveyCreationPatternObj.OnDemand) {
                // before create a clone of selected survey and then compile it
                var filter = { SurveyId: currentSurveySelected.SurveyId };
                audService.getSurveyOnDemandClone(filter, true)
                    .then(function (data) {
                        var surveyCloned = Lazy(data.results).first();
                        navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': surveyCloned.SurveyId, 'appStoreCode': appStoreCode });
                    });
            } else {
                navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': currentSurveySelected.SurveyId, 'appStoreCode': appStoreCode });
            }
        };

        vm.workflowModifyAction = function () {
            if (!currentSurveySelected) {
                return;
            }

            navigationService.navToVhrEventWorkflowModifyMode(vm.suspendDirty, { appStoreCode: appStoreCode })
                .then(function (result) {
                    if (result.data) {
                        updateSurveyWorkflowStatusBulk([currentSurveySelected.VhrEventId], moduleTargetId, result.data.WorkflowStatusId, result.data.WorkflowStatusNote, vhrEventShuffleActionObj.ChangeWorkflowStatus);
                    }
                });
        };

        vm.workflowDeleteAction = function () {
            if (!currentSurveySelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        updateSurveyWorkflowStatusBulk([currentSurveySelected.VhrEventId], moduleTargetId, null, null, vhrEventShuffleActionObj.DeleteWorkflowStatus);
                    }
                })
                .catch(function (reason) {                    
                });
        };

        vm.workflowInfoAction = function () {
            if (!currentSurveySelected) {
                return;
            }

            navigationService.navToVhrEventWorkflowHistoryMode(vm.suspendDirty, { VhrEventId: currentSurveySelected.VhrEventId, appStoreCode: appStoreCode });
        };

        //Http Post
        function updateSurveyWorkflowStatusBulk(vhrEventsId, moduleTargetId, workflowStatusId, workflowStatusNote, vhrEventShuffleAction) {
            vm.removeAlerts();

            var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
            vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleAction;
            if (vhrEventShuffleAction === vhrEventShuffleActionObj.DeleteWorkflowStatus) {
                vhreventShuffleDto.WorkflowStatusId = 0;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = null;
            } else {
                vhreventShuffleDto.WorkflowStatusId = workflowStatusId;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = workflowStatusNote;
            }
            vhreventShuffleDto.VhrEventBillingStatus = 0;
            vhreventShuffleDto.VhrEventLifeStatus = 0;
            vhreventShuffleDto.ModuleTargetId = moduleTargetId;
            vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

            vhreventService.saveChanges()
                .then(function () {
                    vm.refreshAction();
                });
        }

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        //#region Override of base methods to add verify on selection record
        function canPrintReport() {
            var result = false;
            if (!currentSurveySelected) {
                navigationService.selectOneItemMessageModal();
            } else {
                result = true;
            }

            return result;
        }

        vm.exportReportToPdfAction = function () { pExportReport('pdf'); };
        vm.exportReportToWordAction = function () { pExportReport('openword'); };
        vm.exportReportToXmlAction = function () { pExportReport('xml'); };
        vm.exportReportToExcelAction = function () { pExportReport('openexcel'); };
        vm.exportReportToHtmlAction = function () { pExportReport('html'); };

        function pExportReport(format) {
            if (canPrintReport()) {
                vm.getReportsForCmsView(cmsService)
                    .then(function () {
                        var cEntity = currentSurveySelected;
                        var currentReportDefinition = vm.currentReportData.reportDefinition;
                        var currentConcatName = cEntity.SurveyCode + ' - ' + cEntity.SurveyName;

                        if (currentReportDefinition) {
                            currentConcatName = currentReportDefinition.ReportDefinitionReferenceCode + ' - ' + currentConcatName;
                        }

                        var reportArgs = {
                            Ds1_Arg1: {                                         //SurveyFilter
                                SurveyId: currentSurveySelected.SurveyId
                            },
                            Ds1_Arg2: {},                                       //SurveyVhrEventFilter
                            Ds1_Arg3: {                                         //VhrEventFilter
                                ModuleTargetId: moduleTargetId
                            },
                            Ds1_Arg4: {},                                       //SurveySectionFilter
                            Ds1_Arg5: {},                                       //SurveyQuestionFilter
                            Ds1_Arg6: {},
                            Ds1_Arg7: {},                                       //VirtualHrFilter
                            Ds1_Arg8: false,                                    //isConfiguration
                            Ds1_Arg9: {                                         //surveyAnalysisFilter
                                SurveyId: currentSurveySelected.SurveyId
                            },
                        };

                        vm.setReportData({ reportArgs: reportArgs, replaceName: currentConcatName });

                        vm.exportReport(format);
                    });
            }
        }
        //#endregion overload of base methods to add verify on selection record


        //#region Filters for datasources
        function parseFromBarFilter(surveyBarFilter) {
            // surveyFilter
            surveyBarFilter.surveyFilter.IsArchived = false;
            // surveyVhrEventFilter
            if (vhrEventLifeStatusKey === 'ondemand') {
                surveyBarFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
            } else {
                surveyBarFilter.surveyVhrEventFilter.ExcludeOnDemand = true;
            }
            // vhrEventFilter
            surveyBarFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            surveyBarFilter.vhrEventFilter.VhrEventTypeCode = vhrEventTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            surveyBarFilter.vhrEventFilter.VhrEventKind = vhrEventKindEnum.Data;
            surveyBarFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;
            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    surveyBarFilter.surveyFilter.IsCompleted = false;
                    break;
                case 'compiled':
                    surveyBarFilter.surveyFilter.IsCompleted = true;
                    break;
                case 'ondemand':
                    // ??????????????????
                    break;
                case 'mysurveys':
                    surveyBarFilter.vhrEventFilter.VhrEventLifeStatusIds = null;
                    break;
                default:
            }

            return surveyBarFilter;
        }
        //#endregion
    }

    mySurveysList.$inject = injectParams;
    mySurveysList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('mySurveysList', mySurveysList);
});