'use strict';
var rbacmodule = angular.module('nbs.rbac.module', ['nbs.shared.module', 'ui.bootstrap']);
define([
    './users/appUserDetail'
    , './users/appUsersList'
    , './users/appUsersListModal'
    , './myaccount/changeMyPassword'
    , './authorization/authorizationDenied'
    , './drbaccommon/drbacAssignmentDetail'
    , './drbacconfig/drbacConfigDetail'
    , './drbacconfig/drbacPermissionGroupList'
    , './drbacconfig/drbacPermissionList'
    , './drbacprocess/drbacProcessConfigDetail'
    , './drbacprocess/drbacProcessConfigList'
    , './drbacprocess/drbacProcessDetail'
    , './drbacprocess/drbacProcessGlobalDetail'
    , './drbacprocess/drbacProcessGlobalList'
    , './drbacprocess/drbacProcessList'
    , './drbacconfig/drbacRoleDetail'
    , './drbacconfig/drbacRoleList'
    , './drbacconfig/drbacSchemaDetail'
    , './drbacconfig/drbacSchemaList'
    , './drbacconfig/drbacUserGroupFtc'
    , './drbacconfig/drbacUserGroupDetail'
    , './drbacconfig/drbacUserToUserGroups'
    , './drbacconfig/drbacUserGroupList'
    , './myaccount/forceChangeMyPassword'
    , './myaccount/mobileAppConfig'
    , './myaccount/myAccount'
    , './myaccount/renewMyTwoFactorSecretCode'
    , './reportformsrbac/authAuditListReportForm'
    , './profiles/userProfileDetail'
    , './profiles/userProfilesList'


    , './users/components/nbsAppUserBarComponent'


], function () {

    
    return rbacmodule;
});
