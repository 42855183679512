﻿// TECCLEANGENERALDATA data is the event of type data viewed in events list

'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var fileBoxDetail = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var fileBoxId = navigationService.stateParams().fileBoxId;
        var fileBoxType = navigationService.stateParams().fileBoxType;
        var fileBoxTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.FileBoxType, null, vm.translate).obj.normal.name_value;
        var fileBoxTypeId = fileBoxTypeEnum[fileBoxType];
        var viewKind = navigationService.stateParams().viewKind;
        var fileBoxTag = navigationService.stateParams().fileBoxTag;
        var viewMode = navigationService.currentViewMode();

        vm.moduleTargets = Nembus.Rds.Library.Tools.ModuleTargetContext({ AppStoreBw: appStoreBw }, contextService);
        
        if (fileBoxId) {
            vm.setIsViewing();

            loadData(true);
        } else {
            vm.setIsAdding();

            vm.currentFileBox = foundationService.dataContext.fileBoxes.add({
                FileBoxFromDate: null,
                FileBoxToDate: null,
                FileBoxTag: fileBoxTag,
                FileBoxTypeId: fileBoxTypeId,
                AppStoreBw: appStoreBw,
                ModuleTargetId: vm.moduleTargets[0].ModuleTargetId,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
            });

            ribbon.setRibbonStatusButton(ribbon.Buttons.New, true);
            ribbon.refreshStatusButtons();
        }

        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getFileBox(forceReload, fileBoxId));

            $q.all(funcsPromises)
                .then(function () {
                    //nothing to load
                });
        }

        function getFileBox(forceReload, fileBoxId) {
            var filter = { FileBoxId: fileBoxId };
            var defer = $q.defer();

            foundationService.getFileBox(filter, forceReload)
                .then(function (data) {
                    vm.currentFileBox = data.results[0];
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState) {
                        navigationService.navigateTo("app.nav.fileboxftc.fileboxdetail-" + viewMode, {
                            'fileBoxId': vm.currentFileBox.FileBoxId,
                            'appStoreCode': appStoreCode,
                            'viewKind': viewKind,
                            'fileBoxTag': fileBoxTag,
                            'fileBoxType': fileBoxType
                        });
                    }
                }
            );
        };
    };

    fileBoxDetail.$inject = injectParams;
    fileBoxDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxDetail', fileBoxDetail);
});