﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'authenticationService', 'cmsService', 'cmstoolService', 'contextService', 'navigationService', 'fileTransferService', 'currentSessionService', 'publicService', 'notificationService'];
    function appPanel($q, $scope, $timeout, $injector, authenticationService, cmsService, cmstoolService, contextService, navigationService, fileTransferService, currentSessionService, publicService, notificationService) {
        var vm = this;
        nbsVm.call(vm, currentSessionService, navigationService, contextService, $q, $injector, $timeout);

        var virtualCompanySelected = null;
        var notificationStatusEnumParsed = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationStatus, null, null);
        var notificationStatusEnumObj = notificationStatusEnumParsed.obj.normal.name_value;
        var notificationChannelKindEnumParsed = Nembus.Common.Library.util.parseEnum(notificationService.dataContext.enumTypes.NotificationChannelKind, null, null);
        var notificationChannelKindEnumObj = notificationChannelKindEnumParsed.obj.normal.name_value;
        var currentNavigationTree = cmsService.currentNavNodes.navigationPanel;

        vm.virtualContext = contextService.virtualContext;
        vm.maintenanceStatus = publicService.maintenanceStatus;
        vm.downloadFiles = contextService.downloadFiles;
        vm.batchRequests = contextService.batchRequests;
        vm.myNotificationCounter = notificationService.myNotificationCounter;
        vm.auditModuleIsPresent = Lazy(vm.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTAUD' }).some();
        vm.hinfosModuleIsPresent = Lazy(vm.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTITS' }).some();
        vm.maintenanceModuleIsPresent = Lazy(vm.virtualContext.ModuleTargets).where({ ModuleTargetCode: 'MTFAS' }).some();
        //vm.IsLicenseValid = vm.virtualContext.IsLicenseValid;

        vm.moduleNodes = [];
        vm.currentTheme = '';
        vm.currentYear = new Date().getFullYear();
        vm.breadCrumbNodes = [];

        cmsService.refreshBreadCrumb = function () {
            vm.breadCrumbNodes = cmsService.currentNavNodes.breadCrumb;
        };

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getMyNotifications(forceReload));
            funcsPromises.push(getNavigationModulesFirstLevel());

            $q.all(funcsPromises)
                .then(function (results) {
                    var notifications = results[0];
                    if (Lazy(notifications).some()) {
                        $timeout(() => {
                            vm.myNotificationCounter = Lazy(notifications).length();
                        })
                    }

                    var modules = results[1];
                    Lazy(modules)
                        .each((module) => {
                            module.dataSource = currentNavigationTree[module.CmsViewMode];
                            module.isVisible = currentNavigationTree[module.CmsViewMode] !== null || currentNavigationTree[module.CmsViewMode] !== undefined;
                        });

                    vm.moduleNodes = modules;
                });
        }

        vm.logout = function () {
            contextService.clearContext();
            publicService.getSsoConfig()
                .then(ssoConfig => {
                    authenticationService.logout()
                        .then(function (authBehavior) {
                            if (authBehavior.authMode === 'owc') {
                                authenticationService.redirectToRootLocation();
                            }
                            else if (authBehavior.authMode === 'idp') {
                                /*
                                var logouturi = ssoConfig.LogoutUri.replace('[clientId]', ssoConfig.ClientId).replace('[returnTo]', ssoConfig.LogoutPostBackUri);
                                while (logouturi.indexOf("&amp;") >= 0) {
                                    logouturi = logouturi.replace('&amp;', '&');
                                }
                                location.href = logouturi;
                                */
                                authenticationService.ssoLogout(ssoConfig);
                            }
                            else {
                                authenticationService.redirectToRootLocation();
                            }
                        });
                });
        };

        vm.navigateToJobsManage = function () {
            navigationService.navigateTo("app.nav.jobsmanage");
        };

        vm.navigateToDevelopTools = function (moduleCode) {
            if (!moduleCode) {
                moduleCode = '';
            }

            navigationService.navigateTo('app.nav.' + moduleCode + 'developertoolsdetail');
        };

        vm.closeFileDownload = function () {
            contextService.clearDownloadFiles();
        };

        vm.navToBatchRequestsQueue = function () {
            navigationService.navigateTo("app.nav.batchrequestsqueuelist", {});
        };

        vm.manageFileDownload = function (file, action) {
            //action: inline,asAttach
            //TODO: MANAGE IT
            if (action === 'inline') {
                file.downloadData.asAttach = false;
            }
            else {
                file.downloadData.asAttach = true;
            }
            fileTransferService.downloadRemoteFile(file.downloadData);
        };

        vm.closeBatchRequest = function () {
            contextService.clearBatchRequests();
        };

        vm.batchPolling = function (t) {
            if (!t) {
                t = 5000;
            }
            $timeout(() => {
                var batchRequestsIdToCheck = contextService.getBatchRequestsIdToCheck(contextService.batchRequests.requests);
                if (batchRequestsIdToCheck.length > 0) {

                    contextService.checkBatchRequests(batchRequestsIdToCheck)
                        .then(function (data) {
                            contextService.updateBatchRequestStatus(data.results);
                            if (contextService.pollingIsActive()) {
                                vm.batchPolling(5000);
                            }
                        });
                }
            }, t);
        };
        contextService.controllerBatchPollingFunction = vm.batchPolling;

        vm.batchRequestOnCompletedAutoAction = function (request) {
            if (request.onCompletedAutoAction) {
                // when request.requestStatus == 'completed'
                // auto called in appPanel.cshtml from ng-init of span element
                request.onCompletedAutoAction(request)
                    .then(function (jobResult) {
                        //jobResult = {
                        //    jobStatus: null,                // done, error, warning, conflict, ....
                        //    jobMessage: null,               // Just translated: message to add on appLayout toolbar (can be null)
                        //    jobDetailActionLabel: null      // Just translated: if set, then add dropdown with item translated (E.g. Dettaglio)
                        //};

                        request.jobResult = jobResult;
                        request.hasDetailAction = typeof request.detailAction === 'function';
                        switch (request.jobResult.jobStatus) {
                            case 'done': request.classButton = 'btn-success'; break;
                            case 'warning': request.classButton = 'btn-warning'; break;
                            case 'conflict': request.classButton = 'btn-warning'; break;
                            case 'error': request.classButton = 'btn-danger'; break;
                            default: request.classButton = 'btn-default';
                        }
                    });
            }
        };

        vm.batchRequestDetailAction = function (request) {
            if (request.detailAction) {
                // eventually navigation to batchrequest detail
                request.detailAction(request);
            }
        };

        vm.openSupport = function () {
            window.open(vm.virtualContext.UriSupport.replace('&amp;', '&'), '_blank');

            //publicService.getSsoConfig()
            //.then(ssoConfig => {
            //if (ssoConfig.SsoEnabled) {
            //    authenticationService.redirectToSsoSupportRequest(ssoConfig);
            //}
            //else {
            //window.open(vm.virtualContext.UriSupport.replace('&amp;','&'), '_blank');
            //}
            // });
        };

        vm.openHelp = function () {
            window.open(vm.virtualContext.UriMan, '_blank');
        };

        vm.openAdminHelp = function () {
            window.open(vm.virtualContext.UriAdminMan, '_blank');
        };

        vm.setTheme = function (theme) {
            vm.currentTheme = theme;
        };

        vm.myAccount = function () {
            navigationService.navToMyAccount();
        };

        vm.myNotification = function () {
            navigationService.navToMyNotification();
        };

        vm.myTickets = function () {
            navigationService.navToMyTickets();
        };

        vm.navigationNodeOnClick = function (args) {
            var currentNavNode = Lazy(cmsService.currentNavNodes.allNodes).findWhere({ NavigationNodeId: args.id });
            if (currentNavNode && currentNavNode.NavigationNodeKindId === 2) {
                navigationService.navigateTo(currentNavNode.NavigationState, castParam(currentNavNode.NavigationParam));
                if (Nembus.Common.Library.ui.isUnderBreakpoint_md()) {
                    Nembus.Common.Library.ui.sidebarToggle();
                }
            }

            cmsService.setBreadcrumbTree(currentNavNode.NavigationNodeCode);
            cmsService.refreshBreadCrumb();
        };

        vm.goMobileAppConfig = function () {
            navigationService.navigateTo("app.nav.mobileappconfig", {});
        }

        vm.goMyNotifications = function () {
            navigationService.navigateTo("app.nav.mynotificationslist", {});
        };

        vm.onBcNavigation = function (currentNavNode) {
            if (currentNavNode.NavigationNodeKindId === 2) {
                navigationService.navigateTo(currentNavNode.NavigationState, castParam(currentNavNode.NavigationParam));
            }
        };

        vm.moduleNavigation = function (cmsViewMode, navigationParam) {
            cmsService.setBreadcrumbTree(cmsViewMode);
            vm.breadCrumbNodes = cmsService.currentNavNodes.breadCrumb;
            navigationService.navigateTo('app.nav.modulehome-' + cmsViewMode, castParam(navigationParam));
        };

        vm.gridSelection = function (args) {
            virtualCompanySelected = args.data;
        };

        vm.selectVirtualContext = function () {
            currentSessionService.getAvailableVirtualCompanies()
                .then(function (data) {
                    openModalSelectionVirtualContext(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        };

        vm.changeVirtualContext = function (targetVirtualCompany) {
            if (!targetVirtualCompany) {
                return;
            }
            authenticationService
                .changeVirtualContext(targetVirtualCompany.VirtualCompanyId)
                .then(function (response) {
                    authenticationService.redirectToRootLocation();
                })
                .catch(function (err, status, headers, config) {
                    err.isMessageVisible = true;
                    err.message = err.error;
                    vm.setErrorMsg(err);
                });
        };

        vm.bookmarkThisPage = function () {
            var path = navigationService.currentPath(); //e.g. /app/dashboard/dashboardHome
            var pageTitle = Nembus.Common.Library.cms.viewTitle(navigationService.currentCmsViewCode()); //e.g. Dashboard Home
            addBookmarkModal(path, pageTitle);
        };

        vm.navToHome = function () {
            navigationService.navToHome();
        };

        function openModalSelectionVirtualContext(virtualCompanies) {
            vm.modal = {
                instanceName: 'modalInstance',
                title: vm.translate('VirtualContextSelectionTitle'),
                datasource: virtualCompanies
            };

            var nbsGridDatacontext = new NbsGrid(vm.translate);
            nbsGridDatacontext.configureGrid('virtualcompany', 'changeVc');
            nbsGridDatacontext.setSelectedIds([vm.virtualContext.CurrentVirtualCompanyId]);
            nbsGridDatacontext.setModalMode();
            nbsGridDatacontext.allowSearching = true;
            nbsGridDatacontext.setPageSize(15);
            nbsGridDatacontext.setDatasource(virtualCompanies, false);
            vm.nbsGridDatacontext = nbsGridDatacontext;

            vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'virtualCompaniesTemplate', scope: $scope }));
            vm.modalInstance.result.then(function () {
                vm.changeVirtualContext(virtualCompanySelected);
            }, function () { });
            vm.modalInstance.ok = function () {
                if (!virtualCompanySelected) {
                    navigationService.selectOneItemMessageModal();
                    return;
                }

                if (virtualCompanySelected.VirtualCompanyId !== vm.virtualContext.CurrentVirtualCompanyId) {
                    // modal is closed only if virtualcompany selected is different from current
                    vm.modalInstance.close();
                }
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
        }

        function castParam(strParam) {
            if (strParam) {
                return eval("(" + strParam + ")");
            }
        }

        function getMyNotifications(forceReload) {
            var filter = {
                NotificationStatus: notificationStatusEnumObj.NotificationNew,
                NotificationChannelKind: notificationChannelKindEnumObj.ChannelWebApp
            };
            var defer = $q.defer();

            notificationService.getMyNotifications(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getNavigationModulesFirstLevel() {
            var defer = $q.defer();

            cmsService.getNavigationModulesFirstLevel()
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function addBookmarkModal(bookmarkPath, bookmarkName) {
            navigationService
                .navToModal(vm.suspendDirty, 'app.nav.bookmarkdetailmodal', { BookmarkName: bookmarkName })
                .then(function (modalResult) {
                    if (modalResult.dialogResult === navigationService.navigationActionTypes.modalOk) {
                        //bookmark
                        //have to add server side VirtualHumanResourceId => UserPersonId
                        //set 1 for validation requirements
                        // set server side UserPersonId

                        //var bookmarkDisplayName = modalResult.data.CmsBookmarkName;

                        var cmsBookmark = cmstoolService.dataContext.cmsBookmarks.add({
                            BookmarkName: bookmarkName,
                            BookmarkDisplayName: modalResult.data,
                            BookmarkPath: bookmarkPath,
                            VirtualHumanResourceId: 1, // setted server side
                            VirtualCompanyId: vm.virtualContext.CurrentVirtualCompanyId
                        });

                        cmstoolService.saveChanges()
                            .then(function (saveResult) {
                                navigationService.customMessageModal('SubmittedSuccesfully');
                            })
                            .catch(function (error) {
                                cmsBookmark.entityAspect.setUnchanged();
                                navigationService.customMessageModal('SubmittedError');
                            });
                    }
                });
        }
    }

    appPanel.$inject = injectParams;
    angular.module('nbs.shell.module').controller('appPanel', appPanel);
});