﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breezeDataLayer'];

    var triggerService = function ($http, $q, $timeout, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('trigger', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            TriggerInstance: 'TriggerInstance',
            TriggerFired: 'TriggerFired',
            TriggerInstanceMatch: 'TriggerInstanceMatch',
            TriggerDefinition: 'TriggerDefinition',
            TriggerDefinitionProperty: 'TriggerDefinitionProperty',
            TriggerInstanceClientData: 'TriggerInstanceClientData'
        };

        nbsService.call(this, manager, $q, $timeout);

        //breeze client type
        //client validation (used to clone triggerinstance entity)
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.TriggerInstanceClientData,
                dataProperties: {
                    TriggerInstanceKey: { type: breeze.DataType.Guid, isPartOfKey: true },
                    TriggerInstanceId: { type: breeze.DataType.Int32 },
                    TriggerInstanceName: { type: breeze.DataType.String }
                }
            }, 'Nembus.Domain'
        );

        var triggerInstanceClientData = manager.metadataStore.getEntityType('TriggerInstanceClientData');
        triggerInstanceClientData.getProperty("TriggerInstanceName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));


        //#region Add client validation to breeze entity
        // add client validation to TriggerInstance entity
        var triggerInstance = manager.metadataStore.getEntityType('TriggerInstance');
        triggerInstance.getProperty("CronExpression").validators.push(
            // is required if IsCronExpressionEnabled
            new breeze.Validator("cronExpressionValidator", function (value, context) {
                return context.entity.IsCronExpressionEnabled && !context.entity.CronExpression ? false : true;
            })
        );
        triggerInstance.getProperty("StartDate").validators.push(
            // is required if IsCronExpressionEnabled
            new breeze.Validator("startDateValidator", function (value, context) {
                return context.entity.IsCronExpressionEnabled && !context.entity.StartDate ? false : true;
            })
        );
        //#endregion

        this.dataContext = {
            triggerInstanceClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerInstanceClientData),
            triggerFireds: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerFired),
            triggerInstances: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerInstance),
            triggerDefinitions: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerDefinition),
            triggerDefinitionProperties: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerDefinitionProperty),
            triggerInstanceMatches: dataLayer.setContextMethods(manager, nbsService, entityTypes.TriggerInstanceMatch),

            enumTypes: {
                BatchRequestStatus: dataLayer.GetEnumType('BatchRequestStatus'),
                BatchRequestType: dataLayer.GetEnumType('BatchRequestType'),
                TriggerMatchType: dataLayer.GetEnumType('TriggerMatchType'),
                TriggerPropertyType: dataLayer.GetEnumType('TriggerPropertyType'),
                TriggerFiredStatus: dataLayer.GetEnumType('TriggerFiredStatus'),
                TriggerFiredResult: dataLayer.GetEnumType('TriggerFiredResult'),
                TriggerMode: dataLayer.GetEnumType('TriggerMode')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // TriggerDefinition
        this.getTriggerDefinition = function (filter, forceReload) {
            var params = { key: filter.TriggerDefinitionId, dataContext: this.dataContext.triggerDefinitions };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "TriggerDefinitions");
        };
        this.getTriggerDefinitions = function (filter, forceReload) {
            var params = { entityType: entityTypes.TriggerDefinition, cache: this.dataContext.triggerDefinitions.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerDefinitions");
        };

        // TriggerDefinitionProperty
        this.getTriggerDefinitionProperty = function (filter, forceReload) {
            var params = { key: filter.TriggerDefinitionPropertyId, dataContext: this.dataContext.triggerDefinitionProperties };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "TriggerDefinitionProperties");
        };
        this.getTriggerDefinitionProperties = function (filter, forceReload) {
            var params = { entityType: entityTypes.TriggerDefinitionProperty, cache: this.dataContext.triggerDefinitionProperties.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerDefinitionProperties");
        };

        // TriggerInstance
        this.getTriggerInstance = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { key: filter.TriggerInstanceId, dataContext: this.dataContext.triggerInstances };

            dataLayer.GetDataDetailPromise(params, filter, forceReload, "TriggerInstances")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToTriggerInstancesList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };
        this.getTriggerInstances = function (filter, forceReload) {
            var defer = $q.defer();
            var params = { entityType: entityTypes.TriggerInstance, cache: this.dataContext.triggerInstances.get() };

            dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerInstances")
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToTriggerInstancesList(data.results);
                    defer.resolve(data);
                });

            return defer.promise;
        };

        // TriggerFired
        this.getTriggerFired = function (filter, forceReload) {
            var params = { key: filter.TriggerFiredId, dataContext: this.dataContext.triggerFireds };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "TriggerFireds");
        };
        this.getTriggerFireds = function (filter, forceReload) {
            var params = { entityType: entityTypes.TriggerFired, cache: this.dataContext.triggerFireds.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerFireds");
        };

        // TriggerInstanceMatch
        this.getTriggerInstanceMatches = function (filter, forceReload) {
            var params = { entityType: entityTypes.TriggerInstanceMatch, cache: this.dataContext.triggerInstanceMatches.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "TriggerInstanceMatches");
        };

        //Http Post
        this.cloneTriggerInstance = function (triggerInstanceClientData) {
            var query = dataLayer.InvokeQuery("CloneTriggerInstance", triggerInstanceClientData);
            return manager.executeQuery(query);
        };

        //cross controller (through wizard steps of trigger instance)
        this.triggerInstanceWzClientData = {};                   // used to validate in insert TriggerInstance wizard
        this.triggerInstanceWzCollector = {};                    // used to collect data for final mapping in insert TriggerInstance wizard
    };

    triggerService.$inject = injectParams;
    triggerService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('triggerService', triggerService);
});