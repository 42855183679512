﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    var fileBoxesList = function ($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return fileBoxSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var fileBoxSelected = null;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var viewKind = navigationService.stateParams().viewKind;
        var fileBoxTag = navigationService.stateParams().fileBoxTag;
        var fileBoxType = navigationService.stateParams().fileBoxType;
        var fileBoxTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.FileBoxType, null, vm.translate).obj.normal.name_value
        var viewMode = navigationService.currentViewMode();
        var target = navigationService.stateParams().target;

        if (target === 'view') {
            ribbon.setRibbonStatusButton(ribbon.Buttons.New, false);
            ribbon.refreshStatusButtons();
        }

        // Grid datasource
        vm.fileBoxes = [];

        loadData(true);
        function loadData(forceReload) {
            var funcsPromises = [];
            funcsPromises.push(getFileBoxes(forceReload, appStoreBw, fileBoxTag, fileBoxType));

            $q.all(funcsPromises)
                .then(function (results) {
                    vm.fileBoxes = results[0];

                    var nbsGridDatacontext = new NbsGrid(vm.translate);
                    nbsGridDatacontext.configureGrid('filebox', null);
                    nbsGridDatacontext.viewCommonBar();
                    nbsGridDatacontext.setDatasource(vm.fileBoxes, false);
                    vm.nbsGridDatacontext = nbsGridDatacontext;
                });
        }

        vm.newAction = function () {
            navigationService.navigateToFileBoxDetail({
                FileBoxId: null,
                Target: target,
                AppStoreCode: appStoreCode,
                ViewKind: viewKind,
                FileBoxTag: fileBoxTag,
                ViewMode: viewMode,
                FileBoxType: fileBoxType
            });
        };

        vm.rowDblClick = function (args) {
            navigationService.navigateToFileBoxDetail({
                FileBoxId: args.data.FileBoxId,
                Target: target,
                AppStoreCode: appStoreCode,
                ViewKind: viewKind,
                FileBoxTag: fileBoxTag,
                ViewMode: viewMode,
                FileBoxType: fileBoxType,
            });
        };

        vm.gridSelection = function (args) {
            $timeout(() => {
                fileBoxSelected = args.data;

                if (fileBoxSelected) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, fileBoxSelected.FileBoxTypeId === fileBoxTypeEnum.ImportData);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.refreshAction = function () {
            getFileBoxes(true, appStoreBw, fileBoxTag, fileBoxType)
                .then((results) => {
                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: results
                    }
                });
        };

        vm.deleteAction = function () {
            if (!fileBoxSelected) {
                return;
            }

            foundationService.deleteEntity(fileBoxSelected);
            vm.submitAction()
                .then(function (saveResult) {
                    vm.refreshAction();
                });
        };

        function getFileBoxes(forceReload, appStoreBw, fileBoxTag, fileBoxType) {
            var filter = {
                AppStoreBw: appStoreBw,
                FileBoxTag: fileBoxTag,
                FileBoxType: fileBoxType
            };
            var defer = $q.defer();

            foundationService.getFileBoxes(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    };

    fileBoxesList.$inject = injectParams;
    fileBoxesList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxesList', fileBoxesList);
});