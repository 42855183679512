﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$scope', 'contextService', 'navigationService', 'audService', 'utilService'];
    var nbsSurveyScheduleComponent = function ($q, $scope, contextService, navigationService, audService, utilService) {
        var vm = this;
        var schedule = null;

        var scheduleOrientation = null;
        var privateDataContext = null;
        var privateDataInit = null;
        var privateGroupingKeys = [];       // privateGroupingKeys ['virtualEquipment', 'assignment'] or empty array
        var privateFilter = null;
        var privateStatusColorArray = [];
        var privateStatusColorObject = [];
        var surveyLifeStatusEnumParsed = null;
        var privateScheduleId = null;
        var defaultAppointmentDuration = 30;
        var privateThesaurus = null;

        var scheduleIsReadonly = true;
        vm.surveyCreationSchedulingTypeIsDetailed = Nembus.Aud.Library.SSV.IsAuditSchedulingScenarioDetailed();

        vm.$onInit = function () {
            // used to categorize appointments
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataInit) {
                destroySchedule();

                if (changes.nbsDataInit.currentValue) {
                    privateDataInit = changes.nbsDataInit.currentValue;

                    surveyLifeStatusEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, 'SurveyDto', vm.uiContext.translate);

                    privateScheduleId = changes.nbsDataInit.currentValue.scheduleId;
                    scheduleOrientation = changes.nbsDataInit.currentValue.orientation;

                    privateThesaurus = {
                        SurveyDto: vm.uiContext.translate('SurveyDto.SurveyDto'),
                        SurveyId: vm.uiContext.translate('SurveyDto.SurveyId'),
                        SurveyCode: vm.uiContext.translate('SurveyDto.SurveyCode'),
                        SurveyName: vm.uiContext.translate('SurveyDto.SurveyName'),
                        SurveyDescription: vm.uiContext.translate('SurveyDto.SurveyDescription'),
                        VhrEventTypeName: vm.uiContext.translate('SurveyDto.VhrEventTypeId'),
                        VhrEventGroupName: vm.uiContext.translate('SurveyDto.VhrEventGroupId'),
                        TargetName: vm.uiContext.translate('SurveyDto.TargetName'),
                        SurveyExpireDate: vm.uiContext.translate('SurveyDto.SurveyExpireDate'),
                        SurveyLifeStatusNameUI: vm.uiContext.translate('SurveyDto.SurveyLifeStatusId'),
                        Assignee: vm.uiContext.translate('AudAssignee')
                    };

                    getGenericElementsVhrEventsLifeStatusColor(false)
                        .then((results) => {
                            if (results[0]) {
                                privateStatusColorArray = results[0].PairValuesArray;
                                privateStatusColorObject = results[0].PairValuesObject;
                            }

                            createSchedule();
                        });
                }
            }

            if (changes.nbsScheduleReadonly && changes.nbsScheduleReadonly.currentValue !== null && changes.nbsScheduleReadonly.currentValue !== undefined) {
                if (changes.nbsScheduleReadonly.currentValue === 'true' || changes.nbsScheduleReadonly.currentValue === true) {
                    scheduleIsReadonly = true;
                } else {
                    scheduleIsReadonly = false;
                }
            }

            if (changes.nbsDataRefresh && changes.nbsDataRefresh.currentValue) {
                privateDataContext = changes.nbsDataRefresh.currentValue;

                // set global private filter
                privateFilter = privateDataContext.dataFilter;

                privateGroupingKeys = privateDataContext.groupingKeys;

                loadScheduleData();
            }
        };

        function loadScheduleData() {
            var forceReload = true;
            var scheduleDatarange = getScheduleRange();
            if (!scheduleDatarange) {
                console.error('schedule datarange mandatory');
                return;
            }

            // refresh from/to range filter in vhreventfilter
            privateFilter.vhrEventFilter.StartDate1 = scheduleDatarange.dateFrom;
            privateFilter.vhrEventFilter.StartDate2 = scheduleDatarange.dateTo;

            var funcsPromises = [];
            if (vm.nbsDataOnlyassigned === 'false') {
                funcsPromises.push(getSurveyDtos(forceReload));
            } else {
                funcsPromises.push(getMySurveyDtos(forceReload));
            }

            $q.all(funcsPromises)
                .then(function (results) {
                    var surveys = results[0];
                    parseSurveys(surveys);
                    refreshSchedule(surveys);
                });
        }

        function getScheduleRange() {
            if (!schedule.model.currentView || !schedule.model.currentDate) {
                return null;
            }

            var range = {
                dateFrom: null,
                dateTo: null
            };

            if (schedule.model.currentView === ej.Schedule.CurrentView.Day) {
                range.dateFrom = schedule.model.currentDate;
                range.dateTo = schedule.model.currentDate;
                return range;
            } else if (schedule.model.currentView === ej.Schedule.CurrentView.Month) {
                return new Nembus.Common.Library.schedule.getFilterMonthRange(schedule.model.currentDate);
            } else if (schedule.model.currentView === ej.Schedule.CurrentView.Agenda) {
                var agendaSpanDays = schedule.model.agendaViewSettings.daysInAgenda;
                range.dateFrom = schedule.model.currentDate;
                range.dateTo = moment(schedule.model.currentDate).add(agendaSpanDays, 'days').toDate();
                return range;
            } else if (schedule.model.currentView === ej.Schedule.CurrentView.Week) {
                range.dateFrom = moment(schedule.model.currentDate).startOf('isoweek').toDate();
                range.dateTo = moment(schedule.model.currentDate).endOf('isoweek').toDate();
                return range;
            } else {
                return null;
            }
        }

        function refreshSchedule(surveys) {
            var appointments = getScheduleAppointments(surveys, schedule.model.currentView, privateGroupingKeys, 7);

            setScheduleResourceModel(appointments);

            schedule.model.appointmentSettings.dataSource = appointments;
            schedule.refresh();
        }

        function getScheduleAppointments(surveys, currentView, groupingKeys, itemsDayMonth) {
            var tmpAppointments = Lazy(surveys)
                .sortBy((survey) => { return survey.SurveyId })
                .map((survey) => { return SurveyAppointment(survey); })
                .toArray();

            if (Lazy(groupingKeys).contains('assignment')) {
                tmpAppointments = getSurveyAppointmentSplittedByAssignments(tmpAppointments);
            }

            var appointments = [];
            var groupedAppointments = [];
            if (Lazy(groupingKeys).some()) {
                appointments = tmpAppointments;
                return appointments;
            }

            if (currentView === ej.Schedule.CurrentView.Month) {
                groupedAppointments = Lazy(tmpAppointments).groupBy((appointment) => { return moment(appointment.StartTime).format('YYYY-MM-DD') }).toArray();
                Lazy(groupedAppointments)
                    .each((group) => {
                        var groupAppointments = Lazy(group[1]).toArray();
                        var maxAppointments = Lazy(groupAppointments).first(itemsDayMonth).toArray();

                        appointments = Lazy(appointments)
                            .union(Lazy(maxAppointments))
                            .toArray();

                        if (Lazy(groupAppointments).length() > itemsDayMonth) {
                            var remainingItems = Lazy(groupAppointments).length() - itemsDayMonth;
                            var currDate = Lazy(groupAppointments).last().StartTime;
                            appointments.push(FakeAppointment(currDate, remainingItems));
                        }
                    });
            } else {
                appointments = tmpAppointments;
            }

            return appointments;
        }

        function setScheduleResourceModel(appointments) {
            if (Lazy(privateGroupingKeys).some()) {
                schedule.model.group = { resources: [] };
                schedule.model.resources = [];
                schedule.model.appointmentSettings.resourceFields = null;
                schedule.model.group.resources = privateGroupingKeys;

                Lazy(privateGroupingKeys)
                    .each((groupKey) => {
                        var resourceDatasource = [];
                        var currentGroupIdField = null;
                        var resource = null;
                        var resourceItem = null;

                        if (schedule.model.resources.length > 0) {
                            var lastResource = Lazy(schedule.model.resources).last();
                            currentGroupIdField = lastResource.field;
                        }

                        if (groupKey === 'virtualEquipment') {
                            resource = new NbsEjSchedulerResource();
                            resource.field = 'TargetId';
                            resource.title = 'TargetId';
                            resource.name = groupKey;

                            Lazy(appointments)
                                .each((appointment) => {
                                    var groupId = currentGroupIdField ? appointment[currentGroupIdField] : 1;

                                    if (!Lazy(resourceDatasource).where({ id: appointment.survey.TargetId, groupId: groupId }).some()) {
                                        resourceItem = new NbsEjSchedulerResourceItem();
                                        resourceItem.id = appointment.survey.TargetId;
                                        resourceItem.text = appointment.survey.TargetName;
                                        resourceItem.groupId = currentGroupIdField ? appointment[currentGroupIdField] : 1;
                                        resourceItem.color = null;
                                        resourceDatasource.push(resourceItem);
                                    }
                                });

                            resource.resourceSettings.dataSource = resourceDatasource;
                            schedule.model.resources.push(resource);

                            if (!schedule.model.appointmentSettings.resourceFields) {
                                schedule.model.appointmentSettings.resourceFields = 'TargetId';
                            } else {
                                schedule.model.appointmentSettings.resourceFields += ',TargetId';
                            }
                        }

                        if (groupKey === 'assignment') {
                            resource = new NbsEjSchedulerResource();
                            resource.field = 'AssignmentId';
                            resource.title = 'AssignmentId';
                            resource.name = groupKey;

                            Lazy(appointments)
                                .each((appointment) => {
                                    var groupId = currentGroupIdField ? appointment[currentGroupIdField] : 1;

                                    if (!Lazy(resourceDatasource).where({ id: appointment.AssignmentId, groupId: groupId }).some()) {
                                        resourceItem = new NbsEjSchedulerResourceItem();
                                        resourceItem.id = appointment.AssignmentId;
                                        resourceItem.text = appointment.AssignmentName;
                                        resourceItem.groupId = groupId;
                                        resourceItem.color = null;

                                        resourceDatasource.push(resourceItem);
                                    }
                                });

                            resource.resourceSettings.dataSource = resourceDatasource;
                            schedule.model.resources.push(resource);

                            if (!schedule.model.appointmentSettings.resourceFields) {
                                schedule.model.appointmentSettings.resourceFields = 'AssignmentId';
                            } else {
                                schedule.model.appointmentSettings.resourceFields += ',AssignmentId';
                            }
                        }
                    });
            }
        }

        function getSurveyAppointmentSplittedByAssignments(appointments) {
            var results = [];

            Lazy(appointments)
                .each((appointment) => {
                    if (appointment.survey) {
                        if (Lazy(appointment.survey.SurveyUsersUI).some()) {
                            Lazy(appointment.survey.SurveyUsersUI)
                                .each((user) => {
                                    var userAppointment = new NbsEjSchedulerAppointment(NbsGuidManager);
                                    userAppointment.Subject = appointment.Subject;
                                    userAppointment.StartTime = appointment.StartTime;
                                    userAppointment.EndTime = appointment.EndTime;
                                    userAppointment.Description = appointment.Description;
                                    userAppointment.survey = appointment.survey;
                                    userAppointment.AssignmentId = 'User_' + user.Value.toString();
                                    userAppointment.AssignmentName = user.Name;
                                    results.push(userAppointment)
                                });
                        }
                        if (Lazy(appointment.survey.SurveyUserGroupsUI).some()) {
                            Lazy(appointment.survey.SurveyUserGroupsUI)
                                .each((userGroup) => {
                                    var userAppointment = new NbsEjSchedulerAppointment(NbsGuidManager);
                                    userAppointment.Subject = appointment.Subject;
                                    userAppointment.StartTime = appointment.StartTime;
                                    userAppointment.EndTime = appointment.EndTime;
                                    userAppointment.Description = appointment.Description;
                                    userAppointment.survey = appointment.survey;
                                    userAppointment.AssignmentId = 'UserGroup_' + userGroup.Value.toString();
                                    userAppointment.AssignmentName = userGroup.Name;
                                    results.push(userAppointment)
                                });
                        }
                    }
                });

            return results;
        }

        function parseSurveys(surveys) {
            Lazy(surveys)
                .each((survey) => {
                    survey.SurveyExpireDateUI = Nembus.Aud.Library.UI.GetSurveyExpireDateParsedString(survey, contextService.virtualContext, vm.uiContext.translate);
                    survey.SurveyExpireDateEndUI = Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyExpireDateEnd, contextService.virtualContext.cultureInfo, true);
                    survey.SurveyUsersUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UsersAssignedSerialized));
                    survey.SurveyUserGroupsUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(survey.UserGroupsAssignedSerialized));
                    survey.SurveyLifeStatusNameUI = surveyLifeStatusEnumParsed.obj.translated.value_name[survey.SurveyLifeStatusId];
                    survey.SurveyLifeStatusColorUI = privateStatusColorObject[survey.SurveyLifeStatusName];
                    survey.ThesaurusUI = privateThesaurus

                    survey.AssignmentsUI = null;
                    if (Lazy(survey.SurveyUsersUI).some()) {
                        survey.AssignmentsUI = Lazy(survey.SurveyUsersUI).pluck('Name');
                    }
                    if (Lazy(survey.SurveyUserGroupsUI).some()) {
                        if (survey.AssignmentsUI) {
                            survey.AssignmentsUI += ',' + Lazy(survey.SurveyUserGroupsUI).pluck('Name');
                        } else {
                            survey.AssignmentsUI = Lazy(survey.SurveyUserGroupsUI).pluck('Name');
                        }
                    }
                });
        }

        function SurveyAppointment(survey) {
            var appointment = new NbsEjSchedulerAppointment(NbsGuidManager);

            // syncfusion fields
            appointment.Subject = survey.SurveyId.toString() + ': ' + survey.SurveyName;
            appointment.StartTime = Nembus.Common.Library.converter.date.toIgnoreTimeZone(survey.SurveyExpireDate);
            if (vm.surveyCreationSchedulingTypeIsDetailed) {
                appointment.EndTime = Nembus.Common.Library.converter.date.toIgnoreTimeZone(survey.SurveyExpireDateEnd);
            } else {
                appointment.EndTime = new Date(appointment.StartTime.getTime() + (defaultAppointmentDuration * 60000));
            }
            if (appointment.StartTime - appointment.EndTime === 0) {
                appointment.EndTime = new Date(appointment.StartTime.getTime() + (defaultAppointmentDuration * 60000));
            }
            appointment.Description = survey.SurveyDescription;

            // support properties 
            appointment.AssignmentId = null;
            appointment.AssignmentName = null;
            appointment.TargetId = survey.TargetId;
            appointment.survey = survey;

            return appointment;
        };

        function FakeAppointment(currentDate, remainingItems) {
            var appointment = new NbsEjSchedulerAppointment(NbsGuidManager);
            appointment.IsFakeAppointment = true;
            appointment.StartTime = currentDate;
            appointment.EndTime = currentDate;
            appointment.AllDay = true;
            appointment.RemainingItems = remainingItems;
            return appointment;
        };

        //#region Schedule create/destroy ejSchedule
        function destroySchedule() {
            if (schedule) {
                schedule.destroy();
                schedule = null;
            }

            $('#nbsScheduleCalendarContainer div').remove();
        }

        function createSchedule() {
            $('#nbsScheduleCalendarContainer').append('<div id="' + privateScheduleId + '"></div>');

            var views = [];
            if (scheduleOrientation === ej.Schedule.Orientation.Vertical) {
                //views = [ej.Schedule.CurrentView.Day, ej.Schedule.CurrentView.Week, ej.Schedule.CurrentView.Month, ej.Schedule.CurrentView.Agenda];
                views = [ej.Schedule.CurrentView.Day, ej.Schedule.CurrentView.Week, ej.Schedule.CurrentView.Month];
            }

            if (scheduleOrientation === ej.Schedule.Orientation.Horizontal) {
                views = [ej.Schedule.CurrentView.Day, ej.Schedule.CurrentView.Week, ej.Schedule.CurrentView.Month];
            }

            $("#" + privateScheduleId).ejSchedule({
                width: "100%",
                currentView: ej.Schedule.CurrentView.Month,
                //height: "550px",
                timezone: "UTC +00: 00",
                showCurrentTimeIndicator: false,
                showOverflowButton: false,
                showAppointmentNavigator: false,
                showQuickWindow: false,
                allowDelete: false,
                allowDragAndDrop: true,
                allowInline: false,
                allowKeyboardNavigation: false,
                allowMultiple: false,
                enableAppointmentResize: false,
                appointmentTemplateId: "#appointmentSurveyTemplate",
                contextMenuSettings: {
                    enable: false
                },
                timeScale: {
                    enable: vm.surveyCreationSchedulingTypeIsDetailed
                },
                readOnly: false,
                currentDate: new Date(),
                tooltipSettings: {
                    templateId: '#appointmentSurveyTooltipTemplate',
                    enable: true
                },
                views: views,       //views: [ej.Schedule.CurrentView.Month],
                orientation: scheduleOrientation,
                create: onScheduleCreate,
                actionBegin: onScheduleActionBegin,
                actionComplete: onScheduleActionComplete,
                cellClick: onScheduleCellClick,
                cellDoubleClick: onScheduleCellDoubleClick,
                resize: onScheduleResize,
                resizeStart: onScheduleResizeStart,
                resizeStop: onScheduleResizeStop,
                drag: onScheduleDrag,
                dragStart: onScheduleDragStart,
                dragStop: onScheduleDragStop,
                appointmentClick: onScheduleAppointmentOnClick,
                queryCellInfo: onQueryCellInfo,
                navigation: function (args) {
                    loadScheduleData();
                },
                group: {
                    resources: [],
                    allowGroupEditing: false
                },
                resources: [],
                categorizeSettings: {
                    enable: false,
                    dataSource: []
                },
                appointmentSettings: {
                    dataSource: [],
                    id: "Id",
                    subject: "Subject",
                    startTime: "StartTime",
                    endTime: "EndTime",
                    description: "Description",
                    allDay: "AllDay",
                    recurrence: "Recurrence",
                    recurrenceRule: "RecurrenceRule",
                    resourceFields: null,
                    categorize: "EventCategorize"
                }
            });

            schedule = $("#" + privateScheduleId).data('ejSchedule');
        }
        //#endregion

        //#region Schedule event methods
        //var currentScheduleDates = [];
        function onScheduleCreate(args) {
            vm.createScheduleEvent({ result: privateScheduleId });
        }

        function onScheduleActionBegin(args) {
            //
        }

        function onScheduleActionComplete(args) {
            //
        }

        function onQueryCellInfo(args) {
            //
        }

        function onScheduleAppointmentOnClick(args) {
            args.cancel = true;

            if (args.appointment.IsFakeAppointment) {
                schedule.model.currentView = ej.Schedule.CurrentView.Day;
                schedule.model.currentDate = new Date(args.appointment.StartTime.getFullYear(), args.appointment.StartTime.getMonth(), args.appointment.StartTime.getDate());
                loadScheduleData();
                return;
            }

            var survey = args.appointment.survey;
            if (!survey) {
                return;
            }

            vm.modalData = survey;

            vm.canCompile = Nembus.Aud.Library.auth.checkAuthCompile(
                contextService.virtualContext
                , vm.modalData.ModuleTargetId
                , vm.modalData.VhrEventTypeId
                , vm.modalData.VhrEventGroupId)
                && (Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, false)).contains(vm.modalData.SurveyLifeStatusId));

            vm.modalInstance = navigationService.modal().open({
                windowClass: 'nbs-modal-form-md',
                templateUrl: 'appointmentModalTemplate',
                size: 'lg',
                scope: $scope
            });

            vm.modalInstance.result.then(function (arg) {
                if (arg === 'detail') {
                    navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': vm.modalData.SurveyId, 'vhrEventId': vm.modalData.VhrEventId });
                }
                if (arg === 'compile') {
                    navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': vm.modalData.SurveyId, 'appStoreCode': 'aud' });
                }
            }, function () {
                //dismiss
            });

            vm.modalInstance.compileOnClick = function () { vm.modalInstance.close('compile'); };
            vm.modalInstance.detailOnClick = function () { vm.modalInstance.close('detail'); };
            vm.modalInstance.cancelOnClick = function () { vm.modalInstance.dismiss(); };
        }

        function onScheduleResize(args) {
            //
        }

        function onScheduleResizeStart(args) {
            //
        }

        function onScheduleResizeStop(args) {
            //
        }

        function onScheduleCellClick(args) {
            args.cancel = true;
        }

        function onScheduleCellDoubleClick(args) {
            args.cancel = true;
        }

        function onScheduleDrag(args) {
            args.cancel = true;
        }

        var fromTargetId = null;
        var fromAssegneeId = null;
        function onScheduleDragStart(args) {
            if (scheduleIsReadonly) {
                args.cancel = true;
                return;
            }

            var survey = args.appointment.survey;
            if (!survey) {
                args.cancel = true;
                return;
            }

            if (survey.SurveyLifeStatusId !== surveyLifeStatusEnumParsed.obj.normal.name_value.Created) {
                navigationService.operationNotPermittedModal();
                args.cancel = true;
                return;
            }

            if (Lazy(privateGroupingKeys).contains('assignment')) {
                navigationService.operationNotPermittedModal();
                args.cancel = true;
                return;
            }

            // save targetid on start drag
            if (args.appointment.TargetId) {
                fromTargetId = Number(args.appointment.TargetId);
            } else {
                fromTargetId = null;
            }

            // save targetid on start drag
            if (args.appointment.AssignmentId) {
                fromAssegneeId = Number(args.appointment.AssignmentId);
            } else {
                fromAssegneeId = null;
            }
        }

        function onScheduleDragStop(args) {
            var toTargetId;
            if (args.appointment.TargetId) {
                toTargetId = Number(args.appointment.TargetId);
            } else {
                toTargetId = null;
            }

            var toAssegneeId;
            if (args.appointment.AssignmentId) {
                toAssegneeId = Number(args.appointment.AssignmentId);
            } else {
                toAssegneeId = null;
            }

            // if target is changed then operation not permitted
            if (fromTargetId !== toTargetId) {
                navigationService.operationNotPermittedModal();
                args.cancel = true;
                return;
            }

            // if assignee is changed then operation not permitted
            if (fromAssegneeId !== toAssegneeId) {
                navigationService.operationNotPermittedModal();
                args.cancel = true;
                return;
            }

            var survey = args.appointment.survey;
            if (!survey) {
                args.cancel = true;
                return;
            }

            var surveyExpireDateTemp = new Date(survey.SurveyExpireDate);
            var resDate = Nembus.Common.Library.converter.date.localAsUtc(args.appointment.StartTime);

            if (resDate.getTime() === surveyExpireDateTemp.getTime()) {
                args.cancel = true;
                return;
            }

            survey.SurveyExpireDate = Nembus.Common.Library.converter.date.localAsUtc(args.appointment.StartTime);
        }
        //#endregion

        //#region Methods to get data
        function getGenericElementsVhrEventsLifeStatusColor(forceReload) {
            var filter = { GenericElementCode: 'Configurations.Surveys.LifeStatusColors' };
            var defer = $q.defer();
            utilService.getGenericElements(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
            return defer.promise;
        }

        function getSurveyDtos(forceReload) {
            var defer = $q.defer();
            audService.getSurveyDtos(privateFilter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });
            return defer.promise;
        }

        function getMySurveyDtos(forceReload) {
            var defer = $q.defer();
            audService.getMySurveyDtos(privateFilter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    defer.reject(error);
                });
            return defer.promise;
        }
        //#endregion




        class NbsEjSchedulerAppointment {
            constructor(nbsGuidManager) {
                this.Id = nbsGuidManager.newGuid();
                this.Subject = null;
                this.StartTime = null;
                this.Description = null;
                this.EndTime = null;
                this.AllDay = false;
                this.Recurrence = false;
                this.RecurrenceRule = '';
                this.Categorize = null;
                this.IsFakeAppointment = false;
            }
        }

        class NbsEjSchedulerResource {
            constructor() {
                this.field = null;
                this.title = null;
                this.name = null;
                this.allowMultiple = true;
                this.resourceSettings = {
                    dataSource: [],
                    text: "text", id: "id", groupId: "groupId", color: "color"
                };
            }
        }

        class NbsEjSchedulerResourceItem {
            constructor() {
                this.text = '';
                this.id = 0;
                this.groupId = 1;
                this.color = null;
            }
        }
    };

    nbsSurveyScheduleComponent.$inject = injectParams;
    angular.module('nbs.aud.module').component('nbsSurveySchedule', {
        templateUrl: '/aud/surveys/components/nbsSurveyScheduleComponent/_?c=' + window.codeCache(),
        controller: nbsSurveyScheduleComponent,
        controllerAs: 'vm',
        bindings: {
            createScheduleEvent: "&",
            changeDateFilterEvent: "&",
            nbsDataInit: "<",
            nbsDataRefresh: "<",
            nbsScheduleReadonly: "<",
            nbsDataOnlyassigned: '@',
            uiContext: "<"
        }
    });
});

