﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'vhreventService', 'audService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService', 'formSummaryService', 'cmsService'];
    function surveysVhrList($q, $injector, $scope, $timeout, vhreventService, audService, utilService, navigationService, checkStateChangeService, contextService, formSummaryService, cmsService) {
        var vm = this;
        nbsVm.call(vm, vhreventService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return currentSelected; });
        checkStateChangeService.checkFormOnStateChange($scope, vhreventService);

        var ribbon = new NbsAudRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.addPrintReportTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetAuditTargetNewSection());
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_manage, ribbon.GetAuditManageSection());
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetSurveysLifeStatusSection(), ribbon.GetVhrEventWorkflowsSection()]));
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var vhrEventFixedTypeCode = navigationService.stateParams().vhrEventFixedTypeCode;
        var vhrEventKindEnum = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventKind, null, vm.translate).obj.normal.name_value;
        var vhrEventKindId = vhrEventKindEnum.Data;
        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        var vhrEventLifeStatusKey = navigationService.stateParams().vhrEventLifeStatusKey;
        var currentSelected = null;
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var surveyCreationPatternObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        var surveyLifeStatusEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyLifeStatus, null, vm.translate).obj.normal.name_value;
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var viewMode = navigationService.currentViewMode();
        var canChangeSurveyWorkflowStatus = Nembus.Aud.Library.SSV.CanManageSurveyWorkflow()
            && vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDIT_WORKFLOW_CHANGESTATUS'], contextService.virtualContext);

        var canManageExctendedStatuses = vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDIT_MANAGE_LIFESTATUS_EXTENDED'], contextService.virtualContext);
        var vhrEventShuffleActionObj = Nembus.Common.Library.util.parseEnum(vhreventService.dataContext.enumTypes.VhrEventShuffleAction, null, vm.translate).obj.normal.name_value;

        // set grid component datacontext 
        var gridUIkey = 'vhr';
        if (vhrEventLifeStatusKey === 'compiled') {
            gridUIkey = 'vhrcompiled';
        }

        var nbsGridDatacontext = new NbsAudGrid(vm.translate);
        nbsGridDatacontext.configureGrid('survey', gridUIkey, null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.compileAction = function () {
            if (!currentSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            if (!canCompile && !canView) {
                navigationService.operationNotPermittedModal();
                return;
            }

            if (currentSelected.SurveyCreationPatternId === surveyCreationPatternObj.OnDemand) {
                // before create a clone of selected survey and then compile it
                var filter = { SurveyId: currentSelected.SurveyId };
                audService.getSurveyOnDemandClone(filter, true)
                    .then(function (data) {
                        var surveyCloned = Lazy(data.results).first();
                        navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': surveyCloned.SurveyId, 'appStoreCode': appStoreCode });
                    });
            } else {
                navigationService.navigateToNewWindow("app.navlight.surveycompile", { 'surveyId': currentSelected.SurveyId, 'appStoreCode': appStoreCode });
            }
        };

        vm.modifyAction = function () {
            if (!canUpdate) {
                navigationService.operationNotPermittedModal();
                return;
            }

            if (!currentSelected) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': currentSelected.SurveyId, 'vhrEventId': currentSelected.VhrEventId });
        };

        vm.rowDblClick = function (args) {
            switch (vhrEventLifeStatusKey) {
                case 'ondemand':
                case 'tocompile':
                    vm.compileAction();
                    break;
                case 'compiled':
                    vm.viewAction();
                    break;
                case 'archived':
                    navigationService.navigateTo("app.nav.surveydetail", { 'surveyId': args.data.SurveyId, 'vhrEventId': args.data.VhrEventId });
                    break;
            }
        };

        var canCompile = false;
        var canUpdate = false;
        var canView = false;
        vm.gridSelection = function (args) {
            $timeout(() => {
                currentSelected = args.data;

                canCompile = false;
                canUpdate = false;
                canView = false;
                if (currentSelected) {
                    canCompile = Nembus.Aud.Library.CanCompileSurvey(contextService, audService, currentSelected);
                    //canUpdate = vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDIT_UPDATE_ALL'], contextService.virtualContext);
                    canUpdate = false;
                    canView = Nembus.Aud.Library.CanViewSurvey(currentSelected, audService);

                    ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, canCompile);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.View, canView);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,canUpdate);
                    ribbon.setActionWorkflowButtons([currentSelected], canChangeSurveyWorkflowStatus);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Compile, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.View, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify,false)
                    ribbon.setActionWorkflowButtons([], canChangeSurveyWorkflowStatus);
                }

                if (currentSelected && vhrEventLifeStatusKey === 'compiled') {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusApprove, canManageExctendedStatuses);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, canManageExctendedStatuses);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, canManageExctendedStatuses);
                } else {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusApprove, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusModify, false);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.VhrEventLifeStatusInfo, false);
                }

                ribbon.refreshStatusButtons();
            });
        };

        vm.viewAction = function () {
            if (!canView) {
                navigationService.operationNotPermittedModal();
                return;
            }

            vm.compileAction();
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        vm.vhrEventLifeStatusApproveAction = function () {
            var vhrEventIds = [currentSelected.SurveyId];
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        var vhrEventsSerialized = JSON.stringify(vhrEventIds);
                        updateSurveyLifeStatusBulk(vhrEventsSerialized, moduleTargetId, surveyLifeStatusEnumObj.Approved, null);
                    }
                })
        };

        vm.vhrEventLifeStatusUpdateAction = function () {
            var vhrEventIds = [currentSelected.SurveyId];
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var parentParams = { viewKind: 'modify', modalKey: 'modify', vhrEventsId: vhrEventIds, moduleTargetId: moduleTargetId };
            navigationService.navToModal(vm.suspendDirty, 'app.nav.surveychangelifestatusdetail-modify', parentParams)
                .then(function (result) {
                    if (result.data) {
                        updateSurveyLifeStatusBulk(result.data.VhrEventsSerialized, result.data.ModuleTargetId, result.data.SurveyLifeStatusId, result.data.SurveyLifeStatusChangeNote);
                    }
                });
        };

        vm.vhrEventLifeStatusInfoAction = function () {
            var vhrEventIds = [currentSelected.SurveyId];
            if (!Lazy(vhrEventIds).some()) {
                navigationService.selectOneItemMessageModal();
                return;
            }

            var parentParams = { viewKind: 'info', modalKey: 'info', vhrEventsId: vhrEventIds, moduleTargetId: moduleTargetId };
            navigationService.navToModal(vm.suspendDirty, 'app.nav.surveychangelifestatusdetail-info', parentParams)
        };

        //Http Post
        function updateSurveyLifeStatusBulk(vhrEventsIdSerialized, moduleTargetId, surveyLifeStatus, changeNote) {
            vm.removeAlerts();

            var surveyShuffleDto = audService.dataContext.surveyShuffleDtos.add({
                SurveyShuffleId: NbsGuidManager.newGuid(),
                SurveyLifeStatus: surveyLifeStatus,
                SurveyLifeStatusChangeNote: changeNote,
                ModuleTargetId: moduleTargetId,
                VhrEventsIdSerialized: vhrEventsIdSerialized
            });

            audService.saveChanges()
                .then(function () {
                    var resultErrors = JSON.parse(Nembus.Common.Library.converter.decodeHtml(surveyShuffleDto.ResultErrorsSerialized));
                    if (Lazy(Object.keys(resultErrors)).some()) {
                        vm.setErrorMsg(({ isMessageVisible: true, message: vm.translate('ChangeStatusNotPermitted') }));
                        return;
                    }

                    vm.refreshAction();
                });
        }

        // hook from gridbar
        var gridFilter = {};
        var ejDataManager = null;

        vm.surveyBarOnChanges = function (result) {
            gridFilter = result;

            //add webapi params (VhrEventFilter)
            gridFilter.vhrEventFilter.AppStoreBw = appStoreBw;
            gridFilter.vhrEventFilter.VhrEventFixedTypeCode = vhrEventFixedTypeCode;
            gridFilter.vhrEventFilter.VhrEventKind = vhrEventKindId;
            gridFilter.vhrEventFilter.VirtualHumanResourceId = virtualHumanResourceId;
            gridFilter.vhrEventFilter.VhrEventLifeStatusExpression = expressionTypeEnum.Equal;
            gridFilter.vhrEventFilter.ModuleTargetId = moduleTargetId;
            gridFilter.surveyVhrEventFilter.ExcludeOnDemand = true;

            switch (vhrEventLifeStatusKey) {
                case 'tocompile':
                    gridFilter.surveyFilter.IsArchived = false;
                    gridFilter.surveyFilter.IsCompleted = false;
                    break;
                case 'compiled':
                    gridFilter.surveyFilter.IsArchived = false;
                    gridFilter.surveyFilter.IsCompleted = true;
                    break;
                case 'archived':
                    gridFilter.surveyFilter.IsArchived = true;
                    break;
                case 'ondemand':
                    gridFilter.surveyVhrEventFilter.SurveyCreationPatternId = surveyCreationPatternObj.OnDemand;
                    gridFilter.surveyVhrEventFilter.ExcludeOnDemand = null;
                    break;
            }

            if (ejDataManager === null) {
                utilService.getGenericElements({ GenericElementCode: 'Configurations.Surveys.LifeStatusColors' }, false)
                    .then((data) => {
                        var ge = data.results[0].PairValuesArray;
                        var statusColorObj = Nembus.Common.Library.parser.objFromList(ge, 'key', 'value');

                        var supportData = { isMySurveys: viewMode.startsWith('my'), translate: vm.translate, contextService: contextService, statusColorObj: statusColorObj };
                        ejDataManager = audService.getSurveyDataManager(supportData, gridFilter, ejDataManager);
                        nbsGridDatacontext.setDatasource(ejDataManager, true);
                        refreshGridDatasource(gridFilter);
                    });
            } else {
                refreshGridDatasource(gridFilter)
            }
        };

        vm.workflowModifyAction = function () {
            if (!currentSelected) {
                return;
            }

            navigationService.navToVhrEventWorkflowModifyMode(vm.suspendDirty, { appStoreCode: appStoreCode })
                .then(function (result) {
                    if (result.data) {
                        updateSurveyWorkflowStatusBulk([currentSelected.VhrEventId], moduleTargetId, result.data.WorkflowStatusId, result.data.WorkflowStatusNote, vhrEventShuffleActionObj.ChangeWorkflowStatus);
                    }
                });
        };

        vm.workflowDeleteAction = function () {
            if (!currentSelected) {
                return;
            }

            navigationService.confirmModal(vm.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        updateSurveyWorkflowStatusBulk([currentSelected.VhrEventId], moduleTargetId, null, null, vhrEventShuffleActionObj.DeleteWorkflowStatus);
                    }
                })
                .catch(function (reason) {
                });
        };

        vm.workflowInfoAction = function () {
            if (!currentSelected) {
                return;
            }

            navigationService.navToVhrEventWorkflowHistoryMode(vm.suspendDirty, { VhrEventId: currentSelected.VhrEventId, appStoreCode: appStoreCode });
        };

        vm.createGridEvent = function (result) {
            vm.surveyBarDataContext = {
                vhrEventLifeStatusKey: vhrEventLifeStatusKey,
                commonBarDatasource: result.barDatasource,
                formSummary: {
                    dataContext: vm.formSummary,
                    isVisible: true
                },
                appStoreBw: appStoreBw,
                moduleTargetId: moduleTargetId,
                defaultKeys: {
                    columnsKey: vhrEventLifeStatusKey, sortingKey: vhrEventLifeStatusKey
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        //Http Post
        function updateSurveyWorkflowStatusBulk(vhrEventsId, moduleTargetId, workflowStatusId, workflowStatusNote, vhrEventShuffleAction) {
            vm.removeAlerts();

            var vhreventShuffleDto = vhreventService.dataContext.vhrEventShuffleDto.add();
            vhreventShuffleDto.VhrEventShuffleAction = vhrEventShuffleAction;
            if (vhrEventShuffleAction === vhrEventShuffleActionObj.DeleteWorkflowStatus) {
                vhreventShuffleDto.WorkflowStatusId = 0;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = null;
            } else {
                vhreventShuffleDto.WorkflowStatusId = workflowStatusId;
                vhreventShuffleDto.VhrEventWorkflowStatusChangeNote = workflowStatusNote;
            }
            vhreventShuffleDto.VhrEventBillingStatus = 0;
            vhreventShuffleDto.VhrEventLifeStatus = 0;
            vhreventShuffleDto.ModuleTargetId = moduleTargetId;
            vhreventShuffleDto.VhrEventsIdSerialized = JSON.stringify(vhrEventsId);

            vhreventService.saveChanges()
                .then(function () {
                    vm.refreshAction();
                });
        }

        function refreshGridDatasource(gridFilter) {
            $timeout(() => {
                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: gridFilter
                }
            });
        }

        //#region Override of base methods to add verify on selection record
        function canPrintReport() {
            var result = false;
            if (!currentSelected) {
                navigationService.selectOneItemMessageModal();
            } else {
                result = true;
            }

            return result;
        }

        vm.exportReportToPdfAction = function () { pExportReport('pdf'); };
        vm.exportReportToWordAction = function () { pExportReport('openword'); };
        vm.exportReportToXmlAction = function () { pExportReport('xml'); };
        vm.exportReportToExcelAction = function () { pExportReport('openexcel'); };
        vm.exportReportToHtmlAction = function () { pExportReport('html'); };

        function pExportReport(format) {
            if (canPrintReport()) {
                vm.getReportsForCmsView(cmsService)
                    .then(function () {
                        var cEntity = currentSelected;
                        var currentReportDefinition = vm.currentReportData.reportDefinition;
                        var currentConcatName = cEntity.SurveyCode + ' - ' + cEntity.SurveyName;

                        if (currentReportDefinition) {
                            currentConcatName = currentReportDefinition.ReportDefinitionReferenceCode + ' - ' + currentConcatName;
                        }

                        var reportArgs = {
                            Ds1_Arg1: {                                         //SurveyFilter
                                SurveyId: cEntity.SurveyId
                            },
                            Ds1_Arg2: {},                                       //SurveyVhrEventFilter
                            Ds1_Arg3: {                                         //VhrEventFilter
                                ModuleTargetId: moduleTargetId
                            },
                            Ds1_Arg4: {},                                       //SurveySectionFilter
                            Ds1_Arg5: {},                                       //SurveyQuestionFilter
                            Ds1_Arg6: {},
                            Ds1_Arg7: {},                                       //VirtualHrFilter
                            Ds1_Arg8: false,                                    //isConfiguration
                            Ds1_Arg9: {                                         //surveyAnalysisFilter
                                SurveyId: cEntity.SurveyId
                            },
                        };

                        vm.setReportData({ reportArgs: reportArgs, replaceName: currentConcatName });

                        vm.exportReport(format);
                    });
            }
        }
        //#endregion overload of base methods to add verify on selection record
    }

    surveysVhrList.$inject = injectParams;
    surveysVhrList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveysVhrList', surveysVhrList);
});