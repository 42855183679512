﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'integrationService', 'fileTransferService', 'navigationService', 'breeze', 'checkStateChangeService', 'contextService', 'formSummaryService'];
    var fileBoxImportDetail = function ($q, $scope, $timeout, $injector, integrationService, fileTransferService, navigationService, breeze, checkStateChangeService, contextService, formSummaryService) {

        var vm = this;
        nbsVm.call(vm, integrationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, integrationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        vm.ribbonDatacontext = ribbon;

        var currentIntegrationRequestId = navigationService.stateParams().integrationRequestId;
        var templateDefName = navigationService.stateParams().fileBoxTag + 'SysIntDefinition';
        var integrationDirection = 'In';

        // get array items for form summary
        vm.formSummary = formSummaryService.getDatasource();

        vm.isImport = integrationDirection === "In";
        vm.showDropZone = integrationDirection === "In" && !currentIntegrationRequestId;
        vm.currentIntegrationRequest = [];
        vm.canDownload = false;
        vm.hasError = false;


        vm.files = [];
        vm.percent = 0;

        if (currentIntegrationRequestId) {
            //integration request view
            vm.setIsViewing();
            getIntegrationRequest(false).then(function () {
                getIntegrationDefinition(currentIntegrationDefinitionId);
            });

        }
        else {
            vm.setIsAdding();
            vm.currentIntegrationRequest = integrationService.dataContext.integrationRequests.add({
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                IntegrationRequestId: breeze.core.getUuid(),
                IntegrationRequestDirectionId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestDirection).findWhere({ name: integrationDirection }).value,
                IntegrationRequestResultId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestResult).findWhere({ name: 'None' }).value,
                IntegrationRequestStatusId: Lazy(integrationService.dataContext.enumTypes.IntegrationRequestStatus).findWhere({ name: 'Inserted' }).value,
                IntegrationDefinitionId: null,
                IsComparedByCode: false,
            });

            if (!vm.isImport) {
                var currentIntegrationDefinitionId = navigationService.stateParams().integrationDefinitionId
                getIntegrationDefinition(currentIntegrationDefinitionId).then(function () {
                    vm.currentIntegrationRequest.IntegrationDefinitionId = vm.currentIntegrationDefinition.IntegrationDefinitionId;
                    vm.currentIntegrationRequest.IntegrationRequestName = vm.currentIntegrationDefinition.IntegrationDefinitionName;

                });
            }

            vm.uploadfileData = {};

        }
        //cannot modify 
        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false);
        ribbon.refreshStatusButtons();

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        vm.submitAction = function () {
            $timeout(() => {
                if (integrationDirection === "In") {
                    //only validation error not managed in UI is IntegrationDefinitionId
                    //IntegrationDefinitionId is set after file uploaded
                    if (vm.currentIntegrationRequest.entityAspect.hasValidationErrors) {
                        vm.setErrorMsg(vm.translate("UploadFileRequired"), true);
                        return;
                    }
                }

                parents(false).then(
                    function (saveResult) {
                        vm.showDropZone = false;
                        currentIntegrationRequestId = vm.currentIntegrationRequest.IntegrationRequestId;
                        vm.hasError = vm.currentIntegrationRequest.IntegrationRequestResultId !== 1;
                        vm.canDownload = !vm.hasError;
                        vm.setIsViewing();
                        if (!vm.isImport) {

                            //DOWNLOAD BOTTOM TEST
                            contextService.addDownloadFile({
                                fileId: vm.currentIntegrationRequest.IntegrationFileId,
                                fileName: vm.currentIntegrationRequest.IntegrationRequestName,
                                fileType: 'Excel',
                                downloadData: {
                                    entityGuid: vm.currentIntegrationRequest.IntegrationFileId,
                                    asAttach: false,
                                    downloadType: "byGuid"
                                },
                            });
                        }
                    }
                );
            });
        }

        vm.downloadFile = function () {
            fileTransferService.downloadRemoteFileByGuid({
                entityGuid: vm.currentIntegrationRequest.IntegrationFileId,
                asAttach: false
            });
        }

        vm.onFileUploaded = function (file, res) {
            vm.removeAlerts();
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            vm.uploadfileData.SourceFileName = file.name;
            vm.currentIntegrationRequest.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);

            //var filenameExtension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : null;
            //if (filenameExtension) {
            //    filenameExtension = filenameExtension[0];
            //    var filenameWithoutExtension = file.name.replace('.' + filenameExtension, '');

            //    if (filenameWithoutExtension != sysIntDef) {
            //        var error = { isMessageVisible: true };
            //        error.message = vm.translate("FileNameError");
            //        vm.setErrorMsg(error);
            //    } else {
            getIntegrationDefinitionFromCode(templateDefName);
            //    }                
            //}
        }

        function getIntegrationRequest(forceReload) {
            var filter = { IntegrationRequestId: currentIntegrationRequestId };
            var defer = $q.defer();

            integrationService.getIntegrationRequests(filter, forceReload)
                .then(function (data) {

                    vm.currentIntegrationRequest = data.results[0];
                    vm.hasError = vm.currentIntegrationRequest.IntegrationRequestResultId !== 1;
                    vm.canDownload = !vm.hasError;

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinitionFromCode(integrationDefinitionCode) {
            var filter = { IntegrationDefinitionCode: integrationDefinitionCode };
            var defer = $q.defer();

            integrationService.getIntegrationDefinitionFromCode(filter)
                .then(function (data) {
                    if (data.results[0]) {
                        vm.currentIntegrationDefinition = data.results[0];
                        vm.currentIntegrationRequest.IntegrationDefinitionId = vm.currentIntegrationDefinition.IntegrationDefinitionId;
                        vm.currentIntegrationRequest.IntegrationRequestName = vm.currentIntegrationDefinition.IntegrationDefinitionName;
                        vm.currentIntegrationRequest.DeleteDataBeforeImport = vm.currentIntegrationDefinition.CanDeleteDataBeforeImport;
                        vm.currentIntegrationRequest.UseTemplateDefinition = vm.currentIntegrationDefinition.UseTemplateDefinition;

                        var deleteQueryFilter = Nembus.Common.Library.converter.JsonFieldParse(vm.currentIntegrationDefinition.DeleteQueryFilter);
                        Lazy(Object.keys(navigationService.stateParams())).each((pNav) => {
                            Lazy(Object.keys(deleteQueryFilter)).each((pQuery) => {
                                if (pQuery.toLowerCase() === pNav.toLowerCase()) {
                                    deleteQueryFilter[pQuery] = navigationService.stateParams()[pNav];
                                }
                            })
                        })
                        //                PostedData: JSON.stringify(navigationService.stateParams()),
                        vm.currentIntegrationRequest.DeleteQueryFilter = JSON.stringify(deleteQueryFilter);
                    }
                    else {
                        vm.uploadfileData.SourceFileName = "";
                        vm.setErrorMsg(vm.translate("TemplateError"), true);
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getIntegrationDefinition(integrationDefinitionId) {
            var filter = { IntegrationDefinitionId: integrationDefinitionId };
            var defer = $q.defer();

            integrationService.getIntegrationDefinitions(filter, false)
                .then(function (data) {
                    if (data.results[0]) {
                        vm.currentIntegrationDefinition = data.results[0];
                    }
                    else {
                        vm.uploadfileData.SourceFileName = "";
                        vm.setErrorMsg(vm.translate("TemplateError"), true);
                    }

                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        //Set action of current controller to use them in parent controller
        navigationService.setParentControllerActions(vm);
    };

    fileBoxImportDetail.$inject = injectParams;
    fileBoxImportDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxImportDetail', fileBoxImportDetail);
});