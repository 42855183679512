'use strict';
var rdsmodule = angular.module('nbs.rds.module', ['ui.bootstrap','nbs.shared.module']);

define([
	'./services/util-service'
	, './services/act-service'
	, './services/workflow-service'
	, './services/activity-service'
	, './services/bom-service'
	, './services/production-service'
	, './services/hrbucket-service'
	, './services/bi-service'
	, './services/cmstool-service'
	, './services/customer-service'
	, './services/metric-service'
	, './services/job-service'
	, './services/fdsupport-service'
	, './services/dataflow-service'
	, './services/trigger-service'
	, './services/cmsdoc-service'

	, './parameters/parameterDetail'
	, './parameters/parametersList'
	, './reports/reportDefinitionsList'
	, './vhrevents/vhrEventGroupDetail'
	, './vhrevents/vhrEventGroupsList'
	, './vhrevents/vhrEventLabelDetail'
	, './vhrevents/vhrEventLabelsList'
	, './vhrevents/vhrEventTypeDetail'
	, './vhrevents/vhrEventTypesList'
	, './virtualhumanresources/vhrGroupDetail'
	, './virtualhumanresources/vhrGroupsList'
	, './virtualhumanresources/vhrTypeDetail'
	, './virtualhumanresources/vhrTypesList'
	, './acts/actActionGroupDetail'
	, './acts/actActionGroupsList'
	, './acts/actActionTypeDetail'
	, './acts/actActionTypesList'
	, './acts/actBoardDueDate'
	, './acts/actBoardWorkflow'
	, './acts/actCreationRequestDetail'
	, './acts/actDetail'
	, './common/activitiesListModal'
	, './activities/activityDetail'
	, './common/actJournalsModal'
	, './acts/actsList'
	, './acts/actsSearch'
	, './acts/actStream'
	, './validationschemas/actValidationSchemaConfigDetail'
	, './acts/actWorkflowChangeStatusDetail'
	, './assets/assetFtc'
	, './assets/assetDetail'
	, './virtualhumanresources/vhrReferencesDetail'
	, './assets/assetsList'
	, './assets/assetsSearch'
	, './assets/assetsStream'
	, './assets/assetsTree'
	, './common/attachmentDetail'
	, './avatars/avatarFtc'
	, './avatars/avatarDetail'
	, './virtualhumanresources/hrPersonJobDataDetail'
	, './virtualhumanresources/hrSingleToBucketDetail'
	, './virtualhumanresources/vhrToActivities'
	, './virtualhumanresources/vhrToCategoriesDetail'
	, './virtualhumanresources/vhrToModules'
	, './avatars/avatarsList'
	, './avatars/avatarsSearch'
	, './productions/bankHolidayDetail'
	, './productions/bankHolidaysList'
	, './batchrequests/batchRequestsList'
	, './batchrequests/batchRequestsQueueList'
	, './bis/biDataDefinitionGroupsList'
	, './bis/biDataDefinitionsList'
	, './parameters/bomRouteMappingDetail'
	, './parameters/bomRouteMappingsList'
	, './cmsui/bookmarkDetailModal'
	, './hrbuckets/bucketFtc'
	, './hrbuckets/bucketDetail'
	, './hrbuckets/bucketToActivitiesDetail'
	, './hrbuckets/bucketToCategoriesDetail'
	, './hrbuckets/bucketToHierarchiesDetail'
	, './hrbuckets/bucketToModulesDetail'
	, './vhrevents/vhrEventsVhrList'
	, './hrbuckets/bucketsList'
	, './productions/calendarsList'
	, './productions/calendarTemplateDetail'
	, './productions/calendarTemplatesList'
	, './categories/categoriesManage'
	, './common/categoriesSelection'
	, './cmscontents/cmsStaticContentData'
	, './cmscontents/cmsStaticContentDataDetail'
	, './cmscontents/cmsStaticContentsList'
	, './companies/companiesList'
	, './companies/companyFtc'
	, './companies/companyDetail'
	, './common/hrAddressDetail'
	, './common/hrContactDetail'
	, './common/hrLegalGeneralityDetail'
	, './common/cronExpressionDetail'
	, './customers/customerDataDetail'
	, './jobs/developerToolsDetail'
	, './develop/emptyPage'
	, './jobs/execProcessesList'
	, './fdsupports/fdTicketDetail'
	, './fdsupports/fdTicketsList'
	, './fileboxes/fileBoxesList'
	, './fileboxes/fileBoxesSearch'
	, './fileboxes/fileBoxFtc'
	, './fileboxes/fileBoxAttachDetail'
	, './fileboxes/fileBoxDetail'
	, './fileboxes/fileBoxImportDetail'
	, './fileboxesviewer/fileBoxViewerAttachDetail'
	, './fileboxesviewer/fileBoxViewerDetail'
	, './dataflows/fileShuffleDefinitionFtc'
	, './dataflows/fileShuffleDefinitionDetail'
	, './dataflows/fileShuffleDefinitionsList'
	, './dataflows/fileShuffleRequestsList'
	, './genericelements/genericElementsManage'
	, './common/genericListModal'
	, './hrbuckets/hierarchiesList'
	, './hrbuckets/hierarchyDetail'
	, './common/hrBucketsModal'
	, './integrations/integrationDefinitionsList'
	, './integrations/integrationFileObserversList'
	, './integrations/integrationRequestDetail'
	, './integrations/integrationRequestsList'
	, './jobs/jobsManage'
	, './metrics/metricInstanceDetail'
	, './metrics/metricInstancesList'
	, './metrics/metricKpiDefinitionsList'
	, './metrics/metricSpecializationDetail'
	, './metrics/metricSpecializationsList'
	, './acts/myActBoardDueDate'
	, './acts/myActBoardWorkflow'
	, './acts/myActsList'
	, './acts/myActStream'
	, './notifications/myNotificationsList'
	, './notifications/notificationConfigDetail'
	, './notifications/notificationInstanceDetail'
	, './notifications/notificationInstancesList'
	, './notifications/notificationSchemaDetail'
	, './notifications/notificationSchemasList'
	, './notifications/notificationsList'
	, './reports/reportDefinitionDetail'
	, './reportformsrds/activitiesListReportForm'
	, './reportformsrds/actsExportReportForm'
	, './reportformsrds/categoriesListReportForm'
	, './reportformsrds/categoryQrCodeListReportForm'
	, './reportformsrds/vhrEventsListPricedReportForm'
	, './reportformsrds/vhrTargetsListReportForm'
	, './reportformsrds/virtualEquipmentQrCodeListReportForm'
	, './reportformsrds/virtualEquipmentsListReportForm'
	, './reports/reportDefinitionHtmlToDocDetail'
	, './reports/reportDefinitionsForViewList'
	, './reports/reportEntityDefinitionDetail'
	, './reports/reportEntityDefinitionsList'
	, './productions/routeDetail'
	, './productions/routesList'
	, './jobs/scheduledTaskDetail'
	, './jobs/scheduledTasksList'
	, './jobs/scheduledTaskStepDetail'
	, './jobs/scheduledTaskStepSummary'
	, './common/sendNotificationModal'
	, './targets/targetFtc'
	, './acts/actsVhrList'
	, './targets/targetDetail'
	, './targets/targetsList'
	, './targets/targetsSearch'
	, './triggers/triggerFiredsList'
	, './triggers/triggerInstanceDetail'
	, './triggers/triggerInstanceMatchDetail'
	, './triggers/triggerInstancesList'
	, './validationschemas/validationSchemaInstanceDetail'
	, './validationschemas/validationSchemaInstancesList'
	, './common/vhrEventChangeApproval'
	, './common/vhrEventChangeAssignment'
	, './common/vhrEventChangeCustomerApproval'
	, './common/vhrEventChangeExecution'
	, './common/vhrEventChangeStartDate'
	, './vhreventsmanual/vhrEventManualRequestDetail'
	, './vhreventsmanual/vhrEventManualRequestsList'
	, './vhrevents/vhrEventsBoardApproval'
	, './vhrevents/vhrEventsBoardDueDate'
	, './vhrevents/vhrEventsBoardLifeStatus'
	, './vhrevents/vhrEventsCalendar'
	, './vhrevents/vhrEventsList'
	, './vhreventsmanual/vhrEventsManualStepActivitySelection'
	, './vhreventsmanual/vhrEventsManualStepEventDetail'
	, './vhreventsmanual/vhrEventsManualStepSummary'
	, './vhreventsmanual/vhrEventsManualStepSwitchFromActivity'
	, './vhreventsmanual/vhrEventsManualStepUpdateEventDetail'
	, './vhreventsmanual/vhrEventsManualStepUpdateSummary'
	, './vhreventsmanual/vhrEventsManualStepUpdateSwitch'
	, './vhreventsmanual/vhrEventsManualStepVhr'
	, './vhrevents/vhrEventsSearch'
	, './vhrevents/vhrEventsStream'
	, './vhrevents/vhrEventWorkflowChangeStatusDetail'
	, './common/vhrSelectionModal'
	, './cmsdocs/viewPageElementDetail'
	, './cmsdocs/viewPageElementsList'
	, './virtualcompanies/virtualCompaniesList'
	, './virtualcompanies/virtualCompanyFtc'
	, './virtualcompanies/virtualCompanyDetail'
	, './productions/weekTemplateDetail'
	, './productions/weekTemplatesList'
	, './parameters/workCenterEscapeDetail'
	, './parameters/workCenterEscapesList'
	, './workflows/workflowDetail'
	, './workflows/workflowsList'
	, './activities/wzAddActivityStepActivity'
	, './activities/wzAddActivityStepInfo'
	, './activities/wzAddActivityStepSummary',


	'./common/components/nbsAlertscomponent',
	'./common/components/nbsAttachmentscomponent',
	'./common/components/nbsBooleancomponent',
	'./common/components/nbsCmsviewUsersettingMenucomponent',
	'./common/components/nbsCommonBarcomponent',
	'./common/components/nbsDatepickercomponent',
	'./common/components/nbsDdcomponent',
	'./common/components/nbsDdMscomponent',
	'./common/components/nbsDdSearchMscomponent',
	'./common/components/nbsDdTimecomponent',
	'./common/components/nbsEjChartcomponent',
	'./common/components/nbsEjTreeViewCategorycomponent',
	'./common/components/nbsEjTreeViewcomponent',
	'./common/components/nbsEntityJournalNotesComponent',
	'./common/components/nbsFasttabComponent',
	'./common/components/nbsFormSummarycomponent',
	'./common/components/nbsGoogleMapcomponent',
	'./common/components/nbsGridcomponent',
	'./common/components/nbsHtmltodocMasterpageComponent',
	'./common/components/nbsImagecomponent',
	'./common/components/nbsMsBoxcomponent',
	'./common/components/nbsMsItemcomponent',
	'./common/components/nbsRibboncomponent',
	'./common/components/nbsSearchAssigneecomponent',
	'./common/components/nbsToolbarcomponent',
	'./common/components/nbsTxtSearchcomponent',
	'./common/components/nbsVhrAccountersComponent',
	'./common/components/nbsVhrSelectionComponent',
	'./common/components/nbsViewTitlecomponent',
	'./common/components/nbsWzBeardCrumbcomponent',
	
	'./assets/components/nbsAssetBarComponent',
	'./batchrequests/components/nbsBatchRequestBarComponent',
	'./targets/components/nbsVhrTargetBarComponent',
	'./acts/components/nbsActBarComponent',
	'./notifications/components/nbsNotificationBarComponent',
	'./metrics/components/nbsMetricBarComponent',
	'./reports/components/nbsReportBarComponent',
	'./vhrevents/components/nbsVhreventBarComponent',

], function () {

	rdsmodule.config(['$stateProvider',
		function ($stateProvider) {

            var routeObj = routeResolver.routeObj;

			$stateProvider
				.state("auth", { abstract: false, url: "/auth", templateUrl: "Main/Public" })
				.state('auth.login', routeObj.resolve({ngUrl: '/login',baseName:'rds/authentication/login',regionName:'_'}))
				.state('auth.steptwo', routeObj.resolve({ ngUrl: '/steptwo', baseName: 'rds/authentication/stepTwo', regionName: '_' }))
				.state('auth.resetpassword', routeObj.resolve({ ngUrl: '/resetpassword', baseName: 'rds/authentication/resetPassword', regionName: '_', ngParamsName: '/{username}/{tmpPasswordUid}' }))
				.state('auth.maintenance', routeObj.resolve({ ngUrl: '/maintenance', baseName: 'rds/authentication/maintenance', regionName: '_' }))
				.state('auth.redir', routeObj.resolve({ ngUrl: '/redir', baseName: 'rds/authentication/redir', regionName: '_', ngParamsName: '/{redirparam}' }))
				.state('auth.resetssopassword', routeObj.resolve({ ngUrl: '/resetssopassword', baseName: 'rds/authentication/resetSsoPassword', regionName: '_', ngParamsName: '/{username}/{tmpPasswordUid}' }))
				.state('auth.publicnoauth', routeObj.resolve({ ngUrl: '/publicnoauth', baseName: 'rds/authentication/publicNoAuth', regionName: '_' }))
				.state('auth.loginnormal', routeObj.resolve({ ngUrl: '/loginnormal', baseName: 'rds/authentication/loginNormal', regionName: '_' }))
				.state('auth.testidp', routeObj.resolve({ ngUrl: '/testidp', baseName: 'rds/authentication/testIdp', regionName: '_' }))
				.state('auth.publicmobileappconfig', routeObj.resolve({ ngUrl: '/publicmobileappconfig', baseName: 'rds/authentication/publicMobileAppConfig', regionName: '_' }))
				.state('auth.oidcresponse', routeObj.resolve({ ngUrl: '/oidcresponse', baseName: 'rds/authentication/oidcResponse', regionName: '_' }))
				.state('auth.logoutmessage', routeObj.resolve({ ngUrl: '/logoutmessage', baseName: 'rds/authentication/logoutMessage', regionName: '_' }))

            // Next placeholder is used to FILL all routes by code, DO NOT REMOVE
			// [START-STATEPROVIDER-PLACEHOLDER]
			// version: 10.7.5.0
			// module: Core
			$stateProvider
				.state('app.nav.actactiongroupdetail',routeObj.resolve({ngUrl:'/actactiongroupdetail',ngParamsName:'/{actActionGroupId}',baseName:'rds/acts/actActionGroupDetail'}))
				.state('app.nav.actactiongroupslist',routeObj.resolve({ngUrl:'/actactiongroupslist',baseName:'rds/acts/actActionGroupsList'}))
				.state('app.nav.actactiontypedetail',routeObj.resolve({ngUrl:'/actactiontypedetail',ngParamsName:'/{actActionTypeId}',baseName:'rds/acts/actActionTypeDetail'}))
				.state('app.nav.actactiontypeslist',routeObj.resolve({ngUrl:'/actactiontypeslist',baseName:'rds/acts/actActionTypesList'}))
				.state('app.nav.actboardduedate-all',routeObj.resolve({ngUrl:'/actboardduedate-all',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardDueDate'}))
				.state('app.nav.actboardduedate-executed',routeObj.resolve({ngUrl:'/actboardduedate-executed',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardDueDate'}))
				.state('app.nav.actboardduedate-toexecute',routeObj.resolve({ngUrl:'/actboardduedate-toexecute',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardDueDate'}))
				.state('app.nav.actboardworkflow-all',routeObj.resolve({ngUrl:'/actboardworkflow-all',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardWorkflow'}))
				.state('app.nav.actboardworkflow-executed',routeObj.resolve({ngUrl:'/actboardworkflow-executed',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardWorkflow'}))
				.state('app.nav.actboardworkflow-toexecute',routeObj.resolve({ngUrl:'/actboardworkflow-toexecute',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actBoardWorkflow'}))
				.state('app.nav.actcreationrequestdetail',routeObj.resolve({ngUrl:'/actcreationrequestdetail',ngParamsName:'/{appStoreCode}/{virtualHumanResourceId}/{actFixedCode}',baseName:'rds/acts/actCreationRequestDetail'}))
				.state('app.nav.actdetail',routeObj.resolve({ngUrl:'/actdetail',ngParamsName:'/{actId}',baseName:'rds/acts/actDetail'}))
				.state('app.nav.activitieslistmodal',routeObj.resolve({ngUrl:'/activitieslistmodal',ngParamsName:'/{parentParams}',baseName:'rds/common/activitiesListModal'}))
				.state('app.nav.activitydetail-hrsactivity',routeObj.resolve({ngUrl:'/activitydetail-hrsactivity',ngParamsName:'/{activityId}/{appStoreCode}/{viewKind}/{vhrEventTypeId}',viewMode:'hrsactivity',baseName:'rds/activities/activityDetail'}))
				.state('app.nav.activitydetail-hrsbillofmaterial',routeObj.resolve({ngUrl:'/activitydetail-hrsbillofmaterial',ngParamsName:'/{activityId}/{appStoreCode}/{viewKind}/{vhrEventTypeId}',viewMode:'hrsbillofmaterial',baseName:'rds/activities/activityDetail'}))
				.state('app.nav.actjournalsmodal-manage',routeObj.resolve({ngUrl:'/actjournalsmodal-manage',ngParamsName:'/{parentParams}',viewMode:'manage',baseName:'rds/common/actJournalsModal'}))
				.state('app.nav.actslist-all',routeObj.resolve({ngUrl:'/actslist-all',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actTemplateCode}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsList'}))
				.state('app.nav.actslist-executed',routeObj.resolve({ngUrl:'/actslist-executed',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actTemplateCode}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsList'}))
				.state('app.nav.actslist-toexecute',routeObj.resolve({ngUrl:'/actslist-toexecute',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actTemplateCode}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsList'}))
				.state('app.nav.actssearch',routeObj.resolve({ngUrl:'/actssearch',ngParamsName:'/{appStoreCode}/{actFixedCode}',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsSearch'}))
				.state('app.nav.actstream-all',routeObj.resolve({ngUrl:'/actstream-all',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actStream'}))
				.state('app.nav.actstream-executed',routeObj.resolve({ngUrl:'/actstream-executed',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actStream'}))
				.state('app.nav.actstream-toexecute',routeObj.resolve({ngUrl:'/actstream-toexecute',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actStream'}))
				.state('app.nav.actvalidationschemaconfigdetail',routeObj.resolve({ngUrl:'/actvalidationschemaconfigdetail',baseName:'rds/validationschemas/actValidationSchemaConfigDetail'}))
				.state('app.nav.actworkflowchangestatusdetail-execute',routeObj.resolve({ngUrl:'/actworkflowchangestatusdetail-execute',ngParamsName:'/{parentParams}',viewMode:'execute',baseName:'rds/acts/actWorkflowChangeStatusDetail'}))
				.state('app.nav.actworkflowchangestatusdetail-history',routeObj.resolve({ngUrl:'/actworkflowchangestatusdetail-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/acts/actWorkflowChangeStatusDetail'}))
				.state('app.nav.actworkflowchangestatusdetail-modify',routeObj.resolve({ngUrl:'/actworkflowchangestatusdetail-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/acts/actWorkflowChangeStatusDetail'}))
				.state('app.nav.appuserdetail',routeObj.resolve({ngUrl:'/appuserdetail',ngParamsName:'/{xCentralUserId}/{isNewUser}',baseName:'rbac/users/appUserDetail'}))
				.state('app.nav.appuserslist-context',routeObj.resolve({ngUrl:'/appuserslist-context',ngParamsName:'/{viewKind}',viewMode:'context',baseName:'rbac/users/appUsersList'}))
				.state('app.nav.appuserslist-global',routeObj.resolve({ngUrl:'/appuserslist-global',ngParamsName:'/{viewKind}',viewMode:'global',baseName:'rbac/users/appUsersList'}))
				.state('app.nav.appuserslistmodal',routeObj.resolve({ngUrl:'/appuserslistmodal',ngParamsName:'/{viewKind}',baseName:'rbac/users/appUsersListModal'}))
				.state('app.nav.assetftc',routeObj.resolve({ngUrl:'/assetftc',ngParamsName:'/{virtualHumanResourceId}/{appStoreCodeFtc}/{viewKindFtc}/{viewMode}',baseName:'rds/assets/assetFtc'}))
				.state('app.nav.assetftc.assetdetail-ers',routeObj.resolve({ngUrl:'/assetdetail-ers',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{viewKind}',viewMode:'ers',baseName:'rds/assets/assetDetail'}))
				.state('app.nav.assetftc.assetdetail-erscus',routeObj.resolve({ngUrl:'/assetdetail-erscus',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{viewKind}',viewMode:'erscus',baseName:'rds/assets/assetDetail'}))
				.state('app.nav.assetftc.assetdetail-erstree',routeObj.resolve({ngUrl:'/assetdetail-erstree',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{viewKind}',viewMode:'erstree',baseName:'rds/assets/assetDetail'}))
				.state('app.nav.assetftc.vhrreferencesdetail-asset',routeObj.resolve({ngUrl:'/vhrreferencesdetail-asset',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}/{vhrType}',viewMode:'asset',baseName:'rds/virtualhumanresources/vhrReferencesDetail'}))
				.state('app.nav.assetslist-ers',routeObj.resolve({ngUrl:'/assetslist-ers',ngParamsName:'/{appStoreCode}/{viewKind}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'ers',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsList'}))
				.state('app.nav.assetslist-erscus',routeObj.resolve({ngUrl:'/assetslist-erscus',ngParamsName:'/{appStoreCode}/{viewKind}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'erscus',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsList'}))
				.state('app.nav.assetslist-erstree',routeObj.resolve({ngUrl:'/assetslist-erstree',ngParamsName:'/{appStoreCode}/{viewKind}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'erstree',srvParamsValue:'{"BucketClassificationId": 501}',baseName:'rds/assets/assetsList'}))
				.state('app.nav.assetssearch-ers',routeObj.resolve({ngUrl:'/assetssearch-ers',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{wzVhrEntityType}',viewMode:'ers',baseName:'rds/assets/assetsSearch'}))
				.state('app.nav.assetsstream-ers',routeObj.resolve({ngUrl:'/assetsstream-ers',ngParamsName:'/{appStoreCode}/{viewKind}/{sourceEntityNavCode}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'ers',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsStream'}))
				.state('app.nav.assetsstream-erscus',routeObj.resolve({ngUrl:'/assetsstream-erscus',ngParamsName:'/{appStoreCode}/{viewKind}/{sourceEntityNavCode}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'erscus',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsStream'}))
				.state('app.nav.assetsstream-erstree',routeObj.resolve({ngUrl:'/assetsstream-erstree',ngParamsName:'/{appStoreCode}/{viewKind}/{sourceEntityNavCode}/{AssetTreeEntityType}/{BucketClassificationId}',viewMode:'erstree',srvParamsValue:'{"BucketClassificationId": 501}',baseName:'rds/assets/assetsStream'}))
				.state('app.nav.assetstree-ers',routeObj.resolve({ngUrl:'/assetstree-ers',ngParamsName:'/{appStoreCode}/{viewKind}/{bucketClassificationId}/{sourceEntityNavCode}',viewMode:'ers',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsTree'}))
				.state('app.nav.assetstree-erscus',routeObj.resolve({ngUrl:'/assetstree-erscus',ngParamsName:'/{appStoreCode}/{viewKind}/{bucketClassificationId}/{sourceEntityNavCode}',viewMode:'erscus',srvParamsValue:'{"AssetTreeEntityType": "VirtualEquipment"}',baseName:'rds/assets/assetsTree'}))
				.state('app.nav.assetstree-erstree',routeObj.resolve({ngUrl:'/assetstree-erstree',ngParamsName:'/{appStoreCode}/{viewKind}/{bucketClassificationId}/{sourceEntityNavCode}',viewMode:'erstree',srvParamsValue:'{"bucketClassificationId": 501}',baseName:'rds/assets/assetsTree'}))
				.state('app.nav.attachmentdetail',routeObj.resolve({ngUrl:'/attachmentdetail',ngParamsName:'/{fsFolderId}/{viewKind}/{viewKindFmd}',baseName:'rds/common/attachmentDetail'}))
				.state('app.nav.authorizationdenied',routeObj.resolve({ngUrl:'/authorizationdenied',baseName:'rbac/authorization/authorizationDenied'}))
				.state('app.nav.avatarftc',routeObj.resolve({ngUrl:'/avatarftc',ngParamsName:'/{virtualHumanResourceId}/{appStoreCodeFtc}',baseName:'rds/avatars/avatarFtc'}))
				.state('app.nav.avatarftc.avatardetail',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}',baseName:'rds/avatars/avatarDetail'}))
				.state('app.nav.avatarftc.hrpersonjobdatadetail',routeObj.resolve({ngUrl:'/hrpersonjobdatadetail',regionName:'ftcRegion',ngParamsName:'/{personJobDataId}',baseName:'rds/virtualhumanresources/hrPersonJobDataDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail',routeObj.resolve({ngUrl:'/hrsingletobucketdetail',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail',routeObj.resolve({ngUrl:'/hrsingletobucketdetail',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-businessunit',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-businessunit',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'businessunit',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-businessunit',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-businessunit',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'businessunit',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-costcenter',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-costcenter',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'costcenter',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-costcenter',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-costcenter',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'costcenter',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-hrgroup',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-hrgroup',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'hrgroup',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-hrgroup',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-hrgroup',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'hrgroup',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-hrs',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-hrs',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'hrs',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-hrs',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-hrs',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'hrs',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-legalentity',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-legalentity',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'legalentity',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-legalentity',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-legalentity',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'legalentity',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-plant',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-plant',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'plant',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-plant',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-plant',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'plant',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.hrsingletobucketdetail-sector',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-sector',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'sector',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.assetftc.hrsingletobucketdetail-sector',routeObj.resolve({ngUrl:'/hrsingletobucketdetail-sector',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{viewKind}/{resourceTypeId}/{bucketClassificationId}',viewMode:'sector',baseName:'rds/virtualhumanresources/hrSingleToBucketDetail'}))
				.state('app.nav.avatarftc.vhrreferencesdetail-avatar',routeObj.resolve({ngUrl:'/vhrreferencesdetail-avatar',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}/{vhrType}',viewMode:'avatar',baseName:'rds/virtualhumanresources/vhrReferencesDetail'}))
				.state('app.nav.avatarftc.vhrtoactivities',routeObj.resolve({ngUrl:'/vhrtoactivities',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{viewKind}',baseName:'rds/virtualhumanresources/vhrToActivities'}))
				.state('app.nav.avatarftc.vhrtoactivities-hrs',routeObj.resolve({ngUrl:'/vhrtoactivities-hrs',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{viewKind}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrToActivities'}))
				.state('app.nav.avatarftc.vhrtocategoriesdetail',routeObj.resolve({ngUrl:'/vhrtocategoriesdetail',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}',baseName:'rds/virtualhumanresources/vhrToCategoriesDetail'}))
				.state('app.nav.assetftc.vhrtocategoriesdetail',routeObj.resolve({ngUrl:'/vhrtocategoriesdetail',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}',baseName:'rds/virtualhumanresources/vhrToCategoriesDetail'}))
				.state('app.nav.bucketftc.vhrtocategoriesdetail',routeObj.resolve({ngUrl:'/vhrtocategoriesdetail',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}',baseName:'rds/virtualhumanresources/vhrToCategoriesDetail'}))
				.state('app.nav.avatarftc.vhrtomodules',routeObj.resolve({ngUrl:'/vhrtomodules',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{viewKind}/{entityName}',baseName:'rds/virtualhumanresources/vhrToModules'}))
				.state('app.nav.avatarftc.vhrtomodules-hrs',routeObj.resolve({ngUrl:'/vhrtomodules-hrs',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{viewKind}/{entityName}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrToModules'}))
				.state('app.nav.avatarftc.vhrtomodules-she',routeObj.resolve({ngUrl:'/vhrtomodules-she',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{viewKind}/{entityName}',viewMode:'she',baseName:'rds/virtualhumanresources/vhrToModules'}))
				.state('app.nav.avatarslist',routeObj.resolve({ngUrl:'/avatarslist',ngParamsName:'/{appStoreCode}',baseName:'rds/avatars/avatarsList'}))
				.state('app.nav.avatarssearch',routeObj.resolve({ngUrl:'/avatarssearch',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{wzVhrEntityType}',baseName:'rds/avatars/avatarsSearch'}))
				.state('app.nav.bankholidaydetail',routeObj.resolve({ngUrl:'/bankholidaydetail',ngParamsName:'/{bankHolidayId}',baseName:'rds/productions/bankHolidayDetail'}))
				.state('app.nav.bankholidayslist',routeObj.resolve({ngUrl:'/bankholidayslist',baseName:'rds/productions/bankHolidaysList'}))
				.state('app.nav.batchrequestslist',routeObj.resolve({ngUrl:'/batchrequestslist',baseName:'rds/batchrequests/batchRequestsList'}))
				.state('app.nav.batchrequestsqueuelist',routeObj.resolve({ngUrl:'/batchrequestsqueuelist',baseName:'rds/batchrequests/batchRequestsQueueList'}))
				.state('app.nav.bidatadefinitiongroupslist',routeObj.resolve({ngUrl:'/bidatadefinitiongroupslist',ignoreHistory:true,baseName:'rds/bis/biDataDefinitionGroupsList'}))
				.state('app.nav.bidatadefinitionslist',routeObj.resolve({ngUrl:'/bidatadefinitionslist',ignoreHistory:true,baseName:'rds/bis/biDataDefinitionsList'}))
				.state('app.nav.bomroutemappingdetail-mpl',routeObj.resolve({ngUrl:'/bomroutemappingdetail-mpl',ngParamsName:'/{appStoreCode}/{bomRouteMappingId}',viewMode:'mpl',baseName:'rds/parameters/bomRouteMappingDetail'}))
				.state('app.nav.bomroutemappingslist-mpl',routeObj.resolve({ngUrl:'/bomroutemappingslist-mpl',ngParamsName:'/{appStoreCode}',viewMode:'mpl',baseName:'rds/parameters/bomRouteMappingsList'}))
				.state('app.nav.bookmarkdetailmodal',routeObj.resolve({ngUrl:'/bookmarkdetailmodal',baseName:'rds/cmsui/bookmarkDetailModal'}))
				.state('app.nav.bucketftc',routeObj.resolve({ngUrl:'/bucketftc',ngParamsName:'/{virtualHumanResourceId}/{appStoreCodeFtc}/{resourceType}/{bucketClassification}/{viewMode}',baseName:'rds/hrbuckets/bucketFtc'}))
				.state('app.nav.bucketftc.bucketdetail-dace',routeObj.resolve({ngUrl:'/bucketdetail-dace',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'dace',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-grge',routeObj.resolve({ngUrl:'/bucketdetail-grge',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'grge',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-lege',routeObj.resolve({ngUrl:'/bucketdetail-lege',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'lege',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-plnt',routeObj.resolve({ngUrl:'/bucketdetail-plnt',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'plnt',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-pubu',routeObj.resolve({ngUrl:'/bucketdetail-pubu',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'pubu',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-pucc',routeObj.resolve({ngUrl:'/bucketdetail-pucc',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'pucc',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.bucketdetail-puse',routeObj.resolve({ngUrl:'/bucketdetail-puse',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'puse',baseName:'rds/hrbuckets/bucketDetail'}))
				.state('app.nav.bucketftc.buckettoactivitiesdetail',routeObj.resolve({ngUrl:'/buckettoactivitiesdetail',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}',baseName:'rds/hrbuckets/bucketToActivitiesDetail'}))
				.state('app.nav.bucketftc.buckettocategoriesdetail',routeObj.resolve({ngUrl:'/buckettocategoriesdetail',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{appStoreCode}/{systemCategoryCode}',baseName:'rds/hrbuckets/bucketToCategoriesDetail'}))
				.state('app.nav.bucketftc.buckettohierarchiesdetail',routeObj.resolve({ngUrl:'/buckettohierarchiesdetail',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{appStoreCode}/{virtualHumanResourceId}',baseName:'rds/hrbuckets/bucketToHierarchiesDetail'}))
				.state('app.nav.bucketftc.buckettomodulesdetail',routeObj.resolve({ngUrl:'/buckettomodulesdetail',regionName:'ftcRegion',ngParamsName:'/{entityName}/{virtualHumanResourceId}',baseName:'rds/hrbuckets/bucketToModulesDetail'}))
				.state('app.nav.bucketftc.vhreventsvhrlist-ats',routeObj.resolve({ngUrl:'/vhreventsvhrlist-ats',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{wzVhrEntityType}',viewMode:'ats',baseName:'rds/vhrevents/vhrEventsVhrList'}))
				.state('app.nav.bucketftc.vhrreferencesdetail-bucket',routeObj.resolve({ngUrl:'/vhrreferencesdetail-bucket',regionName:'ftcRegion',ngParamsName:'/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}/{vhrType}',viewMode:'bucket',baseName:'rds/virtualhumanresources/vhrReferencesDetail'}))
				.state('app.nav.bucketslist-dace',routeObj.resolve({ngUrl:'/bucketslist-dace',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'dace',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-grge',routeObj.resolve({ngUrl:'/bucketslist-grge',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'grge',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-lege',routeObj.resolve({ngUrl:'/bucketslist-lege',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'lege',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-plnt',routeObj.resolve({ngUrl:'/bucketslist-plnt',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'plnt',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-pubu',routeObj.resolve({ngUrl:'/bucketslist-pubu',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'pubu',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-pucc',routeObj.resolve({ngUrl:'/bucketslist-pucc',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'pucc',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.bucketslist-puse',routeObj.resolve({ngUrl:'/bucketslist-puse',ngParamsName:'/{appStoreCode}/{resourceType}/{bucketClassification}',viewMode:'puse',baseName:'rds/hrbuckets/bucketsList'}))
				.state('app.nav.calendarslist',routeObj.resolve({ngUrl:'/calendarslist',ngParamsName:'/{appStoreCode}',baseName:'rds/productions/calendarsList'}))
				.state('app.nav.calendartemplatedetail',routeObj.resolve({ngUrl:'/calendartemplatedetail',ngParamsName:'/{calendarId}',baseName:'rds/productions/calendarTemplateDetail'}))
				.state('app.nav.calendartemplateslist',routeObj.resolve({ngUrl:'/calendartemplateslist',ngParamsName:'/{appStoreCode}',baseName:'rds/productions/calendarTemplatesList'}))
				.state('app.nav.categoriesmanage',routeObj.resolve({ngUrl:'/categoriesmanage',ngParamsName:'/{systemCategoryCode}',baseName:'rds/categories/categoriesManage'}))
				.state('app.nav.categoriesmanage-act',routeObj.resolve({ngUrl:'/categoriesmanage-act',ngParamsName:'/{systemCategoryCode}',viewMode:'act',baseName:'rds/categories/categoriesManage'}))
				.state('app.nav.categoriesmanage-hazards',routeObj.resolve({ngUrl:'/categoriesmanage-hazards',ngParamsName:'/{systemCategoryCode}',viewMode:'hazards',baseName:'rds/categories/categoriesManage'}))
				.state('app.nav.categoriesselection',routeObj.resolve({ngUrl:'/categoriesselection',ngParamsName:'/{parentParams}',baseName:'rds/common/categoriesSelection'}))
				.state('app.nav.changemypassword',routeObj.resolve({ngUrl:'/changemypassword',baseName:'rbac/myaccount/changeMyPassword'}))
				.state('app.nav.cmsstaticcontentdata',routeObj.resolve({ngUrl:'/cmsstaticcontentdata',ignoreHistory:true,ngParamsName:'/{appStoreCode}/{cmsStaticContentCode}',baseName:'rds/cmscontents/cmsStaticContentData'}))
				.state('app.nav.cmsstaticcontentdata.cmsstaticcontentdatadetail',routeObj.resolve({ngUrl:'/cmsstaticcontentdatadetail',regionName:'cmsStaticContentRegion',ignoreHistory:true,ngParamsName:'/{cmsStaticContentCode}/{fsFolderId}',baseName:'rds/cmscontents/cmsStaticContentDataDetail'}))
				.state('app.nav.cmsstaticcontentslist',routeObj.resolve({ngUrl:'/cmsstaticcontentslist',baseName:'rds/cmscontents/cmsStaticContentsList'}))
				.state('app.nav.companieslist',routeObj.resolve({ngUrl:'/companieslist',baseName:'rds/companies/companiesList'}))
				.state('app.nav.companieslist-ats',routeObj.resolve({ngUrl:'/companieslist-ats',viewMode:'ats',baseName:'rds/companies/companiesList'}))
				.state('app.nav.companyftc',routeObj.resolve({ngUrl:'/companyftc',ngParamsName:'/{companyId}',baseName:'rds/companies/companyFtc'}))
				.state('app.nav.companyftc.companydetail',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{companyId}',baseName:'rds/companies/companyDetail'}))
				.state('app.nav.companyftc.hraddressdetail',routeObj.resolve({ngUrl:'/hraddressdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrAddressDetail'}))
				.state('app.nav.virtualcompanyftc.hraddressdetail',routeObj.resolve({ngUrl:'/hraddressdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrAddressDetail'}))
				.state('app.nav.avatarftc.hraddressdetail',routeObj.resolve({ngUrl:'/hraddressdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrAddressDetail'}))
				.state('app.nav.assetftc.hraddressdetail',routeObj.resolve({ngUrl:'/hraddressdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrAddressDetail'}))
				.state('app.nav.companyftc.hrcontactdetail',routeObj.resolve({ngUrl:'/hrcontactdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrContactDetail'}))
				.state('app.nav.virtualcompanyftc.hrcontactdetail',routeObj.resolve({ngUrl:'/hrcontactdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrContactDetail'}))
				.state('app.nav.avatarftc.hrcontactdetail',routeObj.resolve({ngUrl:'/hrcontactdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrContactDetail'}))
				.state('app.nav.assetftc.hrcontactdetail',routeObj.resolve({ngUrl:'/hrcontactdetail',regionName:'ftcRegion',ngParamsName:'/{hrContactsId}/{referral}',baseName:'rds/common/hrContactDetail'}))
				.state('app.nav.companyftc.hrlegalgeneralitydetail',routeObj.resolve({ngUrl:'/hrlegalgeneralitydetail',regionName:'ftcRegion',ngParamsName:'/{hrLegalGeneralityId}/{referral}',baseName:'rds/common/hrLegalGeneralityDetail'}))
				.state('app.nav.virtualcompanyftc.hrlegalgeneralitydetail',routeObj.resolve({ngUrl:'/hrlegalgeneralitydetail',regionName:'ftcRegion',ngParamsName:'/{hrLegalGeneralityId}/{referral}',baseName:'rds/common/hrLegalGeneralityDetail'}))
				.state('app.nav.cronexpressiondetail',routeObj.resolve({ngUrl:'/cronexpressiondetail',ngParamsName:'/{parentParams}',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-bidatarequest',routeObj.resolve({ngUrl:'/cronexpressiondetail-bidatarequest',ngParamsName:'/{parentParams}',viewMode:'bidatarequest',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-execprocessrequest',routeObj.resolve({ngUrl:'/cronexpressiondetail-execprocessrequest',ngParamsName:'/{parentParams}',viewMode:'execprocessrequest',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-fileshufflerequest',routeObj.resolve({ngUrl:'/cronexpressiondetail-fileshufflerequest',ngParamsName:'/{parentParams}',viewMode:'fileshufflerequest',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-schedulingrequest',routeObj.resolve({ngUrl:'/cronexpressiondetail-schedulingrequest',ngParamsName:'/{parentParams}',viewMode:'schedulingrequest',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-survey',routeObj.resolve({ngUrl:'/cronexpressiondetail-survey',ngParamsName:'/{parentParams}',viewMode:'survey',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-vhreventmanual',routeObj.resolve({ngUrl:'/cronexpressiondetail-vhreventmanual',ngParamsName:'/{parentParams}',viewMode:'vhreventmanual',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.cronexpressiondetail-vhreventmanualupdate',routeObj.resolve({ngUrl:'/cronexpressiondetail-vhreventmanualupdate',ngParamsName:'/{parentParams}',viewMode:'vhreventmanualupdate',baseName:'rds/common/cronExpressionDetail'}))
				.state('app.nav.customerdatadetail',routeObj.resolve({ngUrl:'/customerdatadetail',baseName:'rds/customers/customerDataDetail'}))
				.state('app.nav.developertoolsdetail',routeObj.resolve({ngUrl:'/developertoolsdetail',baseName:'rds/jobs/developerToolsDetail'}))
				.state('app.nav.drbacassignmentdetail',routeObj.resolve({ngUrl:'/drbacassignmentdetail',ngParamsName:'/{parentParams}',baseName:'rbac/drbaccommon/drbacAssignmentDetail'}))
				.state('app.nav.drbacconfigdetail',routeObj.resolve({ngUrl:'/drbacconfigdetail',baseName:'rbac/drbacconfig/drbacConfigDetail'}))
				.state('app.nav.drbacpermissiongrouplist',routeObj.resolve({ngUrl:'/drbacpermissiongrouplist',baseName:'rbac/drbacconfig/drbacPermissionGroupList'}))
				.state('app.nav.drbacpermissionlist',routeObj.resolve({ngUrl:'/drbacpermissionlist',baseName:'rbac/drbacconfig/drbacPermissionList'}))
				.state('app.nav.drbacprocessconfigdetail',routeObj.resolve({ngUrl:'/drbacprocessconfigdetail',ngParamsName:'/{drbacProcessConfigId}',baseName:'rbac/drbacprocess/drbacProcessConfigDetail'}))
				.state('app.nav.drbacprocessconfiglist',routeObj.resolve({ngUrl:'/drbacprocessconfiglist',baseName:'rbac/drbacprocess/drbacProcessConfigList'}))
				.state('app.nav.drbacprocessdetail',routeObj.resolve({ngUrl:'/drbacprocessdetail',ngParamsName:'/{drbacProcessId}',baseName:'rbac/drbacprocess/drbacProcessDetail'}))
				.state('app.nav.drbacprocessglobaldetail',routeObj.resolve({ngUrl:'/drbacprocessglobaldetail',ngParamsName:'/{drbacProcessId}',baseName:'rbac/drbacprocess/drbacProcessGlobalDetail'}))
				.state('app.nav.drbacprocessgloballist',routeObj.resolve({ngUrl:'/drbacprocessgloballist',baseName:'rbac/drbacprocess/drbacProcessGlobalList'}))
				.state('app.nav.drbacprocesslist',routeObj.resolve({ngUrl:'/drbacprocesslist',baseName:'rbac/drbacprocess/drbacProcessList'}))
				.state('app.nav.drbacroledetail',routeObj.resolve({ngUrl:'/drbacroledetail',ngParamsName:'/{drbacRoleId}',baseName:'rbac/drbacconfig/drbacRoleDetail'}))
				.state('app.nav.drbacrolelist',routeObj.resolve({ngUrl:'/drbacrolelist',baseName:'rbac/drbacconfig/drbacRoleList'}))
				.state('app.nav.drbacschemadetail',routeObj.resolve({ngUrl:'/drbacschemadetail',ngParamsName:'/{drbacSchemaId}',baseName:'rbac/drbacconfig/drbacSchemaDetail'}))
				.state('app.nav.drbacschemalist',routeObj.resolve({ngUrl:'/drbacschemalist',baseName:'rbac/drbacconfig/drbacSchemaList'}))
				.state('app.nav.drbacusergroupftc',routeObj.resolve({ngUrl:'/drbacusergroupftc',ngParamsName:'/{drbacUserGroupId}',baseName:'rbac/drbacconfig/drbacUserGroupFtc'}))
				.state('app.nav.drbacusergroupftc.drbacusergroupdetail',routeObj.resolve({ngUrl:'/drbacusergroupdetail',regionName:'ftcRegion',ngParamsName:'/{drbacUserGroupId}',baseName:'rbac/drbacconfig/drbacUserGroupDetail'}))
				.state('app.nav.drbacusergroupftc.drbacusertousergroups',routeObj.resolve({ngUrl:'/drbacusertousergroups',regionName:'ftcRegion',ngParamsName:'/{drbacUserGroupId}',baseName:'rbac/drbacconfig/drbacUserToUserGroups'}))
				.state('app.nav.drbacusergrouplist',routeObj.resolve({ngUrl:'/drbacusergrouplist',baseName:'rbac/drbacconfig/drbacUserGroupList'}))
				.state('app.nav.emptypage',routeObj.resolve({ngUrl:'/emptypage',baseName:'rds/develop/emptyPage'}))
				.state('app.nav.execprocesseslist',routeObj.resolve({ngUrl:'/execprocesseslist',ngParamsName:'/{targetClassFullName}/{targetEntityId}/{viewFormSummary}',baseName:'rds/jobs/execProcessesList'}))
				.state('app.nav.fdticketdetail',routeObj.resolve({ngUrl:'/fdticketdetail',ngParamsName:'/{id}',baseName:'rds/fdsupports/fdTicketDetail'}))
				.state('app.nav.fdticketslist',routeObj.resolve({ngUrl:'/fdticketslist',baseName:'rds/fdsupports/fdTicketsList'}))
				.state('app.nav.fileboxeslist-ecc',routeObj.resolve({ngUrl:'/fileboxeslist-ecc',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'ecc',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-economicdash',routeObj.resolve({ngUrl:'/fileboxeslist-economicdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'economicdash',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-labourdash',routeObj.resolve({ngUrl:'/fileboxeslist-labourdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'labourdash',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-uploadecc',routeObj.resolve({ngUrl:'/fileboxeslist-uploadecc',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'uploadecc',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-uploadeconomicdash',routeObj.resolve({ngUrl:'/fileboxeslist-uploadeconomicdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'uploadeconomicdash',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-uploadlabourdash',routeObj.resolve({ngUrl:'/fileboxeslist-uploadlabourdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'uploadlabourdash',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-uploadwcs',routeObj.resolve({ngUrl:'/fileboxeslist-uploadwcs',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'uploadwcs',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxeslist-wcs',routeObj.resolve({ngUrl:'/fileboxeslist-wcs',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{fileBoxType}/{target}',viewMode:'wcs',baseName:'rds/fileboxes/fileBoxesList'}))
				.state('app.nav.fileboxessearch-ecc',routeObj.resolve({ngUrl:'/fileboxessearch-ecc',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{target}',viewMode:'ecc',baseName:'rds/fileboxes/fileBoxesSearch'}))
				.state('app.nav.fileboxessearch-economicdash',routeObj.resolve({ngUrl:'/fileboxessearch-economicdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{target}',viewMode:'economicdash',baseName:'rds/fileboxes/fileBoxesSearch'}))
				.state('app.nav.fileboxessearch-labourdash',routeObj.resolve({ngUrl:'/fileboxessearch-labourdash',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{target}',viewMode:'labourdash',baseName:'rds/fileboxes/fileBoxesSearch'}))
				.state('app.nav.fileboxessearch-wcs',routeObj.resolve({ngUrl:'/fileboxessearch-wcs',ngParamsName:'/{appStoreCode}/{viewKind}/{fileBoxTag}/{target}',viewMode:'wcs',baseName:'rds/fileboxes/fileBoxesSearch'}))
				.state('app.nav.fileboxftc',routeObj.resolve({ngUrl:'/fileboxftc',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{target}/{fileBoxType}/{appStoreCode}/{fsFolderId}',baseName:'rds/fileboxes/fileBoxFtc'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-ecc',routeObj.resolve({ngUrl:'/fileboxattachdetail-ecc',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'ecc',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-economicdash',routeObj.resolve({ngUrl:'/fileboxattachdetail-economicdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'economicdash',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-labourdash',routeObj.resolve({ngUrl:'/fileboxattachdetail-labourdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'labourdash',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-uploadecc',routeObj.resolve({ngUrl:'/fileboxattachdetail-uploadecc',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'uploadecc',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-uploadeconomicdash',routeObj.resolve({ngUrl:'/fileboxattachdetail-uploadeconomicdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'uploadeconomicdash',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-uploadlabourdash',routeObj.resolve({ngUrl:'/fileboxattachdetail-uploadlabourdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'uploadlabourdash',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-uploadwcs',routeObj.resolve({ngUrl:'/fileboxattachdetail-uploadwcs',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'uploadwcs',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxattachdetail-wcs',routeObj.resolve({ngUrl:'/fileboxattachdetail-wcs',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}',viewMode:'wcs',baseName:'rds/fileboxes/fileBoxAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-ecc',routeObj.resolve({ngUrl:'/fileboxdetail-ecc',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'ecc',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-economicdash',routeObj.resolve({ngUrl:'/fileboxdetail-economicdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'economicdash',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-labourdash',routeObj.resolve({ngUrl:'/fileboxdetail-labourdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'labourdash',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-uploadecc',routeObj.resolve({ngUrl:'/fileboxdetail-uploadecc',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'uploadecc',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-uploadeconomicdash',routeObj.resolve({ngUrl:'/fileboxdetail-uploadeconomicdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'uploadeconomicdash',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-uploadlabourdash',routeObj.resolve({ngUrl:'/fileboxdetail-uploadlabourdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'uploadlabourdash',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-uploadwcs',routeObj.resolve({ngUrl:'/fileboxdetail-uploadwcs',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'uploadwcs',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboxdetail-wcs',routeObj.resolve({ngUrl:'/fileboxdetail-wcs',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxTag}/{fileBoxType}/{readonly}/{appStoreCode}',viewMode:'wcs',baseName:'rds/fileboxes/fileBoxDetail'}))
				.state('app.nav.fileboxftc.fileboximportdetail',routeObj.resolve({ngUrl:'/fileboximportdetail',regionName:'ftcRegion',ngParamsName:'/{viewKind}',baseName:'rds/fileboxes/fileBoxImportDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerattachdetail-ecc',routeObj.resolve({ngUrl:'/fileboxviewerattachdetail-ecc',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}/{sysInt}',viewMode:'ecc',baseName:'rds/fileboxesviewer/fileBoxViewerAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerattachdetail-economicdash',routeObj.resolve({ngUrl:'/fileboxviewerattachdetail-economicdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}/{sysInt}',viewMode:'economicdash',baseName:'rds/fileboxesviewer/fileBoxViewerAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerattachdetail-labourdash',routeObj.resolve({ngUrl:'/fileboxviewerattachdetail-labourdash',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}/{sysInt}',viewMode:'labourdash',baseName:'rds/fileboxesviewer/fileBoxViewerAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerattachdetail-wcs',routeObj.resolve({ngUrl:'/fileboxviewerattachdetail-wcs',regionName:'ftcRegion',ngParamsName:'/{fsFolderId}/{sysInt}',viewMode:'wcs',baseName:'rds/fileboxesviewer/fileBoxViewerAttachDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerdetail-ecc',routeObj.resolve({ngUrl:'/fileboxviewerdetail-ecc',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxType}/{sysInt}/{readonly}/{appStoreCode}',viewMode:'ecc',baseName:'rds/fileboxesviewer/fileBoxViewerDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerdetail-economicdash',routeObj.resolve({ngUrl:'/fileboxviewerdetail-economicdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxType}/{sysInt}/{readonly}/{appStoreCode}',viewMode:'economicdash',baseName:'rds/fileboxesviewer/fileBoxViewerDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerdetail-labourdash',routeObj.resolve({ngUrl:'/fileboxviewerdetail-labourdash',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxType}/{sysInt}/{readonly}/{appStoreCode}',viewMode:'labourdash',baseName:'rds/fileboxesviewer/fileBoxViewerDetail'}))
				.state('app.nav.fileboxftc.fileboxviewerdetail-wcs',routeObj.resolve({ngUrl:'/fileboxviewerdetail-wcs',regionName:'ftcRegion',ngParamsName:'/{fileBoxId}/{viewKind}/{fileBoxType}/{sysInt}/{readonly}/{appStoreCode}',viewMode:'wcs',baseName:'rds/fileboxesviewer/fileBoxViewerDetail'}))
				.state('app.nav.fileshuffledefinitionftc',routeObj.resolve({ngUrl:'/fileshuffledefinitionftc',ngParamsName:'/{appStoreCode}/{fileShuffleDefinitionId}/{targetClassFullName}/{targetEntityId}',baseName:'rds/dataflows/fileShuffleDefinitionFtc'}))
				.state('app.nav.fileshuffledefinitionftc.fileshuffledefinitiondetail',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{fileShuffleDefinitionId}',baseName:'rds/dataflows/fileShuffleDefinitionDetail'}))
				.state('app.nav.fileshuffledefinitionslist',routeObj.resolve({ngUrl:'/fileshuffledefinitionslist',baseName:'rds/dataflows/fileShuffleDefinitionsList'}))
				.state('app.nav.fileshufflerequestslist',routeObj.resolve({ngUrl:'/fileshufflerequestslist',baseName:'rds/dataflows/fileShuffleRequestsList'}))
				.state('app.nav.forcechangemypassword',routeObj.resolve({ngUrl:'/forcechangemypassword',baseName:'rbac/myaccount/forceChangeMyPassword'}))
				.state('app.nav.genericelementsmanage-ats',routeObj.resolve({ngUrl:'/genericelementsmanage-ats',ngParamsName:'/{systemCode}',viewMode:'ats',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-bsi',routeObj.resolve({ngUrl:'/genericelementsmanage-bsi',ngParamsName:'/{systemCode}',viewMode:'bsi',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-ers',routeObj.resolve({ngUrl:'/genericelementsmanage-ers',ngParamsName:'/{systemCode}',viewMode:'ers',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-hrs',routeObj.resolve({ngUrl:'/genericelementsmanage-hrs',ngParamsName:'/{systemCode}',viewMode:'hrs',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-mpl',routeObj.resolve({ngUrl:'/genericelementsmanage-mpl',ngParamsName:'/{systemCode}',viewMode:'mpl',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-sam',routeObj.resolve({ngUrl:'/genericelementsmanage-sam',ngParamsName:'/{systemCode}',viewMode:'sam',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericelementsmanage-srm',routeObj.resolve({ngUrl:'/genericelementsmanage-srm',ngParamsName:'/{systemCode}',viewMode:'srm',baseName:'rds/genericelements/genericElementsManage'}))
				.state('app.nav.genericlistmodal',routeObj.resolve({ngUrl:'/genericlistmodal',ngParamsName:'/{parentParams}',baseName:'rds/common/genericListModal'}))
				.state('app.nav.hierarchieslist-ats',routeObj.resolve({ngUrl:'/hierarchieslist-ats',ngParamsName:'/{appStoreCode}',viewMode:'ats',baseName:'rds/hrbuckets/hierarchiesList'}))
				.state('app.nav.hierarchydetail-ats',routeObj.resolve({ngUrl:'/hierarchydetail-ats',ngParamsName:'/{hrHierarchyId}/{appStoreCode}',viewMode:'ats',baseName:'rds/hrbuckets/hierarchyDetail'}))
				.state('app.nav.hrbucketsmodal',routeObj.resolve({ngUrl:'/hrbucketsmodal',ngParamsName:'/{parentParams}',baseName:'rds/common/hrBucketsModal'}))
				.state('app.nav.integrationdefinitionslist-inexternaltemplate',routeObj.resolve({ngUrl:'/integrationdefinitionslist-inexternaltemplate',ngParamsName:'/{viewKind}/{integrationDefinitionType}/{integrationTemplateFormat}/{useTemplateDefinition}/{appStoreCode}',viewMode:'inexternaltemplate',baseName:'rds/integrations/integrationDefinitionsList'}))
				.state('app.nav.integrationdefinitionslist-out',routeObj.resolve({ngUrl:'/integrationdefinitionslist-out',ngParamsName:'/{viewKind}/{integrationDefinitionType}/{integrationTemplateFormat}/{useTemplateDefinition}/{appStoreCode}',viewMode:'out',baseName:'rds/integrations/integrationDefinitionsList'}))
				.state('app.nav.integrationdefinitionslist-outexcel',routeObj.resolve({ngUrl:'/integrationdefinitionslist-outexcel',ngParamsName:'/{viewKind}/{integrationDefinitionType}/{integrationTemplateFormat}/{useTemplateDefinition}/{appStoreCode}',viewMode:'outexcel',baseName:'rds/integrations/integrationDefinitionsList'}))
				.state('app.nav.integrationdefinitionslist-outjson',routeObj.resolve({ngUrl:'/integrationdefinitionslist-outjson',ngParamsName:'/{viewKind}/{integrationDefinitionType}/{integrationTemplateFormat}/{useTemplateDefinition}/{appStoreCode}',viewMode:'outjson',baseName:'rds/integrations/integrationDefinitionsList'}))
				.state('app.nav.integrationfileobserverslist',routeObj.resolve({ngUrl:'/integrationfileobserverslist',baseName:'rds/integrations/integrationFileObserversList'}))
				.state('app.nav.integrationrequestdetail',routeObj.resolve({ngUrl:'/integrationrequestdetail',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestdetail-in',routeObj.resolve({ngUrl:'/integrationrequestdetail-in',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',viewMode:'in',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestdetail-inexternaltemplate',routeObj.resolve({ngUrl:'/integrationrequestdetail-inexternaltemplate',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',viewMode:'inexternaltemplate',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestdetail-out',routeObj.resolve({ngUrl:'/integrationrequestdetail-out',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',viewMode:'out',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestdetail-outexcel',routeObj.resolve({ngUrl:'/integrationrequestdetail-outexcel',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',viewMode:'outexcel',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestdetail-outjson',routeObj.resolve({ngUrl:'/integrationrequestdetail-outjson',ngParamsName:'/{viewKind}/{integrationDefinitionId}/{integrationRequestId}/{useTemplateDefinition}',viewMode:'outjson',baseName:'rds/integrations/integrationRequestDetail'}))
				.state('app.nav.integrationrequestslist',routeObj.resolve({ngUrl:'/integrationrequestslist',baseName:'rds/integrations/integrationRequestsList'}))
				.state('app.nav.jobrequestcalendar-mpl',routeObj.resolve({ngUrl:'/jobrequestcalendar-mpl',viewMode:'mpl',baseName:'sfl/jobrequests/jobRequestCalendar'}))
				.state('app.nav.jobrequestchangestartdate',routeObj.resolve({ngUrl:'/jobrequestchangestartdate',ngParamsName:'/{parentParams}',baseName:'sfl/sflcommon/jobRequestChangeStartDate'}))
				.state('app.nav.jobrequestslist-mpl',routeObj.resolve({ngUrl:'/jobrequestslist-mpl',ngParamsName:'/{appStoreCode}/{jobRequestLifeStatusId}',viewMode:'mpl',baseName:'sfl/jobrequests/jobRequestsList'}))
				.state('app.nav.jobsmanage',routeObj.resolve({ngUrl:'/jobsmanage',baseName:'rds/jobs/jobsManage'}))
				.state('app.nav.masterplandetail',routeObj.resolve({ngUrl:'/masterplandetail',ngParamsName:'/{appStoreCode}/{masterPlanId}',baseName:'sfl/plannings/masterPlanDetail'}))
				.state('app.nav.masterplanslist',routeObj.resolve({ngUrl:'/masterplanslist',ngParamsName:'/{appStoreCode}',baseName:'sfl/plannings/masterPlansList'}))
				.state('app.nav.metricinstancedetail',routeObj.resolve({ngUrl:'/metricinstancedetail',ngParamsName:'/{metricInstanceId}/{appStoreCode}',baseName:'rds/metrics/metricInstanceDetail'}))
				.state('app.nav.metricinstanceslist',routeObj.resolve({ngUrl:'/metricinstanceslist',ngParamsName:'/{appStoreCode}',baseName:'rds/metrics/metricInstancesList'}))
				.state('app.nav.metrickpidefinitionslist',routeObj.resolve({ngUrl:'/metrickpidefinitionslist',baseName:'rds/metrics/metricKpiDefinitionsList'}))
				.state('app.nav.metricspecializationdetail',routeObj.resolve({ngUrl:'/metricspecializationdetail',ngParamsName:'/{metricSpecializationId}',baseName:'rds/metrics/metricSpecializationDetail'}))
				.state('app.nav.metricspecializationslist',routeObj.resolve({ngUrl:'/metricspecializationslist',baseName:'rds/metrics/metricSpecializationsList'}))
				.state('app.nav.mobileappconfig',routeObj.resolve({ngUrl:'/mobileappconfig',baseName:'rbac/myaccount/mobileAppConfig'}))
				.state('app.nav.myaccount',routeObj.resolve({ngUrl:'/myaccount',baseName:'rbac/myaccount/myAccount'}))
				.state('app.nav.myactboardduedate',routeObj.resolve({ngUrl:'/myactboardduedate',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/myActBoardDueDate'}))
				.state('app.nav.myactboardworkflow',routeObj.resolve({ngUrl:'/myactboardworkflow',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/myActBoardWorkflow'}))
				.state('app.nav.myactslist',routeObj.resolve({ngUrl:'/myactslist',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actTemplateCode}/{actFixedCode}',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/myActsList'}))
				.state('app.nav.myactstream',routeObj.resolve({ngUrl:'/myactstream',ngParamsName:'/{viewKind}/{appStoreCode}/{workflowStatusKindId}/{actFixedCode}',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/myActStream'}))
				.state('app.nav.mynotificationslist',routeObj.resolve({ngUrl:'/mynotificationslist',baseName:'rds/notifications/myNotificationsList'}))
				.state('app.nav.notificationconfigdetail',routeObj.resolve({ngUrl:'/notificationconfigdetail',ngParamsName:'/{notificationConfigId}',baseName:'rds/notifications/notificationConfigDetail'}))
				.state('app.nav.notificationinstancedetail',routeObj.resolve({ngUrl:'/notificationinstancedetail',ngParamsName:'/{notificationInstanceId}',baseName:'rds/notifications/notificationInstanceDetail'}))
				.state('app.nav.notificationinstanceslist',routeObj.resolve({ngUrl:'/notificationinstanceslist',baseName:'rds/notifications/notificationInstancesList'}))
				.state('app.nav.notificationschemadetail',routeObj.resolve({ngUrl:'/notificationschemadetail',ngParamsName:'/{notificationSchemaId}',baseName:'rds/notifications/notificationSchemaDetail'}))
				.state('app.nav.notificationschemaslist',routeObj.resolve({ngUrl:'/notificationschemaslist',ngParamsName:'/{appStoreCode}',baseName:'rds/notifications/notificationSchemasList'}))
				.state('app.nav.notificationslist',routeObj.resolve({ngUrl:'/notificationslist',baseName:'rds/notifications/notificationsList'}))
				.state('app.nav.parameterdetail-act',routeObj.resolve({ngUrl:'/parameterdetail-act',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'act',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-ats',routeObj.resolve({ngUrl:'/parameterdetail-ats',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'ats',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-bsi',routeObj.resolve({ngUrl:'/parameterdetail-bsi',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'bsi',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-ers',routeObj.resolve({ngUrl:'/parameterdetail-ers',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'ers',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-hrs',routeObj.resolve({ngUrl:'/parameterdetail-hrs',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'hrs',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-mpl',routeObj.resolve({ngUrl:'/parameterdetail-mpl',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'mpl',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-sam',routeObj.resolve({ngUrl:'/parameterdetail-sam',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'sam',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterdetail-srm',routeObj.resolve({ngUrl:'/parameterdetail-srm',ngParamsName:'/{appStoreCode}/{variableCode}',viewMode:'srm',baseName:'rds/parameters/parameterDetail'}))
				.state('app.nav.parameterslist-act',routeObj.resolve({ngUrl:'/parameterslist-act',ngParamsName:'/{appStoreCode}',viewMode:'act',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-ats',routeObj.resolve({ngUrl:'/parameterslist-ats',ngParamsName:'/{appStoreCode}',viewMode:'ats',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-bsi',routeObj.resolve({ngUrl:'/parameterslist-bsi',ngParamsName:'/{appStoreCode}',viewMode:'bsi',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-ers',routeObj.resolve({ngUrl:'/parameterslist-ers',ngParamsName:'/{appStoreCode}',viewMode:'ers',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-hrs',routeObj.resolve({ngUrl:'/parameterslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-mpl',routeObj.resolve({ngUrl:'/parameterslist-mpl',ngParamsName:'/{appStoreCode}',viewMode:'mpl',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-sam',routeObj.resolve({ngUrl:'/parameterslist-sam',ngParamsName:'/{appStoreCode}',viewMode:'sam',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.parameterslist-srm',routeObj.resolve({ngUrl:'/parameterslist-srm',ngParamsName:'/{appStoreCode}',viewMode:'srm',baseName:'rds/parameters/parametersList'}))
				.state('app.nav.renewmytwofactorsecretcode',routeObj.resolve({ngUrl:'/renewmytwofactorsecretcode',baseName:'rbac/myaccount/renewMyTwoFactorSecretCode'}))
				.state('app.nav.reportdefinitiondetail',routeObj.resolve({ngUrl:'/reportdefinitiondetail',ngParamsName:'/{appStoreCode}',baseName:'rds/reports/reportDefinitionDetail'}))
				.state('app.nav.reportdefinitiondetail.activitieslistreportform-bom',routeObj.resolve({ngUrl:'/activitieslistreportform-bom',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'bom',srvParamsValue:'{"viewKind":"Bom"}',baseName:'rds/reportformsrds/activitiesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.activitieslistreportform-bomextended',routeObj.resolve({ngUrl:'/activitieslistreportform-bomextended',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'bomextended',srvParamsValue:'{"viewKind":""}',baseName:'rds/reportformsrds/activitiesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.activitieslistreportform-subbom',routeObj.resolve({ngUrl:'/activitieslistreportform-subbom',regionName:'reportFormRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{reportDefinitionId}',viewMode:'subbom',srvParamsValue:'{"viewKind":"SubBom"}',baseName:'rds/reportformsrds/activitiesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.actsexportreportform-exporta',routeObj.resolve({ngUrl:'/actsexportreportform-exporta',regionName:'reportFormRegion',ngParamsName:'/{exportCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'exporta',srvParamsValue:'{"exportCode":"exporta"}',baseName:'rds/reportformsrds/actsExportReportForm'}))
				.state('app.nav.reportdefinitiondetail.actsexportreportform-exportb',routeObj.resolve({ngUrl:'/actsexportreportform-exportb',regionName:'reportFormRegion',ngParamsName:'/{exportCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'exportb',srvParamsValue:'{"exportCode":"exportb"}',baseName:'rds/reportformsrds/actsExportReportForm'}))
				.state('app.nav.reportdefinitiondetail.actsexportreportform-exportc',routeObj.resolve({ngUrl:'/actsexportreportform-exportc',regionName:'reportFormRegion',ngParamsName:'/{exportCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'exportc',srvParamsValue:'{"exportCode":"exportc"}',baseName:'rds/reportformsrds/actsExportReportForm'}))
				.state('app.nav.reportdefinitiondetail.authauditlistreportform',routeObj.resolve({ngUrl:'/authauditlistreportform',regionName:'reportFormRegion',ngParamsName:'/{reportDefinitionId}',baseName:'rbac/reportformsrbac/authAuditListReportForm'}))
				.state('app.nav.reportdefinitiondetail.categorieslistreportform',routeObj.resolve({ngUrl:'/categorieslistreportform',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{viewDescriptionCheckbox}/{appStoreCode}/{reportDefinitionId}',srvParamsValue:'{"systemCategoryCode":"BSC", "viewDescriptionCheckbox":"false"}',baseName:'rds/reportformsrds/categoriesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.categorieslistreportform-act',routeObj.resolve({ngUrl:'/categorieslistreportform-act',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{viewDescriptionCheckbox}/{appStoreCode}/{reportDefinitionId}',viewMode:'act',srvParamsValue:'{"systemCategoryCode":"ACT", "viewDescriptionCheckbox":"true"}',baseName:'rds/reportformsrds/categoriesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.categorieslistreportform-bscextended',routeObj.resolve({ngUrl:'/categorieslistreportform-bscextended',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{viewDescriptionCheckbox}/{appStoreCode}/{reportDefinitionId}',viewMode:'bscextended',srvParamsValue:'{"systemCategoryCode":"BSC", "viewDescriptionCheckbox":"false"}',baseName:'rds/reportformsrds/categoriesListReportForm'}))
				.state('app.nav.reportdefinitiondetail.categoryqrcodelistreportform-a4_2x4',routeObj.resolve({ngUrl:'/categoryqrcodelistreportform-a4_2x4',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'a4_2x4',srvParamsValue:'{"systemCategoryCode":"BSC"}',baseName:'rds/reportformsrds/categoryQrCodeListReportForm'}))
				.state('app.nav.reportdefinitiondetail.categoryqrcodelistreportform-a4_3x8',routeObj.resolve({ngUrl:'/categoryqrcodelistreportform-a4_3x8',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'a4_3x8',srvParamsValue:'{"systemCategoryCode":"BSC"}',baseName:'rds/reportformsrds/categoryQrCodeListReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhreventslistpricedreportform-executionsummarypriced_s2',routeObj.resolve({ngUrl:'/vhreventslistpricedreportform-executionsummarypriced_s2',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}/{catPathParamSeparator}/{catPathParamTake}/{catPathParamSkip}',viewMode:'executionsummarypriced_s2',srvParamsValue:'{"systemCategoryCode":"ALB_IMP", "catPathParamSkip":"2", "isGroupedBy":"true", "groupedBy":"activityexternalcode" }',baseName:'rds/reportformsrds/vhrEventsListPricedReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhreventslistpricedreportform-executionsummarypriced_s2_theorical',routeObj.resolve({ngUrl:'/vhreventslistpricedreportform-executionsummarypriced_s2_theorical',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}/{catPathParamSeparator}/{catPathParamTake}/{catPathParamSkip}',viewMode:'executionsummarypriced_s2_theorical',srvParamsValue:'{"systemCategoryCode":"ALB_IMP", "catPathParamSkip":"2", "isGroupedBy":"true", "groupedBy":"activityexternalcode" }',baseName:'rds/reportformsrds/vhrEventsListPricedReportForm'}))
				.state('app.nav.reportdefinitiondetail.vhrtargetslistreportform-vhrtargets',routeObj.resolve({ngUrl:'/vhrtargetslistreportform-vhrtargets',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}/{isGroupedBy}/{groupedBy}/{catPathParamSeparator}/{catPathParamTake}/{catPathParamSkip}',viewMode:'vhrtargets',srvParamsValue:'{"systemCategoryCode":"ACT" }',baseName:'rds/reportformsrds/vhrTargetsListReportForm'}))
				.state('app.nav.reportdefinitiondetail.virtualequipmentqrcodelistreportform-a4_2x4',routeObj.resolve({ngUrl:'/virtualequipmentqrcodelistreportform-a4_2x4',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'a4_2x4',baseName:'rds/reportformsrds/virtualEquipmentQrCodeListReportForm'}))
				.state('app.nav.reportdefinitiondetail.virtualequipmentqrcodelistreportform-a4_3x8',routeObj.resolve({ngUrl:'/virtualequipmentqrcodelistreportform-a4_3x8',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}',viewMode:'a4_3x8',baseName:'rds/reportformsrds/virtualEquipmentQrCodeListReportForm'}))
				.state('app.nav.reportdefinitiondetail.virtualequipmentslistreportform',routeObj.resolve({ngUrl:'/virtualequipmentslistreportform',regionName:'reportFormRegion',ngParamsName:'/{systemCategoryCode}/{appStoreCode}/{reportDefinitionId}',baseName:'rds/reportformsrds/virtualEquipmentsListReportForm'}))
				.state('app.nav.reportdefinitionhtmltodocdetail',routeObj.resolve({ngUrl:'/reportdefinitionhtmltodocdetail',ngParamsName:'/{appStoreCode}',baseName:'rds/reports/reportDefinitionHtmlToDocDetail'}))
				.state('app.nav.reportdefinitionsforviewlist',routeObj.resolve({ngUrl:'/reportdefinitionsforviewlist',ngParamsName:'/{cmsViewCodeReport}',baseName:'rds/reports/reportDefinitionsForViewList'}))
				.state('app.nav.reportdefinitionslist-act',routeObj.resolve({ngUrl:'/reportdefinitionslist-act',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'act',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-ats',routeObj.resolve({ngUrl:'/reportdefinitionslist-ats',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'ats',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-bsi',routeObj.resolve({ngUrl:'/reportdefinitionslist-bsi',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'bsi',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-ced',routeObj.resolve({ngUrl:'/reportdefinitionslist-ced',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'ced',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-ers',routeObj.resolve({ngUrl:'/reportdefinitionslist-ers',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'ers',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-hrs',routeObj.resolve({ngUrl:'/reportdefinitionslist-hrs',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'hrs',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-sam',routeObj.resolve({ngUrl:'/reportdefinitionslist-sam',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'sam',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportdefinitionslist-srm',routeObj.resolve({ngUrl:'/reportdefinitionslist-srm',ngParamsName:'/{appStoreCode}/{reportDefinitionCode}',viewMode:'srm',baseName:'rds/reports/reportDefinitionsList'}))
				.state('app.nav.reportentitydefinitiondetail-ats',routeObj.resolve({ngUrl:'/reportentitydefinitiondetail-ats',ngParamsName:'/{reportDefinitionId}',viewMode:'ats',baseName:'rds/reports/reportEntityDefinitionDetail'}))
				.state('app.nav.reportentitydefinitionslist-ats',routeObj.resolve({ngUrl:'/reportentitydefinitionslist-ats',ngParamsName:'/{appStoreCode}',viewMode:'ats',baseName:'rds/reports/reportEntityDefinitionsList'}))
				.state('app.nav.routedetail-hrs',routeObj.resolve({ngUrl:'/routedetail-hrs',ngParamsName:'/{routeId}/{appStoreCode}',viewMode:'hrs',baseName:'rds/productions/routeDetail'}))
				.state('app.nav.routeslist-hrs',routeObj.resolve({ngUrl:'/routeslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/productions/routesList'}))
				.state('app.nav.scheduledtaskdetail',routeObj.resolve({ngUrl:'/scheduledtaskdetail',ngParamsName:'/{scheduledTaskId}',baseName:'rds/jobs/scheduledTaskDetail'}))
				.state('app.nav.scheduledtaskslist',routeObj.resolve({ngUrl:'/scheduledtaskslist',ngParamsName:'/{targetClassFullName}/{targetEntityId}/{viewFormSummary}',baseName:'rds/jobs/scheduledTasksList'}))
				.state('app.nav.scheduledtaskstepdetail',routeObj.resolve({ngUrl:'/scheduledtaskstepdetail',ngParamsName:'/{appStoreCode}',baseName:'rds/jobs/scheduledTaskStepDetail'}))
				.state('app.nav.scheduledtaskstepdetail-bidatarequest',routeObj.resolve({ngUrl:'/scheduledtaskstepdetail-bidatarequest',ngParamsName:'/{appStoreCode}',viewMode:'bidatarequest',baseName:'rds/jobs/scheduledTaskStepDetail'}))
				.state('app.nav.scheduledtaskstepdetail-execprocessrequest',routeObj.resolve({ngUrl:'/scheduledtaskstepdetail-execprocessrequest',ngParamsName:'/{appStoreCode}',viewMode:'execprocessrequest',baseName:'rds/jobs/scheduledTaskStepDetail'}))
				.state('app.nav.scheduledtaskstepdetail-fileshufflerequest',routeObj.resolve({ngUrl:'/scheduledtaskstepdetail-fileshufflerequest',ngParamsName:'/{appStoreCode}',viewMode:'fileshufflerequest',baseName:'rds/jobs/scheduledTaskStepDetail'}))
				.state('app.nav.scheduledtaskstepdetail-schedulingrequest',routeObj.resolve({ngUrl:'/scheduledtaskstepdetail-schedulingrequest',ngParamsName:'/{appStoreCode}',viewMode:'schedulingrequest',baseName:'rds/jobs/scheduledTaskStepDetail'}))
				.state('app.nav.scheduledtaskstepsummary',routeObj.resolve({ngUrl:'/scheduledtaskstepsummary',ngParamsName:'/{appStoreCode}',baseName:'rds/jobs/scheduledTaskStepSummary'}))
				.state('app.nav.scheduledtaskstepsummary-bidatarequest',routeObj.resolve({ngUrl:'/scheduledtaskstepsummary-bidatarequest',ngParamsName:'/{appStoreCode}',viewMode:'bidatarequest',baseName:'rds/jobs/scheduledTaskStepSummary'}))
				.state('app.nav.scheduledtaskstepsummary-execprocessrequest',routeObj.resolve({ngUrl:'/scheduledtaskstepsummary-execprocessrequest',ngParamsName:'/{appStoreCode}',viewMode:'execprocessrequest',baseName:'rds/jobs/scheduledTaskStepSummary'}))
				.state('app.nav.scheduledtaskstepsummary-fileshufflerequest',routeObj.resolve({ngUrl:'/scheduledtaskstepsummary-fileshufflerequest',ngParamsName:'/{appStoreCode}',viewMode:'fileshufflerequest',baseName:'rds/jobs/scheduledTaskStepSummary'}))
				.state('app.nav.scheduledtaskstepsummary-schedulingrequest',routeObj.resolve({ngUrl:'/scheduledtaskstepsummary-schedulingrequest',ngParamsName:'/{appStoreCode}',viewMode:'schedulingrequest',baseName:'rds/jobs/scheduledTaskStepSummary'}))
				.state('app.nav.schedulingftc',routeObj.resolve({ngUrl:'/schedulingftc',ngParamsName:'/{appStoreCode}/{schedulingId}/{targetClassFullName}/{targetEntityId}',baseName:'sfl/plannings/schedulingFtc'}))
				.state('app.nav.schedulingftc.scheduledtaskslist-ftcview',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{targetClassFullName}/{targetEntityId}/{viewFormSummary}',viewMode:'ftcview',baseName:'rds/jobs/scheduledTasksList'}))
				.state('app.nav.fileshuffledefinitionftc.scheduledtaskslist-ftcview',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{targetClassFullName}/{targetEntityId}/{viewFormSummary}',viewMode:'ftcview',baseName:'rds/jobs/scheduledTasksList'}))
				.state('app.nav.schedulingftc.schedulingdetail',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{schedulingId}',baseName:'sfl/plannings/schedulingDetail'}))
				.state('app.nav.schedulingrequestslist',routeObj.resolve({ngUrl:'/schedulingrequestslist',baseName:'sfl/plannings/schedulingRequestsList'}))
				.state('app.nav.schedulingslist',routeObj.resolve({ngUrl:'/schedulingslist',ngParamsName:'/{appStoreCode}',baseName:'sfl/plannings/schedulingsList'}))
				.state('app.nav.sendnotificationmodal',routeObj.resolve({ngUrl:'/sendnotificationmodal',ngParamsName:'/{parentParams}',baseName:'rds/common/sendNotificationModal'}))
				.state('app.nav.targetftc',routeObj.resolve({ngUrl:'/targetftc',ngParamsName:'/{virtualHumanResourceId}/{appStoreCodeFtc}',baseName:'rds/targets/targetFtc'}))
				.state('app.nav.targetftc.actsvhrlist-all',routeObj.resolve({ngUrl:'/actsvhrlist-all',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.assetftc.actsvhrlist-all',routeObj.resolve({ngUrl:'/actsvhrlist-all',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.treassetftc.actsvhrlist-all',routeObj.resolve({ngUrl:'/actsvhrlist-all',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.bucketftc.actsvhrlist-all',routeObj.resolve({ngUrl:'/actsvhrlist-all',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'all',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.targetftc.actsvhrlist-executed',routeObj.resolve({ngUrl:'/actsvhrlist-executed',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.assetftc.actsvhrlist-executed',routeObj.resolve({ngUrl:'/actsvhrlist-executed',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.treassetftc.actsvhrlist-executed',routeObj.resolve({ngUrl:'/actsvhrlist-executed',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.bucketftc.actsvhrlist-executed',routeObj.resolve({ngUrl:'/actsvhrlist-executed',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'executed',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.targetftc.actsvhrlist-myall',routeObj.resolve({ngUrl:'/actsvhrlist-myall',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'myall',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.assetftc.actsvhrlist-myall',routeObj.resolve({ngUrl:'/actsvhrlist-myall',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'myall',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.treassetftc.actsvhrlist-myall',routeObj.resolve({ngUrl:'/actsvhrlist-myall',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'myall',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.bucketftc.actsvhrlist-myall',routeObj.resolve({ngUrl:'/actsvhrlist-myall',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'myall',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.targetftc.actsvhrlist-toexecute',routeObj.resolve({ngUrl:'/actsvhrlist-toexecute',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.assetftc.actsvhrlist-toexecute',routeObj.resolve({ngUrl:'/actsvhrlist-toexecute',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.treassetftc.actsvhrlist-toexecute',routeObj.resolve({ngUrl:'/actsvhrlist-toexecute',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.bucketftc.actsvhrlist-toexecute',routeObj.resolve({ngUrl:'/actsvhrlist-toexecute',regionName:'ftcRegion',ngParamsName:'/{viewKind}/{appStoreCode}/{actTemplateCode}/{actFixedCode}',viewMode:'toexecute',srvParamsValue:'{"actFixedCode": null}',baseName:'rds/acts/actsVhrList'}))
				.state('app.nav.targetftc.targetdetail',routeObj.resolve({ngUrl:'/targetdetail',regionName:'ftcRegion',ngParamsName:'/{virtualHumanResourceId}/{appStoreCode}',baseName:'rds/targets/targetDetail'}))
				.state('app.nav.targetftc.vhrtocategoriesdetail-target',routeObj.resolve({ngUrl:'/vhrtocategoriesdetail-target',regionName:'ftcRegion',ngParamsName:'/{appStoreCode}/{hrMetadataId}/{virtualHumanResourceId}/{viewKind}/{systemCategoryCode}',viewMode:'target',baseName:'rds/virtualhumanresources/vhrToCategoriesDetail'}))
				.state('app.nav.targetslist',routeObj.resolve({ngUrl:'/targetslist',ngParamsName:'/{appStoreCode}',baseName:'rds/targets/targetsList'}))
				.state('app.nav.targetssearch',routeObj.resolve({ngUrl:'/targetssearch',ngParamsName:'/{appStoreCode}',baseName:'rds/targets/targetsSearch'}))
				.state('app.nav.triggerfiredslist',routeObj.resolve({ngUrl:'/triggerfiredslist',ngParamsName:'/{appStoreCode}',baseName:'rds/triggers/triggerFiredsList'}))
				.state('app.nav.triggerinstancedetail',routeObj.resolve({ngUrl:'/triggerinstancedetail',ngParamsName:'/{triggerInstanceId}/{appStoreCode}',baseName:'rds/triggers/triggerInstanceDetail'}))
				.state('app.nav.triggerinstancematchdetail',routeObj.resolve({ngUrl:'/triggerinstancematchdetail',ngParamsName:'/{parentParams}',baseName:'rds/triggers/triggerInstanceMatchDetail'}))
				.state('app.nav.triggerinstanceslist',routeObj.resolve({ngUrl:'/triggerinstanceslist',ngParamsName:'/{appStoreCode}',baseName:'rds/triggers/triggerInstancesList'}))
				.state('app.nav.userprofiledetail',routeObj.resolve({ngUrl:'/userprofiledetail',ngParamsName:'/{userProfileId}/{viewKind}/{appStoreCode}',baseName:'rbac/profiles/userProfileDetail'}))
				.state('app.nav.userprofileslist',routeObj.resolve({ngUrl:'/userprofileslist',ngParamsName:'/{viewKind}/{appStoreCode}',baseName:'rbac/profiles/userProfilesList'}))
				.state('app.nav.validationschemainstancedetail',routeObj.resolve({ngUrl:'/validationschemainstancedetail',ngParamsName:'/{validationSchemaInstanceId}',baseName:'rds/validationschemas/validationSchemaInstanceDetail'}))
				.state('app.nav.validationschemainstanceslist',routeObj.resolve({ngUrl:'/validationschemainstanceslist',baseName:'rds/validationschemas/validationSchemaInstancesList'}))
				.state('app.nav.vhreventchangeapproval-authorize',routeObj.resolve({ngUrl:'/vhreventchangeapproval-authorize',ngParamsName:'/{parentParams}',viewMode:'authorize',baseName:'rds/common/vhrEventChangeApproval'}))
				.state('app.nav.vhreventchangeapproval-history',routeObj.resolve({ngUrl:'/vhreventchangeapproval-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/common/vhrEventChangeApproval'}))
				.state('app.nav.vhreventchangeapproval-modify',routeObj.resolve({ngUrl:'/vhreventchangeapproval-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/common/vhrEventChangeApproval'}))
				.state('app.nav.vhreventchangeassignment-execute',routeObj.resolve({ngUrl:'/vhreventchangeassignment-execute',ngParamsName:'/{parentParams}',viewMode:'execute',baseName:'rds/common/vhrEventChangeAssignment'}))
				.state('app.nav.vhreventchangeassignment-history',routeObj.resolve({ngUrl:'/vhreventchangeassignment-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/common/vhrEventChangeAssignment'}))
				.state('app.nav.vhreventchangeassignment-modify',routeObj.resolve({ngUrl:'/vhreventchangeassignment-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/common/vhrEventChangeAssignment'}))
				.state('app.nav.vhreventchangecustomerapproval-authorize',routeObj.resolve({ngUrl:'/vhreventchangecustomerapproval-authorize',ngParamsName:'/{parentParams}',viewMode:'authorize',baseName:'rds/common/vhrEventChangeCustomerApproval'}))
				.state('app.nav.vhreventchangecustomerapproval-history',routeObj.resolve({ngUrl:'/vhreventchangecustomerapproval-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/common/vhrEventChangeCustomerApproval'}))
				.state('app.nav.vhreventchangecustomerapproval-modify',routeObj.resolve({ngUrl:'/vhreventchangecustomerapproval-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/common/vhrEventChangeCustomerApproval'}))
				.state('app.nav.vhreventchangeexecution-execute',routeObj.resolve({ngUrl:'/vhreventchangeexecution-execute',ngParamsName:'/{parentParams}',viewMode:'execute',baseName:'rds/common/vhrEventChangeExecution'}))
				.state('app.nav.vhreventchangeexecution-history',routeObj.resolve({ngUrl:'/vhreventchangeexecution-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/common/vhrEventChangeExecution'}))
				.state('app.nav.vhreventchangeexecution-modify',routeObj.resolve({ngUrl:'/vhreventchangeexecution-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/common/vhrEventChangeExecution'}))
				.state('app.nav.vhreventchangestartdate',routeObj.resolve({ngUrl:'/vhreventchangestartdate',ngParamsName:'/{parentParams}',baseName:'rds/common/vhrEventChangeStartDate'}))
				.state('app.nav.vhreventgroupdetail-sra',routeObj.resolve({ngUrl:'/vhreventgroupdetail-sra',ngParamsName:'/{appStoreCode}/{vhrEventGroupId}',viewMode:'sra',baseName:'rds/vhrevents/vhrEventGroupDetail'}))
				.state('app.nav.vhreventgroupslist-hrs',routeObj.resolve({ngUrl:'/vhreventgroupslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventGroupsList'}))
				.state('app.nav.vhreventmanualrequestdetail',routeObj.resolve({ngUrl:'/vhreventmanualrequestdetail',ngParamsName:'/{appStoreCode}/{vhrEventManualRequestId}',baseName:'rds/vhreventsmanual/vhrEventManualRequestDetail'}))
				.state('app.nav.vhreventmanualrequestslist',routeObj.resolve({ngUrl:'/vhreventmanualrequestslist',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventManualRequestsList'}))
				.state('app.nav.vhreventsboardapproval-hrs',routeObj.resolve({ngUrl:'/vhreventsboardapproval-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{filter}/{vhrEventLifeStatusId}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsBoardApproval'}))
				.state('app.nav.vhreventsboardduedate-hrs',routeObj.resolve({ngUrl:'/vhreventsboardduedate-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{filter}/{vhrEventLifeStatusId}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsBoardDueDate'}))
				.state('app.nav.vhreventsboardlifestatus-hrs',routeObj.resolve({ngUrl:'/vhreventsboardlifestatus-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{filter}/{vhrEventLifeStatusId}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsBoardLifeStatus'}))
				.state('app.nav.vhreventscalendar-hrs',routeObj.resolve({ngUrl:'/vhreventscalendar-hrs',ngParamsName:'/{appStoreCode}/{wzVhrEntityType}/{vhrEventTypeId}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsCalendar'}))
				.state('app.nav.vhreventscalendar-hrstimeline',routeObj.resolve({ngUrl:'/vhreventscalendar-hrstimeline',ngParamsName:'/{appStoreCode}/{wzVhrEntityType}/{vhrEventTypeId}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewType}',viewMode:'hrstimeline',baseName:'rds/vhrevents/vhrEventsCalendar'}))
				.state('app.nav.vhreventslist-hrs',routeObj.resolve({ngUrl:'/vhreventslist-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{filter}/{vhrEventLifeStatusId}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsList'}))
				.state('app.nav.vhreventsmanualstepactivityselection',routeObj.resolve({ngUrl:'/vhreventsmanualstepactivityselection',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepActivitySelection'}))
				.state('app.nav.vhreventsmanualstepeventdetail',routeObj.resolve({ngUrl:'/vhreventsmanualstepeventdetail',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepEventDetail'}))
				.state('app.nav.vhreventsmanualstepsummary',routeObj.resolve({ngUrl:'/vhreventsmanualstepsummary',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepSummary'}))
				.state('app.nav.vhreventsmanualstepswitchfromactivity',routeObj.resolve({ngUrl:'/vhreventsmanualstepswitchfromactivity',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepSwitchFromActivity'}))
				.state('app.nav.vhreventsmanualstepupdateeventdetail',routeObj.resolve({ngUrl:'/vhreventsmanualstepupdateeventdetail',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepUpdateEventDetail'}))
				.state('app.nav.vhreventsmanualstepupdatesummary',routeObj.resolve({ngUrl:'/vhreventsmanualstepupdatesummary',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepUpdateSummary'}))
				.state('app.nav.vhreventsmanualstepupdateswitch',routeObj.resolve({ngUrl:'/vhreventsmanualstepupdateswitch',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepUpdateSwitch'}))
				.state('app.nav.vhreventsmanualstepvhr',routeObj.resolve({ngUrl:'/vhreventsmanualstepvhr',ngParamsName:'/{appStoreCode}',baseName:'rds/vhreventsmanual/vhrEventsManualStepVhr'}))
				.state('app.nav.vhreventssearch-hrs',routeObj.resolve({ngUrl:'/vhreventssearch-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsSearch'}))
				.state('app.nav.vhreventsstream-hrs',routeObj.resolve({ngUrl:'/vhreventsstream-hrs',ngParamsName:'/{appStoreCode}/{vhrEventTypeCode}/{vhrEventFixedTypeCode}/{viewKind}/{filter}/{vhrEventLifeStatusId}/{wzVhrEntityType}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventsStream'}))
				.state('app.nav.vhreventtypedetail-sra',routeObj.resolve({ngUrl:'/vhreventtypedetail-sra',ngParamsName:'/{appStoreCode}/{vhrEventTypeId}',viewMode:'sra',baseName:'rds/vhrevents/vhrEventTypeDetail'}))
				.state('app.nav.vhreventtypeslist-hrs',routeObj.resolve({ngUrl:'/vhreventtypeslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/vhrevents/vhrEventTypesList'}))
				.state('app.nav.vhreventworkflowchangestatusdetail-history',routeObj.resolve({ngUrl:'/vhreventworkflowchangestatusdetail-history',ngParamsName:'/{parentParams}',viewMode:'history',baseName:'rds/vhrevents/vhrEventWorkflowChangeStatusDetail'}))
				.state('app.nav.vhreventworkflowchangestatusdetail-modify',routeObj.resolve({ngUrl:'/vhreventworkflowchangestatusdetail-modify',ngParamsName:'/{parentParams}',viewMode:'modify',baseName:'rds/vhrevents/vhrEventWorkflowChangeStatusDetail'}))
				.state('app.nav.vhrgroupdetail-ats',routeObj.resolve({ngUrl:'/vhrgroupdetail-ats',ngParamsName:'/{appStoreCode}/{vhrGroupId}',viewMode:'ats',baseName:'rds/virtualhumanresources/vhrGroupDetail'}))
				.state('app.nav.vhrgroupdetail-ers',routeObj.resolve({ngUrl:'/vhrgroupdetail-ers',ngParamsName:'/{appStoreCode}/{vhrGroupId}',viewMode:'ers',baseName:'rds/virtualhumanresources/vhrGroupDetail'}))
				.state('app.nav.vhrgroupdetail-hrs',routeObj.resolve({ngUrl:'/vhrgroupdetail-hrs',ngParamsName:'/{appStoreCode}/{vhrGroupId}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrGroupDetail'}))
				.state('app.nav.vhrgroupslist-ats',routeObj.resolve({ngUrl:'/vhrgroupslist-ats',ngParamsName:'/{appStoreCode}',viewMode:'ats',baseName:'rds/virtualhumanresources/vhrGroupsList'}))
				.state('app.nav.vhrgroupslist-ers',routeObj.resolve({ngUrl:'/vhrgroupslist-ers',ngParamsName:'/{appStoreCode}',viewMode:'ers',baseName:'rds/virtualhumanresources/vhrGroupsList'}))
				.state('app.nav.vhrgroupslist-hrs',routeObj.resolve({ngUrl:'/vhrgroupslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrGroupsList'}))
				.state('app.nav.vhrselectionmodal',routeObj.resolve({ngUrl:'/vhrselectionmodal',ngParamsName:'/{parentParams}',baseName:'rds/common/vhrSelectionModal'}))
				.state('app.nav.vhrtypedetail-ats',routeObj.resolve({ngUrl:'/vhrtypedetail-ats',ngParamsName:'/{appStoreCode}/{vhrTypeId}',viewMode:'ats',baseName:'rds/virtualhumanresources/vhrTypeDetail'}))
				.state('app.nav.vhrtypedetail-ers',routeObj.resolve({ngUrl:'/vhrtypedetail-ers',ngParamsName:'/{appStoreCode}/{vhrTypeId}',viewMode:'ers',baseName:'rds/virtualhumanresources/vhrTypeDetail'}))
				.state('app.nav.vhrtypedetail-hrs',routeObj.resolve({ngUrl:'/vhrtypedetail-hrs',ngParamsName:'/{appStoreCode}/{vhrTypeId}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrTypeDetail'}))
				.state('app.nav.vhrtypeslist-ats',routeObj.resolve({ngUrl:'/vhrtypeslist-ats',ngParamsName:'/{appStoreCode}',viewMode:'ats',baseName:'rds/virtualhumanresources/vhrTypesList'}))
				.state('app.nav.vhrtypeslist-ers',routeObj.resolve({ngUrl:'/vhrtypeslist-ers',ngParamsName:'/{appStoreCode}',viewMode:'ers',baseName:'rds/virtualhumanresources/vhrTypesList'}))
				.state('app.nav.vhrtypeslist-hrs',routeObj.resolve({ngUrl:'/vhrtypeslist-hrs',ngParamsName:'/{appStoreCode}',viewMode:'hrs',baseName:'rds/virtualhumanresources/vhrTypesList'}))
				.state('app.nav.viewpageelementdetail',routeObj.resolve({ngUrl:'/viewpageelementdetail',ngParamsName:'/{viewPageElementId}',baseName:'rds/cmsdocs/viewPageElementDetail'}))
				.state('app.nav.viewpageelementslist',routeObj.resolve({ngUrl:'/viewpageelementslist',ngParamsName:'/{cmsViewCode}',baseName:'rds/cmsdocs/viewPageElementsList'}))
				.state('app.nav.virtualcompanieslist',routeObj.resolve({ngUrl:'/virtualcompanieslist',baseName:'rds/virtualcompanies/virtualCompaniesList'}))
				.state('app.nav.virtualcompanyftc',routeObj.resolve({ngUrl:'/virtualcompanyftc',ngParamsName:'/{virtualCompanyId}',baseName:'rds/virtualcompanies/virtualCompanyFtc'}))
				.state('app.nav.virtualcompanyftc.virtualcompanydetail',routeObj.resolve({ngUrl:'',regionName:'ftcRegion',ngParamsName:'/{virtualCompanyId}',baseName:'rds/virtualcompanies/virtualCompanyDetail'}))
				.state('app.nav.weektemplatedetail',routeObj.resolve({ngUrl:'/weektemplatedetail',ngParamsName:'/{wWeekTemplateId}',baseName:'rds/productions/weekTemplateDetail'}))
				.state('app.nav.weektemplateslist',routeObj.resolve({ngUrl:'/weektemplateslist',baseName:'rds/productions/weekTemplatesList'}))
				.state('app.nav.workcenterescapedetail-mpl',routeObj.resolve({ngUrl:'/workcenterescapedetail-mpl',ngParamsName:'/{appStoreCode}/{virtualWorkCenterEscapeId}',viewMode:'mpl',baseName:'rds/parameters/workCenterEscapeDetail'}))
				.state('app.nav.workcenterescapeslist-mpl',routeObj.resolve({ngUrl:'/workcenterescapeslist-mpl',ngParamsName:'/{appStoreCode}',viewMode:'mpl',baseName:'rds/parameters/workCenterEscapesList'}))
				.state('app.nav.workflowdetail-ats',routeObj.resolve({ngUrl:'/workflowdetail-ats',ngParamsName:'/{workflowId}',viewMode:'ats',baseName:'rds/workflows/workflowDetail'}))
				.state('app.nav.workflowslist-ats',routeObj.resolve({ngUrl:'/workflowslist-ats',viewMode:'ats',baseName:'rds/workflows/workflowsList'}))
				.state('app.nav.wzaddactivitystepactivity',routeObj.resolve({ngUrl:'/wzaddactivitystepactivity',ngParamsName:'/{appStoreCode}',baseName:'rds/activities/wzAddActivityStepActivity'}))
				.state('app.nav.wzaddactivitystepinfo',routeObj.resolve({ngUrl:'/wzaddactivitystepinfo',ngParamsName:'/{appStoreCode}',baseName:'rds/activities/wzAddActivityStepInfo'}))
				.state('app.nav.wzaddactivitystepsummary',routeObj.resolve({ngUrl:'/wzaddactivitystepsummary',ngParamsName:'/{appStoreCode}',baseName:'rds/activities/wzAddActivityStepSummary'}))
			;
			// [END-STATEPROVIDER-PLACEHOLDER]


            //manual routes not remove please
			

            $stateProvider.state('app.nav.cmsstaticcontentdata.attachmentdetail', routeObj.resolve({
                ngUrl: '/cmsstaticcontentattachmentdetail',
                regionName: 'cmsStaticContentRegion',
                //services: 'foundation,rds/services/util',
                ignoreHistory: true,
                ngParamsName: '/{appStoreCode}/{fsFolderId}/{viewKind}/{viewKindFmd}/{viewCompleteFileUrl}',
                baseName: 'rds/common/attachmentDetail'
            }));
        }]);

    return rdsmodule;
});
