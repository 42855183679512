﻿'use strict';

define(['../rds-module'], function (rdsmodule) {

    var injectParams = ['$scope', '$http', '$location', '$uibModal', '$q', 'localStorageService', 'authenticationService', 'publicService', 'bootService', 'navigationService'];

    var login = function ($scope, $http, $location, $uibModal, $q, localStorageService, authenticationService, publicService, bootService, navigationService) {
        var vm = this;

        //if (authenticationService.user && authenticationService.user.isAuthenticated) {
        //    authenticationService.redirectToRootLocation();
        //}

        vm.validationError = false;
        vm.passwordIsHide = true;

        vm.localFormVisible = false;
        vm.manualRedirectIsVisible = false;

        vm.whoIam = function () {
            return 'login';
        };

        $scope.data = {
            username: "",
            password: "",
            remember: false
        };
        vm.loginError = false;

        publicService.getSsoConfig()
            .then(ssoConfig => {
                if (ssoConfig.SsoEnabled
                    && !ssoConfig.IsTestMode
                    && ssoConfig.AuthenticationFlow
                    && ssoConfig.AuthenticationFlow.toLowerCase() === 'redirect') {

                    vm.manualIdp();
                } else {
                    vm.localFormVisible = true;


                    if (ssoConfig.SsoEnabled
                        //&& ssoConfig.IsTestMode
                        && ssoConfig.AuthenticationFlow
                        && (ssoConfig.AuthenticationFlow.toLowerCase() === 'idpselection'
                            || ssoConfig.AuthenticationFlow.toLowerCase() === 'redirect')) {

                        vm.manualRedirectIsVisible = true;

                        if (ssoConfig.AuthenticationFlowImage) {
                            vm.ssoLogo = '/Images/Sso/sso-' + ssoConfig.AuthenticationFlowImage + '-logo.png';
                        } else {
                            vm.ssoLogo = '/Images/Sso/sso-default-logo.png';
                        }
                    }
                }
            });

        vm.manualIdp = function () {
            authenticationService.redirectToSsoRequest();
        };

        vm.toggleShowPassword = function (arg) {
            vm.passwordIsHide = !vm.passwordIsHide;
        };

        vm.submitData = function (loginData) {
            publicService.getSsoConfig()
                .then((ssoConfig) => {
                    authenticationService.login(loginData, ssoConfig)
                        .then(function (user) {
                            if (user.twoFactorAuthenticationEnabled
                                && user.isVerified
                                && !user.isAuthenticated) {
                                authenticationService.redirectToStepTwo();
                            }
                            else {
                                bootService.checkContext(true)
                                    .then(function (result) {
                                        vm.loginError = false;
                                        redirToApp(user);
                                    })
                                    .catch(function (error) {
                                        vm.loginError = true;
                                    });
                            }
                        })
                        .catch(function () {
                            vm.loginError = true;
                        });
                })
                .catch(function () {
                    vm.loginError = true;
                });
        };

        var redirToApp = function (user) {
            if (user.twoFactorAuthenticationEnabled) {
                authenticationService.redirectToStepTwo();
            }
            else {
                navigationService.navAfterLogin();
            }
        };

        //labels
        vm.auth_recover_password = $TP('auth_recover_password');
        vm.auth_configure_app = $TP('auth_configure_app');
        vm.auth_sso_continue_with = $TP('auth_sso_continue_with');
        vm.auth_rememberme = $TP('auth_rememberme');
        vm.auth_loginbutton = $TP('auth_loginbutton');
        vm.auth_errorlogin_desc = $TP('auth_errorlogin_desc');
        vm.auth_modal_recover_password = $TP('auth_modal_recover_password');
        vm.auth_modal_recover_password_desc = $TP('auth_modal_recover_password_desc');
        vm.auth_modal_refreshcaptcha = $TP('auth_modal_refreshcaptcha');
        vm.auth_modal_captchatext = $TP('auth_modal_captchatext');
        vm.auth_modal_recover_password_sent = $TP('auth_modal_recover_password_sent');
        vm.auth_modal_recover_password_error = $TP('auth_modal_recover_password_error');

        var modalInstance;
        vm.openRecoverPassword = function () {
            vm.currentCaptcha = {
                CaptchaCode: '',
                Username: '',
                CaptchaSecret: ''
            };
            vm.getCaptcha();
            modalInstance = $uibModal.open({
                templateUrl: 'recoverPasswordTemplate',
                scope: $scope,
                backdrop: 'static',
                keyboard: false
            });
            modalInstance.result.then(
                function () { //close: nothing to do 
                }, function () { //dismiss //nothing to do
                });
        };

        vm.sentMail = function () {
            modalInstance = $uibModal.open({
                templateUrl: 'sentMailTemplate',
                scope: $scope,
                backdrop: 'static',
                keyboard: false
            });
        };

        vm.modalSentMailOk = function () {
            modalInstance.close();
        };

        vm.modalOk = function () {
            vm.validationError = false;
            publicService.recoverMyPasswordRequest(vm.currentCaptcha)
                .then(function (data) {
                    // 1 reset ok
                    // -1 Some error occurred
                    // -2 Captcha validation error
                    var result = Number(Lazy(data.results).first());
                    if (result === -2) {
                        vm.validationError = true;
                        vm.errorMessage = $TP('auth_modal_captcha_error');
                    } else if (result < 0) {
                        vm.validationError = true;
                        vm.errorMessage = $TP('auth_modal_recover_password_error');
                    } else {
                        modalInstance.close();
                        vm.sentMail();
                    }
                });
        };

        vm.modalCancel = function () {
            modalInstance.dismiss();
        };

        vm.getCaptcha = function () {
            vm.validationError = false;

            return publicService.getCaptcha()
                .then(function (data) {

                    vm.captcha64 = "data:image/jpg;base64," + data.results[0].CaptchaImage;
                    vm.currentCaptcha.CaptchaSecret = data.results[0].CaptchaSecret;
                })
                .catch(function (error) {
                    //vm.setErrorMsg(error);
                });
        };

        vm.configureAppMobile = function () {
            navigationService.navPublicConfigMobile();
        }
    };

    login.$inject = injectParams;

    rdsmodule.controller('login', login);

});