﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'foundationService', 'workflowService', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actsSearch($q, $scope, $timeout, $injector, actService, foundationService, workflowService, utilService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 3;
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActWorkflowsSection()]));
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var actStatusEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActStatus, 'Act', vm.translate);
        var actStatusEnumObj = actStatusEnumParsed.obj.normal.name_value;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var actFixedCode = navigationService.stateParams().actFixedCode;
        var actPermissionManager = new NbsActPermissionManager({
            translator: vm.translate,
            virtualContext: contextService.virtualContext,
            actServiceEnumTypes: actService.dataContext.enumTypes,
            havePermissionFn: vm.havePermission,
            validationSchema: null,
            rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
            audPermissionObj: Nembus.Aud.Library.auth.Permission,
            parseEnumFn: Nembus.Common.Library.util.parseEnum
        });
        var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(workflowService.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', vm.translate);

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        var dsCallback = function () { return foundationService.getVhrTypes(false); };
        var selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrType', viewTitle: vm.translate('TargetDto.TargetTypeId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrType = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrType.setOnSelectFnCallback(function () {
            vm.currentActFilter.VhrTypeIds = vm.nbsSelectionGridWithModalVhrType.selectedIds;
        });

        dsCallback = function () { return foundationService.getVhrGroups(false); };
        selectionParams = { destModalKey: 'genericGrid', modalMode: 'VhrGroup', viewTitle: vm.translate('TargetDto.TargetGroupId'), ds: dsCallback };
        vm.nbsSelectionGridWithModalVhrGroup = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalVhrGroup.setOnSelectFnCallback(function () {
            vm.currentActFilter.VhrGroupIds = vm.nbsSelectionGridWithModalVhrGroup.selectedIds;
        });

        vm.actPrioritiesList = actPriorityEnumParsed.list.translated;
        vm.actWorkflowStatusesList = workflowStatusKindEnumParsed.list.translated;

        var filtersToSkip = [];
        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var searchFormData = nbsSearchForm.SearchActsFormData();
        searchFormData.init(vm, filtersToSkip);

        selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: true };
        vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
            vm.currentActFilter.ActTargetIds = [];
            if (vm.nbsSelectionGridWithModalTarget.selectedIds) {
                vm.currentActFilter.ActTargetIds = vm.nbsSelectionGridWithModalTarget.selectedIds;
            }
        });

        selectionParams = { destModalKey: 'category', systemCategoryCode: 'ACT', categoryType: 'user', isAutocheck: false, viewKind: 'all', expandTree: true };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            if (vm.nbsSelectionTreeviewWithModalCategory.selectedIds) {
                vm.currentActFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
            } else {
                vm.currentActFilter.CategoriesId = [];
            }
        });

        vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
            translator: vm.translate,
            suspendDirty: vm.suspendDirty,
            navigationService: navigationService,
            contextUserForSelection: null,
            getServerVarFn: Nembus.Common.Library.server.getServerVar
        });
        vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_core_act_assignee_visibility_selection');

        vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
            if (vm.nbsActionDrbacModalObj.drbacUserGroupAreSelected()) {
                vm.currentActFilter.UserGroupAssignedIds = vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds();
            } else {
                vm.currentActFilter.UserGroupAssignedIds = [];
            }
            if (vm.nbsActionDrbacModalObj.appUserAreSelected()) {
                vm.currentActFilter.AppUserAssignedIds = vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds();
            } else {
                vm.currentActFilter.AppUserAssignedIds = [];
            }
        });

        // workflow buttons disabled onload
        setActionButtonsStatus();

        vm.searchTabIndex = 0;

        vm.currentActFilter.ActFixedCode = actFixedCode;
        vm.currentActFilter.ActStartDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActStartDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vm.currentActFilter.ActExpireDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActExpireDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vm.currentActFilter.ActExecutionDate1Expression = expressionTypeEnum.GreaterThanOrEqual;
        vm.currentActFilter.ActExecutionDate2Expression = expressionTypeEnum.LessThanOrEqual;
        vm.currentActFilter.HaveToExpandVhrsWithAssetTree = true;
        vm.currentActFilter.HaveToExpandCategories = true;

        loadData(false);
        function loadData(forceReload) {
            var funcsPromises = [];

            $q.all(funcsPromises)
                .then(function () {
                });
        }

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            // start search
            searchActs();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionGridWithModalVhrType.clear();
                vm.nbsSelectionGridWithModalVhrGroup.clear();
                vm.nbsSelectionGridWithModalTarget.clear();
                vm.nbsSelectionTreeviewWithModalCategory.clear();

                vm.nbsActionDrbacModalObj.clear();
            });
        };

        var gridResult = {};
        vm.gridSelection = function (args) {
            gridResult = args;
            
            setActionButtonsStatus();
        };

        vm.workflowExecuteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowExecuteMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, actIdsSelected
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();

                    setActionButtonsStatus();
                });
            });
        };

        vm.workflowModifyAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowModifyMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, actIdsSelected
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();

                    setActionButtonsStatus();
                });
            });
        };

        vm.workflowInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var currentAct = gridResult.data[0];
                navigationService.navToActWorkflowHistoryMode(vm.suspendDirty, currentAct.ActId);
            }
        };

        vm.deleteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canDeleteAct) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            actService.actMassivelyUpdateRequestManageDeleteAct(contextService, vm.translate, gridResult.dataIds)
                .then(() => {
                    // force refresh grid
                    vm.refreshAction();
                });
        };

        function setActionButtonsStatus() {
            $timeout(function () {
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusExecute, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusInfo, gridResult.isSingleSelection);
                ribbon.refreshStatusButtons();
            });
        }

        var ejDataManager = null;
        var currentActFilter = null;
        function searchActs() {
            var actFilter = {};
            actFilter = nbsSearchForm.ParseActFilter(vm.currentActFilter);
            actFilter.ActStatusId = actStatusEnumObj.Opened;

            // refresh of filter parsed
            currentActFilter = actFilter;

            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService };
                ejDataManager = actService.getActDataManager(supportData, currentActFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentActFilter);
            } else {
                refreshGridDatasource(currentActFilter)
            }
        }

        vm.rowDblClick = function (args) {
            navigationService.navigateToActDetail({ act: args.data, appStoreCode: appStoreCode });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 1;
                vm.forceGridRefresh = new Date().getTime();
            });
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                vm.searchTabIndex = 1;

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }
    }

    actsSearch.$inject = injectParams;
    actsSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actsSearch', actsSearch);
});