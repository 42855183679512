﻿'use strict';

define([], function () {
    //hack deprecated angular function
    angular.lowercase = function (text) {
        if (!text) return; return text.toLowerCase();
    };
    angular.uppercase = function (text) {
        if (!text) return; return text.toUpperCase();
    };
    var app = angular.module('app', [
        'ngRoute',
        'LocalStorageModule',
        //'routeResolverServices',
        'ui.router',
        'ui.sortable',
        'breeze.angular',
        'breeze.directives',
        'loadingService',
        'ejangular',
        'ui.bootstrap',
        'ngSanitize',
        'plupload.directive',
        'ui.select',
        'ngMap',
        //numbus common
        'nbs.common.directives',
        'nbs.plupload.directive',
        //nembus module
        'nbs.shared.module',
        'nbs.entry.module',
        'nbs.rbac.module',
        'nbs.shell.module',
        'nbs.rds.module',
        'nbs.aud.module',

    ]);

    app.config(['$routeProvider', '$controllerProvider',
        '$compileProvider', '$filterProvider', '$provide', '$httpProvider',
        '$stateProvider', '$urlRouterProvider', '$locationProvider', '$qProvider',

        function ($routeProvider, $controllerProvider,
            $compileProvider, $filterProvider, $provide, $httpProvider,
            $stateProvider, $urlRouterProvider, $locationProvider, $qProvider) {

            //Change default views and controllers directory using the following:
            //routeResolver.routeConfig.setBaseDirectories('/app/views', '/app/controllers');
            $qProvider.errorOnUnhandledRejections(false);
            $locationProvider.hashPrefix('');
            $httpProvider.interceptors.push('authInterceptorService');

            app.addRouterUIStateResolve = function (name, state) {
                // add router to navigate
                $stateProvider.state(name, routeResolver.routeObj.resolve(state));
            };

            app.addRouterUIStateDocumentation = function (state, parentState) {
                // add router to navigate
                Nembus.Common.Library.modal.addDocModalRoute($stateProvider, routeResolver.routeObj, state, parentState);
            };

            //Define routes - controllers will be loaded dynamically
            //var route = routeResolver.route;

            // router UI home state
            // each module have to define his own router states (rds-module.js etc...
            $urlRouterProvider.otherwise(
                function ($injector, $location) {
                    var authenticationService = $injector.get('authenticationService');
                    var navigationService = $injector.get('navigationService');
                    var publicService = $injector.get('publicService');
                    var bootService = $injector.get('bootService');
                    var cState = $injector.get('$state');
                    if (authenticationService.user.isAuthenticated) {
                        bootService.checkContext().then(function () {
                            if (!authenticationService.user.changePasswordNextLogin) {
                                navigationService.navToNextState();
                            }
                            else {
                                cState.go('app.nav.forcechangemypassword');
                            }
                        })
                            .catch(function error() {
                                console.log(error);
                                authenticationService.logout();
                                publicService.getSsoConfig()
                                    .then(ssoConfig => {
                                        authenticationService.redirectToLogin();

                                    });
                            });
                    }
                    else {
                        publicService.getSsoConfig()
                            .then(ssoConfig => {
                                if (ssoConfig.SsoEnabled && !ssoConfig.IsTestMode)
                                    if (ssoConfig.AuthenticationFlow && ssoConfig.AuthenticationFlow.toLowerCase() === 'redirect') {
                                        authenticationService.redirectToSsoRequest();
                                    } else if (ssoConfig.AuthenticationFlow && ssoConfig.AuthenticationFlow.toLowerCase() === 'idpselection') {
                                        cState.go('auth.login');
                                    } else {
                                        cState.go('auth.login');
                                    }
                                else {
                                    cState.go('auth.login');
                                }
                            });
                    }
                });

            $stateProvider
                .state("app", { abstract: false, url: "/app", templateUrl: "Main/App" })
                ;
        }]);

    //log errors
    app.config(['$provide', function ($provide) {
        $provide.decorator('$log', ['$delegate', function ($delegate) {
            var _log = $delegate.log; //Saving the original behavior
            var _debug = $delegate.debug; //Saving the original behavior

            $delegate.log = function (message) { _log(message); };
            $delegate.error = function (message) {
                _debug(message);
                alert(message);
            };

            return $delegate;
        }]);

        $provide.decorator('$templateRequest', ['$delegate', function ($delegate) {

            var fn = $delegate;

            $delegate = function (tpl) {

                for (var key in fn) {
                    $delegate[key] = fn[key];
                }

                return fn.apply(this, [tpl, true]);
            };

            return $delegate;
        }]);

    }]);

    //Configure the Breeze Validation Directive for bootstrap 2
    app.config(['zDirectivesConfigProvider', configDirective]);
    function configDirective(cfg) {
        // Custom template with warning icon before the error message
        cfg.zValidateTemplate = '<span class="label label-danger">%error%</span>';
    }
    app.run(['$q', '$rootScope', '$location', '$timeout', '$window', 'navigationService', 'bootService', '$urlRouter', 'localStorageService', 'fileTransferService', 'authenticationService', 'watchdogService', 'publicService', 'contextService', 'cmsService', 'breeze', '$uibModal',
        function ($q, $rootScope, $location, $timeout, $window, navigationService, bootService, $urlRouter, localStorageService, fileTransferService, authenticationService, watchdogService, publicService, contextService, cmsService, breeze, $uibModal) {
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

                if (publicService.maintenanceStatus.IsInMaintenance && toState.name.indexOf('maintenance') < 0) {
                    event.preventDefault();
                    navigationService.navToMaintenance();
                }

                else if (!publicService.maintenanceStatus.IsInMaintenance && toState.name.indexOf('maintenance') > 0) {
                    event.preventDefault();
                    contextService.clearContext();
                    authenticationService.logout()
                        .then(function (response) {
                            authenticationService.redirectToRootLocation();
                        });
                }

                else if (Nembus.Common.Library.auth.isPublicRouteState(toState.name)) {
                    if (authenticationService.user.isAuthenticated) {
                        event.preventDefault();
                        navigationService.navToHome();
                    }
                    else {
                        publicService.getSsoConfig()
                            .then(ssoConfig => {
                                if (ssoConfig.SsoEnabled
                                    && !ssoConfig.IsTestMode
                                    && ssoConfig.AuthenticationFlow && ssoConfig.AuthenticationFlow.toLowerCase() === 'redirect'
                                    && !Nembus.Common.Library.auth.isSsoIgnoreRouteState(toState.name)) {

                                    authenticationService.redirectToSsoRequest();
                                }
                                else {
                                    //nothing to do: tostate == login ...
                                }

                            });
                    }
                }

                else if (!Nembus.Common.Library.auth.isPublicRouteState(toState.name)) {
                    if (toState.name === 'auth.steptwo') {
                        // nothing todo
                    }
                    else if (toState.name === 'auth.maintenance') {
                        // nothing todo
                    }

                    else if (toState.name === 'auth.publicnoauth') {
                        // nothing todo
                    }
                    else {
                        if (authenticationService.checkVirtualCompanySwitched()) {
                            authenticationService.redirectToRootLocation()
                        }
                        if (publicService.checkVersionChanged()) {
                            navigationService.customMessageModal('NewVersionDetectedRefreshBrowser')
                                .then(function (result) {
                                    authenticationService.redirectToRootLocation();
                                });
                        }

                        if (authenticationService.haveToRefreshToken()
                            && authenticationService.canRefreshToken()) {
                            event.preventDefault();
                            authenticationService.refreshToken()
                                .then(function () {
                                    // call `$urlRouter.sync()` to get the route processed again
                                    $urlRouter.sync();
                                })
                                .catch(function () {
                                    console.log("refresh token error");
                                    authenticationService.logout();
                                    publicService.getSsoConfig()
                                        .then(ssoConfig => {
                                            authenticationService.redirectToLogin(ssoConfig);

                                        });
                                });
                        }
                        else {
                            if (!authenticationService.user.isAuthenticated) {
                                if (toState.name.toLowerCase().indexOf('forgotpassword') > 0) {
                                    //no redirect because change pwd
                                }
                                else {
                                    if (toState.name == 'auth.redir') {
                                        var externalRedir = navigationService.getExternalNextUrl(toParams.redirparam);
                                        authenticationService.setExternalVc(externalRedir.vc);
                                    }
                                    else {
                                        navigationService.setRedirAfterLogin(toState, toParams);
                                    }
                                    event.preventDefault();
                                    publicService.getSsoConfig()
                                        .then(ssoConfig => {
                                            authenticationService.redirectToLogin(ssoConfig);

                                        });
                                }
                            }
                            var haveTochangePassword = authenticationService.user.isAuthenticated && authenticationService.user.changePasswordNextLogin;
                            if (haveTochangePassword && toState.name.toLowerCase().indexOf('forcechangemypassword') < 0) {
                                event.preventDefault();
                                navigationService.navToForceChangeMyPassword();
                            }
                            else if (!haveTochangePassword && toState.name.toLowerCase().indexOf('forcechangemypassword') >= 0) {

                                event.preventDefault();
                                navigationService.navToHome();
                            }
                            else if (authenticationService.user.isAuthenticated) {
                                if (!contextService.isVirtualContextAlreadySet()) {

                                    if (toState.name == 'auth.redir') {
                                        //event.preventDefault();
                                        var externalRedir = navigationService.getExternalNextUrl(toParams.redirparam)
                                        authenticationService.setExternalVc(externalRedir.vc);
                                    }
                                    else {
                                        //event.preventDefault();
                                        //navigationService.setNextState(toState, toParams);
                                    }

                                    event.preventDefault();
                                    bootService.checkContext().then(function () {
                                        if (!watchdogService.hasViewAuth(toState)) {
                                            //event.preventDefault();//Use event.preventDefault() to abort the transition 
                                            navigationService.navToUnauthorized(toState.name);
                                        }
                                        else {
                                            if (toState.name == 'auth.redir') {
                                                $urlRouter.sync();
                                            }
                                            else {
                                                //navigationService.setNextState(toState, toParams);
                                                $urlRouter.sync();
                                            }
                                        }
                                    })
                                        .catch(function (error) {
                                            authenticationService.logout();
                                            publicService.getSsoConfig()
                                                .then(ssoConfig => {
                                                    authenticationService.redirectToLogin(ssoConfig);

                                                });
                                        });
                                }
                                else {
                                    if (!watchdogService.hasViewAuth(toState)) {
                                        //event.preventDefault();//Use event.preventDefault() to abort the transition 
                                        event.preventDefault();
                                        navigationService.navToUnauthorized(toState.name);
                                    }
                                    else {
                                        if (toState.name == 'auth.redir') {
                                        }
                                        else {
                                            //navigationService.setNextState(toState, toParams);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            });
            $rootScope
                .$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                    if (toState.routeParams && toState.routeParams.cmsViewCode) {
                        var cmsViewCode = Nembus.Common.Library.cms.parseCmsViewCode(toState.routeParams.cmsViewCode);
                        var title = Nembus.Common.Library.cms.viewTitle(cmsViewCode);
                        Nembus.Common.Library.cms.setViewTitle(title);
                        navigationService.notifyStateChange(toState, cmsViewCode);
                        if ($window.ga) {
                            $window.ga('send', 'pageview', { page: $location.path(), title: title });
                        }
                    }
                    if (toState.name == 'auth.redir') {
                        event.preventDefault();
                        //enforce External Vc;
                        var externalVc = authenticationService.getExternalVc();
                        if (externalVc && externalVc > 0) {
                            authenticationService.setExternalVc(-1);
                            authenticationService.changeVirtualContext(externalVc)
                                .then(function (response) {
                                    navigationService.navToNextState();
                                });
                        }
                        else {
                            navigationService.navToNextState();
                        }
                        // nothing todo
                        //event.preventDefault();
                        //if (authenticationService.user.isAuthenticated && contextService.isVirtualContextAlreadySet()) {
                        //	authenticationService.setExternalNextUrl(toParams.redirparam)
                        //}
                    }
                    if (!navigationService.redirectIsChecked) {
                        publicService.haveToRedirect()
                            .then(res => {
                                if (res && res != '-') {
                                    location.href = res;
                                }
                                navigationService.redirectIsChecked = true;

                            })
                            .catch(reason => {
                                //nothing to do;
                            });

                    }
                });
            $rootScope.$on('$stateChangeError',
                function (event, toState, toParams, fromState, fromParams, error) {
                    var test = error;
                }
            );
            //polling maintenancestate
            tickMaintenance();
            function tickMaintenance() {
                var fixInterval = 320000;
                publicService.isInMaintenance()
                    .then(function (maintenanceStatus) {
                        var interval = fixInterval;
                        if (maintenanceStatus.MaintenancePollingInterval) {
                            interval = maintenanceStatus.MaintenancePollingInterval;
                            if (interval < fixInterval) {
                                interval = fixInterval;
                            }
                        }

                        if (maintenanceStatus.PollingEnabled) {
                            if (publicService.maintenanceStatus.IsInMaintenance !== maintenanceStatus.IsInMaintenance) {
                                publicService.maintenanceStatus = maintenanceStatus;
                                if (maintenanceStatus.IsInMaintenance) {
                                    navigationService.navToMaintenance();
                                }
                                else {
                                    //navigationService
                                    authenticationService.redirectToRootLocation();
                                }
                            }
                            publicService.maintenanceStatus = maintenanceStatus;

                            $timeout(tickMaintenance, interval);
                        }
                    })
                    .catch(function (error) {
                        $timeout(tickMaintenance, interval * 5);
                    });

            }

            //wait call refresh token because when app start might be called before by $stateChangeStart 
            $timeout(tickRefreshToken, 15);
            function tickRefreshToken() {
                if (authenticationService.haveToRefreshToken() && authenticationService.canRefreshToken()) {
                    authenticationService.refreshToken()
                        .then(function () {

                            $timeout(tickRefreshToken, Nembus.Common.Library.auth.refreshTokenCheckInterval);
                        })
                        .catch(function () {

                            console.log("refresh token error");

                            authenticationService.logout();
                            authenticationService.redirectToRootLocation();
                            //authenticationService.sessionExpired();
                            //todo: session will expire modal message
                        });
                }
                else {
                    $timeout(tickRefreshToken, Nembus.Common.Library.auth.refreshTokenCheckInterval);
                }
            }
        }]);

    return app;
});
