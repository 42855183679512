﻿'use strict';
var shellmodule = angular.module('nbs.shell.module', []);
define([
    './navigation/appLightPanel',
    './navigation/modalPanel',
], function () {


    shellmodule.config(['$stateProvider',
        function ($stateProvider) {

            var routeObj = routeResolver.routeObj;
          

            $stateProvider
                .state("app.navlight", routeObj.resolve({ baseName: 'shell/navigation/appLightPanel', regionName: 'appRegion' }))

                //notice:
                //add MANUALLY common components reference under app.nav state
                .state("app.nav", routeObj.resolve({ baseName: 'shell/navigation/appPanel', regionName: 'appRegion' }))

                //.state("app.nav.appHome", {
                //    url: "/appHome",
                //    views: {
                //        "mainRegion": { templateUrl: "shell/navigation/appHome/_" },
                //    }
                //})

                .state("app.nav.appHome", routeObj.resolve({ ngUrl: '/appHome', baseName: 'shell/navigation/appHome' }))


                //todo: create home view for each module outside shell
                //change navigation to module home
                //add route x modulehome in right module project (tec,sem etc....)
                .state("app.nav.modulehome-ced", routeObj.resolve({
                    ngUrl: '/modulehome-ced',
                    ngParamsName: '/{isCustomSections}',
                    baseName: 'shell/navigation/moduleHome',
                    viewMode: 'ced',
                }))
                .state("app.nav.modulehome-acc", routeObj.resolve({
                    ngUrl: '/modulehome-acc',
                    ngParamsName: '/{isCustomSections}',
                    baseName: 'shell/navigation/moduleHome',
                    viewMode: 'acc',
                }))
                .state("app.nav.modulehome-ats", routeObj.resolve({
                    ngUrl: '/modulehome-ats',
                    ngParamsName: '/{isCustomSections}',
                    baseName: 'shell/navigation/moduleHome',
                    viewMode: 'ats',
                }))
                .state("app.nav.modulehome-hrs", routeObj.resolve({
                    ngUrl: '/modulehome-hrs',
                    ngParamsName: '/{isCustomSections}',
                    baseName: 'shell/navigation/moduleHome',
                    viewMode: 'hrs',
                }))
                .state("app.nav.modulehome-its", routeObj.resolve({
                    ngUrl: '/modulehome-its',
                    ngParamsName: '/{isCustomSections}',
                    baseName: 'shell/navigation/moduleHome',
                    viewMode: 'its',
                }))
                .state("app.nav.modulehome-ers", routeObj.resolve({
                    ngUrl: '/modulehome-ers',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'ers',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-oms", routeObj.resolve({
                    ngUrl: '/modulehome-oms',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'oms',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-ets", routeObj.resolve({
                    ngUrl: '/modulehome-ets',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'ets',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-jam", routeObj.resolve({
                    ngUrl: '/modulehome-jam',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'jam',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-jts", routeObj.resolve({
                    ngUrl: '/modulehome-jts',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'jts',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-sam", routeObj.resolve({
                    ngUrl: '/modulehome-sam',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'sam',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-srm", routeObj.resolve({
                    ngUrl: '/modulehome-srm',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'srm',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-sra", routeObj.resolve({
                    ngUrl: '/modulehome-sra',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'sra',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-bsi", routeObj.resolve({
                    ngUrl: '/modulehome-bsi',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'bsi',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-mpl", routeObj.resolve({
                    ngUrl: '/modulehome-mpl',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'mpl',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-tec", routeObj.resolve({
                    ngUrl: '/modulehome-tec',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'tec',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-tes", routeObj.resolve({
                    ngUrl: '/modulehome-tes',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'tes',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-ims", routeObj.resolve({
                    ngUrl: '/modulehome-ims',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'ims',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-ecc", routeObj.resolve({
                    ngUrl: '/modulehome-ecc',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'ecc',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-wcs", routeObj.resolve({
                    ngUrl: '/modulehome-wcs',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'wcs',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-aud", routeObj.resolve({
                    ngUrl: '/modulehome-aud',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'aud',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-audtpl", routeObj.resolve({
                    ngUrl: '/modulehome-audtpl',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'audtpl',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-audtgt", routeObj.resolve({
                    ngUrl: '/modulehome-audtgt',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'audtgt',
                    baseName: 'shell/navigation/moduleHome',
                }))
                .state("app.nav.modulehome-act", routeObj.resolve({
                    ngUrl: '/modulehome-act',
                    ngParamsName: '/{isCustomSections}',
                    viewMode: 'act',
                    baseName: 'shell/navigation/moduleHome',
                }))

                .state("app.nav.dashboard", routeObj.resolve({
                    ngUrl: '/dashboard',
                    ngParamsName: '/{dashboardCode}',
                    baseName: 'shell/navigation/dashboard',
                    srvExtraParam: 'dashboardCode', //at moment not managed in GlossaryCmsViewAttribute
                }));
        }]);


    return shellmodule;

});
