﻿'use strict';
define([], function () {


    var injectParams = ['$scope', '$http', '$state', '$timeout', '$location', '$templateCache', 'cmsService', 'contextService', 'navigationService'];
    function moduleHome($scope, $http, $state, $timeout, $location, $templateCache, cmsService, contextService, navigationService) {

        var vm = this;
        var currentModule = navigationService.currentViewMode();
        var isCustomSections = navigationService.stateParams().isCustomSections;
        isCustomSections = isCustomSections === 'true' ? true : false;
        vm.currentNavigationService = navigationService;

        var chunk = function (arr, size) {
            var newArr = [];
            for (var i = 0; i < arr.length; i += size) {
                newArr.push(arr.slice(i, i + size));
            }
            return newArr;
        };
        vm.allNavigationNodes = cmsService.dataContext.navigationNodes.get();

        // this function add module home section nodes if are been deleted for NavigationTree
        function getModuleHomeWithAllSections(moduleNodes) {
            //CommonProcedures, ModuleReports, InformationsAndSearching, ModuleSetup, PeriodicProcedures
            var moduleSections = [
                {
                    NavigationNodeCode: currentModule + '.CommonProcedures',
                    NavigationNodeName: window.$T(navigationService.currentCmsViewCode(), 'CommonProcedures'),
                    NavigationNodeKind: 129,
                    Order: 10
                },
                {
                    NavigationNodeCode: currentModule + '.ModuleReports',
                    NavigationNodeName: window.$T(navigationService.currentCmsViewCode(), 'ModuleReports'),
                    NavigationNodeKind: 129,
                    Order: 20
                },
                {
                    NavigationNodeCode: currentModule + '.InformationsAndSearching',
                    NavigationNodeName: window.$T(navigationService.currentCmsViewCode(), 'InformationsAndSearching'),
                    NavigationNodeKind: 129,
                    Order: 30
                },
                {
                    NavigationNodeCode: currentModule + '.ModuleSetup',
                    NavigationNodeName: window.$T(navigationService.currentCmsViewCode(), 'ModuleSetup'),
                    NavigationNodeKind: 129,
                    Order: 40
                },
                {
                    NavigationNodeCode: currentModule + '.PeriodicProcedures',
                    NavigationNodeName: window.$T(navigationService.currentCmsViewCode(), 'PeriodicProcedures'),
                    NavigationNodeKind: 129,
                    Order: 50
                }
            ];

            Lazy(moduleSections).each((currentModuleSection) => {
                if (!Lazy(moduleNodes)
                    .filter((moduleNode) => {
                        return moduleNode.NavigationNodeCode.toLowerCase() === currentModuleSection.NavigationNodeCode.toLowerCase();
                    }).some()) {

                    moduleNodes.push(currentModuleSection);
                }
            });

            // sort module nodes by order ascending
            var result = Lazy(moduleNodes).sortBy((node) => { return node.Order; }, false).toArray();
            return result;
        }

        // this function add module home section nodes if are been deleted for NavigationTree
        function getModuleHome(moduleNodes) {
            // sort module nodes by order ascending
            var result = Lazy(moduleNodes).sortBy((node) => { return node.Order; }, false).toArray();
            return result;
        }

        if (currentModule) {
            var moduleNodes = buildTree(Lazy(vm.allNavigationNodes).where({ ModuleGroupCode: currentModule }).toArray());

            if (isCustomSections) {
                moduleNodes = getModuleHome(moduleNodes);
            } else {
                moduleNodes = getModuleHomeWithAllSections(moduleNodes);
            }

            vm.array = chunk(moduleNodes, 2);
        }
        else {
            vm.array = [];
        }

        vm.navigateTo = function (currentNavNodeId) {
            var currentNavNode = Lazy(vm.allNavigationNodes).findWhere({ NavigationNodeId: currentNavNodeId });
            if (currentNavNode) {
                cmsService.setBreadcrumbTree(currentNavNode.NavigationNodeCode);
                cmsService.refreshBreadCrumb();
                navigationService.navigateTo(currentNavNode.NavigationState, castParam(currentNavNode.NavigationParam));
                if (Nembus.Common.Library.ui.isUnderBreakpoint_md()) {
                    Nembus.Common.Library.ui.sidebarClose();
                }
            }
        };

        function castParam(strParam) {
            if (strParam)
                return eval("(" + strParam + ")");
        }

        function buildTree(plainNodes) {
            for (var i = 0; i < plainNodes.length; i++) {
                plainNodes[i].children = Lazy(plainNodes).where({ ParentNavigationNodeId: plainNodes[i].NavigationNodeId }).toArray();
            }
            return Lazy(plainNodes).where({ Depth: 2 }).toArray();
        }

        vm.whoIam = function () {
            return 'modulehome-' + currentModule;
        };
    }

    moduleHome.$inject = injectParams;
    angular.module('nbs.shell.module').controller('moduleHome', moduleHome);
});