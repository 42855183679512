﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'utilService'];
    function targetDetail($q, $scope, $timeout, $injector, foundationService, navigationService, checkStateChangeService, contextService, utilService) {

        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetTargetNewSection());
        vm.ribbonDatacontext = ribbon;

        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var resourceTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.ResourceType, null, vm.translate).obj.normal.name_value;
        var vhrStatusEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.VhrStatus, null, vm.translate).obj.normal.name_value;
        var canManageTarget = vm.havePermission(Nembus.Aud.Library.auth.Permission['AUDTARGET_MANAGE'], contextService.virtualContext);

        vm.canViewTargetAttachments = vm.havePermission(Nembus.Aud.Library.auth.Permission['AUD_AUDTARGET_TARGET_VIEW_ATTACHMENT'], contextService.virtualContext);
        vm.viewNotGeneralTab = false;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, canManageTarget);
        ribbon.refreshStatusButtons();

        vm.companies = [];

        getCompanies(true);
        getVhrGroups(true, appStoreBw);
        getVhrTypes(true, appStoreBw);

        var virtualHumanResourceId = navigationService.stateParams().virtualHumanResourceId;
        if (virtualHumanResourceId) {
            vm.setIsViewing();

            getTargetDto(true, virtualHumanResourceId);
        }
        else {
            vm.setIsAdding();

            vm.currentTarget = foundationService.dataContext.targetDtos.add({
                CompanyId: null,
                AppStoreBw: appStoreBw,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                ResourceTypeId: resourceTypeEnum.Asset,
                VhrStatusId: vhrStatusEnum.Enabled,
                TargetGroupId: null,
                TargetTypeId: null
            });

            // manage of folders
            if (!vm.currentTarget.FsFolderId) {
                vm.rootFsDtoId = NbsGuidManager.newGuid();
                utilService.setFsDtoRootAddFunction({
                    entityService: foundationService,
                    rootFolderKey: vm.rootFsDtoId,
                    fnSetRootFolder: function (folderId) { vm.currentTarget.FsFolderId = folderId; }
                });
            } else {
                vm.rootFsDtoId = vm.currentTarget.FsFolderId;
            }

            // manage of passport photo
            vm.photoDatacontext = {
                photoImageId: null
            };

            // google map component
            vm.googleMapDatacontext = {
                positionSerialized: vm.currentTarget.PositionSerialized,
                modalOptions: vm.modalOptions
            };
        }

        vm.onImageChange = function (result) {
            if (result.action === 'upload') {
                vm.currentTarget.TemporaryFileName = result.temporaryFileName;
                vm.currentTarget.TemporaryFileNameAction = 1; // ImageLogoAction.Upload
            }
            if (result.action === 'delete') {
                vm.currentTarget.TemporaryFileName = null;
                vm.currentTarget.TemporaryFileNameAction = 2; // ImageLogoAction.Delete
            }
        };

        vm.onAttachmentsTabSelect = function () {
            // send fsfolderid to media gallery component
            vm.attachmentsDataContext = {
                canShare: false,
                canCategorize: true,
                fsFolderId: vm.rootFsDtoId
            };
        };

        vm.onVhrAccountersTabSelect = function () {
            // send fsfolderid to media gallery component
            vm.vhrAccountersDataContext = {
                VirtualHumanResourceId: vm.currentTarget.VirtualHumanResourceId
            };
        };

        function getVhrGroups(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.vhrGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getVhrTypes(forceReload, appStoreBw) {
            var filter = { AppStoreBw: appStoreBw };
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.vhrTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getCompanies(forceReload) {
            var defer = $q.defer();

            foundationService.getCompanies({}, forceReload)
                .then(function (data) {
                    vm.companies = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getTargetDto(forceReload, virtualHumanResourceId) {
            var defer = $q.defer();
            var filter = { VirtualHumanResourceId: virtualHumanResourceId };

            foundationService.getTargetDto(filter, forceReload)
                .then(function (data) {
                    vm.currentTarget = data.results[0];

                    vm.viewNotGeneralTab = vm.canViewTargetAttachments;

                    // manage of folders
                    if (!vm.currentTarget.FsFolderId) {
                        vm.rootFsDtoId = NbsGuidManager.newGuid();
                        utilService.setFsDtoRootAddFunction({
                            entityService: foundationService,
                            rootFolderKey: vm.rootFsDtoId,
                            fnSetRootFolder: function (folderId) { vm.currentTarget.FsFolderId = folderId; }
                        });
                    } else {
                        vm.rootFsDtoId = vm.currentTarget.FsFolderId;
                    }

                    // manage of passport photo
                    vm.photoDatacontext = {
                        resizeMode: 'no-resize',
                        photoImageId: vm.currentTarget.TargetImageId
                    };

                    vm.googleMapDatacontext = {
                        positionSerialized: vm.currentTarget.PositionSerialized,
                        modalOptions: vm.modalOptions
                    };

                    defer.resolve(entity);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        vm.onPositionChange = function (args) {
            vm.currentTarget.PositionSerialized = args.positionSerialized;
        }

        // get reference of submitaction present into nbs-vm
        // this allow to manage response and do more in current controller
        var parents = vm.submitAction;
        var ftcScope = $scope.$parent.vm;
        vm.submitAction = function () {
            vm.saveActionComponent = new Date().getTime();

            parents().then(
                function (saveResult) {
                    if (saveResult.entities.length > 0 && vm.viewContext.isAddingState && ftcScope.ftcOnAddEntity) {
                        ftcScope.ftcOnAddEntity(vm.currentTarget);
                        vm.viewNotGeneralTab = true;
                    }
                }
            );
        };
    }

    targetDetail.$inject = injectParams;
    targetDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('targetDetail', targetDetail);
});