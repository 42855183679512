﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$injector', '$scope', '$timeout', 'actService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function actsList($q, $injector, $scope, $timeout, actService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        vm.setItemSelectedFn.call(vm, () => { return gridResult.data; });
        checkStateChangeService.checkFormOnStateChange($scope, actService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'list');
        ribbon.addHomeTab();
        ribbon.replaceSectionWith(ribbon.SECTIONKEY.home_new, ribbon.GetActNewSection());
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActWorkflowsSection()]));
        ribbon.addTab(ribbon.GetActViewsTab());
        vm.ribbonDatacontext = ribbon;

        var viewKind = navigationService.stateParams().viewKind;
        var actTemplateCode = navigationService.stateParams().actTemplateCode;
        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var actPermissionManager = new NbsActPermissionManager({
            translator: vm.translate,
            virtualContext: contextService.virtualContext,
            actServiceEnumTypes: actService.dataContext.enumTypes,
            havePermissionFn: vm.havePermission,
            validationSchema: null,
            rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
            audPermissionObj: Nembus.Aud.Library.auth.Permission,
            parseEnumFn: Nembus.Common.Library.util.parseEnum
        });
        var workflowStatusKindId = navigationService.stateParams().workflowStatusKindId;
        var actFixedCode = navigationService.stateParams().actFixedCode;

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('act', null);
        nbsGridDatacontext.viewCustomBar();
        nbsGridDatacontext.setIsMultiselect();
        nbsGridDatacontext.setGridIsRenderedInlineBlock();
        nbsGridDatacontext.setDatasource([], false); // fake datasource, only to create grid
        vm.nbsGridDatacontext = nbsGridDatacontext;

        // TODO: refactor 
        // settando il prefisso al datalayre  il servizio chiamerà il controllor relativo
        // che dovrà avere gli stessi metodi, questo per questioni di autorizzazione
        // FARE TUTTI I CONTROLLER RELATIVI AL MODULO (QUESTO ANCHE IN MYACTSLIST)
        actService.setDataLayerPrefix('act');

        vm.categoriesMultiselectDs = new NbsMultiselect(vm.translate);
        vm.actPrioritiesForDropDown = actPriorityEnumParsed.list.translated;

        //#region set ribbon fn navigations
        var navParams = { 'workflowStatusKindId': workflowStatusKindId, 'viewKind': viewKind, 'appStoreCode': appStoreCode };
        vm.onRibbonGrid = () => { ribbon.onRibbonGrid_Act(navParams); };
        vm.onRibbonStream = () => { ribbon.onRibbonStream_Act(navParams); };
        vm.onRibbonBoardWorkflow = () => { ribbon.onRibbonBoardWorkflow_Act(navParams); };
        vm.onRibbonBoard = () => { ribbon.onRibbonBoard_Act(navParams); };
        //#endregion set ribbon fn navigations

        //#region set ribbon enabled buttons
        ribbon.setRibbonStatusButton(ribbon.Buttons.NewTask, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.ViewStream, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.Board, true);
        ribbon.setRibbonStatusButton(ribbon.Buttons.BoardWorkflow, true);
        ribbon.refreshStatusButtons();
        //#endregion set ribbon enabled buttons

        // button enable/disable
        setActionButtonsStatus();

        vm.rowDblClick = function (args) {
            navigationService.navigateToActDetail({ act: args.data, appStoreCode: appStoreCode });
        };

        // open wizard to add action
        vm.newAction = function () {
            var canAddAct = vm.havePermission(Nembus.Rds.Library.auth.Permission['ADDACT'], contextService.virtualContext);
            if (!canAddAct) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navigateToAddAct({
                actTemplateCode: actTemplateCode,
                actFixedCode: actFixedCode,
                appStoreCode: appStoreCode
            });
        };

        vm.refreshAction = function () {
            $timeout(() => {
                vm.forceGridRefresh = new Date().getTime();
                setActionButtonsStatus();
            });
        };

        var gridResult = {};
        vm.gridSelection = function (args) {
            gridResult = args;

            setActionButtonsStatus();
        };

        vm.workflowExecuteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowExecuteMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, gridResult.dataIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();
                });
            });
        };

        vm.workflowModifyAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowModifyMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, gridResult.dataIds
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    // force refresh grid
                    vm.refreshAction();
                });
            });
        };

        vm.workflowInfoAction = function () {
            if (gridResult.isSingleSelection) {
                var currentAct = gridResult.data[0];
                navigationService.navToActWorkflowHistoryMode(vm.suspendDirty, currentAct.ActId);
            }
        };

        // hook from gridbar
        var ejDataManager = null;
        var currentActFilter = null;
        vm.actBarOnChanges = function (result) {
            // refresh of filter parsed  
            currentActFilter = result.actFilter;
            currentActFilter.ActFixedCode = actFixedCode;

            if (ejDataManager === null) {
                var supportData = { isMyActs: false, translate: vm.translate, contextService: contextService };
                ejDataManager = actService.getActDataManager(supportData, currentActFilter, ejDataManager);
                nbsGridDatacontext.setDatasource(ejDataManager, true);
                refreshGridDatasource(currentActFilter);
            } else {
                refreshGridDatasource(currentActFilter)
            }
        };

        function refreshGridDatasource(currentGridFilter) {
            $timeout(() => {
                setActionButtonsStatus();

                vm.forceDatamanagerFilter = {
                    refreshKey: new Date().getTime(),
                    filter: currentGridFilter
                }
            });
        }

        vm.createGridEvent = function (result) {
            vm.actBarDataContext = {
                workflowStatusKindId: workflowStatusKindId,
                commonBarDatasource: result.barDatasource,
                uiMode: viewKind,
                appStoreBw: appStoreBw,
                defaultKeys: {
                    columnsKey: viewKind, sortingKey: viewKind
                }
            };
        };

        vm.gridUiOnChanges = function (result) {
            vm.refreshGridUiComponent = {
                refreshKey: new Date().getTime(),
                uiData: result
            }
        }

        vm.deleteAction = function () {
            if (!gridResult.hasSelection) {
                return;
            }

            var actPermission = actPermissionManager.getPermissionByActList(gridResult.data);

            if (!actPermission.canDeleteAct) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            actService.actMassivelyUpdateRequestManageDeleteAct(contextService, vm.translate, gridResult.dataIds)
                .then(() => {
                    // force refresh grid
                    vm.refreshAction();
                });
        };

        function setActionButtonsStatus() {
            $timeout(function () {
                ribbon.setRibbonStatusButton(ribbon.Buttons.Delete, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusExecute, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusModify, gridResult.hasSelection);
                ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusInfo, gridResult.isSingleSelection);
                ribbon.refreshStatusButtons();
            });
        }
    }

    actsList.$inject = injectParams;
    actsList.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actsList', actsList);
});