﻿'use strict';
define([], function () {

    var injectParams = ['$scope', '$http', '$state', '$timeout', '$location', '$templateCache', 'cmsService', 'contextService', 'navigationService'];
    function modalPanel($scope, $http, $state, $timeout, $location, $templateCache, cmsService, contextService, navigationService) {
        //var vm = this;
        //vm.modalCancel = function () {
        //    navigationService.closeCurrentModal();
        //}
    };
    modalPanel.$inject = injectParams;
    angular.module('app').controller('modalPanel', modalPanel);


});