﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'actService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService', 'cmsService', 'utilService', 'fileTransferService'];
    function actDetail($q, $scope, $timeout, $injector, actService, foundationService, navigationService, checkStateChangeService, contextService, cmsService, utilService, fileTransferService) {
        var vm = this;
        nbsVm.call(vm, actService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, actService, { dirtyFlagIsSuspended: vm.isDirtySuspended });

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'form');
        ribbon.addHomeTab();
        ribbon.addTab(new ribbon.Tab("ActionPanelTabManage", [ribbon.GetActWorkflowsSection(), ribbon.GetCommentSection(), ribbon.GetActManageSection()]));
        vm.ribbonDatacontext = ribbon;

        var actId = navigationService.stateParams().actId;
        var actTypeEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActType, 'Act', vm.translate);
        var actTypeEnumObj = actTypeEnumParsed.obj.normal.name_value;

        vm.TABS = {
            DETAIL: 0,  // index of selected tab
            ORIGIN: 1,  // index of selected tab
            NOTE: 2,  // index of selected tab
            ATTACHMENTS: 3,  // index of selected tab
            EXECUTION: 4  // index of selected tab
        }
        vm.tabIndexSelected = vm.TABS.DETAIL;

        ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, false); //act executed cannot  
        ribbon.refreshStatusButtons();
        
        vm.actTypesForDropDown = actTypeEnumParsed.list.translated;
        vm.actTypeIsManually = false;

        var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(actService.dataContext.enumTypes.ActPriority, 'Act', vm.translate);
        vm.actPrioritiesForDropDown = actPriorityEnumParsed.list.translated;

        vm.categoriesMultiselectDs = new NbsMultiselect(vm.translate);
        vm.categoriesMultiselectDs.setOnchangeFnCallback(function () {
            vm.currentAct.CategoriesSerialized = JSON.stringify(vm.categoriesMultiselectDs.selectedIds);
        });


        if (actId) {
            vm.setIsViewing();

            loadData(true);
        }
        else {
            // act is inserted by creation request
        }

        vm.submitAction = function () {
            vm.removeAlertFromList(true);

            $timeout(() => {
                if (!vm.currentAct.ActTargetId) {
                    vm.setErrorMsg({ isMessageVisible: true, message: vm.translate('bz-validation-targetIdValidator') });
                    return;
                }

                vm.currentAct.entityAspect.validateEntity();
                if (!vm.currentAct.entityAspect.hasValidationErrors) {

                    Nembus.Rds.Library.SetBindingM2MEntitiesInContext(actService.dataContext.actM2MCategoryDtos, 'ActId', [Number(actId)], 'CategoryId', vm.categoriesMultiselectDs.selectedIds);
                    Nembus.Rds.Library.SetBindingM2MEntitiesInContext(actService.dataContext.actM2MDrbacUserGroupDtos, 'ActId', [Number(actId)], 'DrbacUserGroupId', vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedIds());
                    Nembus.Rds.Library.SetBindingM2MEntitiesInContext(actService.dataContext.actM2MAppUserDtos, 'ActId', [Number(actId)], 'AppUserId', vm.nbsActionDrbacModalObj.appUserMultiselectSelectedIds());

                    actService.saveChanges()
                        .then(function (result) {
                            // submit ActDto (custom changes on act eventually)
                            foundationService.saveChanges()
                                .then(function (result) {
                                    //getActM2MCategories(true, actId);
                                });
                        });
                }
            }, 0);
        };

        var parentm = vm.modifyAction;
        vm.modifyAction = function () {
            // at moment no controls
            modifyOnClick();
        };

        function modifyOnClick() {
            parentm().then(
                function (saveResult) {
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Comment, !vm.viewContext.isLocked);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusExecute, !vm.viewContext.isLocked);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.WorkflowStatusModify, !vm.viewContext.isLocked);
                    ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, vm.currentAct.permissions.canModifyAct); //act executed cannot                     
                    ribbon.refreshStatusButtons();
                }
            );
        }

        vm.workflowExecuteAction = function () {
            if (!vm.currentAct.permissions.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowExecuteMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, [vm.currentAct.ActId]
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    getActDto(true, vm.currentAct.ActId);
                });
            });
        };

        vm.workflowModifyAction = function () {
            if (!vm.currentAct.permissions.canModifyActWkfStatus) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            navigationService.navToActWorkflowModifyMode(vm.suspendDirty).then(function (result) {
                actService.actMassivelyUpdateRequestManageUpdateWorkflow(contextService, vm.translate, [vm.currentAct.ActId]
                    , { WorkflowStatusId: result.data.WorkflowStatusId, ExecutionNote: result.data.ExecutionNote, ExecutionDate: result.data.ExecutionDate }
                ).then(() => {
                    getActDto(true, vm.currentAct.ActId);
                });
            });
        };

        vm.workflowInfoAction = function () {
            navigationService.navToActWorkflowHistoryMode(vm.suspendDirty, vm.currentAct.ActId);
        };

        vm.attachNoteAction = function () {
            navigationService.navToActJournalsModal(vm.suspendDirty, { entityId: vm.currentAct.ActId })
                .then(function (result) {
                    // refresh data on modal ok
                    getActJournalDtos(true, vm.currentAct.ActId);
                });
        };

        vm.openSource = function (surveyId) {
            
        };

        vm.downloadFile = function (file, asAttach) {
            if (!asAttach) {
                asAttach = false;
            }

            fileTransferService.downloadRemoteFileByGuid({
                entityGuid: file.FsDtoId,
                asAttach: asAttach
            });
        };

        function loadData(forceReload) {
            var funcsPromises = [];

            actService.dataContext.actDtos.clearCache();

            funcsPromises.push(getActDto(forceReload, actId));
            funcsPromises.push(getActActionGroups(false));
            funcsPromises.push(getActActionTypes(false));
            funcsPromises.push(getCategoriesSubTreeOutsideAuthorization(forceReload, 'ACT'));
            funcsPromises.push(getActM2MCategories(forceReload, actId));
            funcsPromises.push(getActM2MDrbacUserGroups(forceReload, actId));
            funcsPromises.push(getActM2MAppUsers(forceReload, actId));
            funcsPromises.push(getActJournalDtos(forceReload, actId));

            $q.all(funcsPromises)
                .then(function () {
                    var funcsSubPromises = [];
                    funcsSubPromises.push(getValidationSchemaInstanceDto(true, vm.currentAct.ValidationSchemaId));

                    $q.all(funcsSubPromises)
                        .then(function (results) {
                            setTarget();

                            vm.validationSchema = results[0];

                            // Set permission in UI for current act
                            actService.customizeActDtoValidation(vm.validationSchema);

                            var actPermissionManager = new NbsActPermissionManager({
                                translator: vm.translate,
                                virtualContext: contextService.virtualContext,
                                actServiceEnumTypes: actService.dataContext.enumTypes,
                                havePermissionFn: vm.havePermission,
                                validationSchema: vm.validationSchema,
                                rdsPermissionObj: Nembus.Rds.Library.auth.Permission,
                                audPermissionObj: Nembus.Aud.Library.auth.Permission,
                                parseEnumFn: Nembus.Common.Library.util.parseEnum
                            });
                            vm.currentAct.permissions = actPermissionManager.getPermissionByAct(vm.currentAct);

                            vm.nbsActionDrbacModalObj = new NbsAssigneeSelectorModal({
                                translator: vm.translate,
                                suspendDirty: vm.suspendDirty,
                                navigationService: navigationService,
                                contextUserForSelection: null,
                                getServerVarFn: Nembus.Common.Library.server.getServerVar
                            });
                            vm.nbsActionDrbacModalObj.setOnSelectFnCallback(function () {
                                vm.currentAct.UsersAssignedSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.appUserMultiselectSelectedItems());
                                vm.currentAct.UserGroupsAssignedSerialized = JSON.stringify(vm.nbsActionDrbacModalObj.drbacUserGroupMultiselectSelectedItems());
                            });
                            vm.nbsActionDrbacModalObj.setVisibilityServerVar('ssv_core_act_assignee_visibility_selection');
                            vm.nbsActionDrbacModalObj.setStatusByActionPermissions(vm.currentAct.permissions);

                            // multiselect categories
                            var categoriesMultiselect = Nembus.Common.Library.util.parseCategoryDtoForMultiSelectNew(vm.userCategoriesTree);
                            var categoriesId = Lazy(vm.actM2MCategories).pluck('CategoryId').toArray();
                            vm.categoriesMultiselectDs.addData(categoriesMultiselect);
                            vm.categoriesMultiselectDs.setSelected(categoriesId, false);

                            vm.nbsActionDrbacModalObj.setAppUserItemsSelected(vm.actM2MAppUsers, 'AppUserId', 'DisplayNameUI');
                            vm.nbsActionDrbacModalObj.setDrbacUserGroupItemsSelected(vm.actM2MDrbacUserGroups, 'DrbacUserGroupId', 'DrbacUserGroupName');

                            vm.actTypeIsManually = vm.currentAct.ActTypeId === actTypeEnumObj.Manually;

                            // datasource for actSourceObj component
                            manageActExtraKeys(vm.currentAct);

                            // manage of folders
                            if (!vm.currentAct.FsFolderId) {
                                vm.rootFsDtoId = NbsGuidManager.newGuid();
                                utilService.setFsDtoRootAddFunction({
                                    entityService: foundationService,
                                    rootFolderKey: vm.rootFsDtoId,
                                    fnSetRootFolder: function (folderId) { vm.currentAct.FsFolderId = folderId; }
                                });
                            } else {
                                vm.rootFsDtoId = vm.currentAct.FsFolderId;
                            }

                            // send fsfolderid to media gallery component
                            vm.attachmentsDataContext = {
                                canShare: false,
                                canCategorize: true,
                                bypassAuth: true,
                                fsFolderId: vm.rootFsDtoId
                            };

                            ribbon.setRibbonStatusButton(ribbon.Buttons.Modify, vm.currentAct.permissions.canModifyAct);
                            ribbon.refreshStatusButtons();
                        });
                });
        }

        function setTarget() {
            var selectionParams = { destModalKey: 'vhr', entityMode: 'Target', translateKey: 'Target', isMultiselect: false };
            vm.nbsSelectionGridWithModalTarget = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
            vm.nbsSelectionGridWithModalTarget.setOnSelectFnCallback(function () {
                if (Lazy(vm.nbsSelectionGridWithModalTarget.selectedIds).some()) {
                    vm.currentAct.ActTargetId = Lazy(vm.nbsSelectionGridWithModalTarget.selectedIds).first();
                } else {
                    vm.currentAct.ActTargetId = null;
                }
            });

            vm.nbsSelectionGridWithModalTarget.setSelectedItems([{
                nbs_id: vm.currentAct.ActTargetId,
                nbs_code: vm.currentAct.ActTargetCode,
                nbs_name: vm.currentAct.ActTargetName
            }]);
        }

        function getActDto(forceReload, actId) {
            var filter = { ActId: actId };
            var defer = $q.defer();

            actService.getActDto(filter, forceReload)
                .then(function (data) {
                    vm.currentAct = data.results[0];
                    defer.resolve(vm.currentAct);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function manageActExtraKeys(act) {
            if (!act.ActExtraKeys) {
                return;
            }

            //vm.currentAct = act; // no remove, binded to UI
            vm.actSourceForm = JSON.parse(Nembus.Common.Library.converter.decodeHtml(act.ActExtraKeys));

            vm.sourceExists = false;
            

            // get source attachments
            vm.sourceActFiles = [];
            if (vm.currentAct.SourceFsFolderId) {
                genericFsDtosNoAuth(true, vm.currentAct.SourceFsFolderId)
                    .then(function (files) {
                        vm.sourceActFiles = files;
                    });
            }
        }

        function getValidationSchemaInstanceDto(forceReload, validationSchemaInstanceId) {
            var defer = $q.defer();

            foundationService.getValidationSchemaInstanceDto(validationSchemaInstanceId, forceReload)
                .then(function (data) {
                    defer.resolve(data);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActJournalDtos(forceReload, actId) {
            var filter = { EntityId: actId };
            var defer = $q.defer();

            actService.getActJournalDtos(filter, forceReload)
                .then(function (data) {
                    Lazy(data.results)
                        .each((item) => {
                            item.CreationDateUI = Nembus.Common.Library.converter.date.formatDateTime(item.CreationDate, contextService.virtualContext.cultureInfo, false);
                        });

                    vm.currentActJournals = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function genericFsDtosNoAuth(forceReload, currentFsFolderId) {
            var filter = { FsFolderId: currentFsFolderId };
            var defer = $q.defer();

            utilService.genericFsDtosNoAuth(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getCategoriesSubTreeOutsideAuthorization(forceReload, systemCategoryCode) {
            var filter = {
                SystemCategoryCode: systemCategoryCode
            };
            var defer = $q.defer();

            foundationService.getCategoriesSubTreeOutsideAuthorization(filter, forceReload)
                .then(function (data) {
                    vm.userCategoriesTree = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActM2MCategories(forceReload, actId) {
            var filter = { ActId: actId };
            var defer = $q.defer();

            actService.getActM2MCategories(filter, forceReload)
                .then(function (data) {
                    vm.actM2MCategories = data.results;

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActM2MDrbacUserGroups(forceReload, actId) {
            var filter = { ActId: actId };
            var defer = $q.defer();

            actService.getActM2MDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.actM2MDrbacUserGroups = data.results;

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActM2MAppUsers(forceReload, actId) {
            var filter = { ActId: actId };
            var defer = $q.defer();

            actService.getActM2MAppUsers(filter, forceReload)
                .then(function (data) {
                    Nembus.Common.Library.util.addDisplayNameToM2MUsersList(data.results);
                    vm.actM2MAppUsers = data.results;

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    vm.actActionTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    vm.actActionGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }
    }

    actDetail.$inject = injectParams;
    actDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('actDetail', actDetail);
});