﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$injector', '$timeout', 'workflowService', 'audService', 'utilService', 'actService', 'drbacService', 'foundationService', 'navigationService', 'checkStateChangeService', 'fileTransferService', 'contextService', 'rbacService'];
    function surveyCompile($q, $scope, $injector, $timeout, workflowService, audService, utilService, actService, drbacService, foundationService, navigationService, checkStateChangeService, fileTransferService, contextService, rbacService) {
        var vm = this;
        nbsVm.call(vm, audService, navigationService, contextService, $q, $injector, $timeout);

        var surveyId = navigationService.stateParams().surveyId;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var moduleTargetId = vm.getModuleTargetId(contextService, appStoreBw);
        var virtualCompanyId = contextService.virtualContext.CurrentVirtualCompanyId;
        var surveyInputTypeEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyInputType, null, vm.translate).obj.normal.name_value;
        var surveyActionTypeObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyActionType, null, vm.translate).obj.normal.name_value;
        var surveyCreationRequestType = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationRequestType, null, vm.translate).obj.normal.name_value;
        var stepCurrentIndex = 0;   // index used to manage steps and buttons visibility
        var sections = [];
        var questions = [];
        var questionOptions = [];
        var answerSummaries = [];
        var steps = [];
        var fsDtoDataDocuments = new NbsFsDtoData({
            fileTransferService: fileTransferService,
            virtualContext: contextService.virtualContext,
            translator: vm.translate,
            thumbnailDatasourceIsGrouped: false,
            formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
            nbsGuidManager: NbsGuidManager
        });
        var fsDtoDataAttachments = new NbsFsDtoData({
            fileTransferService: fileTransferService,
            virtualContext: contextService.virtualContext,
            translator: vm.translate,
            thumbnailDatasourceIsGrouped: false,
            formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
            nbsGuidManager: NbsGuidManager
        });
        var canCompileAll = vm.havePermission(Nembus.Aud.Library.auth.Permission['COMPILE'], contextService.virtualContext);
        var canCompileAssigned = vm.havePermission(Nembus.Aud.Library.auth.Permission['COMPILEASSIGNED'], contextService.virtualContext);
        var canManageActFreetextQuestions = Nembus.Common.Library.server.getServerVarBool('ssv_audit_act_question_freetext_manage_rule');

        //var canCompileAll = true;
        //var canCompileAssigned = true;

        var savingEachOption = false;


        vm.surveyCreationPatternEnumParsed = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyCreationPattern, null, vm.translate).obj.normal.name_value;
        vm.stepTypes = { general: 'general', section: 'section', conclusion: 'conclusion', completed: 'completed' };
        vm.tab = { note: 1, attachments: 2, actions: 3 };

        vm.survey = {};
        vm.surveyAuthor = {};
        vm.files = [];              // do not remove (for upload attachments)
        vm.currentStep = null;

        vm.srcCompanyImage = null;
        vm.surveyIsCompleted = true;
        vm.thumbnailQuestionDatasource = {};
        vm.thumbnailSurveyDatasource = [];
        vm.documentQuestionDatasource = {};

        vm.surveyInputTypeEnumObj = Nembus.Common.Library.util.parseEnum(audService.dataContext.enumTypes.SurveyInputType, null, vm.translate).obj.normal.name_value;

        // datasource step section updated step by step
        vm.currentSection = {};
        vm.currentQuestionDatasource = null;

        vm.downloadFile = function (file, asAttach) {
            if (!file.TemporaryFileName) {
                fileTransferService.downloadRemoteFileByGuid({
                    entityGuid: file.FsDtoId,
                    asAttach: asAttach
                });
            } else {
                fileTransferService.downloadRemoteTemporary({
                    temporaryFileName: file.TemporaryFileName,
                    asAttach: asAttach
                });
            }
        };

        loadSurveyCompileData();
        function loadSurveyCompileData() {
            var forceReload = true;
            vm.surveyNotFound = false;
            vm.surveyFound = false;

            getSurveyCompileDto(forceReload, surveyId)
                .then(function (surveyCompileDto) {
                    if (!surveyCompileDto.IsSurveyFound) {
                        vm.surveyNotFound = true;
                        return;
                    }

                    vm.surveyFound = true;

                    var canContinueCompile = false;
                    if (canCompileAll === true) {
                        canContinueCompile = true;
                    } else {
                        if (canCompileAssigned) {
                            if (surveyCompileDto.IsCurrentUserAssigned === true) {
                                canContinueCompile = true;
                            }
                        }
                    }

                    if (!canContinueCompile) {
                        // REDIRECT TO UNAUTHORIZED STATE
                        navigationService.navToUnauthorized('app.navlight.surveycompile');
                    } else {
                        loadCompleteData(surveyCompileDto);
                    }
                });
        }

        function loadCompleteData(survey) {
            var forceReload = true;

            survey.SurveyExpireDateForUI = Nembus.Aud.Library.UI.GetSurveyExpireDateParsedString(survey, contextService.virtualContext, vm.translate);

            // used in UI to manage ability to check and change data
            vm.surveyIsCompleted = Lazy(Nembus.Aud.Library.GetSurveyLifeStatusListIds(audService, true)).contains(survey.SurveyLifeStatusId);
            if (vm.surveyIsCompleted) {
                survey.SurveyCompileDateSystemForUI = Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyCompileDateSystem, contextService.virtualContext.cultureInfo, false);
                survey.SurveyCompileDateForUI = Nembus.Common.Library.converter.date.formatDateTime(survey.SurveyCompileDate, contextService.virtualContext.cultureInfo, true);
                vm.surveyAuthor.AuthorName = survey.SurveyAuthorName;
            } else {
                vm.surveyAuthor.AuthorName = contextService.virtualContext.CurrentUserDisplayName;
            }

            var funcsPromises = [];
            funcsPromises.push(getSurveyActionDtos(forceReload, surveyId));
            funcsPromises.push(getSurveySections(forceReload, surveyId));
            funcsPromises.push(getSurveyQuestions(forceReload, surveyId));
            funcsPromises.push(getSurveyQuestionOptionDtos(forceReload, surveyId));
            funcsPromises.push(getSurveyAnswerSummaries(forceReload, surveyId));
            funcsPromises.push(getSurveyDocuments(forceReload, surveyId));
            funcsPromises.push(getSurveyAttachments(forceReload, surveyId));
            funcsPromises.push(getValidationSchemaInstanceDto(forceReload, survey.ValidationSchemaId));

            $q.all(funcsPromises)
                .then(function () {
                    vm.survey = getSurveyTree(survey, sections, questions, questionOptions, answerSummaries);

                    // target logo
                    if (vm.survey.TargetImageId) {
                        vm.srcCompanyImage = fileTransferService.uriByGuid({ entityGuid: vm.survey.TargetImageId });
                    }

                    collectSurveyAttachments();
                    collectQuestionAttachments();
                    collectQuestionDocuments();

                    setFastSelectionDatasource(vm.survey.sections);

                    setSteps(vm.survey.sections);

                    // start step
                    vm.currentStep = steps[stepCurrentIndex];
                });
        }

        function setFastSelectionDatasource(sections) {
            // datasource for dropdown fast navigation
            var ds = [];

            // do not change value '-1', is used on change selection
            ds.push({ value: -1, name: vm.translate('General') });

            Lazy(sections)
                .each((section) => {
                    ds.push({ value: section.SurveySectionId, name: section.SurveySectionTitle });
                });

            // do not change value '-2', is used on change selection
            ds.push({ value: -2, name: vm.translate('Conclusion') });

            vm.dropDownSections = ds;
        }

        function StepVisibility(stepType, section) {
            var me = this;
            me.stepType = stepType;

            // used in navigation steps
            me.currentIndex = null;

            me.section = null;
            if (stepType === vm.stepTypes.section) {
                me.section = section;
                me.SurveySectionId = section.SurveySectionId;     // fast search on Lazy
            }


            me.stepSurveyCompletedIsVisible = stepType === vm.stepTypes.completed;
        }

        function setSteps(sections) {
            var step = new StepVisibility(vm.stepTypes.general);
            step.currentIndex = 0;
            steps.push(step);

            var index = 0;
            Lazy(sections)
                .each((section) => {
                    index++;

                    step = new StepVisibility(vm.stepTypes.section, section);
                    step.currentIndex = index;
                    steps.push(step);
                });

            index++;

            step = new StepVisibility(vm.stepTypes.conclusion);
            step.currentIndex = index;
            steps.push(step);
        }

        function setCurrentSection(currentStep) {
            vm.currentSection = {};
            if (currentStep.stepType === vm.stepTypes.section) {
                vm.currentSection = currentStep.section;

                vm.dropDownSectionSelectedId = vm.currentSection.SurveySectionId;

                // navigate page on top when next button is pressed
                window.scrollTo(0, 0);
            }

            if (currentStep.stepType === vm.stepTypes.conclusion) {
                // set now date and now time 
                if (!vm.survey.SurveyCompileDate) {
                    vm.survey.SurveyCompileDate = moment().toDate();
                }
                if (!vm.survey.SurveyCompileTime) {
                    vm.survey.SurveyCompileTime = Nembus.Common.Library.converter.date.formatTimePicker(moment().toDate(), contextService.virtualContext.cultureInfo, false);
                }
            }
        }

        function surveySave() {
            var defer = $q.defer();

            if (vm.surveyIsCompleted) {
                defer.resolve(true);
            } else {
                utilService.saveChanges()
                    .then(function (saveResult) {
                        vm.submitAction()
                            .then(function (result) {
                                defer.resolve(true);
                            })
                            .catch(function (error) {
                                vm.setErrorMsg(error);
                                defer.reject(error);
                            });
                    });
            }

            return defer.promise;
        }

        //called from UI
        vm.currentQuestionSelected = null;
        vm.manageSurveyQuestionData = function (tab, surveyQuestion) {
            vm.currentQuestionSelected = surveyQuestion;

            openDataModal('surveyquestion', tab, surveyQuestion);
        };

        //called frum UI
        vm.manageSurveyData = function (tab) {
            openDataModal('survey', tab);
        };

        function openDataModal(src, tab, surveyQuestion) {
            var isSurveyQuestion = false;
            if (surveyQuestion) {
                isSurveyQuestion = true;
            }
            var isSurvey = !isSurveyQuestion;

            loadModalSupportData(false)
                .then(function () {
                    vm.addUpdateSurveyQuestionErrorSummary = null;

                    vm.removeAlertFromList(true);

                    // selection tab onload
                    if (tab === vm.tab.note) {
                        vm.tabModalIndexSelected = 0;
                    }
                    if (tab === vm.tab.attachments) {
                        vm.tabModalIndexSelected = src === 'surveyquestion' ? 1 : 0;
                    }
                    if (tab === vm.tab.actions) {
                        vm.tabModalIndexSelected = src === 'surveyquestion' ? 2 : 1;
                    }

                    var surveyFsFolderId = vm.survey.FsFolderId;

                    if (isSurveyQuestion) {
                        vm.modalQuestion = surveyQuestion;
                        vm.modalQuestionOptions = surveyQuestion.questionOptions;

                        // CurrentAnswerSummary
                        vm.currentAnswerSummary = Lazy(answerSummaries).findWhere({ SurveyQuestionId: vm.modalQuestion.SurveyQuestionId });
                        var summaryRootNode = null;
                        if (!vm.currentAnswerSummary) {
                            summaryRootNode = fsDtoDataAttachments.getNewRootNode();
                            vm.currentAnswerSummary = audService.dataContext.surveyAnswerSummaries.add({
                                FsFolderId: summaryRootNode.FsDtoId,
                                SurveyQuestionId: surveyQuestion.SurveyQuestionId
                            });
                            utilService.dataContext.fsDtos.add(summaryRootNode);
                        } else {
                            if (!vm.currentAnswerSummary.FsFolderId) {
                                summaryRootNode = fsDtoDataAttachments.getNewRootNode();
                                vm.currentAnswerSummary.FsFolderId = summaryRootNode.FsDtoId;
                                utilService.dataContext.fsDtos.add(summaryRootNode);
                            }
                        }
                    } else {
                        if (!vm.survey.FsFolderId) {
                            summaryRootNode = fsDtoDataAttachments.getNewRootNode();
                            vm.survey.FsFolderId = summaryRootNode.FsDtoId;
                            utilService.dataContext.fsDtos.add(summaryRootNode);
                        }
                    }

                    var params = {
                        surveyId: surveyId,
                        surveyQuestionId: isSurveyQuestion ? vm.modalQuestion.SurveyQuestionId : null,
                        appStoreBw: appStoreBw,
                        virtualCompanyId: virtualCompanyId,
                        actService: actService,
                        audService: audService,
                        utilService: utilService,
                        navigationService: navigationService,
                        workflowService: workflowService
                    };

                    var datasources = {
                        categories: vm.categories,
                        questionOptions: isSurveyQuestion ? vm.modalQuestion.questionOptions : [],
                        drbacUserGroups: vm.drbacUserGroups,
                        appUsers: vm.currentUsers,
                        workflowStatuses: vm.workflowStatuses,
                        actActionGroups: vm.actActionGroups,
                        actActionTypes: vm.actActionTypes,
                        validationSchema: vm.validationSchema
                    };

                    // create js obj for action card template
                    vm.actionCardDatasource = new Nembus.Aud.Library.SurveyActionTemplateDs(vm.translate, vm.havePermission, contextService);
                    vm.actionCardDatasource.actFireIsOptionableVisible = vm.survey.IsActionFireOptional;
                    if (vm.surveyIsCompleted) {
                        vm.actionCardDatasource.setDisabled();
                    }

                    vm.actionCardDatasource.init(params, datasources);
                    vm.actionCardDatasource.setActionPostDefined();
                    vm.actionCardDatasource.setIsCompilingMode();
                    vm.actionCardDatasource.useAddPermissionForPostDefined();
                    vm.actionCardDatasource.byPassAssignmentControl();
                    vm.actionCardDatasource.setIsAddingActionToSurvey(isSurvey);
                    vm.actionCardDatasource.setCurrentQuestion(surveyQuestion);

                    var currentModalActions = {};

                    if (isSurvey) {
                        currentModalActions = getActionForSurvey(surveyActionTypeObj, vm.actions);
                    } else {
                        currentModalActions = getActionForSurveyQuestion(vm.modalQuestion, surveyActionTypeObj, vm.actions);
                        //currentModalActions = getActionForSurveyQuestionOnlyChecked(vm.modalQuestion, surveyActionTypeObj, vm.actions);
                    }

                    vm.actionCardDatasource.setActions(currentModalActions);
                    vm.actionCardDatasource.setActionDsFnFilter(function (a) {
                        return !a.entityAspect.entityState.isDeleted() && !a.entityAspect.entityState.isDetached();
                    });

                    vm.actionCardDatasource.refreshActionDs();

                    // open modal
                    if (isSurveyQuestion) {
                        vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'manageSurveyQuestionData', scope: $scope }));
                    } else {
                        vm.modalInstance = navigationService.modal().open(vm.modalOptions({ templateUrl: 'manageSurveyData', scope: $scope }));
                    }

                    vm.modalInstance.result.then(function () {
                        // onClose
                    }, function () {
                        // onDismiss
                    });

                    vm.modalInstance.ok = function () {
                        vm.addUpdateSurveyQuestionErrorSummary = null;

                        if (isSurveyQuestion) {
                            var resultValidation = Nembus.Aud.Library.ValidateSurveyQuestion(vm.modalQuestion
                                , vm.modalQuestionOptions
                                , vm.actionCardDatasource
                                , true
                                , null);

                            if (resultValidation.hasError) {
                                vm.addUpdateSurveyQuestionErrorSummary = vm.translate(resultValidation.errorMessageKey);
                                return;
                            }
                        } else {
                            // todo
                        }

                        // submit 
                        surveySave()
                            .then(function () {
                                if (isSurveyQuestion) {
                                    if (!Lazy(answerSummaries).where({ SurveyAnswerSummaryId: vm.currentAnswerSummary.SurveyAnswerSummaryId }).some()) {
                                        answerSummaries.push(vm.currentAnswerSummary);
                                        vm.survey = getSurveyTree(vm.survey, sections, questions, questionOptions, answerSummaries);
                                    }
                                    collectQuestionAttachments();

                                } else {
                                    collectSurveyAttachments();
                                }

                                // refresh action for UI
                                vm.actions = audService.dataContext.surveyActionDtos.get();

                                vm.modalInstance.close();
                            });
                    };
                    vm.modalInstance.cancel = function () {
                        if (isSurveyQuestion) {
                            Nembus.Common.Library.entity.rejectChanges([vm.currentAnswerSummary]);
                        }

                        Nembus.Common.Library.entity.rejectChanges(vm.summariesAttachs);
                        Nembus.Common.Library.entity.rejectChanges(vm.actionCardDatasource.actions);
                        vm.survey.FsFolderId = surveyFsFolderId;

                        vm.modalInstance.dismiss();
                    };
                });
        }

        vm.onFileQuestionUploaded = function (file, res) {
            vm.removeAlerts();

            var fs = getFs(file, vm.currentAnswerSummary.FsFolderId);
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            fs.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);
            var fsDto = utilService.dataContext.fsDtos.add(fs);

            vm.summariesAttachs.push(fsDto);
            fsDtoDataAttachments.setFsDtos(vm.summariesAttachs);
        };

        vm.onFileSurveyUploaded = function (file, res) {
            vm.removeAlerts();

            var fs = getFs(file, vm.survey.FsFolderId);
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            fs.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);
            var fsDto = utilService.dataContext.fsDtos.add(fs);

            vm.summariesAttachs.push(fsDto);
            fsDtoDataAttachments.setFsDtos(vm.summariesAttachs);
        };

        function getFs(file, parentFsDtoId) {
            return {
                FsDtoId: NbsGuidManager.newGuid(),
                ParentFsDtoId: parentFsDtoId,
                FsName: file.name,
                DisplayName: file.name,
                DisplayFileName: file.name,
                FsTypeId: audService.fsType.file,
                VirtualCompanyId: virtualCompanyId,
                FileNotSavedYet: true
            };
        }

        vm.editDocument = function (document) {
            vm.currentAttachment = document;

            vm.modalInstanceDocument = navigationService.modal().open(vm.modalOptions({ templateUrl: 'editAttachmentTemplate', scope: $scope }));
            vm.modalInstanceDocument.ok = function () {
                vm.modalInstanceDocument.close();
            };
            vm.modalInstanceDocument.cancel = function () {
                vm.modalInstanceDocument.dismiss();
            };
            vm.modalInstanceDocument.result.then(function () {
                // onClose
            }, function () {
                Nembus.Common.Library.entity.rejectChanges([vm.currentAttachment]);
            });
        };

        vm.actionsLinkIsHighlighted = function (question) {
            if (question) {
                return getActionForSurveyQuestion(question, surveyActionTypeObj, vm.actions).length > 0;
                //return getActionForSurveyQuestionOnlyChecked(question, surveyActionTypeObj, vm.actions).length > 0;
            } else {
                return getActionForSurvey(surveyActionTypeObj, vm.actions).length > 0;
            }
        };

        vm.closeButtonIsVisible = false;
        if (window.opener) {
            vm.closeButtonIsVisible = true;
        }

        vm.closeOnClick = function () {
            if (window.opener) {
                //// window is opened by another window (surveyslist, ...)
                window.close();
            }

            // button not managed at moment
            // navigationService.navToHome();
        };

        function getActionForSurveyQuestionOnlyChecked(surveyQuestion, surveyActionTypeObj, currentActions) {
            // actions => all actions for current surveyquestion
            var currentQuestionActions = Lazy(currentActions).filter((a) => { return a.SurveyQuestionId === surveyQuestion.SurveyQuestionId; }).toArray();
            var questionOptionsChecked = Lazy(surveyQuestion.questionOptions).where({ IsChecked: true }).toArray();

            if (!Lazy(questionOptionsChecked).some()) {
                // all actions for questions were returned
                return currentQuestionActions;
            }

            // action result were filled by surveyQuestionActions and surveyQuestionOptionActions
            var actionsResult = [];

            // add actions related to questions
            var questionActions = Lazy(currentQuestionActions).filter((a) => { return a.SurveyActionTypeId === surveyActionTypeObj.SurveyQuestion; }).toArray();
            Lazy(questionActions).each((questionAction) => {
                actionsResult.push(questionAction);
            });

            // add actions related to question options checked
            var questionOptionActions = Lazy(currentQuestionActions).filter((a) => { return a.SurveyActionTypeId === surveyActionTypeObj.SurveyQuestionOption; }).toArray();
            var questionOptionsIdChecked = Lazy(questionOptionsChecked).pluck('SurveyQuestionOptionId').toArray();
            Lazy(questionOptionActions).each((questionOptionAction) => {
                var currentIds = JSON.parse(questionOptionAction.SurveyQuestionOptionsIdSerialized);
                Lazy(currentIds)
                    .each((id) => {
                        if (Lazy(questionOptionsIdChecked).contains(id)) {
                            if (!Lazy(actionsResult).where({ GroupCode: questionOptionAction.GroupCode }).some()) {
                                actionsResult.push(questionOptionAction);
                            }
                        }
                    });
            });

            return actionsResult;
        }

        function getActionForSurveyQuestion(surveyQuestion, surveyActionTypeObj, currentActions) {
            return Lazy(currentActions).filter((a) => { return a.SurveyQuestionId === surveyQuestion.SurveyQuestionId; }).toArray();
        }

        function getActionForSurvey(surveyActionTypeObj, currentActions) {
            return Lazy(currentActions).where({ SurveyActionTypeId: surveyActionTypeObj.Survey }).toArray();
        }

        function getValidationSchemaInstanceDto(forceReload, validationSchemaInstanceId) {
            var defer = $q.defer();

            foundationService.getValidationSchemaInstanceDto(validationSchemaInstanceId, forceReload)
                .then(function (data) {
                    vm.validationSchema = data;
                    defer.resolve(vm.validationSchema);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyAttachments(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            audService.getSurveyAttachments($sxb(filter), forceReload)
                .then(function (data) {
                    fsDtoDataAttachments.setFsDtos(data.results);

                    vm.summariesAttachs = fsDtoDataAttachments.getFiles();                    // attachments related to AnswerSummary

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function collectQuestionAttachments() {
            Lazy(questions)
                .each((question) => {
                    var cAnswerSummary = Lazy(answerSummaries).findWhere({ SurveyQuestionId: question.SurveyQuestionId });
                    if (cAnswerSummary && cAnswerSummary.FsFolderId) {
                        var fsDtoDataQuestionAttachments = new NbsFsDtoData({
                            fileTransferService: fileTransferService,
                            virtualContext: contextService.virtualContext,
                            translator: vm.translate,
                            thumbnailDatasourceIsGrouped: false,
                            formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
                            nbsGuidManager: NbsGuidManager
                        });
                        var qAttachments = Lazy(vm.summariesAttachs).filter((doc) => {
                            return doc.ParentFsDtoId === cAnswerSummary.FsFolderId
                                && !doc.entityAspect.entityState.isDeleted()
                                && !doc.entityAspect.entityState.isDetached();
                        }).toArray();
                        fsDtoDataQuestionAttachments.setFsDtos(qAttachments, false);
                        vm.thumbnailQuestionDatasource[question.SurveyQuestionId] = fsDtoDataQuestionAttachments.thumbnailDatasource[0].files;
                    } else {
                        vm.thumbnailQuestionDatasource[question.SurveyQuestionId] = [];
                    }
                });
        }

        function collectSurveyAttachments() {
            if (vm.survey && vm.survey.FsFolderId) {
                var fsDtoDataSurveyAttachments = new NbsFsDtoData({
                    fileTransferService: fileTransferService,
                    virtualContext: contextService.virtualContext,
                    translator: vm.translate,
                    thumbnailDatasourceIsGrouped: false,
                    formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
                    nbsGuidManager: NbsGuidManager
                });
                var qAttachments = Lazy(vm.summariesAttachs).filter((doc) => {
                    return doc.ParentFsDtoId === vm.survey.FsFolderId
                        && !doc.entityAspect.entityState.isDeleted()
                        && !doc.entityAspect.entityState.isDetached();
                }).toArray();
                fsDtoDataSurveyAttachments.setFsDtos(qAttachments, false);
                vm.thumbnailSurveyDatasource = fsDtoDataSurveyAttachments.thumbnailDatasource[0].files;
            } else {
                vm.thumbnailSurveyDatasource = [];
            }
        }

        function collectQuestionDocuments() {
            Lazy(questions)
                .each((question) => {
                    var cAnswerSummary = Lazy(answerSummaries).findWhere({ SurveyQuestionId: question.SurveyQuestionId });
                    if (cAnswerSummary && cAnswerSummary.DocFsFolderId) {
                        vm.documentQuestionDatasource[question.SurveyQuestionId] = Lazy(vm.summariesDocs).filter((doc) => {
                            return doc.ParentFsDtoId === cAnswerSummary.DocFsFolderId;
                        }).toArray();
                    } else {
                        vm.documentQuestionDatasource[question.SurveyQuestionId] = [];
                    }
                });
        }

        function getSurveyCompileDto(forceReload, surveyId) {
            var surveyFilter = {
                SurveyId: surveyId
            };
            var vhrEventFilter = {
                ModuleTargetId: moduleTargetId
            };

            var filter = {	// FilterSurveyClientData
                surveyFilter: surveyFilter,
                vhrEventFilter: vhrEventFilter
            };

            var defer = $q.defer();
            audService.getSurveyCompileDto(filter, forceReload)
                .then(function (data) {
                    defer.resolve(data.results[0]);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveySections(forceReload, surveyId) {
            var defer = $q.defer();
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };

            audService.getSurveySections(filter, forceReload)
                .then(function (data) {
                    sections = data.results;
                    defer.resolve(sections);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyQuestions(forceReload, surveyId) {
            var defer = $q.defer();
            var filter = {
                surveyFilter: { SurveyId: surveyId }
            };

            audService.getSurveyQuestions(filter, forceReload)
                .then(function (data) {
                    questions = data.results;
                    defer.resolve(questions);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getSurveyQuestionOptionDtos(forceReload, surveyId) {
            var defer = $q.defer();
            var filter = {
                surveyFilter: {
                    SurveyId: surveyId
                }
            };

            audService.getSurveyQuestionOptionDtos($sxb(filter), forceReload)
                .then(function (data) {
                    questionOptions = data.results;
                    defer.resolve(questionOptions);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyAnswerSummaries(forceReload, surveyId) {
            var defer = $q.defer();

            audService.getSurveyAnswerSummariesBySurveyId($sxb(surveyId), forceReload)
                .then(function (data) {
                    answerSummaries = data.results;

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyActionDtos(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            //audService.dataContext.surveyActionDtos.clearCache();
            audService.getSurveyActionDtos(filter, forceReload)
                .then(function (data) {
                    vm.actions = data.results;

                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getActWorkflowActiveStatuses(forceReload) {
            var defer = $q.defer();
            var filter = {};

            workflowService.getActWorkflowActiveStatuses(filter, forceReload)
                .then(function (data) {
                    vm.workflowStatuses = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function loadModalSupportData(forceReload) {
            var defer = $q.defer();

            var funcsPromises = [];
            funcsPromises.push(getActActionGroups(forceReload));
            funcsPromises.push(getActActionTypes(forceReload));
            funcsPromises.push(getCategoriesSubTreeOutsideAuthorization(forceReload, 'ACT'));
            funcsPromises.push(getCurrentContextAppUsers(forceReload));
            funcsPromises.push(getActWorkflowActiveStatuses(forceReload));
            funcsPromises.push(getDrbacUserGroups(forceReload, virtualCompanyId));

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve({});
                });

            return defer.promise;
        }

        function getCategoriesSubTreeOutsideAuthorization(forceReload, systemCategoryCode) {
            var defer = $q.defer();
            var filter = {
                SystemCategoryCode: systemCategoryCode
            };

            foundationService.getCategoriesSubTreeOutsideAuthorization(filter, forceReload)
                .then(function (data) {
                    vm.categories = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getCurrentContextAppUsers(forceReload) {
            var defer = $q.defer();
            var filter = {};

            rbacService.getCurrentContextAppUsers(filter, forceReload)
                .then(function (data) {
                    vm.currentUsers = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getDrbacUserGroups(forceReload, virtualCompanyId) {
            var defer = $q.defer();
            var filter = {
                VirtualCompanyId: virtualCompanyId
            };

            drbacService.getDrbacUserGroups(filter, forceReload)
                .then(function (data) {
                    vm.drbacUserGroups = data.results;
                    defer.resolve(data.results);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getSurveyDocuments(forceReload, surveyId) {
            var filter = { SurveyId: surveyId };
            var defer = $q.defer();

            audService.getSurveyDocuments($sxb(filter), forceReload)
                .then(function (data) {
                    fsDtoDataDocuments.setFsDtos(data.results);

                    vm.summariesDocs = fsDtoDataDocuments.getFiles();
                    defer.resolve(vm.summariesDocs);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionTypes(filter, forceReload)
                .then(function (data) {
                    vm.actActionTypes = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        function getActActionGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            actService.getActActionGroups(filter, forceReload)
                .then(function (data) {
                    vm.actActionGroups = data.results;
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });

            return defer.promise;
        }

        // private support method
        function getSurveyTree(survey, sections, questions, questionOptions, questionAnswers) {
            var surveyTree = survey;
            surveyTree.sections = Lazy(sections).sortBy((section) => { return section.SurveySectionOrder; }, false).toArray();
            Lazy(surveyTree.sections)
                .each((section) => {
                    section.questions = Lazy(questions)
                        .where({ SurveySectionId: section.SurveySectionId })
                        .sortBy((question) => { return question.SurveyQuestionOrder; }, false)
                        .toArray();

                    Lazy(section.questions)
                        .each((question) => {
                            var nbsCanViewActionsLink = true;
                            if (survey.IsActionsEnabled) {
                                if (question.SurveyInputTypeId === surveyInputTypeEnumObj.SurveyFreeText && !canManageActFreetextQuestions) {
                                    nbsCanViewActionsLink = false;
                                }
                            } else {
                                nbsCanViewActionsLink = false;
                            }

                            question.nbsCanViewActionsLink = nbsCanViewActionsLink;

                            question.questionOptions = Lazy(questionOptions)
                                .where({ SurveyQuestionId: question.SurveyQuestionId })
                                .sortBy((questionOption) => { return questionOption.SurveyQuestionOptionOrder; }, false)
                                .toArray();

                            // one answer summary for question
                            question.answerSummary = Lazy(questionAnswers).findWhere({ SurveyQuestionId: question.SurveyQuestionId });
                        });
                });

            return surveyTree;
        }

        var tdStyle = "style='text-align: left;padding: 10px ;vertical-align: top;'";
        // Click on "SAVE AND COMPLETE" button
        vm.surveyCompileSaveAndComplete = function () {
            // POST to compile survey (backend verify that all required questions are checked)
            vm.surveyCreationRequest = audService.dataContext.surveyCreationRequests.add({
                SurveyCreationRequestId: NbsGuidManager.newGuid(),
                SurveyId: surveyId,
                SurveyCreationRequestCode: 'complete-survey',
                SurveyCreationRequestName: 'complete-survey',
                ModuleTargetId: moduleTargetId,
                SurveyCreationRequestTypeId: surveyCreationRequestType.CompleteSurvey,
                VirtualCompanyId: virtualCompanyId
            });

            // SAVE DATA
            vm.submitAction()
                .then(function (result) {
                    var res = JSON.parse(Nembus.Common.Library.converter.decodeHtml(vm.surveyCreationRequest.SurveyCreationCompleteResponse))
                    switch (res.SurveyCreationCompleteResponse) {
                        case 'SurveyCannotExecuteAction':
                        case 'CurrentStatusCannotBeCompleted':
                            navigationService.customMessageModal(vm.translate('SurveyCompile.' + res.SurveyCreationCompleteResponse));
                            break;
                        case 'SurveyCompilationIncomplete':
                            var msg = vm.translate('SurveyCompile.' + res.SurveyCreationCompleteResponse) + "<br><br>";
                            //msg += "<table><tr><th [tdStyle]>Sezione</td><th [tdStyle]>Domanda</td></tr>";
                            msg += "<table>";
                            Lazy(res.SurveyCreationCompleteResponseItems)
                                .each((item) => {
                                    msg += '<tr><td [tdStyle]><b>' + vm.translate('sezione') + ':</b></td><td [tdStyle]>  ' + item.SectionName + '</td></tr>'
                                    msg += '<tr style="border-bottom: 1px;border: 1px;border-bottom-style: inset;"><td [tdStyle]><b>' + vm.translate('domanda') + ':</b></td><td [tdStyle]> ' + item.QuestionIndex + ') ' + item.QuestionName + '</b></td></tr>'
                                });
                            msg += "</table>";
                            msg = msg.replaceAll('[tdStyle]', tdStyle);

                            //navigationService.customMessageModal(vm.translate('SurveyCompile.' + res.SurveyCreationCompleteResponse));
                            navigationService.customMessageModal(msg);
                            break;
                        case 'OperationNotPermitted':
                            navigationService.customMessageModal(vm.translate(res.SurveyCreationCompleteResponse));
                            break;

                        case 'SurveyCurrentStatusCompletedSuccessfully':
                            vm.surveyIsCompleted = true;

                            // step visible is only step of completed status
                            var step = new StepVisibility(vm.stepTypes.completed);
                            vm.currentStep = step;
                            break;
                    }
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        };

        // Click on "SAVE" button
        vm.surveyCompileSave = function () {
            vm.submitAction()
                .then(function (result) {
                    navigationService.customMessageModal(vm.translate('SurveyCompile.SurveySaved'));
                })
                .catch(function (error) {
                });
        };

        // Leave focus on option FreeText
        vm.optionFreeTextOnBlur = function () {
            if (savingEachOption) {
                surveySave();
            }
        };

        // Click on option to ANSWER
        vm.questionOptionOnClick = function (currentQuestion, currentQuestionOption) {
            // if questionoption is just checked then remove check (for radio and checkbox)
            currentQuestionOption.IsChecked = !currentQuestionOption.IsChecked;

            if (currentQuestion.SurveyInputTypeId === surveyInputTypeEnumObj.SurveyRadio
                && currentQuestionOption.IsChecked) {

                Lazy(currentQuestion.questionOptions)
                    .each((q) => {
                        q.IsChecked = q.SurveyQuestionOptionId === currentQuestionOption.SurveyQuestionOptionId;
                    });
            }

            if (savingEachOption) {
                surveySave();
            }
        };

        // Click on next section
        vm.navigateForward = function () {
            var leavingStep = steps[stepCurrentIndex];
            if (leavingStep.stepType !== vm.stepTypes.general) {
                surveySave()
                    .then(function () {
                        nextSection();
                    });
            } else {
                nextSection();
            }
        };

        // Click on previous section
        vm.navigateBackward = function () {
            var leavingStep = steps[stepCurrentIndex];
            if (leavingStep.stepType !== vm.stepTypes.general) {
                surveySave()
                    .then(function () {
                        previousSection();
                    });
            } else {
                previousSection();
            }
        };

        function nextSection() {
            stepCurrentIndex += 1;
            vm.currentStep = steps[stepCurrentIndex];
            setCurrentSection(vm.currentStep);
        }

        function previousSection() {
            stepCurrentIndex -= 1;
            vm.currentStep = steps[stepCurrentIndex];
            setCurrentSection(vm.currentStep);
        }

        // shortcut to change section from list
        vm.sectionDropDownOnChange = function () {
            var leavingStep = steps[stepCurrentIndex];
            if (leavingStep.stepType !== vm.stepTypes.general) {
                surveySave()
                    .then(function () {
                        selectSection();
                    });
            } else {
                selectSection();
            }
        };

        function selectSection() {
            if (vm.dropDownSectionSelectedId.toString() === '-1') {
                // general is first step
                vm.currentStep = Lazy(steps).first();
            } else if (vm.dropDownSectionSelectedId.toString() === '-2') {
                // conclusion is last step
                vm.currentStep = Lazy(steps).last();
            } else {
                // search selected step in list
                vm.currentStep = Lazy(steps).findWhere({ SurveySectionId: Number(vm.dropDownSectionSelectedId) });
            }

            stepCurrentIndex = vm.currentStep.currentIndex;

            setCurrentSection(vm.currentStep);
        }

        // overload checkstate on exit message with save
        checkStateChangeService.setOnPageExit({
            //stateName: $state.current.name,
            stateName: 'any',
            fn: function () {
                surveySave();
            }
        });

        // TODO: for silent autosaving
        //vm.timeoutSaving = function () {
        //    busyHttpInterceptor.setMainLoadingIsEnabled(false);
        //    vm.submitAction()
        //        .then(function () {
        //            busyHttpInterceptor.setMainLoadingIsEnabled(true);
        //            $timeout(vm.timeoutSaving, 5000);
        //        });
        //};
        //vm.timeoutSaving();
    }

    surveyCompile.$inject = injectParams;
    surveyCompile.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.aud.module').controller('surveyCompile', surveyCompile);
});