﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var hrbucketService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('hrbucket', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            HrBucket: 'HrBucket',
            HrBucketDto: 'HrBucketDto',
            HrHierarchy: 'HrHierarchy',
            HrHierarchyConfig: 'HrHierarchyConfig',
            HrBucketGenerality: 'HrBucketGenerality',
			MagicBucketRequest: 'MagicBucketRequest' //also managed in foundation-service,activity-service
        };

        this.entityTypes = entityTypes;

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            hrBuckets: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrBucket),
            hrHierarchies: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrHierarchy),
            hrHierarchyConfigs: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrHierarchyConfig),
            hrBucketGeneralities: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrBucketGenerality),
            hrBucketRelationships: dataLayer.setContextMethods(manager, nbsService, entityTypes.HrBucketDto),
            magicBucketRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.MagicBucketRequest),

            enumTypes: {
                ResourceType: dataLayer.GetEnumType('ResourceType'),
                SingleXBucketMode: dataLayer.GetEnumType('SingleXBucketMode'),
                SingleXBucketStatus: dataLayer.GetEnumType('SingleXBucketStatus'),
                BucketClassification: dataLayer.GetEnumType('BucketClassification'),
                ResourcePrototype: dataLayer.GetEnumType('ResourcePrototype'),
                MagicBucketAction: dataLayer.GetEnumType('MagicBucketAction')
            }
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

		// HrBucket
		this.getHrBucket = function (filter, forceReload) {
            var params = { key: filter.VirtualHumanResourceId, dataContext: this.dataContext.hrBuckets };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrBuckets");
        };
        this.getHrBuckets = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrBucket, cache: this.dataContext.hrBuckets.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrBuckets");
        };

        // HrHierarchy
        this.getHrHierarchy = function (filter, forceReload) {
            var params = { key: filter.HrHierarchyId, dataContext: this.dataContext.hrHierarchies };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrHierarchies");
        };
        this.getHrHierarchies = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrHierarchy, cache: this.dataContext.hrHierarchies.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrHierarchies");
        };

        // HrHierarchyConfig
        this.getHrHierarchyConfigs = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrHierarchyConfig, cache: this.dataContext.hrHierarchyConfigs.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrHierarchyConfigs");
        };

        //HrBucketGenerality
        this.getHrBucketGenerality = function (filter, forceReload) {
            var params = { key: filter.HrBucketGeneralityId, dataContext: this.dataContext.hrBucketGeneralities };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "HrBucketGenerality");
        };
        this.getHrBucketGeneralities = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrBucketGenerality, cache: this.dataContext.hrBucketGeneralities.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrBucketGenerality");
        };

        //HrBucketRelationships
        this.getHrBucketRelationships = function (filter, forceReload) {
            var params = { entityType: entityTypes.HrBucketDto, cache: this.dataContext.hrBucketRelationships.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "HrBucketRelationships");
        };

        //Http Post
        this.hrHierarchyPublish = function (filter) {
            var query = dataLayer.InvokeQuery("HrHierarchyPublish", filter);
            return manager.executeQuery(query);
        };
    };

    hrbucketService.$inject = injectParams;
    hrbucketService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('hrbucketService', hrbucketService);
});