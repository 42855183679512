﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'foundationService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function fileBoxesSearch($q, $scope, $timeout, $injector, utilService, foundationService, navigationService, checkStateChangeService, contextService) {
        var vm = this;
        nbsVm.call(vm, foundationService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, foundationService);

        var ribbon = new NbsRibbon(navigationService, contextService.virtualContext, 'search');
        ribbon.addHomeTab();
        ribbon.addSearchTab();
        ribbon.tabIndexSelected = 2;
        vm.ribbonDatacontext = ribbon;

        vm.setIsEditingSearch();

        var viewKind = navigationService.stateParams().viewKind;
        var fileBoxTag = navigationService.stateParams().fileBoxTag;
        var appStoreCode = navigationService.stateParams().appStoreCode;
        var appStoreBw = vm.getAppStoreBitwise(contextService.virtualContext, appStoreCode);
        var expressionTypeEnum = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.ExpressionType, null, vm.translate).obj.normal.name_value;
        var target = navigationService.stateParams().target;
        var viewMode = navigationService.currentViewMode();
        var fileBoxTypeEnum = Nembus.Common.Library.util.parseEnum(foundationService.dataContext.enumTypes.FileBoxType, null, vm.translate).obj.normal.value_name;
        
        var filtersToSkip = [];
        var nbsSearchForm = new NbsSearchForm(vm.translate);
        var searchFormData = nbsSearchForm.SearchFileboxesFormData();
        try { filtersToSkip = contextService.virtualContext.behaviors.search.asset[appStoreCode].skipFilters; } catch (e) { }
        searchFormData.init(vm, filtersToSkip);

        var selectionParams = { destModalKey: 'category', systemCategoryCode: 'ROOT', categoryType: 'user' };
        vm.nbsSelectionTreeviewWithModalCategory = new NbsSelectionModal(vm.suspendDirty, navigationService, selectionParams);
        vm.nbsSelectionTreeviewWithModalCategory.setOnSelectFnCallback(function () {
            vm.currentFilemetadataFilter.CategoriesId = vm.nbsSelectionTreeviewWithModalCategory.selectedIds;
        });

        vm.searchTabIndex = 0;

        vm.addFilterAction = function () {
            vm.searchTabIndex = 0;
            searchFormData.addFilters(navigationService, vm.translate, $scope);
        };

        vm.searchAction = function () {
            searchFileBoxes();
        };

        vm.deleteFilterAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                vm.resetAction();
                searchFormData.removeFilters(filtersToSkip);
            });
        };

        vm.resetAction = function () {
            $timeout(() => {
                vm.searchTabIndex = 0;
                searchFormData.resetFilters();

                vm.nbsSelectionTreeviewWithModalCategory.clear();
            });
        };

        var nbsGridDatacontext = new NbsGrid(vm.translate);
        nbsGridDatacontext.configureGrid('filebox', null);
        nbsGridDatacontext.setAdaptToPage();
        nbsGridDatacontext.allowPaging = false;
        nbsGridDatacontext.allowSearching = false;
        nbsGridDatacontext.allowGrouping = false;
        nbsGridDatacontext.setDatasource([], false);
        vm.nbsGridDatacontext = nbsGridDatacontext;

        function searchFileBoxes() {
            var searchFileBoxClientData = {};
            searchFileBoxClientData.fileBoxFilter = nbsSearchForm.ParseFileBoxFilter(vm.currentFileBoxFilter, appStoreBw, expressionTypeEnum, fileBoxTag);
            searchFileBoxClientData.fileMetadataFilter = nbsSearchForm.ParseFileMetadataFilter(vm.currentFilemetadataFilter, appStoreBw);

            vm.currentFileBoxes = [];

            foundationService.searchFileBoxes(searchFileBoxClientData)
                .then(function (data) {
                    vm.currentFileBoxes = data.results;

                    vm.forceDatasourceLocal = {
                        refreshkey: new Date().getTime(),
                        dataSource: vm.currentFileBoxes
                    }

                    vm.searchTabIndex = 1;
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                });
        }

        vm.currentFileBoxes = [];

        vm.rowDblClick = function (args) {
            navigationService.navigateToFileBoxDetail({
                FileBoxId: args.data.FileBoxId,
                Target: target,
                AppStoreCode: appStoreCode,
                ViewKind: viewKind,
                FileBoxTag: fileBoxTag,
                ViewMode: viewMode,
                FileBoxType: fileBoxTypeEnum[args.data.FileBoxTypeId]
            });
        };

        vm.refreshAction = function () {
            vm.searchTabIndex = 1;

            searchFileBoxes(true);
        };
    }

    fileBoxesSearch.$inject = injectParams;
    fileBoxesSearch.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('fileBoxesSearch', fileBoxesSearch);
});