﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$q', '$timeout', 'breezeDataLayer', '$injector', 'nbsStorageService'];
    var cmsSvc = function cmsService($q, $timeout, breezeDataLayer, $injector, nbsStorageService) {

        var dataLayer = breezeDataLayer.Initialize('cms', true);
        var manager = dataLayer.GetManager();
        var $http;

        nbsService.call(this, manager, $q, $timeout);

        var entityTypes = {
            Thesaurus: 'Thesaurus',
            ReportDefinitionDto: 'ReportDefinitionDto',

            // client validation
            UiDataClientData: 'UiDataClientData'
        };

        //client validation
        breezeDataLayer.addType(
            manager.metadataStore,
            {
                name: entityTypes.UiDataClientData,
                dataProperties: {
                    UiDataClientDataId: { type: breeze.DataType.Guid },
                    UiDataViewName: { type: breeze.DataType.String }
                }
            },
            'Nembus.Domain'
        );

        var uiDataClientData = manager.metadataStore.getEntityType(entityTypes.UiDataClientData);
        uiDataClientData.getProperty("UiDataViewName").validators.push(new breeze.Validator("nameValidator", Nembus.Common.Library.validators.requiredFieldValidator));

        this.dataContext = {
            uiDataClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.UiDataClientData),
            thesaurus: {
                get: function () { return manager.getEntities(entityTypes.Thesaurus); }
            },
            navigationNodes: {
                get: function () { return manager.getEntities('NavigationNodeDto'); }
                //add here attach entity etc...
            },
            reportDefinitionDtos: {
                get: function () { return manager.getEntities(entityTypes.ReportDefinitionDto); },
                getByKey: function (key) { return manager.getEntityByKey(entityTypes.ReportDefinitionDto, key); }
            }
        };

        this.getThesaurusCmsDomObjs = function (filter, forceReload) {
            var params = { entityType: entityTypes.Thesaurus, cache: this.dataContext.thesaurus.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ThesaurusCmsDomObjs");
        };

        this.currentNavNodes = {
            allNodes: [],
            navigationPanel: [],
            breadCrumb: []
        };

        this.getNodesString = function (nodes) {
            return manager.exportEntities(nodes, false);
        };

        this.setNodesString = function (nodes) {
            manager.importEntities(nodes);
        };

        this.setBreadcrumbTree = function (navNodeCode) {
            var currentNode = Lazy(this.currentNavNodes.allNodes).findWhere({ NavigationNodeCode: navNodeCode });
            var bc = [];
            if (!currentNode) {
                currentNode = Lazy(this.currentNavNodes.allNodes).filter((n) => { return n.NavigationNodeCode.toLowerCase() === navNodeCode; }).first();
                this.currentNavNodes.breadCrumb = [];
                //add only module node
                this.currentNavNodes.breadCrumb.push(currentNode);

                return;
            }
            bc.push(currentNode);
            var parentNode = Lazy(this.currentNavNodes.allNodes).where({ NavigationNodeId: currentNode.ParentNavigationNodeId }).toArray()[0];
            while (parentNode) {
                parentNode = Lazy(this.currentNavNodes.allNodes).where({ NavigationNodeId: currentNode.ParentNavigationNodeId }).toArray()[0];

                currentNode = parentNode;
                if (currentNode) {
                    bc.push(currentNode);
                }
            }
            if (bc && bc.length > 0) {
                //also add module node
                bc.push(Lazy(this.currentNavNodes.allNodes).findWhere({ ModuleGroupCode: bc[0].ModuleGroupCode, Depth: 1 }));
            }

            this.currentNavNodes.breadCrumb = Lazy(bc).sortBy((n) => { return n.Depth; }, false).toArray();
        };

        this.refreshBreadCrumb = null;

        this.getNavigationNodes = function () {
            dataLayer.ClearCachedEntityType("NavigationNodeDto");
            var query = dataLayer.CreateQuery("NavigationNodeDto");

            return manager.executeQuery(query)
                .then(function (queryResult) {
                    return queryResult;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        };

        this.getNavigationModulesFirstLevel = function () {
            var query = dataLayer.CreateQuery("NavigationModulesFirstLevel");

            return manager.executeQuery(query)
                .then(function (queryResult) {
                    return queryResult;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        };

        function parseFirstLevelNodes(nodes) {
            nodes = Lazy(nodes).reject({ Depth: 1 }).toArray();
            Lazy(nodes)
                .where({ Depth: 2 })
                .each((node) => {
                    node.ParentNavigationNodeId = null;
                });
            return nodes;
        }

        this.manageNavNodes = function (navNodes) {
            Lazy(navNodes)
                .each((node) => {
                    if (node.NavigationParam != null) {
                        node.NavigationParam = Nembus.Common.Library.converter.decodeHtml(node.NavigationParam);
                    }
                });

            this.currentNavNodes.allNodes = navNodes;

            var grouped = Lazy(navNodes).groupBy('ModuleGroupCode').toArray();
            Lazy(grouped)
                .each((group) => {
                    if (group[0] !== 'null' && group[1].length > 1) {
                        this.currentNavNodes.navigationPanel[group[0]] = parseFirstLevelNodes(group[1]);
                    }
                });
        };

        this.getReportDefinitionsForCmsView = function (filter, forceReload) {
            var params = { entityType: entityTypes.ReportDefinitionDto, cache: this.dataContext.reportDefinitionDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ReportDefinitionForCmsView");
        };

        this.synchroThesaurus = function () {
            var query = dataLayer.InvokeQuery("SynchroThesaurus", {});
            return manager.executeQuery(query);
        };

        this.persistNavigationModules = function () {
            var query = dataLayer.InvokeQuery("PersistNavigationModules", {});
            return manager.executeQuery(query);
        };


        this.synchroNotificationTemplates = function () {
            var query = dataLayer.InvokeQuery("SynchroNotificationTemplates", {});
            return manager.executeQuery(query);
        };

        this.updateAllXsdFiles = function () {
            var query = dataLayer.InvokeQuery("UpdateAllXsdFiles", {});
            return manager.executeQuery(query);
        };



        this.addRouterUIStateResolve = function (name, state) {
            try {
                // if route already exists an exception is throw
                angular.module('app').addRouterUIStateResolve(name, state);
            } catch (e) {
            }
        };

        this.addRouterUIStateDocumentation = function (name, state) {
            try {
                // if route already exists an exception is throw
                angular.module('app').addRouterUIStateDocumentation(name, state);
            } catch (e) {
            }
        };

        // params shared throw dashboards and widgets for set editable mode
        this.dashboardParams = {
            isDashboardLocked: true,						// like isLocked of contextService
            managementContractId: null,
            widgetsParams: {}
        };

        //{
        //usercode:'myusercode'
        //datacode:'surveylist-aud'
        //vc:1
        //dataname:'my data name',
        //datatype:'gridbar' //
        //}

        this.createNewUiData = function (uiParam) {
            var uiDataCode = null;
            if (uiParam.usercode === 'default') {
                uiDataCode = getUiDataCodeDefaultRef(uiParam);
            }
            else {
                uiDataCode = getUiDataCodeRef(uiParam);
            }
            return {
                uiDataOrder: 0,
                uiDataCode: uiDataCode,
                value: uiParam,
                filterList: [], //[{UiDataFilter1},{UiDataFilter2},...]
                filters: {
                    freeText: {
                        text: ''
                    }
                    //e.g. vhreventtype:[{uiOption1},{uiOption2},...]
                },
                columns: {
                    columns: [],
                    selected: []
                },
                grouping: {
                    columns: [],  //e.g. [{uiOption1},{uiOption2},...]
                    selected: []
                },
                sorting: {
                    direction: null, // e.g. ascending, descending
                    columns: [], //e.g. [{uiOption1},{uiOption2}]
                    selected: []
                },
                startData: null
            };
        };
        var createNewUiDataRef = this.createNewUiData;

        //todo: add api for store data server side
        this.putUiData = function (uiData) {
            var defer = $q.defer();
            nbsStorageService.putUiData(uiData)
                .then(function (data) {
                    defer.resolve(data);
                })
                .catch(function (error) {
                    defer.reject(error);
                });
            return defer.promise;
        };

        //todo: add api for store data server side
        //for delete id is required
        this.deleteUiData = function (uiData) {
            var defer = $q.defer();
            nbsStorageService.deleteUiData(uiData)
                .then(function () {
                    defer.resolve();
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };
        var deleteUiDataRef = this.deleteUiData;

        this.getUiDataCode = function (uiParam) {
            return uiParam.cmsviewcode + '-' + uiParam.datatype + '-' + uiParam.usercode + '-' + uiParam.vc;
        };
        var getUiDataCodeRef = this.getUiDataCode;

        this.getUiDataCodeDefault = function (uiParam) {
            return uiParam.cmsviewcode + '-' + uiParam.datatype + '-default-' + uiParam.vc;
        };
        var getUiDataCodeDefaultRef = this.getUiDataCodeDefault;

        //not called in current servcice only externally
        this.getUiDataAll = function (uiParam, startData) {
            var defer = $q.defer();

            getUiDataDefaultRef(uiParam, startData)
                .then(function (uiDataDefault) {

                    var uiDataCode = getUiDataCodeRef(uiParam);
                    nbsStorageService.getUiDataAll(uiDataCode)
                        .then(function (uiDatas) {
                            if (!uiDatas) { uiDatas = []; }
                            var result = [uiDataDefault];

                            if (Lazy(uiDatas).some()) {
                                Lazy(uiDatas)
                                    .each((currentUiData) => {
                                        setUiDataFilters(currentUiData, startData);
                                        result.push(currentUiData);
                                    });
                            }

                            defer.resolve(result);
                        })
                        .catch(function (error) {
                            defer.reject(error);
                        });
                });

            return defer.promise;
        };

        function setUiDataFilters(uiData, startData) {
            Lazy(uiData.filterList)
                .each((filter) => {
                    if (!Lazy(startData.filterList).where({ code: filter.code }).some()) {
                        uiData.filterList = Lazy(uiData.filterList).reject({ code: filter.code }).toArray();
                    }
                });


            // add eventual filter in uiDataDefault filtersList array
            Lazy(startData.filterList)
                .each((filter) => {
                    if (!Lazy(uiData.filterList).where({ code: filter.code }).some()) {
                        uiData.filterList.push(filter);
                    }
                });

            // add eventual property in uiDataDefault filters object
            Lazy(Object.keys(startData.filters))
                .each((p) => {
                    if (!uiData.filters[p]) {
                        uiData.filters[p] = startData.filters[p];
                    }
                });

            return uiData;
        }

        //todo: add api for fetch data server side
        this.getUiDataDefault = function (uiParam, startData) {
            var defer = $q.defer();
            var uiDataCodeDefault = uiParam.cmsviewcode + '-' + uiParam.datatype + '-' + 'default-' + uiParam.vc;

            nbsStorageService.getUiDataAll(uiDataCodeDefault)
                .then(function (currentUiDataDefault) {
                    if (currentUiDataDefault && currentUiDataDefault.length > 0) {

                        // Loading existent UiData default
                        var uiDataDefault = currentUiDataDefault[0];
                        if (startData
                            && uiDataDefault.startData
                            && uiDataDefault.startData.versionCode !== startData.versionCode) {

                            //reset uidatadefault with startdata params and update in browser
                            setStartDataToUIData(uiDataDefault, startData);
                            setUiDataFilters(uiDataDefault, startData);

                            // save uiDataDefault before returns
                            nbsStorageService.putUiData(uiDataDefault)
                                .then(function (data) {
                                    defer.resolve(uiDataDefault);
                                })
                                .catch(function (error) {
                                    defer.reject(error);
                                });
                        } else {
                            defer.resolve(uiDataDefault);
                        }
                    }
                    else {
                        // Loading new UiData default
                        uiParam.usercode = 'default';
                        uiParam.dataname = Nembus.Common.Library.cms.viewTitle(uiParam.cmsviewcode);

                        var newUiDataDefault = createNewUiDataRef(uiParam);
                        setStartDataToUIData(newUiDataDefault, startData);

                        nbsStorageService.putUiData(newUiDataDefault)
                            .then(function (data) {
                                defer.resolve(newUiDataDefault);
                            })
                            .catch(function (error) {
                                defer.reject(error);
                            });

                        ////todo: get from server
                        //defer.resolve(null);
                    }
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };
        var getUiDataDefaultRef = this.getUiDataDefault;

        function setStartDataToUIData(uiData, startData) {
            uiData.startData = startData;
            uiData.filterList = startData.filterList;
            uiData.filters = startData.filters;
            uiData.columns.columns = startData.columns.columns;
            uiData.columns.selected = startData.columns.selected;
            uiData.grouping.columns = startData.grouping.columns;
            uiData.grouping.selected = startData.grouping.selected;
            uiData.sorting.selected = startData.sorting.selected;
            uiData.sorting.direction = startData.sorting.direction;
        }

        this.putUiDataPreference = function (pParam, uiData) {
            var defer = $q.defer();
            var uiDataCode = getUiDataCodeRef(pParam);
            var uiDataCodeDefault = getUiDataCodeDefaultRef(pParam);


            nbsStorageService.deleteUiDataPreference({ uiDataCode: uiDataCode })
                .then(function () {
                    nbsStorageService.deleteUiDataPreference({ uiDataCode: uiDataCodeDefault })
                        .then(function () {
                            var uiDataPreference = {
                                uiDataCode: uiData.uiDataCode,
                                uiDataId: uiData.uiDataId
                            };
                            nbsStorageService.putUiDataPreference(uiDataPreference)
                                .then(function () {
                                    defer.resolve();
                                })
                                .catch(function (error) {
                                    defer.reject(error);
                                });
                        });
                })
                .catch(function (error) {
                    defer.reject(error);
                });



            return defer.promise;
        };

        this.clearUiDataDefault = function (uiParam) {
            var defer = $q.defer();
            uiParam.usercode = 'default';
            if (uiParam.vc) {
                uiParam.vc = null;
            }
            uiParam.dataname = Nembus.Common.Library.cms.viewTitle(uiParam.cmsviewcode);
            getUiDataDefaultRef(uiParam).
                then(res => {
                    res.filters = res.startData.filters;
                    res.filterList = res.startData.filterList;
                    res.columns.selected = res.startData.columns.selected;
                    res.grouping.selected = res.startData.grouping.selected;
                    res.sorting.selected = res.startData.sorting.selected;
                    res.sorting.direction = res.startData.sorting.direction;
                    //todo: 
                    nbsStorageService.putUiData(res)
                        .then(function (data) {
                            defer.resolve(res);
                        })
                        .catch(function (error) {
                            defer.reject(error);
                        });

                });
            return defer.promise;
        };

        //todo: add api for store data server side
        //for delete id is required
        this.deleteUiDataPreference = function (uiData) {
            var defer = $q.defer();

            nbsStorageService.deleteUiDataPreference(uiData)
                .then(function () {
                    defer.resolve();
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };
        var deleteUiDataPreferenceRef = this.deleteUiDataPreference;

        //todo: add api for fetch data server side
        this.getUiDataPreference = function (uiParam) {
            var defer = $q.defer();
            var uiDataCode = getUiDataCodeRef(uiParam);

            nbsStorageService.getUiDataPreference(uiDataCode)
                .then(function (uiDataPreference) {
                    getUiDataDefaultRef(uiParam)
                        .then(function (uiDataDefault) {
                            if (uiDataPreference) {
                                nbsStorageService.getUiDataSingle(uiDataPreference.uiDataId)
                                    .then(function (res) {
                                        if (res) {
                                            defer.resolve(res);
                                        }
                                        else {
                                            deleteUiDataPreferenceRef({ uiDataCode: uiDataCode })
                                                .then(function () {
                                                    defer.resolve(uiDataDefault);
                                                });
                                        }
                                    })
                                    .catch(function (error) {
                                        defer.reject(error);
                                    });
                            }
                            else {
                                defer.resolve(uiDataDefault);
                            }
                        });
                })
                .catch(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        };
    };

    cmsSvc.$inject = injectParams;
    cmsSvc.prototype = Object.create(nbsService.prototype);

    angular.module('nbs.shared.module').service('cmsService', cmsSvc);
});