﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$scope', '$timeout', 'navigationService', 'contextService', 'utilService', 'fileTransferService'];
    var nbsAttachmentsComponent = function ($q, $scope, $timeout, navigationService, contextService, utilService, fileTransferService) {

        var vm = this;
        var pDataContext = null;

        var pTree = null;
        var pTreeDatasource = [];
        var pFsRootFolderId = null; // if null, no attachments uploaded
        var pFileMetadataRequestType = null;
        var pFsType = null;
        var pRootFolderActioFn = null;
        var pCanShare = false;
        var pBypassAuth = false;

        // sorts default
        vm.sortId = 'date';
        vm.isDescending = false;

        vm.fsDtoData = null;
        vm.folderSelected = null;
        vm.thumbnails = [];
        vm.havePermissionToShare = false;
        vm.attachmentList = [];
        vm.isLocked = true;


        //used by upload
        vm.files = [];

        vm.$onChanges = function (changes) {
            if (changes.nbsDatacontext && changes.nbsDatacontext.currentValue) {
                pDataContext = changes.nbsDatacontext.currentValue;
                pFsRootFolderId = pDataContext.fsFolderId;
                if (!pFsRootFolderId) {
                    console.error('nbsAttachmentsComponent: pFsRootFolderId is mandatory');
                }
                pRootFolderActioFn = utilService.getFsDtoRootAddFunction(pFsRootFolderId);
                pCanShare = pDataContext.canShare === 'true' || pDataContext.canShare === true ? true : false;
                pBypassAuth = pDataContext.bypassAuth === 'true' || pDataContext.bypassAuth === true ? true : false;

                vm.txtAttachmentSearch = null;

                // grid or thumbnail default
                vm.isListMode = true;
                vm.isThumbnailMode = false;
                vm.havePermissionToShare = pCanShare;

                pFileMetadataRequestType = Nembus.Common.Library.util.parseEnum(utilService.dataContext.enumTypes.FileMetadataRequestType, null, vm.uiContext.translate).obj.normal.name_value;
                pFsType = utilService.fsType;

                vm.fsDtoData = new NbsFsDtoData({
                    fileTransferService: fileTransferService,
                    virtualContext: contextService.virtualContext,
                    translator: vm.uiContext.translate,
                    thumbnailDatasourceIsGrouped: true,
                    formatDateTimeFn: Nembus.Common.Library.converter.date.formatDateTime,
                    nbsGuidManager: NbsGuidManager
                });

                createTree()
                    .then(function () {
                        getFsDtos(true);
                    });
            }

            if (changes.nbsRefresh && changes.nbsRefresh.currentValue) {
                getFsDtos(true);
            }

            if (changes.isReadonly) {
                if (changes.isReadonly.currentValue === true) {
                    vm.isLocked = true;
                } else {
                    vm.isLocked = false;
                }
            }
        };

        vm.txtAttachmentSearchOnChange = function () {
            refreshList();
        };

        vm.setSorting = function (sortfield) {
            vm.sortId = sortfield;
            vm.isDescending = !vm.isDescending;

            refreshList();
        };

        vm.rowDblClick = function (args) {
            vm.downloadFile(args.data);
        };

        vm.onFileUploaded = function (file, res) {
            //vm.removeAlerts();
            var dData = {
                fileId: file.id,
                displayFileName: file.name
            };
            vm.uploadfileData = { SourceFileName: file.name };

            var addedFolderRoot = null;
            if (!vm.fsDtoData.hasRootNode()) {
                addedFolderRoot = vm.fsDtoData.getNewRootNode();
                utilService.dataContext.fsDtos.add(addedFolderRoot);
            }

            var fs = {
                FsDtoId: NbsGuidManager.newGuid(),
                AuthCode: getFileMetadataAuthCode(contextService.virtualContext),
                ParentFsDtoId: addedFolderRoot ? addedFolderRoot.FsDtoId : vm.folderSelected.FsDtoId,
                FsName: file.name,
                DisplayName: file.name,
                DisplayFileName: file.name,
                FsTypeId: pFsType.file,
                VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId,
                FileNotSavedYet: true
            };

            fs.TemporaryFileName = fileTransferService.temporaryFileNameByFileId(dData);

            utilService.dataContext.fsDtos.add(fs);
            // submit fsdtos
            submit()
                .then(function () {
                    vm.txtAttachmentSearch = null;
                    getFsDtos(true);
                });
        };

        vm.openFolderModal = function (action) {
            if (!vm.folderSelected) {
                navigationService.customMessageModal(vm.uiContext.translate('SelectFolder'));
                return;
            }

            if (action === 'add') {
                // adding
                vm.fsDtoClientData = utilService.dataContext.fsDtoAttachmentClientDatas.add();
            } else if (action === 'update') {
                if (isRootNode(vm.folderSelected)) {
                    navigationService.customMessageModal(vm.uiContext.translate('OperationNotPermitted'));
                    return;
                }

                // updating folder
                vm.fsDtoClientData = utilService.dataContext.fsDtoAttachmentClientDatas.add(vm.folderSelected);
            }

            vm.modalInstance = navigationService.modal().open({ templateUrl: 'fsDtoFolderTemplate', scope: $scope });
            if (action === 'add') {
                // adding
                vm.modalInstance.title = vm.uiContext.translate('AddFolder');
            } else if (action === 'update') {
                // updating folder
                vm.modalInstance.title = vm.uiContext.translate('UpdateFolder');
            }
            vm.modalInstance.ok = function () {
                vm.fsDtoClientData.entityAspect.validateEntity();

                if (!vm.fsDtoClientData.entityAspect.hasValidationErrors) {
                    vm.modalInstance.close();
                }
            };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
            vm.modalInstance.result.then(function () {
                vm.fsDtoClientData.entityAspect.rejectChanges();

                if (action === 'add') {
                    var addedFolderRoot = null;
                    if (!vm.fsDtoData.hasRootNode()) {
                        // add root
                        addedFolderRoot = vm.fsDtoData.getNewRootNode();
                        utilService.dataContext.fsDtos.add(addedFolderRoot);
                    }

                    // add folder
                    var folder = utilService.dataContext.fsDtos.add({
                        FsDtoId: NbsGuidManager.newGuid(),
                        ParentFsDtoId: addedFolderRoot ? addedFolderRoot.FsDtoId : vm.folderSelected.FsDtoId,
                        FsTypeId: pFsType.folder,
                        FsCode: vm.fsDtoClientData.FsCode,
                        FsName: vm.fsDtoClientData.FsName,
                        FsDescription: vm.fsDtoClientData.FsDescription,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                    });
                } else if (action === 'update') {
                    // updating folder/file
                    vm.folderSelected.FsCode = vm.fsDtoClientData.FsCode;
                    vm.folderSelected.FsName = vm.fsDtoClientData.FsName;
                    vm.folderSelected.FsDescription = vm.fsDtoClientData.FsDescription;
                }

                // submit fsdtos
                submit()
                    .then(function () {
                        getFsDtos(false);
                    });


            }, function () {
                vm.fsDtoClientData.entityAspect.rejectChanges();
            });
        };

        vm.openFileModal = function (file) {
            // updating file
            vm.currentFile = file;

            vm.modalInstance = navigationService.modal().open({ templateUrl: 'fsDtoFileTemplate', scope: $scope });
            vm.modalInstance.ok = function () { vm.modalInstance.close(); };
            vm.modalInstance.cancel = function () { vm.modalInstance.dismiss(); };
            vm.modalInstance.result.then(function () {
                // submit fsdtos
                submit()
                    .then(function () {
                        refreshList(false);
                    });

            }, function () {
                vm.currentFile.entityAspect.rejectChanges();
            });
        };

        vm.deleteFolder = function () {
            if (!vm.folderSelected) {
                navigationService.customMessageModal(vm.uiContext.translate('SelectFolder'));
                return;
            }

            if (isRootNode(vm.folderSelected)) {
                navigationService.customMessageModal(vm.uiContext.translate('OperationNotPermitted'));
                return;
            }

            navigationService.confirmModal(vm.uiContext.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        utilService.deleteEntity(vm.folderSelected);
                        submit()
                            .then(function () {
                                vm.folderSelected = null;
                                getFsDtos(false);
                            });
                    }
                });
        };

        vm.deleteFile = function (file) {
            navigationService.confirmModal(vm.uiContext.translate("Continue"))
                .then(function (result) {
                    if (result) {
                        utilService.deleteEntity(file);
                        submit()
                            .then(function () {
                                getFsDtos(false);
                            });
                    }
                });
        };

        vm.switchToList = function () {
            vm.isListMode = true;
            vm.isThumbnailMode = false;

            //refreshList();
        };

        vm.switchToThumbnails = function () {
            vm.isListMode = false;
            vm.isThumbnailMode = true;

            //refreshList();
        };

        vm.shareAttachmentAction = function (file) {
            if (!vm.havePermissionToShare) {
                navigationService.userNotAuthorizedMessageModal();
                return;
            }

            // object binded to form on UI
            vm.shareFilemetadata = { IsShared: true };

            vm.modalInstance = navigationService.modal().open({ templateUrl: 'shareFileMetadataTemplate', scope: $scope });

            var fileMetadataTypeId = null;
            if (file.FsTypeId === pFsType.folder) {
                vm.modalInstance.title = vm.uiContext.translate('ShareFolder');
                fileMetadataTypeId = pFsType.folder;
            } else if (file.FsTypeId === pFsType.file) {
                vm.modalInstance.title = vm.uiContext.translate('ShareFile');
                fileMetadataTypeId = pFsType.file;
            }

            vm.modalInstance.ok = function () {
                // create a FilemetadataRequest (change permissions)
                var fileMetadataPermissionRequest = utilService.dataContext.fileMetadataPermissionRequests.add({

                    FileMetadataId: file.FsDtoId,
                    AuthCode: vm.shareFilemetadata.IsShared
                        ? Nembus.Rds.Library.auth.Permission['FMDPUBLIC'].code
                        : Nembus.Rds.Library.auth.Permission['FMDPRIVATE'].code,

                    FileMetadataRequestTypeId: pFileMetadataRequestType.SetPermission,
                    FileMetadataRequestFileTypeId: fileMetadataTypeId
                });

                submit()
                    .then(function () {
                        getFsDtos(true)
                            .then(function () {
                                vm.modalInstance.close();
                            });
                    });
            };
            vm.modalInstance.cancel = function () {
                vm.modalInstance.dismiss();
            };
            vm.modalInstance.result.then(function () {

            }, function () {
                //dismiss
                //utilService.rejectChanges();
            });
        };

        vm.downloadFile = function (file, asAttach) {
            if (!asAttach) {
                asAttach = false;
            }
            fileTransferService.downloadRemoteFileByGuid({
                entityGuid: file.FsDtoId,
                asAttach: asAttach
            });
        };

        function getFsDtos(forceReload) {
            var filter = { FsFolderId: pFsRootFolderId };
            var defer = $q.defer();

            if (pBypassAuth) {
                // no auth
                utilService.genericFsDtosNoAuth(filter, forceReload)
                    .then(function (data) {
                        vm.fsDtoData.setFsDtos(data.results, vm.havePermissionToShare);
                        refreshUI();
                        defer.resolve(true);
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });
            } else {
                utilService.genericFsDtos(filter, forceReload)
                    .then(function (data) {
                        vm.fsDtoData.setFsDtos(data.results, vm.havePermissionToShare);
                        refreshUI();
                        defer.resolve(true);
                    })
                    .catch(function (error) {
                        vm.setErrorMsg(error);
                    });
            }


            return defer.promise;
        }

        function refreshUI() {
            refreshTree();
            refreshList();
        }

        function refreshTree() {
            if (!pTree) {
                return;
            }

            var rootNodeId = null;
            if (Lazy(vm.fsDtoData.getFsDtos()).some()) {
                if (vm.fsDtoData.hasRootNode()) {
                    var rootNode = vm.fsDtoData.getRootNode();
                    rootNode.ParentFsDtoId = null;
                    rootNode.entityAspect.setUnchanged();
                    rootNodeId = rootNode.FsDtoId;
                }

                pTreeDatasource = vm.fsDtoData.getFolders();
                Lazy(pTreeDatasource)
                    .each((node) => {
                        node.FsFileCount = vm.fsDtoData.getFolderFiles(node).length;
                        node.Expanded = true;
                    });
            } else {
                if (!vm.fsDtoData.hasRootNode()) {
                    var pRootTmp = {
                        FsDtoId: pFsRootFolderId,
                        ParentFsDtoId: null,
                        FsName: vm.fsDtoData.getFsFolderRootName(),
                        FsTypeId: pFsType.folder,
                        Depth: 0,
                        FsFileCount: 0,
                        VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                    };

                    rootNodeId = pRootTmp.FsDtoId;
                    pTreeDatasource = [pRootTmp];
                }
            }

            pTree.dataSource([]);
            pTree.refresh();

            pTree.dataSource(pTreeDatasource);
            pTree.refresh();
            pTree.expandAll();

            if (!vm.folderSelected) {
                pTree.selectNode(rootNodeId);
            }
        }

        function filterFile(file) {
            // filter files by txt search
            if (!vm.txtAttachmentSearch) {
                return true;
            }

            return Lazy(file.DisplayName.toLowerCase()).contains(vm.txtAttachmentSearch.toLowerCase()) || Lazy(file.DisplayFileName.toLowerCase()).contains(vm.txtAttachmentSearch.toLowerCase());
        }

        function sortFile(file) {
            if (vm.sortId === 'name') {
                return file.DisplayFileName;
            }
            if (vm.sortId === 'description') {
                return file.DisplayName;
            }
            if (vm.sortId === 'date') {
                return file.CreationDate;
            }
        }

        function refreshList() {
            $timeout(function () {
                // list 
                if (vm.folderSelected) {
                    vm.attachmentList = Lazy(vm.fsDtoData.getFolderFiles(vm.folderSelected)).filter(filterFile).sortBy(sortFile, vm.isDescending).toArray();
                }

                // thumbnails
                vm.thumbnails = [];
                if (vm.folderSelected) {
                    Lazy(vm.fsDtoData.thumbnailDatasource)
                        .each((group) => {
                            Lazy(group.files)
                                .each((file) => {
                                    file.thumbnailIsChecked = false;
                                });

                            var tmp = {
                                name: group.name,
                                files: Lazy(group.files).where({ ParentFsDtoId: vm.folderSelected.FsDtoId }).filter(filterFile).sortBy(sortFile, vm.isDescending).toArray()
                            };

                            if (Lazy(tmp.files).some()) {
                                vm.thumbnails.push(tmp);
                            }
                        });
                }
            });
        }

        function createTree() {
            var defer = $q.defer();

            if (!pTree) {
                $('#nbsTreeViewAttachments').ejTreeView({
                    allowEditing: false,
                    fields: {
                        dataSource: [],
                        id: 'FsDtoId', parentId: 'ParentFsDtoId', text: 'FsName', expanded: 'Expanded'
                    },
                    template: '#nbsTreeAttachmentsTemplate',
                    nodeSelect: function (args) {
                        vm.folderSelected = Lazy(pTreeDatasource).findWhere({ FsDtoId: args.id });
                        refreshList(true);
                    },
                    create: function (args) {
                        defer.resolve(true);
                    }
                });

                pTree = $('#nbsTreeViewAttachments').data('ejTreeView');
            } else {
                defer.resolve(true);
            }

            return defer.promise;
        }

        function getFileMetadataAuthCode(virtualContext) {
            var fmdPrivatePermission = Nembus.Rds.Library.auth.Permission['FMDPRIVATE'];
            var fmdPublicPermission = Nembus.Rds.Library.auth.Permission['FMDPUBLIC'];
            var userHasFmdPrivatePermission = Nembus.Common.Library.auth.checkGlobalPermissionCode(fmdPrivatePermission, virtualContext) > 0;
            var userHasFmdPublicPermission = Nembus.Common.Library.auth.checkGlobalPermissionCode(fmdPublicPermission, virtualContext) > 0;

            if (userHasFmdPrivatePermission) {
                return fmdPrivatePermission.code;
            } if (!userHasFmdPrivatePermission && userHasFmdPublicPermission) {
                return fmdPublicPermission.code;
            } else {
                console.error('AuthCode error selection');
            }
        };

        function isRootNode(node) {
            return node.FsName === vm.fsDtoData.getFsFolderRootName() && node.Depth === 0;
        }

        function submit() {
            var defer = $q.defer();

            // submit fsdtos
            utilService.saveChanges()
                .then(function (saveResult) {
                    if (pRootFolderActioFn && !vm.fsDtoData.hasRootNode()) {
                        // update root folder id to current event
                        var rootSubmitted = Lazy(saveResult.entities).filter((e) => {
                            return e.entityType.shortName === 'FsDto' && e.FsName === vm.fsDtoData.getFsFolderRootName();
                        }).first();

                        if (rootSubmitted) {
                            pFsRootFolderId = rootSubmitted.FsDtoId;
                            vm.folderSelected.FsDtoId = rootSubmitted.FsDtoId;

                            pRootFolderActioFn.fnSetRootFolder(pFsRootFolderId);
                            pRootFolderActioFn.entityService.saveChanges().then(
                                function (saveResult) {
                                    utilService.clearFsDtoRootAddFunction(pFsRootFolderId);
                                    defer.resolve(true);
                                });
                        } else {
                            defer.resolve(true);
                        }
                    } else {
                        defer.resolve(true);
                    }
                });

            return defer.promise;
        }
    };

    nbsAttachmentsComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsAttachments', {
        templateUrl: '/rds/common/components/nbsAttachmentsComponent/_?c=' + window.codeCache(),
        controller: nbsAttachmentsComponent,
        controllerAs: 'vm',
        bindings: {
            nbsDatacontext: '<',
            nbsRefresh: '<',
            uiContext: '<',
            isReadonly: '<'
        }
    });
});

