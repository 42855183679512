﻿'use strict';
define([], function () {

    var injectParams = ['$q', '$timeout', 'foundationService'];
    var nbsVhrTargetBarComponent = function ($q, $timeout, foundationService) {
        var vm = this;
        var privateDataContext = null;
        var privateUIData = null;
        var defaultKeys;
        var nbsUiOptionParser = null;

        vm.privateComponentsStatus = {};
        vm.nbsDataPrivate = {};

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changes) {
            if (changes.nbsDataContext && changes.nbsDataContext.currentValue) {
                nbsUiOptionParser = new NbsUiOptionParser(vm.uiContext.translate);

                privateDataContext = changes.nbsDataContext.currentValue;

                // set default visible columns list in grid
                defaultKeys = changes.nbsDataContext.currentValue.defaultKeys;

                // Start of execution workflow of load data bar components 
                initViewTitle();
            }
        };

        function initViewTitle() {
            var cols = ['VirtualHumanResourceId', 'TargetCode', 'TargetName', 'TargetGroupName', 'TargetTypeName'];
            var defaultStartData = new NbsUiData();
            defaultStartData.setColumns(cols);
            defaultStartData.setFilterList(getFilterList());

            var sortField = 'TargetCode';
            var sortDirection = false; // ascending
            //switch (defaultKeys.sortingKey) {

            //}
            defaultStartData.setSortingField(sortField, sortDirection);

            vm.initViewTitle = defaultStartData.startData;
        }

        //#region hook from components
        vm.onViewTitleSelectionEvent = function (result) {
            privateUIData = JSON.parse(JSON.stringify(result.uiData));
            setComponents(privateUIData);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onUiChanges = function (result) {
            privateUIData.columns = result.uiData.columns;
            privateUIData.grouping = result.uiData.grouping;
            privateUIData.sorting = result.uiData.sorting;
            privateUIData.filters.freeText = result.uiData.filters.freeText;

            refreshViewTitleDataContext(privateUIData);

            vm.onGridUiChangesEvent({ result: privateUIData });
        };
        vm.onFilterChanges = function (result) {
            privateUIData.filterList = result.filters.filterList;

            refreshViewTitleDataContext(privateUIData);
            setStatusComponents(privateUIData.filterList);

            setBarDatasources()
                .then(function () {
                    barOnChanges(privateUIData);
                });
        };
        vm.onDdMsVhrGroups = function (options) {
            privateUIData.filters.vhrgroups = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        vm.onDdMsVhrTypes = function (options) {
            privateUIData.filters.vhrtypes = options;
            refreshViewTitleDataContext(privateUIData);
            barOnChanges(privateUIData);
        };
        //#endregion

        // refresh current preference uiData
        function refreshViewTitleDataContext(uiDataParam) {
            vm.viewTitleDataContext = Nembus.Common.Library.component.getDataContextViewTitle(uiDataParam);
        }

        function setBarDatasources() {
            var defer = $q.defer();
            var funcsPromises = [];

            if (vm.privateComponentsStatus.vhrgroups.isVisible) {
                funcsPromises.push(getVhrGroups(false));
            }
            if (vm.privateComponentsStatus.vhrtypes.isVisible) {
                funcsPromises.push(getVhrTypes(false));
            }

            $q.all(funcsPromises)
                .then(function () {
                    defer.resolve(true);
                });

            return defer.promise;
        }

        function getFilterList() {
            var uiDataFilters = [];
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrtypes', name: 'TargetType' }));
            uiDataFilters.push(new NbsUiDataFilter({ code: 'vhrgroups', name: 'TargetGroup' }));
            return uiDataFilters;
        }

        function getVhrTypes(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrTypes(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrtypes = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrType');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function getVhrGroups(forceReload) {
            var filter = {};
            var defer = $q.defer();

            foundationService.getVhrGroups(filter, forceReload)
                .then(function (data) {
                    vm.nbsDataPrivate.vhrgroups = nbsUiOptionParser.getUiOptionsByKey(data.results, 'VhrGroup');
                    defer.resolve(true);
                })
                .catch(function (error) {
                    vm.setErrorMsg(error);
                    defer.reject(error);
                });

            return defer.promise;
        }

        function setComponents(uiDataParam) {
            setCommonGridBarDataContext(uiDataParam);
            setSelectionComponents(uiDataParam);
            setStatusComponents(uiDataParam.filterList);
            vm.onGridUiChangesEvent({ result: privateUIData });
        }

        function setCommonGridBarDataContext(uiDataParam) {
            vm.commonGridBarDataContext = Nembus.Common.Library.component.getDataContextCommonGridBar(uiDataParam);
            vm.commonBarDatasource = privateDataContext.commonBarDatasource
        }

        function setSelectionComponents(uiDataParam) {
            vm.nbsDataPrivate.vhrgroupsselected = uiDataParam.filters.vhrgroups ? uiDataParam.filters.vhrgroups : [];
            vm.nbsDataPrivate.vhrtypesselected = uiDataParam.filters.vhrtypes ? uiDataParam.filters.vhrtypes : [];
        }

        function setStatusComponents(filterList) {
            vm.privateComponentsStatus.vhrgroups = Nembus.Common.Library.component.getStatus('vhrgroups', filterList);
            vm.privateComponentsStatus.vhrtypes = Nembus.Common.Library.component.getStatus('vhrtypes', filterList);
        }

        function barOnChanges(uiDataParam) {
            var selectedVhrGroupsId = vm.privateComponentsStatus.vhrgroups.isVisible
                ? Lazy(uiDataParam.filters.vhrgroups).pluck('optionValue').toArray()
                : [];

            var selectedVhrTypesId = vm.privateComponentsStatus.vhrtypes.isVisible
                ? Lazy(uiDataParam.filters.vhrtypes).pluck('optionValue').toArray()
                : [];

            $timeout(() => {
                vm.onBarChanges({
                    result: {
                        targetFilter: {
                            VhrGroupsId: selectedVhrGroupsId,
                            VhrTypesId: selectedVhrTypesId
                        }
                    }
                });
            });
        }
        //#endregion 
    };


    nbsVhrTargetBarComponent.$inject = injectParams;
    angular.module('nbs.rds.module').component('nbsVhrTargetBar', {
        templateUrl: '/rds/targets/components/nbsVhrTargetBarComponent/_?c=' + window.codeCache(),
        controller: nbsVhrTargetBarComponent,
        controllerAs: 'vm',
        bindings: {
            onBarChanges: '&',
            onGridUiChangesEvent: '&',

            nbsDataContext: "<",
            uiContext: "<"
        }
    });
});

