﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var actService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('act', true);
        var manager = dataLayer.GetManager();
        var ejDataManager = dataLayer.GetEjDataManager();
        var entityTypes = {
            Act: 'Act',
            ActDto: 'ActDto',
            ActJournal: 'ActJournal',
            ActJournalDto: 'ActJournalDto',
            ActChangeHistoryDto: 'ActChangeHistoryDto',
            ActCreationRequest: 'ActCreationRequest',
            ActActionGroup: 'ActActionGroup',
            ActActionType: 'ActActionType',

            ActM2MCategoryDto: 'ActM2MCategoryDto',
            ActM2MDrbacUserGroupDto: 'ActM2MDrbacUserGroupDto',
            ActM2MAppUserDto: 'ActM2MAppUserDto',

            ActMassivelyUpdateRequest: 'ActMassivelyUpdateRequest',
            ActMassivelyUpdateWarning: 'ActMassivelyUpdateWarning',

            ActValidationSchemaConfig: 'ActValidationSchemaConfig'
        };
        var me = this;
        this.entityTypes = entityTypes;

        // add client validation based on schema validation related to survey/surveyTemplate
        this.customizeActDtoValidation = function (validationSchema) {
            if (!validationSchema) {
                return;
            }

            var actDto = manager.metadataStore.getEntityType(entityTypes.ActDto);

            Nembus.Rds.Library.ParseActValidationSchemaValidation(validationSchema);

            var validationsList = [
                { toValidate: validationSchema.Validation.Category, fieldName: 'CategoriesSerialized', valName: 'categoryValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.Description, fieldName: 'ActDescription', valName: 'descriptionValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator },
                { toValidate: validationSchema.Validation.UserAssigned, fieldName: 'UsersAssignedSerialized', valName: 'userValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.UserGroupAssigned, fieldName: 'UserGroupsAssignedSerialized', valName: 'userGroupValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.ExpireDate, fieldName: 'ActExpireDate', valName: 'expireValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator }
            ];

            Lazy(validationsList)
                .each((item) => {
                    if (item.toValidate) {
                        // add validation
                        if (!Lazy(actDto.getProperty(item.fieldName).validators).where({ name: item.valName }).some()) {
                            actDto.getProperty(item.fieldName).validators.push(new breeze.Validator(item.valName, item.valFn));
                        }
                    } else {
                        // remove validation
                        actDto.getProperty(item.fieldName).validators = Lazy(actDto.getProperty(item.fieldName).validators)
                            .filter((validator) => { return validator.name !== item.valName; }).toArray();
                    }
                });
        };

        // add client validation based on schema validation related to ActCreationRequest (new action in audit)
        this.customizeActCreationRequestValidation = function (validationSchema) {
            if (!validationSchema) {
                return;
            }

            var actCreationRequest = manager.metadataStore.getEntityType(entityTypes.ActCreationRequest);

            Nembus.Rds.Library.ParseActValidationSchemaValidation(validationSchema);

            var validationsList = [
                { toValidate: validationSchema.Validation.Category, fieldName: 'CategoriesIdSerialized', valName: 'categoryValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.Description, fieldName: 'ActDescription', valName: 'descriptionValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator },
                { toValidate: validationSchema.Validation.UserAssigned, fieldName: 'UsersAssignedIdSerialized', valName: 'userValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.UserGroupAssigned, fieldName: 'UserGroupsAssignedIdSerialized', valName: 'userGroupValidator', valFn: Nembus.Common.Library.validators.requiredFieldSerializedValidator },
                { toValidate: validationSchema.Validation.ExpireDate, fieldName: 'ActExpireDate', valName: 'expireValidator', valFn: Nembus.Common.Library.validators.requiredFieldValidator }
            ];

            Lazy(validationsList)
                .each((item) => {
                    if (item.toValidate) {
                        // add validation
                        if (!Lazy(actCreationRequest.getProperty(item.fieldName).validators).where({ name: item.valName }).some()) {
                            actCreationRequest.getProperty(item.fieldName).validators.push(new breeze.Validator(item.valName, item.valFn));
                        }
                    } else {
                        // remove validation
                        actCreationRequest.getProperty(item.fieldName).validators = Lazy(actCreationRequest.getProperty(item.fieldName).validators)
                            .filter((validator) => { return validator.name !== item.valName; }).toArray();
                    }
                });
        };

        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            acts: dataLayer.setContextMethods(manager, nbsService, entityTypes.Act),
            actActionGroups: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActActionGroup),
            actActionTypes: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActActionType),
            actDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActDto),
            actJournals: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActJournal),
            actJournalDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActJournalDto),
            actChangeHistoriesDto: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActChangeHistoryDto),
            actM2MCategoryDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActM2MCategoryDto),
            actM2MDrbacUserGroupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActM2MDrbacUserGroupDto),
            actM2MAppUserDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActM2MAppUserDto),
            actMassivelyUpdateRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActMassivelyUpdateRequest),
            actMassivelyUpdateWarnings: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActMassivelyUpdateWarning),
            actValidationSchemaConfigs: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActValidationSchemaConfig),
            actCreationRequests: dataLayer.setContextMethods(manager, nbsService, entityTypes.ActCreationRequest),
            entityJournalStatus: dataLayer.GetEnumType('EntityJournalStatus'),

            enumTypes: {
                ActType: dataLayer.GetEnumType('ActType'),                                                          // pre, post, ...
                ActStatus: dataLayer.GetEnumType('ActStatus'),                                                      // none, open, ...
                ActPriority: getPriorityEnumCustomOrdered(dataLayer.GetEnumType('ActPriority')),                    // low, medium, ...       
                ActMassivelyUpdateRequestStatus: dataLayer.GetEnumType('ActMassivelyUpdateRequestStatus'),
                ActMassivelyUpdateRequestType: dataLayer.GetEnumType('ActMassivelyUpdateRequestType'),

                // uset in act-service too
                WorkflowStatusKind: dataLayer.GetEnumType('WorkflowStatusKind')
            },

            constants: {}
        };

        this.setDataLayerPrefix = function (xx) {
            dataLayer = breezeDataLayer.Initialize(xx, true);
        };

        function getPriorityEnumCustomOrdered(priorityEnum) {
            var result = [];
            var veyhigh = Lazy(priorityEnum).findWhere({ name: 'VeryHigh' });
            var high = Lazy(priorityEnum).findWhere({ name: 'High' });
            var medium = Lazy(priorityEnum).findWhere({ name: 'Medium' });
            var low = Lazy(priorityEnum).findWhere({ name: 'Low' });


            if (veyhigh) { result.push(veyhigh); }
            if (high) { result.push(high); }
            if (medium) { result.push(medium); }
            if (low) { result.push(low); }

            return result;
        }

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // ActActionGroup
        this.getActActionGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActActionGroup, cache: this.dataContext.actActionGroups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActActionGroup");
        };
        this.getActActionGroup = function (filter, forceReload) {
            var params = { key: filter.ActActionGroupId, dataContext: this.dataContext.actActionGroups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActActionGroup");
        };

        // ActActionType
        this.getActActionTypes = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActActionType, cache: this.dataContext.actActionTypes.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActActionType");
        };
        this.getActActionType = function (filter, forceReload) {
            var params = { key: filter.ActActionTypeId, dataContext: this.dataContext.actActionTypes };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActActionType");
        };

        // ActJournal (dto)
        this.getActJournalDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActJournal, cache: this.dataContext.actJournalDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActJournalDtos");
        };

        // ActChangeHistoryDto
        this.getActChangeHistoriesDto = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActChangeHistoryDto, cache: this.dataContext.actChangeHistoriesDto.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActChangeHistories");
        };

        // ActM2MCategoryDto
        this.getActM2MCategories = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActM2MCategoryDto, cache: this.dataContext.actM2MCategoryDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActM2MCategoryDto");
        };

        // ActM2MDrbacUserGroupDto
        this.getActM2MDrbacUserGroups = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActM2MDrbacUserGroupDto, cache: this.dataContext.actM2MDrbacUserGroupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActM2MDrbacUserGroupDto");
        };

        // ActM2MAppUserDto
        this.getActM2MAppUsers = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActM2MAppUserDto, cache: this.dataContext.actM2MAppUserDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActM2MAppUserDto");
        };

        this.getActMassivelyUpdateRequests = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActMassivelyUpdateRequest, cache: this.dataContext.actMassivelyUpdateRequests.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActMassivelyUpdateRequests");
        };
        this.getActMassivelyUpdateRequest = function (filter, forceReload) {
            var params = { key: filter.ActMassivelyUpdateRequestId, dataContext: this.dataContext.actMassivelyUpdateRequests };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActMassivelyUpdateRequests");
        };

        //#region Post methods
        this.updateActExpireDateBulk = function (actClientData) {
            var query = dataLayer.InvokeQuery("UpdateActExpireDateBulk", actClientData);
            return manager.executeQuery(query);
        };
        //#endregion

        // Act (dto)
        this.getActDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.ActDto, cache: this.dataContext.actDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "ActDtos");
        };
        this.getActDto = function (filter, forceReload) {
            var params = { key: filter.ActId, dataContext: this.dataContext.actDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActDto");
        };

        // acts paged (with audit filters)
        this.getSearchActsPagedDm = function () {
            return ejDataManager.getEjManager('SearchActDtosPaged');
        };
        this.getSearchActsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchActDtosPaged');
            return dmObj;
        };

        // ActValidationSchemaConfig
        this.getActValidationSchemaConfig = function (filter, forceReload) {
            var params = { key: filter.ActValidationSchemaConfigId, dataContext: this.dataContext.actValidationSchemaConfigs };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "ActValidationSchemaConfig");
        };

        this.getSearchMyActsPagedDm = function () {
            return ejDataManager.getEjManager('SearchMyActDtosPaged');
        };
        this.getSearchMyActsPagedDmQuery = function () {
            var dmObj = {};
            dmObj.dm = ejDataManager.getEjManager('');
            dmObj.query = ej.Query().from('SearchMyActDtosPaged');
            return dmObj;
        };

        this.actMassivelyUpdateRequestManageUpdateWorkflow = function (contextService, translator, actIds, params) {
            var defer = $q.defer();

            var parsedIds = [];
            Lazy(actIds)
                .each((id) => {
                    if (id) {
                        parsedIds.push(id);
                    }
                });

            if (!Lazy(parsedIds).some()) {
                defer.reject({});
            } else {
                var actFilter = { ActIds: parsedIds };
                var actMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(this.dataContext.enumTypes.ActMassivelyUpdateRequestType, null, translator).obj.normal.name_value;
                this.actMassivelyUpdateRequestManage(contextService, actFilter, params, actMassivelyUpdateRequestType.UpdateWorkflow)
                    .then(() => {
                        defer.resolve(true);
                    }).catch(function (error) {
                        defer.reject(error);
                    });
            }

            return defer.promise;
        };

        this.actMassivelyUpdateRequestManageDeleteAct = function (contextService, translator, actIds) {
            var defer = $q.defer();

            var parsedIds = [];
            Lazy(actIds)
                .each((id) => {
                    if (id) {
                        parsedIds.push(id);
                    }
                });

            if (!Lazy(parsedIds).some()) {
                defer.reject({});
            } else {
                var actFilter = { ActIds: parsedIds };
                var actMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(this.dataContext.enumTypes.ActMassivelyUpdateRequestType, null, translator).obj.normal.name_value;
                this.actMassivelyUpdateRequestManage(contextService, actFilter, {}, actMassivelyUpdateRequestType.DeleteAct)
                    .then(() => {
                        defer.resolve(true);
                    }).catch(function (error) {
                        defer.reject(error);
                    });
            }

            return defer.promise;
        };

        this.actMassivelyUpdateRequestManage = function (contextService, actFilter, params, requestTypeId) {
            var defer = $q.defer();
            var actMassivelyUpdateRequestType = Nembus.Common.Library.util.parseEnum(this.dataContext.enumTypes.ActMassivelyUpdateRequestType, null, null).obj.normal.name_value;

            if (!params.WorkflowStatusId && requestTypeId !== actMassivelyUpdateRequestType.DeleteAct) {
                defer.reject({});
            } else {
                var actMassivelyUpdateRequest = this.dataContext.actMassivelyUpdateRequests.add({
                    WorkflowStatusId: requestTypeId === actMassivelyUpdateRequestType.DeleteAct ? null : params.WorkflowStatusId,
                    ExecutionNote: params.ExecutionNote,
                    ExecutionDate: params.ExecutionDate,
                    ActMassivelyUpdateRequestTypeId: requestTypeId,
                    ActFilterSerialized: JSON.stringify(actFilter),
                    VirtualCompanyId: contextService.virtualContext.CurrentVirtualCompanyId
                });

                this.manager.saveChanges()
                    .then(() => {
                        if (actMassivelyUpdateRequest.IsBatch) {
                            contextService.addBatchRequest({
                                requestId: actMassivelyUpdateRequest.BatchRequestId,
                                requestName: actMassivelyUpdateRequest.BatchRequestName,
                                requestStatus: 'inserted'
                            });
                        }

                        defer.resolve(true);
                    }).catch(function (error) {
                        defer.reject(error);
                    });
            }

            return defer.promise;
        };

        this.canModifyAct = function (filter) {
            var query = dataLayer.InvokeQuery("CanModifyAct", filter);
            return manager.executeQuery(query);
        };

        //#region DataManager
        this.getActDataManager = function (supportData, filter, dmObj) {
            if (dmObj) {
                dmObj.complexParams = [{ filter: filter }];
                return dmObj;
            }

            var actPriorityEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ActPriority, 'Act', supportData.translate);
            var actPriorityEnumObjReverse = actPriorityEnumParsed.obj.translated.value_name;
            var actStatusEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ActStatus, 'Act', supportData.translate);
            var actStatusEnumObjReverse = actStatusEnumParsed.obj.translated.value_name;
            var actTypeEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.ActType, 'Act', supportData.translate);
            var actTypeEnumObjReverse = actTypeEnumParsed.obj.translated.value_name;
            var workflowStatusKindEnumParsed = Nembus.Common.Library.util.parseEnum(me.dataContext.enumTypes.WorkflowStatusKind, 'WorkflowStatus', supportData.translate);
            var workflowStatusKindObjReverse = workflowStatusKindEnumParsed.obj.translated.value_name;

            if (supportData.isMyActs === true) {
                dmObj = me.getSearchMyActsPagedDmQuery();
                dmObj.dmgrid = me.getSearchMyActsPagedDm();
            } else {
                if (supportData.audService) {
                    dmObj = supportData.audService.getSearchActsPagedDmQuery();
                    dmObj.dmgrid = supportData.audService.getSearchActsPagedDm();
                } else {
                    dmObj = me.getSearchActsPagedDmQuery();
                    dmObj.dmgrid = me.getSearchActsPagedDm();
                }
            }

            var ejDataManager = dmObj.dmgrid;

            ejDataManager.complexParams.push({ filter: filter });
            ejDataManager.fieldNameReplacer = {
                ActPriorityName: 'ActPriorityId',
                ActTypeName: 'ActTypeId',
                ActWorkflowStatusKindName: 'ActWorkflowStatusKindId',
                ActAuthorName: 'ActAuthor.VirtualName',
                ActExecutorName: 'ActExecutor.VirtualName',
                ActTargetName: 'ActTarget.VirtualName',
                ActActionTypeName: 'ActActionType.ActActionTypeName',
                ActActionGroupName: 'ActActionGroup.ActActionGroupName'
            };
            // properties inserte are calculated before query is launched in breezeDataLayer service
            ejDataManager.onWhereCustomPredicate = {
                ActWorkflowStatusKindName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActWorkflowStatusKindId',
                    Datasource: Lazy(me.dataContext.enumTypes.WorkflowStatusKind).map((item) => { return { value: item.value, name: supportData.translate('WorkflowStatus.' + item.name) }; }).toArray()
                },
                ActPriorityName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActPriorityId',
                    Datasource: Lazy(me.dataContext.enumTypes.ActPriority).map((item) => { return { value: item.value, name: supportData.translate('Act.' + item.name) }; }).toArray()
                },
                ActTypeName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActTypeId',
                    Datasource: Lazy(me.dataContext.enumTypes.ActType).map((item) => { return { value: item.value, name: supportData.translate('Act.' + item.name) }; }).toArray()
                },
                ActAuthorName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActAuthorId',
                    Datasource: []
                },
                ActExecutorName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActExecutorId',
                    Datasource: []
                },
                ActTargetName: {
                    FieldType: this.FilterMenu.FieldType.Enum,
                    ReplaceFieldNameWith: 'ActTargetId',
                    Datasource: []
                }
            };

            ejDataManager.processResponseData = function (data) {
                if (data) {
                    dmObj.dm.currentViewChildGridData = setActChildgridDatasource(data.results, supportData);

                    data.results = me.getAttachedEntities(data.results);

                    Lazy(data.results).each((item) => {
                        if (!item.cardSupport) {
                            item.cardSupport = {
                                translations: {}
                            };
                        }

                        item.ActPriorityName = actPriorityEnumObjReverse[item.ActPriorityId];
                        item.ActTypeName = actTypeEnumObjReverse[item.ActTypeId];
                        item.ActStatusName = actStatusEnumObjReverse[item.ActStatusId];
                        item.ActWorkflowStatusKindName = workflowStatusKindObjReverse[item.ActWorkflowStatusKindId];
                        item.CategoriesUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.CategoriesSerialized));
                        item.UsersUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.UsersAssignedSerialized));
                        item.UserGroupsUI = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.UserGroupsAssignedSerialized));

                        // format custom fields for grid cards
                        item.cardSupport.ActExpireDate = Nembus.Common.Library.converter.date.formatDate(item.ActExpireDate, supportData.contextService.virtualContext.cultureInfo, true);
                        item.cardSupport.translations.expire = supportData.translate('Expire');
                        item.cardSupport.translations.priority = supportData.translate('Priority');
                        item.cardSupport.translations.status = supportData.translate('Status');
                    });
                    return data.results;
                }
            };

            ejDataManager.freeSearchFields = ['ActDescription', 'ActExecutionNote', 'ActAuthor.VirtualName', 'ActExecutor.VirtualName', 'ActTarget.VirtualName', 'ActActionType.ActActionTypeName', 'ActActionGroup.ActActionGroupName'];
            ejDataManager.freeSearchFieldsNumber = ['ActId'];

            dmObj.dm.onWhereCustomPredicate = ejDataManager.onWhereCustomPredicate;
            dmObj.dm.fieldNameReplacer = ejDataManager.fieldNameReplacer;
            dmObj.dm.complexParams = ejDataManager.complexParams;
            dmObj.dm.processResponseData = ejDataManager.processResponseData;
            return dmObj;
        }

        function setActChildgridDatasource(ds, supportData) {
            var dsChildGrid = [];
            Lazy(ds)
                .each((item) => {
                    // datasource for childgrid
                    if (item.ActSourceObjectSerialized) {
                        var actSourceObject = JSON.parse(Nembus.Common.Library.converter.decodeHtml(item.ActSourceObjectSerialized));
                        var actSourceObjectParsed = {};
                        actSourceObjectParsed.ActId = item.ActId;
                        actSourceObjectParsed.ActSourceObjectId = actSourceObject.ActSourceObjectId;
                        actSourceObjectParsed.ActSourceObjectCode = actSourceObject.ActSourceObjectCode;
                        actSourceObjectParsed.ActSourceObjectName = actSourceObject.ActSourceObjectName;
                        if (actSourceObjectParsed.ActSourceObjectCreationDate) {
                            actSourceObjectParsed.ActSourceObjectCreationDate = Nembus.Common.Library.converter.date.formatDateTime(actSourceObjectParsed.ActSourceObjectCreationDate, supportData.contextService.virtualContext.cultureInfo, false);
                        }
                        if (actSourceObject.ActSourceObjectTypeTranslationKey) {
                            actSourceObjectParsed.ActSourceObjectType = supportData.translate(actSourceObject.ActSourceObjectTypeTranslationKey);
                        }

                        dsChildGrid.push(actSourceObjectParsed);
                    }
                });

            return dsChildGrid;
        }
        //#endregion
    };

    actService.$inject = injectParams;
    actService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('actService', actService);
});