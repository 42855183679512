﻿'use strict';
define(['../../common/controllers/nbs-vm'], function (nbsVm) {

    var injectParams = ['$q', '$scope', '$timeout', '$injector', 'utilService', 'navigationService', 'checkStateChangeService', 'contextService'];
    function attachmentDetail($q, $scope, $timeout, $injector, utilService, navigationService, checkStateChangeService, contextService) {

        var vm = this;
        nbsVm.call(vm, utilService, navigationService, contextService, $q, $injector, $timeout);
        checkStateChangeService.checkFormOnStateChange($scope, utilService);

        var fsFolderId = navigationService.stateParams().fsFolderId;
        // viewKind => fmdauthcodes => icons private, public are visible
        var viewKindFmd = navigationService.stateParams().viewKindFmd;

        var canShare = vm.checkPermissionCode(Nembus.Rds.Library.auth.Permission['FMDPRIVATE'], contextService.virtualContext) > 0
            && vm.checkPermissionCode(Nembus.Rds.Library.auth.Permission['FMDPUBLIC'], contextService.virtualContext) > 0
            && viewKindFmd === 'fmdauthcodes';

        vm.attachmentsDataContext = {
            canShare: canShare,
            fsFolderId: fsFolderId
        };


        vm.refreshAction = function () {
            $timeout(() => {
                vm.attachmentsRefresh = new Date().getTime();
            });
        };


        var parentScope = $scope.$parent.vm;
        parentScope.ribbonDatacontext.disableAllRibbonStatusButtons();
        parentScope.ribbonDatacontext.setRibbonStatusButtonByName('Modify', true);
        parentScope.ribbonDatacontext.setRibbonStatusButtonByName('Refresh', true);
        parentScope.ribbonDatacontext.refreshStatusButtons();

        navigationService.setParentControllerActions(vm);
    }

    attachmentDetail.$inject = injectParams;
    attachmentDetail.prototype = Object.create(nbsVm.prototype);
    angular.module('nbs.rds.module').controller('attachmentDetail', attachmentDetail);
});