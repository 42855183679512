﻿'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

    var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

    var customerService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

        var dataLayer = breezeDataLayer.Initialize('customer', true);
        var manager = dataLayer.GetManager();

        var entityTypes = {
            CustomerData: 'CustomerData',
            CustomerContact: 'CustomerContact',
            CustomerBackup: 'CustomerBackup',
            CustomerBackupDto: 'CustomerBackupDto',
            CustomerService: 'CustomerService',
        };

        //#region Client validation
        var customerEmailClientData = manager.metadataStore.getEntityType(entityTypes.CustomerContact);
        customerEmailClientData.getProperty("CustomerContactEmail").validators.push(new breeze.Validator("emailAddress2", Nembus.Common.Library.validators.emailNullableFieldValidator));
        //#endregion

        this.entityTypes = entityTypes;
        nbsService.call(this, manager, $q, $timeout);

        this.dataContext = {
            customerDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.CustomerData),
            customerContacts: dataLayer.setContextMethods(manager, nbsService, entityTypes.CustomerContact),
            customerServices: dataLayer.setContextMethods(manager, nbsService, entityTypes.CustomerService),
            customerBackups: dataLayer.setContextMethods(manager, nbsService, entityTypes.CustomerBackup),
            customerBackupDtos: dataLayer.setContextMethods(manager, nbsService, entityTypes.CustomerBackupDto),

            enumTypes: {
                CustomerContactType: dataLayer.GetEnumType('CustomerContactType'),
                CustomerStorageType: dataLayer.GetEnumType('CustomerStorageType'),
                CustomerDataSyncMode: dataLayer.GetEnumType('CustomerDataSyncMode'),
                CustomerBackupType: dataLayer.GetEnumType('CustomerBackupType'),
            },

            constants: {}
        };

        this.createQuery = function (entityName) {
            return dataLayer.CreateQuery(entityName);
        };

        // CustomerData
        this.getCustomerData = function (filter, forceReload) {
            var params = { key: filter.CustomerDataId, dataContext: this.dataContext.customerDatas };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CustomerData");
        };
        this.getCustomerDatas = function (filter, forceReload) {
            var params = { entityType: entityTypes.CustomerData, cache: this.dataContext.customerDatas.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CustomerDatas");
        };

        // CustomerContact
        this.getCustomerContacts = function (filter, forceReload) {
            var params = { entityType: entityTypes.CustomerContact, cache: this.dataContext.customerContacts.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CustomerContact");
        };

        // CustomerService
        this.getCustomerService = function (filter, forceReload) {
            var params = { key: filter.CustomerServiceId, dataContext: this.dataContext.customerServices };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CustomerService");
        };
        this.getCustomerServices = function (filter, forceReload) {
            var params = { entityType: entityTypes.CustomerService, cache: this.dataContext.customerServices.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CustomerService");
        };

        // CustomerBackup
        this.getCustomerBackup = function (filter, forceReload) {
            var params = { key: filter.CustomerBackupId, dataContext: this.dataContext.customerBackups };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CustomerBackup");
        };
        this.getCustomerBackups = function (filter, forceReload) {
            var params = { entityType: entityTypes.CustomerBackup, cache: this.dataContext.customerBackups.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CustomerBackup");
        };

        // CustomerBackupDto
        this.getCustomerBackupDto = function (filter, forceReload) {
            var params = { key: filter.CustomerBackupId, dataContext: this.dataContext.customerBackupDtos };
            return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CustomerBackupDto");
        };
        this.getCustomerBackupDtos = function (filter, forceReload) {
            var params = { entityType: entityTypes.CustomerBackupDto, cache: this.dataContext.customerBackupDtos.get() };
            return dataLayer.GetDataListPromise(params, filter, forceReload, "CustomerBackupDto");
        };

        //Http Post
        this.updateCustomerData = function () {
            var query = dataLayer.InvokeQuery("UpdateCustomerData");
            return manager.executeQuery(query);
        };

        //Http Post
        this.sendCustomerDataToTracy = function () {
            var query = dataLayer.InvokeQuery("SendCustomerDataToTracy");
            return manager.executeQuery(query);
        };
    };

    customerService.$inject = injectParams;
    customerService.prototype = Object.create(nbsService.prototype);
    angular.module('nbs.rds.module').service('customerService', customerService);
});