﻿
'use strict';
define(['../../common/services/nbs-service'], function (nbsService) {

	var injectParams = ['$http', '$q', '$timeout', 'breeze', 'breezeDataLayer'];

	var cmstoolService = function ($http, $q, $timeout, breeze, breezeDataLayer) {

		var dataLayer = breezeDataLayer.Initialize('cmstool', true);
		var manager = dataLayer.GetManager();

		var entityTypes = {
			CmsBookmark: 'CmsBookmark',
			CmsStaticContent: 'CmsStaticContent',
			CmsBookmarkClientData: 'CmsBookmarkClientData'
		};

		this.entityTypes = entityTypes;

		//breeze client type
		//client validation
		breezeDataLayer.addType(
			manager.metadataStore,
			{
                name: entityTypes.CmsBookmarkClientData,
				dataProperties: {
					CmsBookmarkClientDataId: { type: breeze.DataType.Guid },
					CmsBookmarkName: { type: breeze.DataType.String }
				}
			},
			'Nembus.Domain'
		);

		var cmsBookmarkClientData = manager.metadataStore.getEntityType(entityTypes.CmsBookmarkClientData);
        cmsBookmarkClientData.getProperty("CmsBookmarkName").validators.push(new breeze.Validator("cmsBookmarkClientDataNameValidator", Nembus.Common.Library.validators.requiredFieldValidator));

		nbsService.call(this, manager, $q, $timeout);

		this.dataContext = {
            cmsBookmarkClientDatas: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsBookmarkClientData),
            cmsBookmarks: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsBookmark),
            cmsStaticContents: dataLayer.setContextMethods(manager, nbsService, entityTypes.CmsStaticContent),

			enumTypes: {
			},
			constant: {
			}
		};

		this.createQuery = function (entityName) {
			return dataLayer.CreateQuery(entityName);
		};

		this.getCmsBookmarks = function (filter, forceReload) {
			var params = { entityType: entityTypes.CmsBookmark, cache: this.dataContext.cmsBookmarks.get() };
			return dataLayer.GetDataListPromise(params, filter, forceReload, "CmsBookmarks");
		};

		this.getCmsStaticContents = function (filter, forceReload) {
			var params = { entityType: entityTypes.CmsStaticContent, cache: this.dataContext.cmsStaticContents.get() };
			return dataLayer.GetDataListPromise(params, filter, forceReload, "CmsStaticContents");
		};
		this.getCmsStaticContent = function (filter, forceReload) {
			var params = { key: filter.CmsStaticContentCode, dataContext: this.dataContext.cmsStaticContents };
			return dataLayer.GetDataDetailPromise(params, filter, forceReload, "CmsStaticContents");
		};
	};

	cmstoolService.$inject = injectParams;
	cmstoolService.prototype = Object.create(nbsService.prototype);
	angular.module('nbs.rds.module').service('cmstoolService', cmstoolService);
});